/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mediaQueries, { getBreakPoints } from 'css-in-js-media';
import { darken, lighten, transparentize } from 'polished';

import theme from './theme';
import { theme as llamaTheme } from '@karehero/llama';

export { styled };
export const media = mediaQueries;
export const breakpoints = getBreakPoints();
export const useThemeHook = () => {
  return llamaTheme;
};
export const styleHelpers = {
  darken,
  lighten,
  transparentize,
};

export function GlobalStyling({ children }) {
  return (
    <>
      {theme.typography.webFontUrls.map((font) => (
        <link key={font} href={font} rel='stylesheet' />
      ))}
      <div className='care-sourcer-container'>{children}</div>
    </>
  );
}

GlobalStyling.propTypes = {
  children: PropTypes.node.isRequired,
};
