import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// editor
import { Container, Title } from "../../atoms";
// components
import { InviteKeyRegistrationForm } from "../../molecules";
/**
 * InviteKeyRegistration allows registering via
 */
export const InviteKeyRegistration = ({ organization, onSubmit, }) => {
    const inviteKeyName = useMemo(() => organization.inviteKeyName || 'Payroll ID', [organization]);
    return (_jsx(Container, Object.assign({ size: 'md' }, { children: _jsxs(Wrapper, { children: [_jsxs(Title, { children: [organization.name, " Registration"] }), _jsxs(HelperText, { children: ["Register for a KareHero account with your ", inviteKeyName] }), _jsx(InviteKeyRegistrationForm, { inviteKeyLabel: inviteKeyName, isEmailRegistration: organization.isEmailRegistrationEnabled, onSubmit: (inviteKey, email) => onSubmit(organization.id, inviteKey, email) })] }) })));
};
const HelperText = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacing[64]};
`;
InviteKeyRegistration.displayName = 'InviteKeyRegistration';
export default InviteKeyRegistration;
