window.careSourcerSettings = window.careSourcerSettings || {
  bannerImageUrl: '/images/directory-hero.jpg',
};

const defaultCountryNotImplementedText =
  'Sorry, we have been unable to provide results in your local area. You may wish to try searching a different location';

// get client defined settings or use defaults
const {
  googleMapsApiKey = '',
  bannerImageUrl,
  countryNotImplementedText = defaultCountryNotImplementedText,
  onPageEvent = () => {},
} = window.careSourcerSettings;

const settings = {
  apiAddress: process.env.REACT_APP_API_ADDRESS,
  trackingApiAddress: process.env.REACT_APP_TRACKING_API_ADDRESS,
  hostingBaseUrl: process.env.REACT_APP_HOSTING_BASE_URL,
  googleMapsApiKey,
  bannerImageUrl,
  countryNotImplementedText,
  features: {},
  onPageEvent,
};

export default settings;
