import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useMemo, useState } from 'react';
// components
import { Tabs } from "../../atoms/Tabs/Tabs";
import { Title } from "../../atoms/Title/Title";
import { ProfilePreview } from "../../molecules/ProfilePreview/ProfilePreview";
import { CareCircle } from "../../molecules/CareCircle/CareCircle";
/**
 * CareCirclePage is the page for the Care Circle.
 */
export const CareCirclePage = ({ careCircle, onAdd }) => {
    // state
    const [activeTab, setActiveTab] = useState('overview');
    // memo
    const careRecipientName = useMemo(() => {
        var _a, _b;
        return `${(_a = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount) === null || _a === void 0 ? void 0 : _a.firstName} ${(_b = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount) === null || _b === void 0 ? void 0 : _b.lastName}`;
    }, [careCircle]);
    const careRecipientFallback = useMemo(() => {
        var _a, _b;
        return `${(_a = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount) === null || _a === void 0 ? void 0 : _a.firstName[0]}${(_b = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount) === null || _b === void 0 ? void 0 : _b.lastName[0]}`.toUpperCase();
    }, [careCircle]);
    const pluralName = useMemo(() => {
        if (!careRecipientName) {
            return "Care Recipient's";
        }
        if (careRecipientName === null || careRecipientName === void 0 ? void 0 : careRecipientName.endsWith('s')) {
            return `${careRecipientName}'`;
        }
        return `${careRecipientName}'s`;
    }, [careRecipientName]);
    const careCircleMembers = useMemo(() => {
        var _a;
        return (_a = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careCircleMembers) === null || _a === void 0 ? void 0 : _a.map((member) => {
            var _a, _b, _c, _d, _e;
            return (Object.assign(Object.assign({}, member.account), { roles: member.roles, id: ((_a = member === null || member === void 0 ? void 0 : member.account) === null || _a === void 0 ? void 0 : _a.id) || 'overview', fallback: `${(_c = (_b = member === null || member === void 0 ? void 0 : member.account) === null || _b === void 0 ? void 0 : _b.firstName) === null || _c === void 0 ? void 0 : _c[0]}${(_e = (_d = member === null || member === void 0 ? void 0 : member.account) === null || _d === void 0 ? void 0 : _d.lastName) === null || _e === void 0 ? void 0 : _e[0]}`.toUpperCase(), onClick: () => {
                    var _a;
                    setActiveTab(((_a = member === null || member === void 0 ? void 0 : member.account) === null || _a === void 0 ? void 0 : _a.id) || 'overview');
                } }));
        });
    }, [careCircle]);
    const tabs = useMemo(() => {
        return careCircleMembers === null || careCircleMembers === void 0 ? void 0 : careCircleMembers.map((member) => {
            var _a, _b;
            const fallback = `${(_a = member === null || member === void 0 ? void 0 : member.firstName) === null || _a === void 0 ? void 0 : _a[0]}${(_b = member === null || member === void 0 ? void 0 : member.lastName) === null || _b === void 0 ? void 0 : _b[0]}`.toUpperCase();
            return {
                id: member.id,
                label: `${member.firstName} ${member.lastName}`,
                content: (_jsx(ProfilePreview, { imageSrc: member.imgSrc, fallback: fallback, name: `${member.firstName} ${member.lastName}`, email: member.email, careCircle: careRecipientName, isActionsDisabled: true })),
            };
        });
    }, [careCircleMembers, careRecipientName]);
    return (_jsxs(_Fragment, { children: [_jsxs(Title, { children: [pluralName, " Care Circle"] }), careCircle && careCircleMembers && tabs && (_jsx(Tabs, { value: activeTab, onChange: (val) => setActiveTab(val), tabs: [
                    {
                        id: 'overview',
                        label: 'Overview',
                        content: (_jsx("div", Object.assign({ style: { display: 'flex', justifyContent: 'center' } }, { children: _jsx(CareCircle, { careRecipientMember: {
                                    id: careCircle.careRecipientAccount.id,
                                    account: Object.assign({}, careCircle.careRecipientAccount),
                                    fallback: careRecipientFallback,
                                }, members: careCircleMembers.map((member) => ({
                                    id: member.id,
                                    account: Object.assign({}, member),
                                    roles: member.roles,
                                    fallback: member.fallback,
                                })), onAdd: onAdd }) }))),
                    },
                    ...tabs,
                ] }))] }));
};
CareCirclePage.displayName = 'CareCirclePage';
export default CareCirclePage;
