// pdfmake
import { background, createPdf, pageSize } from 'pdf';

// assets
import { carePlanLogo } from 'pdf';

// store
import { CareProfileCategory } from '@karehero/models';

export const header = (title: string) => {
  return [
    {
      text: title,
      style: 'header',
      fontSize: 14,
      color: '#984670',
      bold: true,
      margin: [0, 40, 0, 0],
    },
    '',
  ];
};

export const row = (title: string, value: string) => {
  return [{ text: title, color: '#984670' }, { text: value }];
};

export const table = (body: any) => {
  return {
    layout: 'lightHorizontalLines',
    table: {
      headerRows: 1,
      widths: ['50%', '50%'],
      body: body,
    },
  };
};

export const flattenQuestion = (questions: any) => {
  let tmpQuestions: any[] = [];
  questions.forEach((question: any) => {
    tmpQuestions.push(question);
    if (question.careProfileSubQuestions.length > 0) {
      tmpQuestions = tmpQuestions.concat(
        flattenQuestion(question.careProfileSubQuestions),
      );
    }
  });
  return tmpQuestions;
};

const getAnswer = (
  careProfileCategories: CareProfileCategory[],
  answers: any,
  categoryId: string,
  subCategoryId: string,
  formId: string,
  questionId: string,
  sanitise?: string,
) => {
  let answer = answers?.[formId]?.[questionId];
  if (!answer) return '-';

  switch (sanitise) {
    case 'date':
    case 'date-of-birth':
      answer = new Date(answer).toLocaleDateString();
      break;
    case 'address':
      const addressObject = JSON.parse(answer);
      let addressString = '';
      if (addressObject?.addressLine1) {
        addressString += `${addressObject?.addressLine1}\n`;
      }
      if (addressObject?.addressLine2) {
        addressString += `${addressObject?.addressLine2}\n`;
      }
      if (addressObject?.addressLine3) {
        addressString += `${addressObject?.addressLine3}\n`;
      }
      if (addressObject?.city) addressString += `${addressObject?.city}\n`;
      if (addressObject?.postalCode) {
        addressString += `${addressObject?.postalCode}\n`;
      }

      // check if last two characters are new line
      if (addressString.slice(-2) === '\n') {
        addressString = addressString.slice(0, -2);
      }

      answer = addressString;
      break;
    case 'multi-select':
    case 'multi-select-chip':
    case 'multi-select-box':
      const selectArray = JSON.parse(answer);

      const questions = careProfileCategories
        ?.find((category) => category.id === categoryId)
        ?.careProfileSubCategories.find(
          (subCategory) => subCategory.id === subCategoryId,
        )
        ?.careProfileForms.find(
          (form) => form.id === formId,
        )?.careProfileQuestions;

      const options = flattenQuestion(questions).find(
        (question) => question.id === questionId,
      )?.fieldOptions;

      const labeledArray = selectArray.map((option: any) => {
        const optionLabel = options?.find(
          (optionItem: any) => optionItem.id === option,
        )?.label;
        if (!optionLabel) return option;
        return optionLabel;
      });

      let labeledArrayString = '';
      labeledArray.forEach((element: string) => {
        labeledArrayString += `${element}\n`;
      });

      // check if last two characters are new line
      if (labeledArrayString.slice(-2) === '\n') {
        labeledArrayString = labeledArrayString.slice(0, -2);
      }

      answer = labeledArrayString;
      break;
    case 'boolean':
      answer = answer === 'true' ? 'Yes' : 'No';
      break;
    case 'select':
      const selectQuestions = careProfileCategories
        ?.find((category) => category.id === categoryId)
        ?.careProfileSubCategories.find(
          (subCategory) => subCategory.id === subCategoryId,
        )
        ?.careProfileForms.find(
          (form) => form.id === formId,
        )?.careProfileQuestions;

      answer = flattenQuestion(selectQuestions)
        .find((question) => question.id === questionId)
        ?.fieldOptions.find((option: any) => option.id === answer)?.label;
      break;
    case 'radio':
      const radioQuestions = careProfileCategories
        ?.find((category) => category.id === categoryId)
        ?.careProfileSubCategories.find(
          (subCategory) => subCategory.id === subCategoryId,
        )
        ?.careProfileForms.find(
          (form) => form.id === formId,
        )?.careProfileQuestions;

      answer = flattenQuestion(radioQuestions)
        .find((question) => question.id === questionId)
        ?.fieldOptions.find((option: any) => option.id === answer)?.label;
      break;
    case 'slider-severity':
      let tmpAnswer = '';
      switch (answer) {
        case '1':
          tmpAnswer = '1 (None)';
          break;
        case '2':
          tmpAnswer = '2 (Mild)';
          break;
        case '3':
          tmpAnswer = '3 (Moderate)';
          break;
        case '4':
          tmpAnswer = '4 (Severe)';
          break;
        case '5':
          tmpAnswer = '5 (Critical)';
          break;
      }
      answer = tmpAnswer;
      break;
    case 'boolean-table':
      const booleanTableObject = JSON.parse(answer);
      let tableString = '';
      Object.keys(booleanTableObject).forEach((row) => {
        const columns = Object.keys(booleanTableObject[row]);
        let rowString = `${row}: `;
        columns.forEach((column) => {
          rowString += `${column}, `;
        });
        rowString = rowString.slice(0, -2);
        tableString += `${rowString}\n`;
      });
      answer = tableString;
      break;
  }

  return answer;
};

export const generateTableDefs = (
  careProfileCategories: CareProfileCategory[],
  answers: any,
  firstName: string = 'the care recipient',
) => {
  let tableDefs: any[] = [];
  careProfileCategories.forEach((category) => {
    if (category.id !== 'care-plan') return;
    category.careProfileSubCategories.forEach((subCategory) => {
      subCategory.careProfileForms.forEach((form) => {
        let tableDef: any[] = [
          header(form.title.replace('{careRecipient}', firstName)),
        ];
        const addQuestions = (questions: any) => {
          questions.forEach((question: any) => {
            tableDef.push(
              row(
                question.label.replace('{careRecipient}', firstName),
                getAnswer(
                  careProfileCategories,
                  answers,
                  category.id,
                  subCategory.id,
                  form.id,
                  question.id,
                  question.fieldType,
                ),
              ),
            );
            if (
              question.careProfileSubQuestions &&
              question.careProfileSubQuestions.length > 0
            ) {
              addQuestions(question.careProfileSubQuestions);
            }
          });
        };
        form?.careProfileQuestions && addQuestions(form.careProfileQuestions);
        tableDefs.push(table(tableDef));
      });
    });
  });
  return tableDefs;
};

export const careProfileDocDefinition = (
  careProfileCategories: CareProfileCategory[],
  answers: any,
  careRecipientName: string = 'the care recipient',
) => {
  const firstName =
    careRecipientName !== 'the care recipient'
      ? careRecipientName.split(' ')[0]
      : 'the care recipient';

  const tableDefs = generateTableDefs(
    careProfileCategories,
    answers,
    firstName,
  );

  return createPdf({
    content: [
      {
        image: 'logo',
        width: 200,
        alignment: 'center',
      },
      table([header(`About ${firstName}`), row('Name', careRecipientName)]),
      ...tableDefs,
    ],
    images: {
      logo: carePlanLogo,
    },
    pageSize: pageSize,
    background: background('#fcf9f5'),
  });
};
