import { useContext } from 'react';
import PropTypes from 'prop-types';

import Column from '../../components/primitives/Column';
import Checkbox from '../../components/primitives/form/Checkbox';

import { ProviderFilterContext } from '../../context/ProviderFilterContext';

import Spacer from '../../components/primitives/Spacer';

export default function CareProvidedFilters({ disabled }) {
  const {
    activeFilters: { careProvidedFilters },
    careProvidedTypes,
    toggleCareProvidedFilter,
  } = useContext(ProviderFilterContext);

  return (
    <Column>
      {careProvidedTypes.map((care, i) => (
        <Spacer top={i > 0} key={care}>
          <Checkbox
            name={care}
            label={care}
            value={care}
            checked={careProvidedFilters[care]}
            onChange={toggleCareProvidedFilter}
            disabled={disabled}
          />
        </Spacer>
      ))}
    </Column>
  );
}

CareProvidedFilters.propTypes = {
  disabled: PropTypes.bool,
};

CareProvidedFilters.defaultProps = {
  disabled: false,
};
