import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { Icon, LoadingTransition, Skeleton } from "..";
// utils
import { buildImageUrl } from "../../../utils/sanity";
/**
 * ArticleCard is a container for the content of a Card.
 */
export const ArticleCard = ({ className, article, onBookmark, isBookmarked = false, isLoading, }) => {
    var _a, _b;
    // memos
    const headerImageUrl = useMemo(() => buildImageUrl(article === null || article === void 0 ? void 0 : article.headerImageUrl, 500), [article]);
    return (_jsxs(ContentItem, Object.assign({ className: className, to: `/library/${(_a = article === null || article === void 0 ? void 0 : article.slug) === null || _a === void 0 ? void 0 : _a.current}` }, { children: [_jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(ContentHeader, { children: _jsx(Skeleton, { shape: 'header', isSquare: true }) }), content: _jsx(ContentHeader, { children: _jsx("img", { src: headerImageUrl, alt: 'header', onError: ({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src =
                                'https://cdn.sanity.io/images/x7r1t5o8/production/e8579e2a4c3766cf0cf99a24a9d0679d2d2bbeb4-5322x2365.jpg?h=150';
                        } }) }) }), _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsxs(ContentBody, { children: [_jsx(Skeleton, { shape: 'subtitle' }), _jsx(Skeleton, { shape: 'paragraph', paragraphLength: 3 })] }), content: _jsxs(ContentBody, { children: [_jsxs(ContentBodyHeader, { children: [_jsx(ContentTags, { children: (_b = article === null || article === void 0 ? void 0 : article.tags) === null || _b === void 0 ? void 0 : _b.map((tag, i) => {
                                        var _a;
                                        return (_jsxs(React.Fragment, { children: [_jsx(ContentTag, { children: tag.label }), (article === null || article === void 0 ? void 0 : article.tags) && i !== ((_a = article === null || article === void 0 ? void 0 : article.tags) === null || _a === void 0 ? void 0 : _a.length) - 1 && (_jsx("div", { children: "-" }))] }, `${article.title}-${tag.label}`));
                                    }) }), _jsx(BookmarkWrapper, Object.assign({ "$isBookmarked": isBookmarked, onClick: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        article && (onBookmark === null || onBookmark === void 0 ? void 0 : onBookmark(article));
                                    } }, { children: _jsx(Icon, { icon: 'bookmark', type: isBookmarked ? 'fas' : 'fal' }) }))] }), _jsx(ContentTitle, { children: article === null || article === void 0 ? void 0 : article.title }), _jsx(ContentDescription, { children: article === null || article === void 0 ? void 0 : article.description })] }) })] })));
};
const ContentItem = styled(Link) `
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-width: 250px;
  height: 300px;
  max-height: 300px;
  box-sizing: border-box;
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[1]};
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
  text-decoration: none;

  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;
const ContentHeader = styled.div `
  min-height: 150px;
  height: 150px;
  max-height: 150px;
  display: flex;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
const ContentBody = styled.div `
  padding: ${(props) => props.theme.spacing[16]}
    ${(props) => props.theme.spacing[12]};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]};
  max-height: 150px;
`;
const ContentBodyHeader = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[8]};
`;
const ContentTags = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  color: ${(props) => props.theme.color.neutral[70]};
  overflow: hidden;
`;
const ContentTag = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[70]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ContentTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.semibold};
  color: ${(props) => props.theme.color.neutral[100]};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const ContentDescription = styled.div `
  display: -webkit-box;
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
const BookmarkWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 99999px;
  color: ${(props) => props.theme.color.neutral[80]};

  ${(props) => props.$isBookmarked &&
    css `
      color: ${props.theme.color.primary[30]};
    `}

  transition: background 0.2s;
  &:hover {
    background: ${(props) => props.theme.color.neutral[30]};
  }
`;
ArticleCard.displayName = 'ArticleCard';
export default ArticleCard;
