import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { css } from 'styled-components';
import { Button, Icon } from "..";
import { LoadingTransition } from "../LoadingTransition/LoadingTransition";
import { Skeleton } from "../Skeleton/Skeleton";
/**
 * ServiceCard is a container for Service content.
 */
export const ServiceCard = ({ className, title, description1, description2, icon, size = 'md', isLoading = true, toBookCall, toLearnMore, }) => {
    return (_jsxs(StyledServiceCard, Object.assign({ className: className, "$size": size, "$isLoading": isLoading }, { children: [_jsx(StyledIcon, { children: _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Skeleton, { shape: 'avatar', width: '40px', height: '40px' }), content: icon && _jsx(Icon, { icon: icon, fontSize: 40 }) }) }), _jsxs(Body, { children: [_jsx(Gap, {}), _jsx(Title, { children: _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Skeleton, { width: '200px', shape: 'title' }), content: title }) }), _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Skeleton, { shape: 'subtitle' }), content: _jsx(Description, { children: description1 }) }), _jsx(HoverContent, { children: _jsx(Description, { children: description2 }) })] }), _jsxs(ButtonWrapper, { children: [_jsx(Button, Object.assign({ variant: 'secondary', size: 'md', ariaLabel: 'Learn more', to: toLearnMore }, { children: "Learn more" })), _jsx(Button, Object.assign({ variant: 'primary', size: 'md', ariaLabel: 'Learn more', to: toBookCall }, { children: "Get in touch" }))] })] })));
};
const StyledServiceCard = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.color.neutral[10]};
  border: 1px solid ${(props) => props.theme.color.neutral[60]};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing[32]};
  box-shadow: ${(props) => props.theme.elevation[2]};
  box-sizing: border-box;
  transition-duration: 0.2s;
  width: 100%;
  overflow: hidden;

  ${(props) => props.$isLoading && 'pointer-events: none'};

  ${(props) => {
    switch (props.$size) {
        case 'sm':
            return css `
          height: 280px;
        `;
        case 'md':
            return css `
          height: 340px;
        `;
    }
}}

  &:hover {
    box-shadow: ${(props) => props.theme.elevation[5]};
    transform: scale(1.02);

    ${(props) => props.$size === 'sm' &&
    css `
        height: 340px;
      `}
  }
`;
const Gap = styled.div `
  flex: 1;
  transition-duration: 0.2s;
  ${StyledServiceCard}:hover & {
    flex: 0;
  }
`;
const StyledIcon = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  margin-bottom: ${(props) => props.theme.spacing[12]};
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;

  transition-duration: 0.2s;
  gap: ${(props) => props.theme.spacing[12]};

  ${StyledServiceCard}:hover & {
    padding-bottom: 110px;
  }
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[70]};

  min-height: 0;
`;
const HoverContent = styled.div `
  transition-duration: 0.2s;
  min-height: 0;
  opacity: 0;
  flex: 0;
  transition-duration: 0.2s;
  ${StyledServiceCard}:hover & {
    opacity: 1;
    flex: 1;
  }
`;
const ButtonWrapper = styled.div `
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  gap: ${(props) => props.theme.spacing[16]};

  overflow: hidden;

  padding: ${(props) => props.theme.spacing[32]};
  width: 100%;

  transition-duration: 0.2s;
  opacity: 0;

  pointer-events: none;

  ${StyledServiceCard}:hover & {
    opacity: 1;
    pointer-events: auto;
  }

  & > button {
    flex: 1;
  }
`;
ServiceCard.displayName = 'ServiceCard';
export default ServiceCard;
