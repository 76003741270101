// react
import { useCallback, useEffect, useMemo, useState } from 'react';
/**
 * useValidator is a hook that provides a way to validate a form.
 */
export const useValidator = (setIsValid) => {
    // state
    const [validity, setValidity] = useState({});
    // memos
    const isValid = useMemo(() => Object.values(validity).every((v) => v), [validity]);
    // methods
    const createSetIsValid = useCallback((key) => {
        return (isValid) => setValidity((prev) => {
            if (prev[key] === isValid)
                return prev;
            return Object.assign(Object.assign({}, prev), { [key]: isValid });
        });
    }, []);
    // memos
    const memoizedCreateSetIsValid = useMemo(() => {
        const cache = {};
        return (key) => {
            if (!cache[key]) {
                cache[key] = createSetIsValid(key);
            }
            return cache[key];
        };
    }, [createSetIsValid]);
    // effects
    useEffect(() => {
        if (!setIsValid)
            return;
        const isValid = Object.values(validity).every((v) => v);
        setIsValid(isValid);
    }, [validity, setIsValid]);
    return {
        isValid,
        validity,
        createSetIsValid: memoizedCreateSetIsValid,
    };
};
