import PropTypes from 'prop-types';

import IconLabel from '../../../primitives/IconLabel';
import { icons } from '../../../primitives/Icon';
import { useThemeHook, styled, media } from '../../../styling';
import Row from '../../../primitives/Row';
import Spacer from '../../../primitives/Spacer';

const SpecialismContainer = styled.div`
  width: 50%;
  ${media('<tablet')} {
    width: 100%;
  }
`;

export function Specialism({ name }) {
  const { colour } = useThemeHook();
  return (
    <SpecialismContainer>
      <Spacer doubleTop>
        <IconLabel
          icon={icons.check}
          description={name}
          colour={colour.primary}
        />
      </Spacer>
    </SpecialismContainer>
  );
}

Specialism.propTypes = {
  name: PropTypes.string.isRequired,
};

export default function Specialisms({ specialisms }) {
  return (
    <Row flexWrap>
      {specialisms.map((name) => (
        <Specialism key={name} name={name} />
      ))}
    </Row>
  );
}

Specialisms.propTypes = {
  specialisms: PropTypes.arrayOf(PropTypes.string).isRequired,
};
