import PropTypes from 'prop-types';
import { ColourIcon, icons } from '../Icon';

import { useThemeHook } from '../../styling';

import HiddenInput from './components/HiddenInput';
import LabelText from './components/LabelText';
import Container from './components/Container';

const Radio = ({ name, value, label, checked, onChange, disabled }) => {
  const { colour } = useThemeHook();
  const iconColour = disabled ? colour.status.disabled : colour.primary;

  return (
    <HiddenInput
      type='radio'
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    >
      {(focused) => (
        <Container focused={focused} disabled={disabled}>
          {checked && (
            <ColourIcon icon={icons.circleFilled} colour={iconColour} />
          )}
          {!checked && <ColourIcon icon={icons.circle} colour={iconColour} />}
          <LabelText focused={focused} title={label} disabled={disabled}>
            {label}
          </LabelText>
        </Container>
      )}
    </HiddenInput>
  );
};

Radio.propTypes = {
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  checked: false,
  disabled: false,
};

export default Radio;
