import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Loader } from "../../atoms";
/**
 * Loading is a component that renders a loading page.
 */
export const Loading = ({ className, isEasterEgg }) => {
    return (_jsx(Wrapper, { children: _jsx(Loader, { className: className, isEasterEgg: isEasterEgg }) }));
};
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
Loading.displayName = 'Loading';
export default Loading;
