import { createContext } from 'react';
import PropTypes from 'prop-types';

import settings from '../settings';

export const GlobalSettingsContext = createContext();

export const globalSettings = settings;

export const GlobalSettingsProvider = ({ children }) => (
  <GlobalSettingsContext.Provider value={settings}>
    {children}
  </GlobalSettingsContext.Provider>
);

GlobalSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
