import PropTypes from 'prop-types';

import { styled } from '../styling';
import { renderIf } from '../RenderIf';
import { ColourIcon, icons } from './Icon';
import Row from './Row';
import Column from './Column';
import Spacer from './Spacer';
import InlineText from './typography/InlineText';
import Strong from './typography/Strong';
import Paragraph from './typography/Paragraph';

const Box = styled.div`
  border: 1px solid ${({ theme }) => theme.colour.primary};
  padding: ${({ theme }) =>
    `${theme.spacing.units(6)} ${theme.spacing.units(4)}`};
  margin: ${({ theme }) => `${theme.spacing.units(6)} 0`};
  position: relative;
`;

const BeginQuote = styled(ColourIcon)`
  position: absolute;
  font-size: ${({ theme }) => theme.spacing.units(12)};
  padding: 0 ${({ theme }) => theme.spacing.units(2)};
  top: ${({ theme }) => theme.spacing.units(-6)};
  left: ${({ theme }) => theme.spacing.units(2)};
  background-color: ${({ theme }) => theme.colour.neutral.light};
`;

const EndQuote = styled(ColourIcon)`
  position: absolute;
  font-size: ${({ theme }) => theme.spacing.units(12)};
  padding: 0 ${({ theme }) => theme.spacing.units(2)};
  bottom: ${({ theme }) => theme.spacing.units(-6)};
  right: ${({ theme }) => theme.spacing.units(2)};
  background-color: ${({ theme }) => theme.colour.neutral.light};
`;

export default function Quote({ children, author, title }) {
  const RenderAuthor = renderIf(author || title);
  return (
    <div>
      <Box>
        <BeginQuote icon={icons.quoteBegin} />
        <Paragraph largeLineHeight>{children}</Paragraph>
        <RenderAuthor>
          <Row reverse>
            <Spacer top>
              <Column>
                <Strong>{author}</Strong>
                <InlineText>{title}</InlineText>
              </Column>
            </Spacer>
          </Row>
        </RenderAuthor>
        <EndQuote icon={icons.quoteEnd} />
      </Box>
    </div>
  );
}

Quote.propTypes = {
  children: PropTypes.node.isRequired,
  author: PropTypes.string,
  title: PropTypes.string,
};

Quote.defaultProps = {
  author: undefined,
  title: undefined,
};
