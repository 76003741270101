import { styled } from '../../styling';

const Heading = styled.h1`
  margin: 0;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colour.neutral.dark};
  font-weight: bold;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeading};
`;

export default Heading;
