import { useContext } from 'react';
import { toPairs, compose, filter, map, join } from 'ramda';

import { GlobalSettingsContext } from '../../context/GlobalSettingsContext';
import useMakeApiRequest from './useMakeApiRequest';

const careTypeToSnakeCase = {
  careHome: 'care_home',
  homeCare: 'home_care',
};

const whereEnabled = ([, value]) => value;
const pairToKey = ([key]) => key;
const toCareProvidedParam = (value) =>
  `&care_provided[]=${value.toLowerCase()}`;
const toRegulatorRatingParam = (value) => `&regulator_rating[]=${value}`;
const toHourlyRateRangeParam = (value) => `&hourly_rate_ranges[]=${value}`;
const makeDictionaryProcessor = (outputFn) =>
  compose(
    join(''),
    map(outputFn),
    map(pairToKey),
    filter(whereEnabled),
    toPairs,
  );
const dictToHourlyRateParams = makeDictionaryProcessor(toHourlyRateRangeParam);
const dictToRegulatorRatingParams = makeDictionaryProcessor(
  toRegulatorRatingParam,
);

export default function useMakeSearchRequest() {
  const { get } = useMakeApiRequest();
  const { apiAddress } = useContext(GlobalSettingsContext);

  const makeSearchRequest = ({
    careType,
    searchTerm,
    currentDistance,
    enabledCareProvidedFilters,
    page,
    enabledSortOrder,
    enabledCareVisitDuration,
    enabledRegulatorRatings = {},
    enabledHourlyRates = {},
  }) => {
    const resultsPerRequest = 10;
    const perPageParam = `per_page=${resultsPerRequest}`;
    const pageParam = `page=${page || 1}`;
    const careTypeParam = `care_type=${careTypeToSnakeCase[careType]}`;
    const locationParam = `location=${searchTerm}`;
    const distanceParam = `distance=${currentDistance}`;
    const careHomeCareProvidedParam = enabledCareProvidedFilters
      .map(toCareProvidedParam)
      .join('');
    const sortByParam = `sort_by=${enabledSortOrder}`;

    const homeCareDurationParam = `&minimum_visit_time=${enabledCareVisitDuration}`;
    const homeCareHourlyRatesParam = dictToHourlyRateParams(enabledHourlyRates);
    const regulatorsRatingParam = dictToRegulatorRatingParams(
      enabledRegulatorRatings,
    );

    const baseUri = `profiles?${pageParam}&${sortByParam}&${perPageParam}&${careTypeParam}&${locationParam}&${distanceParam}${regulatorsRatingParam}`;
    const homeCareUri = `${baseUri}${homeCareDurationParam}${homeCareHourlyRatesParam}`;
    const careHomeUri = `${baseUri}${careHomeCareProvidedParam}`;

    const uri = {
      careHome: careHomeUri,
      homeCare: homeCareUri,
    }[careType];

    return get(`${apiAddress}/${uri}`);
  };

  return [makeSearchRequest];
}
