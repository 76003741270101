import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// theme
import theme from "../../../../theme";
// hooks
import { useWindowSize } from 'hooks';
// assets
// @transform-path ../../../../../../../src/assets/chat/speech-bubble.svg
import speechBubble from "../../../../../../../src/assets/chat/speech-bubble.svg";
/**
 * Launcher shows the chat launcher.
 */
export const Launcher = ({ isVisible, isApple, isOpen, setIsOpen, }) => {
    // hooks
    const windowSize = useWindowSize();
    // memo
    const isMobile = useMemo(() => {
        return windowSize[0] <= theme.breakpoint.smValue;
    }, [windowSize]);
    return (_jsx(Wrapper, Object.assign({ "$isVisible": isVisible && !isOpen, "$isMobile": isMobile, "$isApple": isApple, onClick: () => setIsOpen(true) }, { children: _jsx("img", { src: speechBubble, alt: 'chat-launcher' }) })));
};
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.color.custom.ai};
  color: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[4]};
  border-radius: 99999px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition:
    transform 0.1s ease-in-out,
    opacity 0.2s ease-in-out;
  z-index: 100000;
  &:hover {
    transform: scale(1.1);
  }

  background-size: 200% 200%;
  animation: gradient 5s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  ${(props) => !props.$isVisible &&
    css `
      pointer-events: none;
      opacity: 0;
    `}

  ${(props) => props.$isMobile &&
    css `
      bottom: 86px;
    `}

  ${(props) => props.$isApple &&
    css `
      bottom: 107px;
    `}
`;
Launcher.displayName = 'Launcher';
export default Launcher;
