import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
/**
 * EmailPreview renders an email preview.
 */
export const EmailPreview = ({ className, subject = 'Subject', html = '', isReadOnly = true, }) => {
    // refs
    const iframeRef = useRef(null);
    // effects
    useEffect(() => {
        const adjustIFrameHeight = () => {
            const iframe = iframeRef.current;
            if (iframe && iframe.contentDocument && iframe.contentDocument.body) {
                const contentHeight = iframe.contentDocument.body.scrollHeight;
                iframe.style.height = `${contentHeight}px`;
            }
        };
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.addEventListener('load', adjustIFrameHeight);
            adjustIFrameHeight();
            setTimeout(adjustIFrameHeight, 100);
        }
        return () => {
            if (iframe)
                iframe.removeEventListener('load', adjustIFrameHeight);
        };
    }, [html]);
    return (_jsxs(Wrapper, Object.assign({ className: className, "$isReadOnly": isReadOnly }, { children: [_jsx(Subject, { children: subject }), _jsx(StyledIFrame, { scrolling: 'no', srcDoc: html, ref: iframeRef })] })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  border: 1px solid ${(props) => props.theme.color.neutral[50]};
  padding: ${(props) => props.theme.spacing[24]};
  border-radius: 16px;
  background: ${(props) => props.theme.color.neutral[10]};
  min-height: min-content;

  ${(props) => props.$isReadOnly &&
    `
    pointer-events: none;
  `}
`;
const Subject = styled.div `
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const StyledIFrame = styled.iframe `
  display: block;
  height: 100%;
  border: 0;
  margin: 0;
`;
EmailPreview.displayName = 'EmailPreview';
export default EmailPreview;
