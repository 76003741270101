import PropTypes from 'prop-types';

import { styled } from '../styling';

const Picture = styled.picture``;

const Img = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  ${({ blurUpSrc }) =>
    blurUpSrc &&
    `
    background-image: url('${blurUpSrc}');
    background-size: cover;
    background-repeat: no-repeat;
  `}

  :focus {
    outline-color ${({ theme }) => theme.colour.primary};
  }
`;

function Image({
  sources = [],
  fallback,
  alt,
  className,
  blurUpSrc,
  onClick,
  tabIndex,
  onKeyDown,
}) {
  return (
    <Picture>
      {sources.map(({ src, minWidthPx }) => (
        <source
          key={src}
          srcSet={src}
          media={minWidthPx && `(min-width: ${minWidthPx}px)`}
        />
      ))}
      <Img
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
        onClick={onClick}
        src={fallback}
        alt={alt}
        className={className}
        blurUpSrc={blurUpSrc}
        loading='lazy'
      />
    </Picture>
  );
}

export default Image;

Image.propTypes = {
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      minWidthPx: PropTypes.number,
    }),
  ).isRequired,
  fallback: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  blurUpSrc: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
};

Image.defaultProps = {
  className: undefined,
  blurUpSrc: undefined,
  onClick: () => {},
  tabIndex: undefined,
  onKeyDown: () => {},
};
