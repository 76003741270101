// react
import { createSlice } from '@reduxjs/toolkit';
import careCircleApi from 'store/api/careCircle';
import { CareCircle, CareCircleMember } from '@karehero/models';

export interface CareCircleState {
  currentCareCircleId: string | null;
  currentCareCircle: CareCircle | null;
  currentCareCircleMember: CareCircleMember | null;
}

export const careCircleSlice = createSlice({
  name: 'careCircle',
  initialState: {
    currentCareCircleId: null,
  } as CareCircleState,
  reducers: {
    setCurrentCareCircleId: (state, { payload }) => {
      state.currentCareCircleId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        careCircleApi.endpoints.getByAccount.matchFulfilled,
        (state, { payload }) => {
          if (!payload) return;

          const careCircle = payload[0];
          state.currentCareCircleId = careCircle.id;
          state.currentCareCircle = careCircle;
        },
      )
      .addMatcher(
        careCircleApi.endpoints.getSessionCareCircle.matchFulfilled,
        (state, { payload }) => {
          if (!payload) return;

          state.currentCareCircleId = payload.id;
          state.currentCareCircle = payload;
        },
      )
      .addMatcher(
        careCircleApi.endpoints.getSessionCareCircleMember.matchFulfilled,
        (state, { payload }) => {
          if (!payload) return;

          state.currentCareCircleMember = payload;
        },
      );
  },
});

export const { setCurrentCareCircleId } = careCircleSlice.actions;

export const getCurrentCareCircleId = (state: any) =>
  state.careCircle.currentCareCircleId;
export const getCurrentCareCircle = (state: any) =>
  state.careCircle.currentCareCircle;
export const getCurrentCareCircleMember = (state: any) =>
  state.careCircle.currentCareCircleMember;

export default careCircleSlice.reducer;
