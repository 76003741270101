import { useContext } from 'react';
import PropTypes from 'prop-types';

import TextBox from '../../components/primitives/form/TextBox';

import { SearchContext } from '../../context/SearchContext';
import useKeyboardEventHandler from '../../hooks/useKeyboardEventHandler';
import useProviderSearch from '../../hooks/useProviderSearch';

export default function SearchField({ grow }) {
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const { search } = useProviderSearch();
  const { onKeyboardEvent } = useKeyboardEventHandler({
    Enter: () => search(),
  });

  return (
    <TextBox
      placeholder="e.g 'Newcastle' or 'SW1A 1AA'"
      ariaLabel="e.g 'Newcastle' or 'SW1A 1AA'"
      onChange={setSearchTerm}
      value={searchTerm}
      grow={grow}
      keyboardEventHandler={onKeyboardEvent}
    />
  );
}

SearchField.propTypes = {
  grow: PropTypes.bool,
};

SearchField.defaultProps = {
  grow: false,
};
