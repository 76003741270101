import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Title, Button } from "../../atoms";
export const DeleteAccount = ({}) => {
    return (_jsxs(Content, { children: [_jsx(Title, { children: "Delete account" }), _jsxs(HelperText, { children: ["If you'd like to delete your account, feel free to get in touch with us at \u00A0", _jsx(Button, Object.assign({ variant: 'link', size: 'md', isInline: true, ariaLabel: 'here', to: `mailto://${process.env.REACT_APP_SUPPORT_EMAIL}` }, { children: process.env.REACT_APP_SUPPORT_EMAIL })), ".", _jsx("br", {}), " Don't hesitate to reach out if you have any questions or concerns.", _jsx("br", {}), " We're here to help!"] })] }));
};
const Content = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[24]};
  margin: ${(props) => props.theme.spacing[24]};
  margin-top: ${(props) => props.theme.spacing[96]};
`;
const HelperText = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  text-align: center;
`;
DeleteAccount.displayName = 'AccountDelete';
export default DeleteAccount;
