import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
/**
 * NavBarTemplate is a template for the NavBar component.
 */
export const NavBarTemplate = ({ version, navBar, content, banner, }) => {
    return (_jsxs(StyledNavBarTemplate, { children: [version && _jsx(StyledNavBar, { children: version }), navBar && _jsx(StyledNavBar, { children: navBar }), banner && _jsx(StyledNavBar, { children: banner }), _jsx(StyledContent, { children: content })] }));
};
const StyledNavBarTemplate = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const StyledNavBar = styled.div `
  position: sticky;
  top: 0;
  z-index: 1;
  flex: 0 0 auto;
`;
const StyledContent = styled.div `
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
`;
NavBarTemplate.displayName = 'NavBarTemplate';
export default NavBarTemplate;
