import { useContext } from 'react';

import Column from '../../components/primitives/Column';
import Checkbox from '../../components/primitives/form/Checkbox';
import Spacer from '../../components/primitives/Spacer';
import InlineText from '../../components/primitives/typography/InlineText';

import { ProviderFilterContext } from '../../context/ProviderFilterContext';
import { SearchResultsContext } from '../../context/SearchResultsContext';

export default function RegulatorsRatingFilters() {
  const {
    regulatorRatings,
    activeFilters: { enabledRegulatorRatings },
    toggleRegulatorRating,
  } = useContext(ProviderFilterContext);
  const { lastSearchRegulator } = useContext(SearchResultsContext);

  const ratings = regulatorRatings[lastSearchRegulator];

  if (!ratings) {
    return <InlineText>Unknown regulatory body</InlineText>;
  }

  return (
    <Column>
      {ratings.map(({ label, weighting }) => (
        <Spacer bottom key={label}>
          <Checkbox
            name={label}
            label={label}
            value={label}
            checked={enabledRegulatorRatings[weighting]}
            onChange={() => toggleRegulatorRating(weighting)}
          />
        </Spacer>
      ))}
    </Column>
  );
}
