import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
// components
import { Logo } from "../../atoms";
import { Container } from "../../atoms/Container/Container";
import { CarouselIndicator } from "../../atoms/CarouselIndicator/CarouselIndicator";
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import { theme } from "../../../theme";
import { css } from 'styled-components';
// assets
// @transform-path ../../../../../../src/assets/onboarding/filing-cabinet.svg
import filingCabinet from "../../../../../../src/assets/onboarding/filing-cabinet.svg";
// @transform-path ../../../../../../src/assets/onboarding/relaxed.svg
import relaxed from "../../../../../../src/assets/onboarding/relaxed.svg";
// @transform-path ../../../../../../src/assets/onboarding/pocket.svg
import pocket from "../../../../../../src/assets/onboarding/pocket.svg";
const assetOrder = [filingCabinet, pocket, relaxed];
/**
 * OnboardingCarousel renders the carousel onboarding views.
 */
export const OnboardingCarousel = toGenericOnboarding(({ config, currentIndex = 0, setIsNextDisabled, }) => {
    var _a, _b, _c;
    // hooks
    const [width] = useWindowSize();
    // memo
    const isMobile = useMemo(() => width < theme.breakpoint.mdValue, [width]);
    // effects
    useEffect(() => {
        setIsNextDisabled(false);
    }, [setIsNextDisabled]);
    return (_jsxs(Main, { children: [_jsxs(ImageWrapper, { children: [_jsx("img", { alt: 'header', src: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.imgUrl }), _jsx(Cover, {})] }), _jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(LogoContainer, { children: _jsx(Logo, { size: 'xl', isLight: true }) }), _jsxs(MainWrapper, { children: [_jsx(SubTitle, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.subTitle }), _jsx(Text, { children: (_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.text })] }), _jsx(IndicatorWrapper, { children: _jsx(CarouselIndicator, { total: config.length, index: currentIndex }) })] }) }))] }));
});
const Main = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
`;
const LogoContainer = styled.div `
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: ${(props) => props.theme.spacing[24]};
`;
const StyledContainer = styled(Container) `
  overflow-x: hidden;
`;
const Cover = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // black fade
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.7) 100%
  );
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-top: ${(props) => props.theme.spacing[24]};
  gap: ${(props) => props.theme.spacing[24]};
  height: 100%;

  ${(props) => props.theme.breakpoint.md(css `
      justify-content: flex-start;
      gap: ${props.theme.spacing[24]};
    `)};
`;
const MainWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[24]};
  flex: 1;
`;
const IndicatorWrapper = styled.div `
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: ${(props) => props.theme.spacing[32]};
`;
const ImageWrapper = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['4xl']};
  color: ${(props) => props.theme.color.neutral[10]};
  margin-bottom: ${(props) => props.theme.spacing[8]};
  text-align: center;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.heading['6xl']};
    `)}
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.neutral[10]};
  text-align: center;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${(props) => props.theme.typography.body.xl.regular};
    `)}
`;
OnboardingCarousel.displayName = 'OnboardingCarousel';
export default toGenericOnboarding(OnboardingCarousel);
