// components
import { OnboardingKeniConfigItem } from '@karehero/llama';

enum OnboardingActionType {
  Next = 'next',
  Skip = 'nextSection',
  Back = 'back',
  End = 'end',
  Previous = 'previous',
  To = 'to',
}

export const onboardingHeroMemberConfig = [
  {
    id: 'hero1',
    title: 'We salute everything you do for your loved one!',
    text1:
      "We'll ask you some questions to get the most relevant support to you. All your data is 100% private and confidential.",
    ctaButtonLabel: 'Let’s get started',
    actions: null,
  },
];

export const onboardingHeroConfig = [
  {
    id: 'hero1',
    title: 'Dedicated elderly care support at your fingertips',
    text1:
      'Get practical, expert help at every stage of your caregiving journey',
    ctaButtonLabel: 'Start your journey',
    actions: null,
  },
];

export const onboardingAccountMigration = [
  {
    id: '1',
    subTitle: "We've improved our security system",
    imgUrl:
      'https://cdn.sanity.io/images/x7r1t5o8/production/e8579e2a4c3766cf0cf99a24a9d0679d2d2bbeb4-5322x2365.jpg?h=800',
    text: "To continue using KareHero, you need to enter new sign up details for your account; either your social login or your preferred email address. We'll take care of the rest!",
    actions: [
      {
        label: 'Next',
        onClick: OnboardingActionType.Next,
      },
    ],
  },
  {
    id: '2',
    subTitle: "We've made some exciting changes",
    imgUrl:
      'https://cdn.sanity.io/images/x7r1t5o8/production/8d3660ea13e7547c5abd2f4ec09abe105afe7376-7934x5292.jpg?h=800',
    text: "You'll notice the KareHero app looks a bit different. All you need to do is answer some simple questions and we'll personalise our support based on your needs.",
  },
];

export const onboardingMemberCarouselConfig = [
  {
    id: '1',
    subTitle: 'Your Action Plan keeps you on track',
    imgUrl:
      'https://cdn.sanity.io/images/x7r1t5o8/production/81fdf050852681b3032f44dea9690592f3a7741c-1077x556.jpg?h=800',
    text: 'Get a personalised plan for supporting your loved one',
    isIgnoreProgress: true,
    actions: [
      {
        label: 'Next',
        onClick: OnboardingActionType.Next,
      },
    ],
  },
  {
    id: '2',
    subTitle: 'Your Care Expert solves your problems',
    imgUrl:
      'https://cdn.sanity.io/images/x7r1t5o8/production/f573b09707af7fded054e071f8a163bb2ed9e031-1626x1083.jpg?h=800',
    text: 'Get professional advice from your dedicated Care Expert',
    isIgnoreProgress: true,
  },
  {
    id: '3',
    subTitle: 'Your Knowledge Hub keeps you in the know',
    imgUrl:
      'https://cdn.sanity.io/images/x7r1t5o8/production/c8547a5264282d404fb52141c9ffd0ac8db0665a-1045x459.jpg?h=800',
    text: 'Get exclusive content on caregiving best practices',
    isIgnoreProgress: true,
  },
];

export const onboardingCarouselConfig = [
  {
    id: '1',
    subTitle: 'Set your clients up for later life success',
    imgUrl:
      'https://cdn.sanity.io/images/x7r1t5o8/production/e8579e2a4c3766cf0cf99a24a9d0679d2d2bbeb4-5322x2365.jpg?h=800',
    text: 'KareHero supports your clients to find care and navigate care costs',
  },
  {
    id: '2',
    subTitle: 'Save time and admin on care research',
    imgUrl:
      'https://cdn.sanity.io/images/x7r1t5o8/production/8d3660ea13e7547c5abd2f4ec09abe105afe7376-7934x5292.jpg?h=800',
    text: 'Our trusted care experts give your clients 1:1 advice',
  },
  {
    id: '3',
    subTitle: 'Track your client’s progress and access expert resources',
    imgUrl:
      'https://cdn.sanity.io/images/x7r1t5o8/production/084b805fc677b014ad2eefdd1640a86758727fdc-8190x3333.jpg?h=800',
    text: 'Your Partner Portal lets you manage your referrals all in one place',
  },
];

export const onboardingKeniConfig: OnboardingKeniConfigItem[] = [
  {
    id: 'keni1',
    title: 'Meet Keni',
    subTitle: 'Your personal helper in the KareHero app',
    keniSpeech: [
      {
        type: 'strong',
        text: 'Welcome, your care plan has been created!',
      },
      {
        type: 'text',
        text: 'You can save this app to your desktop so its always ready to use!',
      },
    ],
    buttonText: 'Next',
    keniWidth: '300px',
  },
  {
    id: 'keni2',
    title: 'Help us transform the future of care!',
    subTitle: '',
    keniSpeech: [
      {
        type: 'list',
        list: [
          {
            type: 'text',
            text: "We're revolutionising how to support you in looking after your loved one",
          },
          {
            type: 'text',
            text: 'We believe you should be at the centre of everything we do',
          },
          {
            type: 'text',
            text: 'We hope to meet your needs, but feedback is welcomed through our chat',
          },
        ],
      },
    ],
    buttonText: 'Got it',
    keniWidth: '300px',
  },
  {
    id: 'keni3',
    title: '',
    subTitle: 'What would you like to do?',
    options: [
      {
        text: 'View my care plan',
        to: '/',
      },
      {
        text: 'Explore the library',
        to: '/library',
      },
      {
        text: 'Book a call with a care expert',
        to: '/book-call',
      },
    ],
    buttonText: "Let's go",
    keniWidth: '300px',
  },
];

export const onboardingDataConsentConfig = [
  {
    actions: [
      {
        label: 'Next',
        onClick: OnboardingActionType.Next,
      },
    ],
  },
];

export const onboardingKeniConfirmEmailConfig = [
  {
    id: 'keni1',
    title: 'Almost there!',
    subTitle: "Please verify it's you by clicking the link in your email",
    keniSpeech: [
      {
        type: 'strong',
        text: 'Head over to your work email inbox!',
      },
    ],
    keniWidth: '300px',
    actions: [
      {
        label: 'Done',
        onClick: OnboardingActionType.To,
        to: '/sign-in',
      },
    ],
  },
];

export const onboardingKeniConfirmMigrationConfig = [
  {
    id: 'keni1',
    title: 'Welcome back!',
    subTitle:
      'To continue using KareHero, you need to enter new sign up details. Get started by clicking the link in your email',
    keniSpeech: [
      {
        type: 'strong',
        text: 'Head over to your work email inbox!',
      },
    ],
    keniWidth: '300px',
    actions: [
      {
        label: 'Done',
        onClick: OnboardingActionType.To,
        to: '/sign-in',
      },
    ],
  },
];

export const onboardingActivatedStartConfig = [
  {
    id: '1',
    title: 'Personalise Your Journey',
    text: [
      'We’ll ask you a few questions about the loved one you want support for.',
      'Your answers will give you a bespoke action plan that solves your needs.',
    ],
    ctaButtonLabel: 'Personalise Now',
    actions: null,
    isIgnoreProgress: true,
  },
];

export const onboardingLoadingConfig = [
  {
    id: '1',
    icon: 'star',
    text: 'Building your personalised action plan',
    color: 'orange',
    actions: null,
    isIgnoreProgress: true,
  },
  {
    id: '2',
    icon: 'hand-holding-heart',
    text: 'Working out your care needs',
    color: 'red',
    actions: null,
    isIgnoreProgress: true,
  },
  {
    id: '3',
    icon: 'money-bills',
    text: 'Calculating funding options',
    color: 'green',
    actions: null,
    isIgnoreProgress: true,
  },
  {
    id: '4',
    icon: 'route',
    text: 'Finalising your next steps',
    color: 'lime',
    actions: null,
    isIgnoreProgress: true,
  },
];

export const onboardingLoadingDoneConfig = [
  {
    id: '1',
    icon: 'check',
    text: 'Your action plan is ready!',
    subtext: 'Tap to see your first personalised action plan item',
    color: 'green',
    confetti: true,
    ctaButtonLabel: 'Start your journey',
    ctaButtonPath: '/action-plan/book-call-with-care-expert',
    actions: null,
    isIgnoreProgress: true,
  },
];

export const onboardingDoneConfig = [
  {
    id: '1',
    icon: 'check',
    text: 'Your account is set up!',
    subtext: 'Tap to explore the app',
    color: 'green',
    confetti: true,
    ctaButtonLabel: 'Start your journey',
    ctaButtonPath: '/',
    actions: null,
    isIgnoreProgress: true,
  },
];

export const onboardingHelpConfig = [
  {
    id: '1',
    title: 'What can we help you with?',
    subTitle:
      ' Select as many as you need. Don’t worry if you’re not sure, we’ll help you work out next steps.',
    options: [
      {
        label: 'I need to find or manage care',
        value: 'i-need-to-find-or-manage-care',
        sort: 1,
      },
      {
        label: 'I want help with funding care',
        value: 'i-want-help-with-funding-care',
        sort: 2,
      },
      {
        label: 'I need to manage hospital discharge',
        value: 'i-need-to-manage-hospital-discharge',
        sort: 3,
      },
      {
        label: 'I want to understand or apply for POA',
        value: 'i-want-to-understand-or-apply-for-poa',
        sort: 4,
      },
      {
        label: 'I’m struggling as a caregiver',
        value: 'i-m-struggling-as-a-caregiver',
        sort: 5,
      },
      {
        label: 'I don’t know where to start',
        value: 'i-dont-know-where-to-start',
        sort: 6,
      },
      {
        label: 'I’m not actively caring, but I’m thinking ahead',
        value: 'thinking-ahead',
        sort: 7,
      },
    ],
  },
];

export const interestsOptions = [
  {
    value: 'all',
    label: 'All Topics',
  },
  {
    value: 'care-providers',
    label: 'Care Providers',
  },
  {
    value: 'housing',
    label: 'Housing',
  },
  {
    value: 'cognitive',
    label: 'Cognitive',
  },
  {
    value: 'medical',
    label: 'Medical',
  },
  {
    value: 'leaving-hospital',
    label: 'Leaving Hospital',
  },
  {
    value: 'care-needs',
    label: 'Care Needs',
  },
  {
    value: 'dementia',
    label: 'Dementia',
  },
  {
    value: 'power-of-attorney',
    label: 'Power of Attorney',
  },
  {
    value: 'care-funding',
    label: 'Care Funding',
  },
  {
    value: 'support',
    label: 'Support',
  },
  {
    value: 'finding-care',
    label: 'Finding Care',
  },
  {
    value: 'bereavement',
    label: 'Bereavement',
  },
];
