import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// component
import { Title, LoadingTransition, WebinarCard } from "../../atoms";
import { CardContainer } from "../../templates";
// hooks/
import { useWindowSize } from "../../../hooks";
// theme
import theme from "../../../theme";
/**
 * RenderWebinars is a helper component that renders the the webinars.
 */
const RenderWebinars = ({ webinars, isLoading, onClick, }) => {
    // hooks/
    const [width] = useWindowSize();
    // memos
    const maxColumns = useMemo(() => {
        if (width < theme.breakpoint.mdValue)
            return 1;
        if (width < theme.breakpoint.lgValue)
            return 2;
        if (width < theme.breakpoint.xlValue)
            return 3;
        return 4;
    }, [width]);
    if (!webinars || webinars.length === 0)
        return null;
    return (_jsx(_Fragment, { children: _jsx(CardContainer, Object.assign({ maxColumns: maxColumns, isPreventHeightMatch: true }, { children: webinars.map((webinar) => (_jsx(WebinarCard, { webinar: webinar, isLoading: isLoading, onClick: onClick }, webinar.title))) })) }));
};
/**
 * WebinarOverview is a page that displays all of the webinars that
 * are available to the user.
 */
export const WebinarOverview = ({ webinars, isLoading, onClick, }) => {
    // memos
    const dummyWebinars = useMemo(() => {
        const webinars = [];
        for (let i = 0; i < 10; i++) {
            webinars.push({
                _id: `webinar_${i}`,
                title: `Webinar ${i}`,
                description: `Description for Webinar ${i}`,
                date: new Date().toISOString(),
                startTime: '12:00',
                endTime: '13:00',
                speaker: 'Bob Ross',
                tags: [
                    {
                        _key: 'tag1',
                        _type: 'tag',
                        label: 'Leadership',
                        value: 'leadership1',
                    },
                ],
                headerImage: {
                    _key: `headerImage_key_${i}`,
                    _type: 'image',
                    asset: {
                        _ref: `headerImage_ref_${i}`,
                        _type: 'image',
                    },
                },
                callToActionImage: {
                    _key: `headerImage_key_${i}`,
                    _type: 'image',
                    asset: {
                        _ref: `headerImage_ref_${i}`,
                        _type: 'image',
                    },
                },
                content: [
                    {
                        _key: `content_key_${i}`,
                        _type: 'block',
                        children: [
                            {
                                _key: `child_key_${i}`,
                                _type: 'span',
                                marks: ['strong'],
                                text: `Webinar Content ${i}`,
                            },
                        ],
                        markDefs: [],
                        style: 'normal',
                    },
                ],
            });
        }
        return webinars;
    }, []);
    return (_jsxs(StyledWebinar, { children: [_jsxs(PageHeader, { children: [_jsx(Title, Object.assign({ isNoMargin: true }, { children: "Webinars" })), _jsx(Description, { children: "This is where you\u2019ll find the webinars that are available to you." })] }), _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Content, { children: _jsx(RenderWebinars, { webinars: dummyWebinars, isLoading: true }) }), content: _jsx(Content, { children: _jsx(RenderWebinars, { webinars: webinars, isLoading: false, onClick: onClick }) }) })] }));
};
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;
const PageHeader = styled.div ``;
const Content = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[32]};
  flex-wrap: wrap;
`;
const StyledWebinar = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
`;
WebinarOverview.displayName = 'WebinarOverview';
export default WebinarOverview;
