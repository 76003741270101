import PropTypes from 'prop-types';

import Button from '../../components/primitives/Button';

import useProviderSearch from '../../hooks/useProviderSearch';

export default function SearchButton({
  text,
  fullWidth,
  onSearchClick,
  disabled,
}) {
  const { search } = useProviderSearch();

  const handleClick = () => {
    search();
    onSearchClick();
  };

  return (
    <Button
      onClick={handleClick}
      fullWidth={fullWidth}
      title='Search for care providers'
      disabled={disabled}
    >
      {text}
    </Button>
  );
}

SearchButton.propTypes = {
  text: PropTypes.string,
  fullWidth: PropTypes.bool,
  onSearchClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SearchButton.defaultProps = {
  text: 'Search',
  fullWidth: false,
  onSearchClick: () => {},
  disabled: false,
};
