import axios from 'axios';

export default function useMakeApiRequest() {
  const fetchSettings = {
    headers: {
      Accept: 'application/json',
    },
    referrerPolicy: 'no-referrer',
  };

  const makeFetchFunction = (method) => (url, data) =>
    axios({
      method,
      url,
      ...fetchSettings,
      data,
    });

  return {
    get: makeFetchFunction('get'),
    post: makeFetchFunction('post'),
  };
}
