import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
const mode = 'breathe';
/**
 * Skeleton displays a bar that represents something is being searched.
 */
export const Skeleton = ({ shape, width, height, isSquare = false, isRounded = true, isMargin = true, paragraphLength = 6, }) => {
    const paragraphArray = useMemo(() => Array.from(Array(paragraphLength).keys()), [paragraphLength]);
    return shape === 'paragraph' ? (_jsx(Paragraph, Object.assign({ "$isMargin": isMargin }, { children: paragraphArray.map((i) => (_jsx(StyledSkeleton, Object.assign({ "$shape": shape, "$fixedWidth": width, "$fixedHeight": height, "$isRounded": isRounded, "$isMargin": true, "$isSquare": isSquare, "$isHalfLength": i === paragraphLength - 1 }, { children: _jsx(Indicator, { "$mode": mode }) }), i))) }))) : (_jsx(StyledSkeleton, Object.assign({ "$shape": shape, "$fixedWidth": width, "$fixedHeight": height, "$isRounded": isRounded, "$isMargin": isMargin, "$isSquare": isSquare }, { children: _jsx(Indicator, { "$mode": mode }) })));
};
const StyledSkeleton = styled.div `
  position: relative;
  width: ${(props) => (props.$fixedWidth ? props.$fixedWidth : '100%')};
  ${(props) => props.$isHalfLength && 'width: 70%;'}

  ${(props) => {
    switch (props.$shape) {
        case 'title':
            return css `
          height: 42px;
          max-height: 42px;
          min-height: 42px;
          margin-bottom: ${props.theme.spacing[36]};
        `;
        case 'text':
            return css `
          height: 14px;
          max-height: 14px;
          min-height: 14px;
        `;
        case 'paragraph':
            return css `
          height: 14px;
          max-height: 14px;
          min-height: 14px;
          margin-bottom: ${props.theme.spacing[8]};
        `;
        case 'subtitle':
            return css `
          height: 21px;
          max-height: 21px;
          min-height: 21px;
          margin-bottom: ${props.theme.spacing[16]};
        `;
        case 'header-image':
            return css `
          height: 300px;
          max-height: 300px;
          min-height: 300px;
          margin-bottom: ${props.theme.spacing[48]};
        `;
        case 'header':
            return css `
          height: 100%;
        `;
        case 'button':
            return css `
          height: 42px;
          max-height: 42px;
          min-height: 42px;
          width: 150px;
          margin-bottom: ${props.theme.spacing[16]};
        `;
        case 'avatar':
            return css `
          height: 130px;
          max-height: 130px;
          min-height: 130px;
          width: 130px;
          max-width: 130px;
          min-width: 130px;
          margin-bottom: ${props.theme.spacing[16]};
        `;
        default:
            return css `
          height: 14px;
          max-height: 14px;
          min-height: 14px;
        `;
    }
}}

  ${(props) => props.$isRounded
    ? css `
          border-radius: 99999px;
        `
    : css `
          border-radius: 8px;
        `}

  ${(props) => props.$isSquare &&
    css `
      border-radius: 0;
    `}

  overflow: hidden;

  ${(props) => props.$fixedHeight &&
    css `
      height: ${props.$fixedHeight};
      min-height: ${props.$fixedHeight};
      max-height: ${props.$fixedHeight};
    `};

  ${(props) => props.$fixedWidth &&
    css `
      width: ${props.$fixedWidth};
      min-width: ${props.$fixedWidth};
      max-width: ${props.$fixedWidth};
    `};

  transition: background 1.5s;
  background: ${(props) => props.theme.color.neutral[30]};
  ${(props) => !props.$isMargin && `margin-bottom: 0;`}
`;
const Indicator = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  ${(props) => {
    switch (props.$mode) {
        case 'breathe':
            return css `
          width: 100%;
          background: ${props.theme.color.neutral[40]};

          animation: loading 2s infinite ease-in-out;

          @keyframes loading {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
        `;
        case 'shimmer':
            return css `
          width: 30%;
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            rgba(220, 220, 220, 1) 50%,
            rgba(0, 0, 0, 0) 100%
          );

          animation: loading 3s infinite ease-in-out;

          @keyframes loading {
            0% {
              left: -30%;
            }
            100% {
              left: 100%;
            }
          }
        `;
    }
}}
`;
const Paragraph = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[16]};
  ${(props) => !props.$isMargin && `margin-bottom: 0;`}
`;
Skeleton.displayName = 'Skeleton';
export default Skeleton;
