import { addressType } from '../../../types';

export default function Address({ address }) {
  return (
    <address>
      {[
        address.streetAddress1,
        address.streetAddress2,
        address.streetAddress3,
        address.streetAddress4,
        address.city,
        address.county,
        address.district,
        address.region,
        address.country,
        address.postcode,
      ]
        .filter(Boolean)
        .join(', ')}
    </address>
  );
}

Address.propTypes = {
  address: addressType.isRequired,
};
