import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useState } from 'react';
import styled from 'styled-components';
// components
import { Button, Divider, Input } from "../../atoms";
/**
 * InviteKeyRegistrationForm is a component that allows registering with an invite key or domain.
 */
export const InviteKeyRegistrationForm = ({ onSubmit, inviteKeyLabel, isEmailRegistration, }) => {
    // state
    const [email, setEmail] = useState('');
    const [inviteKey, setInviteKey] = useState('');
    // methods
    const handleInviteSubmit = useCallback((e) => {
        e.preventDefault();
        onSubmit(inviteKey, '');
    }, [inviteKey, onSubmit]);
    const handleEmailSubmit = useCallback((e) => {
        e.preventDefault();
        onSubmit('', email);
    }, [email, onSubmit]);
    return (_jsxs(_Fragment, { children: [' ', _jsxs(StyledInviteKeyRegistrationForm, Object.assign({ onSubmit: handleInviteSubmit }, { children: [_jsx(StyledTopSection, { children: _jsx(StyledInputFields, { children: _jsx(Input, { ariaLabel: 'invite key input field', type: 'text', label: inviteKeyLabel, isHideTitle: false, value: inviteKey, onChange: setInviteKey, placeholder: inviteKeyLabel }) }) }), _jsxs(StyledButton, Object.assign({ ariaLabel: 'register', type: 'submit' }, { children: ["Register with ", inviteKeyLabel] }))] })), isEmailRegistration && (_jsxs(_Fragment, { children: [_jsx(StyledDivider, {}), _jsxs(StyledInviteKeyRegistrationForm, Object.assign({ onSubmit: handleEmailSubmit }, { children: [_jsx(StyledTopSection, { children: _jsx(StyledInputFields, { children: _jsx(Input, { ariaLabel: 'email input field', type: 'text', label: 'Work email address', isHideTitle: false, value: email, onChange: setEmail, placeholder: 'example@mycompany.com' }) }) }), _jsx(StyledButton, Object.assign({ ariaLabel: 'register', type: 'submit' }, { children: "Register with Email" }))] }))] }))] }));
};
const StyledInviteKeyRegistrationForm = styled.form `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  align-items: center;
`;
const StyledInputFields = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
`;
const StyledTopSection = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
`;
const StyledButton = styled(Button) `
  width: 100%;
`;
const StyledDivider = styled(Divider) `
  margin: ${(props) => props.theme.spacing[12]} 0;
  font: ${(props) => props.theme.typography.body.lg.regular};
`;
InviteKeyRegistrationForm.displayName = 'InviteKeyRegistrationForm';
export default InviteKeyRegistrationForm;
