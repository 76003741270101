import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// components
import { ArticleCard, ArticleFeatured } from "../../atoms";
import { CardContainer } from "../../templates";
// hooks/
import { useWindowSize } from "../../../hooks";
// theme
import theme from "../../../theme";
/**
 * ArticleFilterd shows a featured article and a list of articles.
 */
export const ArticleFiltered = ({ articles, bookmarkedArticles, isLoading, onBookmark, }) => {
    // hooks/
    const [width] = useWindowSize();
    // memos
    const maxColumns = useMemo(() => {
        if (width < theme.breakpoint.mdValue)
            return 1;
        if (width < theme.breakpoint.lgValue)
            return 2;
        if (width < theme.breakpoint.xlValue)
            return 3;
        return 4;
    }, [width]);
    if (!articles || articles.length === 0)
        return _jsx("div", { children: "No articles found" });
    return (_jsxs(Wrapper, { children: [_jsx(ArticleFeatured, { article: articles === null || articles === void 0 ? void 0 : articles[0], onBookmark: onBookmark, isBookmarked: !!(bookmarkedArticles === null || bookmarkedArticles === void 0 ? void 0 : bookmarkedArticles.find((a) => { var _a; return (a === null || a === void 0 ? void 0 : a._id) === ((_a = articles === null || articles === void 0 ? void 0 : articles[0]) === null || _a === void 0 ? void 0 : _a._id); })), isLoading: isLoading }), _jsx(CardContainer, Object.assign({ maxColumns: maxColumns }, { children: articles === null || articles === void 0 ? void 0 : articles.slice(1).map((article, i) => (_jsx(ArticleCard, { article: article, isBookmarked: !!(bookmarkedArticles === null || bookmarkedArticles === void 0 ? void 0 : bookmarkedArticles.find((a) => (a === null || a === void 0 ? void 0 : a._id) === (article === null || article === void 0 ? void 0 : article._id))), onBookmark: onBookmark, isLoading: isLoading }, i))) }))] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.theme.spacing[32]};
`;
ArticleFiltered.displayName = 'ArticleFiltered';
export default ArticleFiltered;
