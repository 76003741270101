import { useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '../styling';
import { renderIf } from '../RenderIf';
import Spacer from './Spacer';
import Row from './Row';
import { ColourIcon, icons } from './Icon';
import Paragraph from './typography/Paragraph';

const Toggle = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export default function ShowMore({
  text,
  moreTitle,
  lessTitle,
  moreTitleText,
  lessTitleText,
  previewLength,
}) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  const words = text.split(' ');
  const willShowToggles = words.length > previewLength;
  const RenderToggles = renderIf(willShowToggles);
  const previewText = `${words.slice(0, previewLength).join(' ')}…`;

  const RenderIfOpen = renderIf(open);
  const RenderIfClosed = renderIf(!open);

  return (
    <>
      <RenderIfClosed>
        <Row>
          <Paragraph largeLineHeight>
            {willShowToggles ? previewText : text}
          </Paragraph>
        </Row>
      </RenderIfClosed>
      <RenderIfOpen>
        <Row>
          <Paragraph largeLineHeight>{text}</Paragraph>
        </Row>
      </RenderIfOpen>
      <RenderToggles>
        <Toggle
          type='button'
          onClick={toggleOpen}
          title={open ? lessTitleText : moreTitleText}
        >
          <Spacer top>
            <Row>
              {open ? lessTitle : moreTitle}
              <Spacer left>
                <ColourIcon icon={open ? icons.chevronUp : icons.chevronDown} />
              </Spacer>
            </Row>
          </Spacer>
        </Toggle>
      </RenderToggles>
    </>
  );
}

ShowMore.propTypes = {
  moreTitle: PropTypes.node.isRequired,
  lessTitle: PropTypes.node.isRequired,
  moreTitleText: PropTypes.string.isRequired,
  lessTitleText: PropTypes.string.isRequired,
  previewLength: PropTypes.number,
  text: PropTypes.string.isRequired,
};

ShowMore.defaultProps = {
  previewLength: 100,
};
