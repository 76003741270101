import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// components
import { Button, Card, ProgressBar, Title } from "../../atoms";
import { BackButton } from "../../molecules";
import { CardContainer } from "../../templates";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import theme from "../../../theme";
// assets
// @transform-path ../../../../../../src/assets/llama-group.svg
import llamaGroup from "../../../../../../src/assets/llama-group.svg";
/**
 * RecipientProfileCarePlan shows the profile of the care recipient.
 */
export const RecipientProfileCarePlan = ({ firstName, progress, toCareProfile, limit, toBookCall, }) => {
    // hooks
    const [width] = useWindowSize();
    const isComplete = useMemo(() => {
        if (progress === undefined || limit === undefined)
            return false;
        return progress >= limit;
    }, [progress, limit]);
    return (_jsxs(Body, { children: [_jsx(BackButton, {}), _jsxs(Title, { children: [firstName || 'Your loved one', "'s Care Plan"] }), _jsxs(HelperText, { children: ["The KareHero Care Plan will help you record", ' ', firstName || 'your loved one', "\u2019s care needs so you can find suitable care providers and ensure health professionals are taking into account their needs."] }), progress === undefined || progress === 0 ? (_jsxs(CardContainer, Object.assign({ maxColumns: 1, isPreventHeightMatch: true }, { children: [_jsx(StyledCard, Object.assign({ color: 'green' }, { children: _jsxs(CareProfileCardBody, { children: [_jsxs(CardContent, { children: [_jsx(CardTitle, { children: "Get ready for care" }), _jsxs(CardBody, { children: ["Complete a series of questions about", ' ', firstName || 'your loved one', "\u2019s care needs. You may need to collect information from various sources, so you can save and come back to this form at anytime."] })] }), _jsx(Button, Object.assign({ ariaLabel: 'start my care plan', to: toCareProfile }, { children: "Start care plan" }))] }) })), _jsx(StyledCard, Object.assign({ color: 'blue' }, { children: _jsx(CareProfileCardBody, { children: _jsxs(CardContent, { children: [_jsx(CardTitle, { children: "Information you need" }), _jsx(CardBody, { children: _jsxs("ul", { children: [_jsx("li", { children: "Care professionals' details" }), _jsx("li", { children: "Health history" }), _jsx("li", { children: "Condition status" }), _jsx("li", { children: "Mobility status" }), _jsx("li", { children: "Medication details" })] }) })] }) }) }))] }))) : (_jsxs(CardContainer, Object.assign({ maxColumns: 1, isPreventHeightMatch: true }, { children: [_jsx(StyledCard, Object.assign({ color: isComplete ? 'blue' : 'green' }, { children: _jsxs(CareProfileCardWrapper, { children: [_jsxs(CareProfileCardBody, { children: [_jsxs(CardContent, { children: [_jsx(CardTitle, { children: isComplete
                                                        ? "Well done! You've completed the care plan"
                                                        : "You're doing great!" }), _jsx(CardBody, { children: isComplete
                                                        ? 'You can now book a call back to discuss your care plan.'
                                                        : 'Continue filling in your personal care plan.' })] }), _jsx(ProgressBar, { label: 'Progress', limit: limit || 0, progress: progress }), _jsx(ActionGroup, { children: isComplete ? (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ ariaLabel: 'view care plan', variant: 'secondary', to: toCareProfile }, { children: "View care plan" })), _jsx(Button, Object.assign({ ariaLabel: 'Book a call back', to: toBookCall }, { children: "Book call back" }))] })) : (_jsx(Button, Object.assign({ ariaLabel: 'continue care plan', to: toCareProfile }, { children: "Continue care plan" }))) })] }), width > theme.breakpoint.lgValue && (_jsx("img", { src: llamaGroup, alt: 'llama group' }))] }) })), !isComplete && (_jsx(StyledCard, Object.assign({ color: 'blue' }, { children: _jsx(CareProfileCardBody, { children: _jsxs(CardContent, { children: [_jsx(CardTitle, { children: "Information you need" }), _jsx(CardBody, { children: _jsxs("ul", { children: [_jsx("li", { children: "Care professionals' details" }), _jsx("li", { children: "Health history" }), _jsx("li", { children: "Condition status" }), _jsx("li", { children: "Mobility status" }), _jsx("li", { children: "Medication details" })] }) })] }) }) })))] })))] }));
};
const Body = styled.div `
  padding-bottom: ${(props) => props.theme.spacing[24]};
`;
const HelperText = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  padding-bottom: ${(props) => props.theme.spacing[36]};
`;
const StyledCard = styled(Card) `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[32]};
  align-items: flex-start;
  color: ${(props) => props.theme.color.neutral[80]};
  border-radius: 8px;
`;
const CareProfileCardBody = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[32]};
  flex: 1;
`;
const CareProfileCardWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: ${(props) => props.theme.spacing[16]};
`;
const CardContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[20]};
`;
const CardTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const CardBody = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[100]};

  & > ul {
    margin-bottom: 0;
  }
`;
const ActionGroup = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[16]};
`;
RecipientProfileCarePlan.displayName = 'RecipientProfileCarePlan';
export default RecipientProfileCarePlan;
