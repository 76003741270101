import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
// components
import Button from "../Button/Button";
import { CardContainer } from "../../templates";
import BaseField from '../BaseField/BaseField';
export const TimePicker = ({ value, options, className, onChange, id, label, description, tooltip, isRequired = false, isHideTitle = false, }) => {
    // states
    const [selectedTime, setSelectedTime] = useState(value);
    // effects
    useEffect(() => {
        setSelectedTime(value);
    }, [value]);
    // methods
    const handleTimeChange = useCallback((option) => {
        if (onChange) {
            onChange(option);
        }
        setSelectedTime(option);
    }, [onChange]);
    return (_jsx(BaseField, Object.assign({ id: id, className: className, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsx(Wrapper, { children: _jsx(CardContainer, Object.assign({ maxColumns: 1, size: 'sm' }, { children: options.map((option, index) => (_jsx(StyledButton, Object.assign({ ariaLabel: option.label, variant: selectedTime === option.value ? 'primary' : 'secondary', isRectangle: true, isSelected: selectedTime === option.value, onPress: () => handleTimeChange(option.value) }, { children: option.label }), index))) })) }) })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[8]};
  margin-bottom: ${(props) => props.theme.spacing[8]};
  max-height: 45%;
  overflow: auto;
`;
const StyledButton = styled(Button) `
  margin-bottom: ${(props) => props.theme.spacing[16]};
  color: ${(props) => props.isSelected
    ? props.theme.color.neutral[10]
    : props.theme.color.primary[90]};
`;
TimePicker.displayName = 'TimePicker';
export default TimePicker;
