import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
// styles
import { css } from 'styled-components';
// components
import { OnboardingActionType, toGenericOnboarding, } from "../../pages";
import { Container, Icon } from "../../atoms";
import { Editor } from "../Editor/Editor";
import { createFieldHelper, } from "../EditorFields/fieldHelper";
import Tooltip from "../../atoms/Tooltip/Tooltip";
export const OnboardingEditor = toGenericOnboarding(({ currentIndex = 0, config, isNextDisabled, setIsNextDisabled, handleClick, }) => {
    // memos
    const currentConfig = useMemo(() => config[currentIndex], [config, currentIndex]);
    const fields = useMemo(() => {
        return createFieldHelper({
            value: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.fieldDef,
        });
    }, [currentConfig]);
    const value = useMemo(() => currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.value, [currentConfig]);
    const setValue = useMemo(() => currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.onChange, [currentConfig]);
    const size = useMemo(() => (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.size) || 'lg', [currentConfig]);
    // effects
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        if (!((_b = (_a = config === null || config === void 0 ? void 0 : config[currentIndex]) === null || _a === void 0 ? void 0 : _a.fieldDef) === null || _b === void 0 ? void 0 : _b.validation)) {
            setIsNextDisabled(false);
            return;
        }
        setIsNextDisabled(!((_e = (_d = (_c = config === null || config === void 0 ? void 0 : config[currentIndex]) === null || _c === void 0 ? void 0 : _c.fieldDef) === null || _d === void 0 ? void 0 : _d.validation) === null || _e === void 0 ? void 0 : _e.call(_d, value === null || value === void 0 ? void 0 : value.value)) || false);
    }, [value, currentIndex, config]);
    return (_jsx(StyledContainer, Object.assign({ size: 'md', isScroll: true }, { children: _jsx(Wrapper, { children: config && (_jsxs(_Fragment, { children: [_jsx(Title, Object.assign({ "$size": size }, { children: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.title })), _jsxs(Description, { children: [_jsx(Text, Object.assign({ "$size": size }, { children: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.subTitle })), (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.tooltip) && (_jsx(StyledTooltip, Object.assign({ content: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.tooltip }, { children: _jsx(TooltipIcon, { children: _jsx(Icon, { icon: 'info-circle', fontSize: 24 }) }) })))] }), fields && (_jsx("form", Object.assign({ onSubmit: (e) => {
                            e.preventDefault();
                            !isNextDisabled && (handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.Next));
                        } }, { children: _jsx(Editor, { fields: fields, value: value, onChange: setValue, isFloatingActions: false, isActionsDisabled: true, isForm: false }) }))), (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.isDisclaimer) && (_jsxs(Disclaimer, { children: ["By continuing to use this app you agree to our", ' ', _jsx("a", Object.assign({ href: 'https://www.karehero.com/privacy-policy', target: '_blank' }, { children: "privacy policy" }))] }))] })) }) })));
});
const Disclaimer = styled.div `
  color: ${(props) => props.theme.color.neutral[80]};
  font: ${(props) => props.theme.typography.body.sm.regular};
  padding-top: ${(props) => props.theme.spacing[24]};
  text-align: center;
`;
const StyledContainer = styled(Container) `
  height: 100%;
  background: ${(props) => props.theme.color.cards.yellow.secondary};
`;
const Wrapper = styled.div `
  display: flex;
  min-height: content;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[32]} 0;
`;
const Title = styled.div `
  width: 100%;
  color: ${(props) => props.theme.color.primary[80]};
  ${(props) => {
    switch (props.$size) {
        case 'sm':
            return css `
          font: ${props.theme.typography.heading['2xl']};
        `;
        case 'lg':
            return css `
          font: ${props.theme.typography.heading['3xl']};
        `;
    }
}};

  ${(props) => props.theme.breakpoint.md(css `
      ${() => {
    switch (props.$size) {
        case 'sm':
            return css `
              font: ${props.theme.typography.heading['3xl']};
            `;
        case 'lg':
            return css `
              font: ${props.theme.typography.heading['6xl']};
            `;
    }
}};
    `)}
`;
const Description = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
  align-items: flex-end;
`;
const Text = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  ${(props) => {
    switch (props.$size) {
        case 'sm':
            return css `
          font: ${props.theme.typography.body.sm.regular};
        `;
        case 'lg':
            return css `
          font: ${props.theme.typography.body.sm.regular};
        `;
    }
}};

  ${(props) => props.theme.breakpoint.md(css `
      ${() => {
    switch (props.$size) {
        case 'sm':
            return css `
              font: ${props.theme.typography.body.md.regular};
            `;
        case 'lg':
            return css `
              font: ${props.theme.typography.body.xl.regular};
            `;
    }
}};
    `)}
`;
const StyledTooltip = styled(Tooltip) ``;
const TooltipIcon = styled.div `
  color: ${(props) => props.theme.color.neutral[70]};
`;
OnboardingEditor.displayName = 'OnboardingEditor';
export default toGenericOnboarding(OnboardingEditor);
