import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
// components
import { OnboardingActionType, toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
import { Background, Icon } from "../../atoms";
import { Instruction } from "../../molecules";
// theme
import theme from "../../../theme";
// constants
const animationBase = 300;
/**
 * OnboardingKeni renders the Keni screen for onboarding flows.
 */
export const OnboardingKeni = toGenericOnboarding(({ config, currentIndex = 0, handleClick, }) => {
    var _a, _b, _c;
    return (_jsx(Background, Object.assign({ color: theme.color.neutral[20] }, { children: _jsx(Instruction, Object.assign({ title: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.title, subTitle: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.subTitle, instruction: 'Head over to your work email inbox!' }, { children: config[currentIndex].options && (_jsx(Content, Object.assign({ "$isShowing": !!config[currentIndex].options, "$speed": animationBase }, { children: _jsx(RadioWrapper, { children: (_c = config[currentIndex].options) === null || _c === void 0 ? void 0 : _c.map((option) => (_jsxs(Radio, Object.assign({ to: option.to, onClick: () => {
                            handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.End);
                        } }, { children: [_jsx(TextWrapper, { children: option.text }), _jsx(Icon, { icon: 'exclamation-circle', fontSize: 16 })] })))) }) }))) })) })));
});
const Content = styled.div `
  width: 100%;
  min-height: 0;
  flex: 0.00000000000000000001;
  opacity: 0;
  overflow: hidden;
  padding: ${(props) => props.theme.spacing[12]};
  overflow-y: auto;

  transition: ${(props) => css `
    opacity ${props.$speed}ms ease ${props.$speed * 3}ms,
    flex ${props.$speed * 2}ms ease ${props.$speed}ms;
  `}
    ${(props) => props.$isShowing &&
    css `
        opacity: 1;
        flex: 4;
        ${props.theme.breakpoint.sm(css `
          flex: 2;
        `)}
      `};
`;
const RadioWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  margin-bottom: ${(props) => props.theme.spacing[48]};
  overflow: visible;

  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Radio = styled(Link) `
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  background: ${(props) => props.theme.color.neutral[10]};
  border: 1px solid ${(props) => props.theme.color.neutral[60]};
  border-radius: 8px;
  width: 100%;
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.sm.medium};
  padding: ${(props) => props.theme.spacing[16]}
  elevation: ${(props) => props.theme.elevation[1]};
  box-sizing: border-box;
  cursor: pointer;

  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.body.md.medium};
      padding: ${props.theme.spacing[32]} ${props.theme.spacing[24]};
    `)}

  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.02);
  }
`;
const TextWrapper = styled.div `
  display: flex;
  align-items: center;
`;
OnboardingKeni.displayName = 'OnboardingKeni';
export default toGenericOnboarding(OnboardingKeni);
