import Banner from '../search/Banner';
import SearchResultsWithControls from '../search/SearchResultsWithControls';
import Column from '../../components/primitives/Column';

export default function ProfileError() {
  return (
    <Column>
      <Banner />
      <SearchResultsWithControls />
    </Column>
  );
}
