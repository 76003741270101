import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Card, Container, Title } from "../../atoms";
/**
 * AttendanceAllowanceHero introduces the care plan.
 */
export const AttendanceAllowanceHero = ({ eligibilityCalculator, careRecipientName = 'your loved one', }) => {
    return (_jsxs(Container, Object.assign({ size: 'lg', isNoPadding: true }, { children: [eligibilityCalculator && (_jsxs(_Fragment, { children: [_jsxs(StyledTitle, { children: ["Check if ", careRecipientName, " is eligible"] }), _jsx(Card, Object.assign({ isNoPadding: true }, { children: eligibilityCalculator }))] })), _jsx(StyledTitle, { children: "Information you need" }), _jsxs(List, { children: [_jsxs(ListItem, { children: [_jsx(Strong, { children: "Personal details:" }), " full name, address, date of birth, National Insurance number (if they have one), and contact information."] }), _jsxs(ListItem, { children: [_jsx(Strong, { children: "Medical information:" }), " medical condition(s), including any diagnoses, treatments, medications, and how their condition affects their ability to carry out daily tasks."] }), _jsxs(ListItem, { children: [_jsx(Strong, { children: "Care needs:" }), " Describe the specific care needs, including assistance with tasks like bathing, dressing, eating, mobility, and managing medication."] })] })] })));
};
const StyledTitle = styled(Title) `
  color: ${(props) => props.theme.color.neutral[100]};
  margin-top: ${(props) => props.theme.spacing[32]};
  font: ${(props) => props.theme.typography.body.xl.medium};
`;
const List = styled.ol `
  padding-left: 20px;
  padding-bottom: ${(props) => props.theme.spacing[24]};
`;
const ListItem = styled.li `
  margin-bottom: 20px;
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;
const Strong = styled.strong `
  font: ${(props) => props.theme.typography.body.lg.semibold};
`;
AttendanceAllowanceHero.displayName = 'AttendanceAllowanceHero';
export default AttendanceAllowanceHero;
