import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
// components
import { CardColor, Container } from "../../atoms";
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
export const OnboardingCareSupportPrompt = toGenericOnboarding(({ setIsNextDisabled, }) => {
    useEffect(() => setIsNextDisabled === null || setIsNextDisabled === void 0 ? void 0 : setIsNextDisabled(false), [setIsNextDisabled]);
    return (_jsx(StyledContainer, Object.assign({ size: 'md' }, { children: _jsx(Wrapper, { children: _jsxs(Body, { children: [_jsx(StyledTitle, { children: "Is your loved one in hospital now?" }), _jsx(StyledLabel, { children: "We\u2019re on hand if you need support now." }), _jsx(StyledCardColor, Object.assign({ color: 'blue', isBordered: false }, { children: _jsxs(Content, { children: ["If you need care urgently, call our team of care experts on", ' ', _jsx(PhoneNumber, Object.assign({ href: 'tel:08083047348' }, { children: "0808 304 7348" })), ' ', "so they can guide you through the process."] }) }), 'card-content')] }) }) })));
});
const StyledContainer = styled(Container) `
  padding: 0;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: ${(props) => props.theme.spacing[32]}
    ${(props) => props.theme.spacing[12]};
  padding-top: 0;
`;
const Body = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  padding-top: ${(props) => props.theme.spacing[32]};
`;
const Content = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const StyledTitle = styled.div `
  width: 100%;
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.heading['3xl']};

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.heading['6xl']};
    `)}
`;
const StyledLabel = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.sm.regular};

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.body.xl.regular};
    `)}
`;
const StyledCardColor = styled(CardColor) `
  padding: ${(props) => props.theme.spacing[16]};
`;
const PhoneNumber = styled.a `
  text-decoration: underline;
  display: inline;
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.body.md.semibold};
`;
OnboardingCareSupportPrompt.displayName = 'OnboardingCareSupportPrompt';
export default toGenericOnboarding(OnboardingCareSupportPrompt);
