import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { css } from 'styled-components';
const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
};
/**
 * DateIcon is a container for displaying the month and the day.
 */
export const DateIcon = ({ date, size }) => {
    return (_jsxs(IconBody, Object.assign({ size: size }, { children: [_jsx(Month, Object.assign({ size: size }, { children: months[date.getMonth()] })), _jsx(Day, Object.assign({ size: size }, { children: date.getDate() }))] })));
};
const IconBody = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => props.size === 'sm' &&
    css `
      width: 36px;
      height: 36px;
    `}
  ${(props) => props.size === 'md' &&
    css `
      width: 80px;
      height: 80px;
    `}


  background-color: ${(props) => props.theme.color.neutral[10]};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.elevation[3]};
  overflow: hidden;
  z-index: 1;
`;
const Month = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e00000;
  font: ${(props) => props.size === 'sm'
    ? props.theme.typography.body.xxs.semibold
    : props.theme.typography.body.md.semibold};
  color: ${(props) => props.theme.color.neutral[10]};
  width: 100%;
  ${(props) => props.size === 'sm' &&
    css `
      height: 16px;
    `}
  ${(props) => props.size === 'md' &&
    css `
      height: 31px;
    `}
`;
const Day = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  font: ${(props) => props.theme.typography.body.sm.semibold};
  color: ${(props) => props.theme.color.neutral[100]};
  width: 100%;
  ${(props) => props.size === 'sm' &&
    css `
      height: 20px;
    `}
  ${(props) => props.size === 'md' &&
    css `
      height: 49px;
    `}
  font: ${(props) => props.size === 'sm'
    ? props.theme.typography.body.xxs.semibold
    : props.theme.typography.body.xxl.semibold};
`;
DateIcon.displayName = 'DateIcon';
export default DateIcon;
