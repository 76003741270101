import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
/**
 * Version is a component that renders the version of the app.
 */
export const Version = ({ className, nodeEnvironment, buildEnvironment, buildVersion, buildHash, onSignOut, }) => {
    const buildEnvironmentComputed = useMemo(() => {
        if (nodeEnvironment === 'development')
            return 'Local';
        switch (buildEnvironment) {
            case 'local':
                return 'Local';
            case 'dev':
                return 'Development';
            case 'staging':
                return 'Staging';
            default:
                return undefined;
        }
    }, [nodeEnvironment, buildEnvironment]);
    const buildVersionComputed = useMemo(() => {
        if (nodeEnvironment === 'development')
            return;
        switch (buildEnvironment) {
            case 'dev':
                // regex for eng-### any amount of digits
                const regex = /eng-\d+/;
                // get the eng-### from the build version
                const match = buildVersion === null || buildVersion === void 0 ? void 0 : buildVersion.match(regex);
                // if there is a match, return the match
                if (match) {
                    return (_jsx(LinearLink, Object.assign({ href: `https://linear.app/karehero/issue/${match[0].toUpperCase()}`, target: '_blank', rel: 'noreferrer' }, { children: match[0].toUpperCase() })));
                }
                return buildVersion;
            case 'staging':
                return `Build ${buildVersion}`;
            default:
                return buildVersion;
        }
    }, [nodeEnvironment, buildEnvironment, buildVersion]);
    if (buildEnvironment === 'production')
        return null;
    return (_jsxs(Wrapper, Object.assign({ className: className, environment: buildEnvironmentComputed }, { children: [_jsx("strong", { children: buildEnvironmentComputed }), buildVersionComputed && (_jsxs(_Fragment, { children: [' -', " ", buildVersionComputed] })), _jsx(SignOutButton, Object.assign({ onClick: onSignOut }, { children: "Force sign out" })), buildHash && (_jsx(CommitLink, Object.assign({ href: `https://github.com/KareHero/karehero-app/commit/${buildHash}`, target: '_blank', rel: 'noreferrer' }, { children: buildHash.slice(0, 7) })))] })));
};
const Wrapper = styled.div `
  position: relative;
  font: ${(props) => props.theme.typography.body.xs.regular};
  padding: ${(props) => props.theme.spacing[4]};
  text-align: center;

  ${(props) => {
    switch (props.environment) {
        case 'local':
        case 'Local':
            return css `
          background: ${props.theme.color.success[50]};
          color: ${props.theme.color.neutral[10]};
        `;
        case 'dev':
        case 'Development':
            return css `
          background: ${props.theme.color.info[40]};
          color: ${props.theme.color.neutral[10]};
        `;
        case 'staging':
        case 'Staging':
            return css `
          background: ${props.theme.color.warning[50]};
          color: ${props.theme.color.neutral[10]};
        `;
        default:
            return css `
          background: ${props.theme.color.neutral[10]};
          color: ${props.theme.color.neutral[100]};
        `;
    }
}}

  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.body.md.regular};
      padding: ${props.theme.spacing[8]};
    `)}
`;
const SignOutButton = styled.button `
  all: unset;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  color: ${(props) => props.theme.color.neutral[10]};
  font: ${(props) => props.theme.typography.body.xxs.regular};
  padding-left: ${(props) => props.theme.spacing[4]};
  &:hover {
    text-decoration: underline;
  }

  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.body.xs.regular};
      padding-left: ${props.theme.spacing[8]};
    `)}
`;
const LinearLink = styled.a `
  color: ${(props) => props.theme.color.neutral[10]};
`;
const CommitLink = styled.a `
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.neutral[10]};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: ${(props) => props.theme.color.neutral[10]};
  font: ${(props) => props.theme.typography.body.xxs.regular};
  padding-right: ${(props) => props.theme.spacing[4]};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.body.xs.regular};
      padding-right: ${props.theme.spacing[8]};
    `)}
`;
Version.displayName = 'Version';
export default Version;
