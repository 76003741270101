import api from '.';
import { Tier } from '@karehero/models';

export const tierApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllTier: builder.query<Tier[], void>({
      query: () => `/tier`,
      providesTags: ['Tiers'],
    }),
    getTier: builder.query<Tier, string>({
      query: (id) => `/tier/${id}`,
      providesTags: ['Tiers'],
    }),
  }),
});

export const { useGetAllTierQuery, useGetTierQuery } = tierApi;

export default tierApi;
