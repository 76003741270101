import { useContext } from 'react';

import { GlobalSettingsContext } from '../context/GlobalSettingsContext';

export default function useOnPageEvent() {
  const { onPageEvent } = useContext(GlobalSettingsContext);

  const events = {
    onSearch: (data) => onPageEvent('search', data),
    onProviderCardClick: (data) => onPageEvent('providerCardClick', data),
    onLoadMoreProvidersClick: (data) => onPageEvent('loadMoreProviders', data),
    onEmailReveal: (data) => onPageEvent('showEmail', data),
    onPhoneReveal: (data) => onPageEvent('showPhoneNumber', data),
  };

  return events;
}
