// store

import { apiUrl } from 'store/api';

function downloadFile(blob: Blob, name = 'attendance-allowance.pdf') {
  const href = URL.createObjectURL(blob);
  const a = Object.assign(document.createElement('a'), {
    href,
    style: 'display:none',
    download: name,
  });
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(href);
  a.remove();
}

export function generatePdf(careProfileId: string) {
  fetch(`${apiUrl}/v1/care-profile/${careProfileId}/pdf`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  })
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      downloadFile(blob);
    });
}
