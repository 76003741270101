import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState } from 'react';
// theme
import styled from 'styled-components';
// components
import { Button, Input } from "../../atoms";
/**
 * ChangePasswordForm is a component that renders password and confirm password input field with
 * a submit button.
 */
export const ChangePasswordForm = ({ onConfirm }) => {
    // state
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isChangePasswordClicked, setIsChangePasswordClicked] = useState(false);
    // handlers
    const handleSubmit = (e) => {
        e.preventDefault();
        const isPasswordValid = password.length >= 8;
        setIsChangePasswordClicked(!isPasswordValid);
        if (!isPasswordValid)
            return;
        onConfirm(password, confirmPassword);
    };
    return (_jsxs(StyledChangePasswordForm, Object.assign({ onSubmit: handleSubmit }, { children: [_jsxs(StyledTopSection, { children: [_jsx(StyledInputFields, { children: _jsx(Input, { ariaLabel: 'password input field', type: 'password', label: 'New Password', value: password, onChange: setPassword, placeholder: 'New Password', validate: (value) => {
                                return ((value && value.length) || 0) >= 8;
                            } }) }), _jsx(StyledInputFields, { children: _jsx(Input, { ariaLabel: 'password input field', type: 'password', label: 'Confirm New Password', value: confirmPassword, onChange: setConfirmPassword, placeholder: 'Confirm New Password', validate: (value) => {
                                return (((value && value.length) || 0) >= 8 &&
                                    confirmPassword === password);
                            } }) }), isChangePasswordClicked && password.length < 8 && (_jsx(HelperText, { children: "Minimum 8 characters" }))] }), _jsx(ButtonWrapper, { children: _jsx(StyledButton, Object.assign({ ariaLabel: 'change Password', type: 'submit' }, { children: "Change Password" })) })] })));
};
const StyledChangePasswordForm = styled.form `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  align-items: center;
  padding-top: ${(props) => props.theme.spacing[24]};
`;
const StyledInputFields = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
  padding-bottom: ${(props) => props.theme.spacing[16]};
`;
const StyledTopSection = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
`;
const StyledButton = styled(Button) `
  width: 100%;
`;
const HelperText = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.danger[40]};
  padding-bottom: ${(props) => props.theme.spacing[16]};
`;
const ButtonWrapper = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[16]};
  overflow: hidden;
  width: 100%;
`;
ChangePasswordForm.displayName = 'ChangePasswordForm';
export default ChangePasswordForm;
