import { jsx as _jsx } from "react/jsx-runtime";
// styled-components
import { ThemeProvider } from 'styled-components';
// theme
import { theme } from "./theme";
/**
 * ThemeProvider provides a theme to all components within the provider.
 */
const SCThemeProvider = ({ children }) => {
    return _jsx(ThemeProvider, Object.assign({ theme: theme }, { children: children }));
};
SCThemeProvider.componentName = 'ThemeProvider';
export default SCThemeProvider;
