import { jsx as _jsx } from "react/jsx-runtime";
// components
import { Container } from "../../atoms/Container/Container";
import { JourneyFiller } from "../../molecules/JourneyFiller/JourneyFiller";
import { Content } from "../../templates/Content/Content";
/**
 * JourneyPage is a page for the user journey.
 */
export const JourneyPage = ({ title, description, actionLabel, onAction, }) => {
    return (_jsx(Content, { content: _jsx(Container, Object.assign({ size: 'md' }, { children: _jsx(JourneyFiller, { title: title, description: description, actionLabel: actionLabel, onAction: onAction }) })) }));
};
JourneyPage.displayName = 'JourneyPage';
export default JourneyPage;
