import { useContext } from 'react';

import Search from './pages/Search';
import Profile from './pages/Profile';

import { BrowserLocationContext } from './context/BrowserLocationContext';

export default function Router() {
  const { haveProviderId } = useContext(BrowserLocationContext);

  if (haveProviderId) {
    return <Profile />;
  }

  return <Search />;
}
