import api from '.';
import { Relationship } from '@karehero/models';

export const relationshipApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllRelationship: builder.query<Relationship[], void>({
      query: () => '/relationship',
      providesTags: ['Relationships'],
    }),
  }),
});

export const { useGetAllRelationshipQuery } = relationshipApi;

export default relationshipApi;
