import PropTypes from 'prop-types';

import TwoColumn from './TwoColumn';
import BackgroundImage from '../BackgroundImage';
import { styled, media } from '../../styling';

const BannerImage = styled(BackgroundImage)`
  ${media('<tablet')} {
    background: none;
  }
`;

export default function TwoColumnWithBackgroundImage({
  image,
  imagePosition,
  sidebar,
  body,
  sidebarPercent,
}) {
  return (
    <BannerImage source={image} position={imagePosition}>
      <TwoColumn
        sidebarPercent={sidebarPercent}
        sidebar={sidebar}
        body={body}
      />
    </BannerImage>
  );
}

TwoColumnWithBackgroundImage.propTypes = {
  sidebar: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  image: PropTypes.string,
  imagePosition: PropTypes.string,
  sidebarPercent: PropTypes.number,
};

TwoColumnWithBackgroundImage.defaultProps = {
  sidebarPercent: 30,
  imagePosition: undefined,
  image: undefined,
};
