import api from '.';
import { Chat, ChatMessage } from '@karehero/models';

export const chatApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getChat: builder.query<Chat, void>({
      query: () => `/chat`,
      providesTags: ['Chat'],
    }),
    createChat: builder.mutation<void, void>({
      query: (req) => ({
        url: `/chat`,
        method: 'POST',
        body: req,
      }),
      invalidatesTags: ['Chat'],
    }),
    sendChatMessage: builder.mutation<
      ChatMessage,
      { chatID: string; content: string }
    >({
      query: (req) => ({
        url: `/chat/${req.chatID}/message`,
        method: 'POST',
        body: { content: req.content },
      }),
      invalidatesTags: ['Chat'],
    }),
  }),
});

export const {
  useGetChatQuery,
  useCreateChatMutation,
  useSendChatMessageMutation,
} = chatApi;

export default chatApi;
