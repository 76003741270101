import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { Fragment, useMemo } from 'react';
import styled from 'styled-components';
// components
import { DateIcon, LoadingTransition, Skeleton } from "..";
// utils
import { buildImageUrl } from "../../../utils/sanity";
/**
 * WebinarCard is a container for the content of a Card.
 */
export const WebinarCard = ({ webinar, onClick, isLoading, }) => {
    var _a;
    // memos
    const headerImageUrl = useMemo(() => buildImageUrl(webinar === null || webinar === void 0 ? void 0 : webinar.headerImageUrl, 300), [webinar]);
    return (_jsxs(ContentItem, Object.assign({ onClick: () => webinar && (onClick === null || onClick === void 0 ? void 0 : onClick(webinar)) }, { children: [_jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(ContentHeader, { children: _jsx(Skeleton, { shape: 'header', isSquare: true }) }), content: _jsxs(ContentHeader, { children: [_jsx("img", { src: headerImageUrl, alt: 'header', onError: ({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                    'https://cdn.sanity.io/images/x7r1t5o8/production/e8579e2a4c3766cf0cf99a24a9d0679d2d2bbeb4-5322x2365.jpg';
                            } }), _jsx(IconWrapper, { children: _jsx(DateIcon, { date: new Date((webinar === null || webinar === void 0 ? void 0 : webinar.date) || ''), size: 'sm' }) })] }) }), _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsxs(ContentBody, { children: [_jsx(Skeleton, { shape: 'subtitle' }), _jsx(Skeleton, { shape: 'paragraph', paragraphLength: 3 })] }), content: _jsxs(ContentBody, { children: [_jsx(ContentBodyHeader, { children: _jsx(ContentTags, { children: (_a = webinar === null || webinar === void 0 ? void 0 : webinar.tags) === null || _a === void 0 ? void 0 : _a.map((tag, i) => {
                                    var _a;
                                    return (_jsxs(Fragment, { children: [_jsx(ContentTag, { children: tag.label }, `${webinar.title}-${tag.label}`), (webinar === null || webinar === void 0 ? void 0 : webinar.tags) && i !== ((_a = webinar === null || webinar === void 0 ? void 0 : webinar.tags) === null || _a === void 0 ? void 0 : _a.length) - 1 && (_jsx("div", { children: "-" }))] }, i));
                                }) }) }), _jsx(ContentTitle, { children: webinar === null || webinar === void 0 ? void 0 : webinar.title }), _jsx(ContentDescription, { children: webinar === null || webinar === void 0 ? void 0 : webinar.description })] }) })] })));
};
const ContentItem = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-width: 250px;
  height: 300px;
  max-height: 300px;
  box-sizing: border-box;
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[1]};
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};

  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;
const ContentHeader = styled.div `
    min-height: 150px;
    height: 150px;
    max-height: 150px;
display: flex;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
`;
const IconWrapper = styled.div `
  position: absolute;
  top: 128px;
  left: 14px;
  width: 80px;
  height: 80px;
`;
const ContentBody = styled.div `
  padding: ${(props) => props.theme.spacing[16]}
    ${(props) => props.theme.spacing[12]};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]};
  max-height: 150px;
`;
const ContentBodyHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ContentTags = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  font: ${(props) => props.theme.typography.body.xs.regular};
  color: ${(props) => props.theme.color.neutral[70]};
  overflow: hidden;
`;
const ContentTag = styled.div `
  font: ${(props) => props.theme.typography.body.xs.regular};
  color: ${(props) => props.theme.color.neutral[70]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ContentTitle = styled.div `
  font: ${(props) => props.theme.typography.body.sm.semibold};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const ContentDescription = styled.div `
  display: -webkit-box;
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
WebinarCard.displayName = 'WebinarCard';
export default WebinarCard;
