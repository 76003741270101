var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// libs
import Markdown from 'react-markdown';
/**
 * Message renders a message.
 */
export const Message = ({ className, children, value, isSender, }) => {
    return (_jsx(Wrapper, Object.assign({ className: className, "$isSender": isSender }, { children: _jsxs(Content, Object.assign({ "$isSender": isSender }, { children: [children, value &&
                    (isSender ? (value) : (_jsx(Markdown, Object.assign({ components: {
                            p: (_a) => {
                                var { node } = _a, props = __rest(_a, ["node"]);
                                return _jsx("div", Object.assign({}, props));
                            },
                            pre: (_a) => {
                                var { node } = _a, props = __rest(_a, ["node"]);
                                return _jsx(Code, Object.assign({}, props));
                            },
                        } }, { children: value }))))] })) })));
};
const Wrapper = styled.div `
  display: flex;
  ${(props) => props.$isSender
    ? 'justify-content: flex-end;'
    : 'justify-content: flex-start;'}
`;
const Content = styled.div `
  padding: ${(props) => props.theme.spacing[8]}
    ${(props) => props.theme.spacing[12]};
  background: ${(props) => props.$isSender
    ? props.theme.color.info[20]
    : props.theme.color.primary[20]};
  border-radius: 12px;
  font: ${(props) => props.theme.typography.body.sm.regular};
  max-width: 75%;
`;
const Code = styled.pre `
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 8px;
  font-size: 0.9rem;
  font-family: 'Fira Code', monospace;
  overflow-x: auto;
`;
Message.displayName = 'Message';
export default Message;
