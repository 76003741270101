import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// theme
import theme from "../../../theme";
/**
 * Background renders a background.
 */
export const Background = ({ className, children, color = theme.color.neutral[10], }) => {
    return (_jsx(Wrapper, Object.assign({ className: className, "$color": color }, { children: children })));
};
const Wrapper = styled.div `
  height: 100%;
  background: ${(props) => props.$color};
`;
Background.displayName = 'Background';
export default Background;
