import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// radix
import * as RadixTooltip from '@radix-ui/react-tooltip';
/**
 * Tooltip is a component that displays a tooltip on hover.
 */
export const Tooltip = ({ children, content, isSimple = true, }) => {
    // state
    const [isOpen, setIsOpen] = useState(false);
    return (_jsx(RadixTooltip.Provider, { children: _jsxs(RadixTooltip.Root, Object.assign({ delayDuration: 0, open: isOpen, onOpenChange: setIsOpen }, { children: [_jsx("div", Object.assign({ onClick: () => setIsOpen(true) }, { children: _jsx(RadixTooltip.Trigger, Object.assign({ asChild: true }, { children: _jsx(TooltipButton, { children: children }) })) })), _jsx(RadixTooltip.Portal, { children: _jsxs(RadixTooltipContent, Object.assign({ sideOffset: 5, isSimple: isSimple }, { children: [content, isSimple && _jsx(RadixTooltipArrow, {})] })) })] })) }));
};
const TooltipButton = styled.div `
  all: unset;
  display: flex;
  user-select: none;
  height: 100%;
`;
const RadixTooltipContent = styled(RadixTooltip.Content) `
  border-radius: 4px;
  background: ${(props) => props.theme.color.neutral[10]};
  padding: ${(props) => props.theme.spacing[12]}
    ${(props) => props.theme.spacing[16]};
  color: ${(props) => props.theme.color.neutral[90]};
  box-shadow: ${(props) => props.theme.elevation[3]};
  font: ${(props) => props.theme.typography.body.sm.regular};
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  max-width: 320px;

  &[data-state='delayed-open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }
  &[data-state='delayed-open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  &[data-state='delayed-open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  &[data-state='delayed-open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  ${(props) => !props.isSimple &&
    css `
      border: 1px solid ${props.theme.color.primary[40]};
      border-radius: 10px;
      margin: ${props.theme.spacing[12]};
    `}
`;
const RadixTooltipArrow = styled(RadixTooltip.Arrow) `
  fill: ${(props) => props.theme.color.neutral[10]};
`;
Tooltip.displayName = 'Tooltip';
export default Tooltip;
