// react
import { useMemo, useState } from 'react';

// components
import { Avatar, Dialog, ProfilePreview, TablePage } from '@karehero/llama';

// store
import { useDispatch } from 'react-redux';
import { useGetAllQuery } from 'store/api/careCircle';
import { Account } from '@karehero/models';
import { setCurrentCareCircleId } from 'store/slices/careCircleSlice';

// tanstack
import { createColumnHelper } from '@tanstack/react-table';

// columns
interface CareCircleAccount extends Account {
  careCircleId: string;
}
const columnHelper = createColumnHelper<CareCircleAccount>();

const columns = [
  columnHelper.accessor('imgSrc', {
    header: '',
    cell: (info) => {
      const row = info.cell.row.original;
      const fallback =
        `${row.firstName?.[0] || ''}${row.lastName?.[0] || ''}`.toUpperCase();
      return <Avatar size='xs' fallback={fallback} />;
    },
  }),
  columnHelper.accessor('firstName', {
    header: 'First Name',
  }),
  columnHelper.accessor('lastName', {
    header: 'Last Name',
  }),
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('kindeEmail', {
    header: 'Kinde Email',
  }),
];

/**
 * ManageCareCircles is the dashboard page.
 */
const ManageCareCircles = () => {
  // state
  const [isProfilePreviewOpen, setIsProfilePreviewOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] =
    useState<CareCircleAccount | null>(null);

  // hooks
  const { data: careCircles } = useGetAllQuery();
  const dispatch = useDispatch();

  // memos
  const rows = useMemo(() => {
    return careCircles?.map((careCircle) => ({
      ...careCircle.primaryCaregiverAccount,
      careCircleId: careCircle.id,
    }));
  }, [careCircles]);

  const selectedProfileInitials = useMemo(() => {
    if (!selectedProfile) return '';
    return (
      (selectedProfile?.firstName?.[0].toUpperCase() || '') +
      (selectedProfile?.lastName?.[0].toUpperCase() || '')
    );
  }, [selectedProfile]);

  return (
    <>
      <TablePage
        title='Manage Care Circles'
        columns={columns}
        rows={rows}
        actions={[
          {
            label: 'View',
            onPress: (careCircle: CareCircleAccount) => {
              setSelectedProfile(careCircle);
              setIsProfilePreviewOpen(true);
            },
          },
        ]}
      />
      <Dialog
        variant='sheet'
        isOpen={isProfilePreviewOpen}
        setIsOpen={setIsProfilePreviewOpen}
      >
        <ProfilePreview
          fallback={selectedProfileInitials}
          name={`${selectedProfile?.firstName || ''} ${
            selectedProfile?.lastName || ''
          }`}
          email={selectedProfile?.email}
          toViewFullProfile='/'
          onViewFullProfile={() => {
            dispatch(setCurrentCareCircleId(selectedProfile?.careCircleId));
          }}
          onClose={() => setIsProfilePreviewOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default ManageCareCircles;
