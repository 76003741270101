window.careSourcerTheme = window.careSourcerTheme || {};

const spacingUnit = 0.25;
const careSourcer = '#c12b61';

// get client defined theme values or use defaults
const {
  webFontUrls = [],
  primary = careSourcer,
  secondary = '#fcf4ec',
  fontFamilyBody = 'Sans-Serif',
  fontFamilyHeading = 'Sans-Serif',
  baseFontSizePx = 16,
} = window.careSourcerTheme;

const colour = {
  primary,
  secondary,
  neutral: {
    light: '#ffffff',
    mid: '#f5f5f5',
    dark: '#333333',
  },
  brand: {
    careSourcer,
  },
  status: {
    success: '#51ac7c',
    warning: '#ff9c32',
    error: '#9c1111',
    neutral: '#bdbdbd',
    disabled: '#757575',
  },
  feature: {
    rating: {
      registered: '#51ac7c',
      outstanding: '#51ac7c',
      good: '#51ac7c',
      excellent: '#51ac7c',
      veryGood: '#51ac7c',
      adequate: '#ff9c32',
      requiresImprovement: '#ff9c32',
      weak: '#9c1111',
      unsatisfactory: '#9c1111',
      inadequate: '#9c1111',
    },
  },
};

const theme = {
  typography: {
    fontFamilyBody,
    fontFamilyHeading,
    webFontUrls,
    baseFontSizePx,
  },
  colour,
  spacing: {
    unit: spacingUnit,
    units: (multiple) => `${multiple * spacingUnit}rem`,
  },
};

const buildTheme = (custom) => ({
  ...theme,
  ...custom,
});

export default buildTheme({});
