// react
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

// components
import {
  Button,
  Dialog,
  Input,
  ProfilePreview,
  Switch,
  TablePage,
} from '@karehero/llama';

// store
import {
  useCreateInvitedMemberAccountMutation,
  useGetAllInvitedMemberQuery,
} from 'store/api/invitedMember';
import { useInviteMembersToOrganizationAdminMutation } from 'store/api/organization';
import { InvitedMember } from '@karehero/models';
import { newToast } from 'store/slices/toastSlice';
import { useDispatch } from 'react-redux';

// tanstack
import { createColumnHelper } from '@tanstack/react-table';

// columns
const columnHelper = createColumnHelper<InvitedMember>();

const columns = [
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('firstName', {
    header: 'First Name',
  }),
  columnHelper.accessor('tier.name', {
    header: 'Tier',
  }),
];

const validateEmail = (value?: string) => /\S+@\S+\.\S+/.test(value || '');

/**
 * ManageInvitedMembers shows the invited members.
 */
const ManageInvitedMembers = () => {
  // state
  const [isProfilePreviewOpen, setIsProfilePreviewOpen] = useState(false);
  const [selectedProfileID, setSelectedProfileID] = useState<string | null>(
    null,
  );
  const [filterKareHeroEmail, setFilterKareHeroEmail] = useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen] = useState(false);
  const [createAccountKindeEmail, setCreateAccountKindeEmail] = useState('');

  // hooks
  const { data: invitedMembers } = useGetAllInvitedMemberQuery();
  const [createInvitedMemberAccount] = useCreateInvitedMemberAccountMutation();
  const [inviteMembers] = useInviteMembersToOrganizationAdminMutation();
  const dispatch = useDispatch();

  // memos
  const selectedProfile = useMemo(() => {
    if (!selectedProfileID || !invitedMembers) return null;
    return invitedMembers.find(
      (invitedMember) => invitedMember.id === selectedProfileID,
    );
  }, [selectedProfileID, invitedMembers]);

  const rows = useMemo(() => {
    if (!invitedMembers) return [];
    return invitedMembers
      .filter((invitedMember) => {
        if (!filterKareHeroEmail) return true;
        return !invitedMember.email.toLowerCase().includes('@karehero.com');
      })
      .map((invitedMember) => ({
        ...invitedMember,
      }));
  }, [invitedMembers, filterKareHeroEmail]);

  const selectedProfileInitials = useMemo(() => {
    if (!selectedProfile) return '';
    return (selectedProfile?.firstName?.[0] || '').toUpperCase();
  }, [selectedProfile]);

  // methods
  const handleCreateInvitedMemberAccount = useCallback(
    async (id?: string) => {
      if (!id) return;

      const res = await createInvitedMemberAccount({
        id,
        kindeEmail: createAccountKindeEmail,
      });
      if ('error' in res) {
        console.error(res.error);
        return;
      }
      dispatch(
        newToast({
          title: 'Account Created',
          description:
            "The invited member's account has been created successfully.",
        }),
      );
      setIsCreateAccountOpen(false);
      setIsProfilePreviewOpen(false);
    },
    [dispatch, createInvitedMemberAccount, createAccountKindeEmail],
  );

  const handleReinviteMember = useCallback(
    async (id?: string) => {
      if (!id) return;
      const res = await inviteMembers({
        organizationID: selectedProfile?.organizationId || '',
        tierID: selectedProfile?.tierId || '',
        emails: [selectedProfile?.email || ''],
        isForceSend: true,
      });
      if ('error' in res) {
        console.error(res.error);
        return;
      }
      dispatch(
        newToast({
          title: 'Invitation Success',
          description: `Resent invitation to ${selectedProfile?.email}`,
        }),
      );
      setIsProfilePreviewOpen(false);
    },
    [dispatch, selectedProfile, inviteMembers],
  );

  return (
    <>
      <TablePage
        title='Invited Members'
        columns={columns}
        rows={rows}
        rowsPerPage={10}
        actions={[
          {
            label: 'View',
            onPress: (invitedMember: InvitedMember) => {
              setSelectedProfileID(invitedMember.id);
              setIsProfilePreviewOpen(true);
            },
          },
        ]}
        toolbarActions={
          <Switch
            id='filterKareHeroEmail'
            value={filterKareHeroEmail}
            onChange={setFilterKareHeroEmail}
            label='Filter KareHero Emails'
            size='sm'
            isNoWrap
          />
        }
      />
      <Dialog
        variant='sheet'
        isOpen={isProfilePreviewOpen}
        setIsOpen={setIsProfilePreviewOpen}
        zIndex={1}
      >
        <ProfilePreview
          fallback={selectedProfileInitials}
          name={`${selectedProfile?.firstName || ''}`}
          role={selectedProfile?.role?.name}
          tier={selectedProfile?.tier?.name}
          email={selectedProfile?.email}
          onClose={() => setIsProfilePreviewOpen(false)}
          actions={[
            {
              label: 'Reinvite',
              onPress: () => handleReinviteMember(selectedProfile?.id),
            },
            {
              label: 'Create account',
              onPress: () => {
                setCreateAccountKindeEmail(selectedProfile?.email || '');
                setIsCreateAccountOpen(true);
              },
            },
          ]}
          isActionsDisabled
        />
      </Dialog>
      <Dialog
        variant='dialog'
        isOpen={isCreateAccountOpen}
        setIsOpen={setIsCreateAccountOpen}
        title='Create account'
        description={`${selectedProfile?.email}`}
        zIndex={2}
      >
        <CreateAccountWrapper>
          <Input
            label='Type the email address the user will use to login via Kinde'
            value={createAccountKindeEmail}
            onChange={setCreateAccountKindeEmail}
          />
          <Button
            ariaLabel='create account'
            isDisabled={!validateEmail(createAccountKindeEmail)}
            onPress={() =>
              handleCreateInvitedMemberAccount(selectedProfile?.id)
            }
          >
            Create Account
          </Button>
        </CreateAccountWrapper>
      </Dialog>
    </>
  );
};

const CreateAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;

export default ManageInvitedMembers;
