var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// radix
import * as ToggleGroup from '@radix-ui/react-toggle-group';
// components
import { Icon } from "../Icon";
import { BaseField } from "../BaseField/BaseField";
export const ToggleSelect = (_a) => {
    var { id, label, description, tooltip, value, onChange, options, placeholder = '', ariaLabel, isDisabled = false, isRequired = false, isHideTitle = false, defaultValue = '' } = _a, props = __rest(_a, ["id", "label", "description", "tooltip", "value", "onChange", "options", "placeholder", "ariaLabel", "isDisabled", "isRequired", "isHideTitle", "defaultValue"]);
    return (_jsx(BaseField, Object.assign({ id: id, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsx(StyledToggleButtonGroup, Object.assign({ type: 'single', value: value, defaultValue: defaultValue || options[0].value, onValueChange: onChange }, { children: options === null || options === void 0 ? void 0 : options.map((option) => (_jsxs(StyledToggleButton, Object.assign({ value: option.value }, { children: [option.icon && (_jsx(IconWrapper, { children: _jsx(Icon, { fontSize: 16, type: 'fal', icon: option.icon }) })), option.label] }), option.value))) })) })));
};
const StyledToggleButtonGroup = styled(ToggleGroup.Root) `
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.color.neutral[50]};
  border-radius: 4px;
  overflow: hidden;
`;
const StyledToggleButton = styled(ToggleGroup.Item) `
  padding: ${(props) => props.theme.spacing[8]};
  flex-grow: 1;
  user-select: none;
  cursor: pointer;
  border: none;
  color: black;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-state='on'] {
    background-color: ${(props) => props.theme.color.neutral[30]};
  }

  &:focus {
    outline: none;
    background-color: ${(props) => props.theme.color.neutral[30]};
  }
  background: ${(props) => props.theme.color.neutral[10]};
`;
const IconWrapper = styled.div `
  all: unset;
  display: flex;
  padding-right: ${(props) => props.theme.spacing[8]};
`;
ToggleSelect.displayName = 'ToggleSelect';
export default ToggleSelect;
