// react
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import {
  OnboardingActionType,
  OnboardingDirectory,
  OnboardingFlow,
} from '@karehero/llama';

/**
 * OnboardingDirectoryCareHome takes the user through the onboarding flow for a care concierge.
 */
const OnboardingDirectoryCareHome = () => {
  // state
  const [location, setLocation] = useState('');
  const [careHomeType, setCareHomeType] = useState('');

  // hooks
  const navigate = useNavigate();

  // methods
  const handleComplete = useCallback(() => {
    navigate(`/directory?search=${location}&type=${careHomeType}`);
  }, [navigate, location, careHomeType]);

  // memos
  const onboardingDirectoryConfig = useMemo(
    () => [
      {
        id: 'directory',
        Component: OnboardingDirectory,
        config: [
          {
            id: '1',
            title: 'Where are you looking for care?',
            text: 'This will help us recommomend care services closest to the area you need',
            type: 'input',
            value: location,
            onChange: setLocation,
            actions: [
              {
                label: 'Next',
                onClick: OnboardingActionType.Next,
              },
            ],
          },
          {
            id: '2',
            title: 'What type of care home?',
            text: 'This will help us find the right type of care home',
            type: 'radio',
            value: careHomeType,
            onChange: setCareHomeType,
            actions: [
              {
                label: 'Back',
                onClick: OnboardingActionType.Back,
              },
              {
                label: 'Next',
                onClick: OnboardingActionType.Next,
              },
            ],
          },
        ],
      },
    ],
    [location, careHomeType],
  );

  return (
    <OnboardingFlow
      config={onboardingDirectoryConfig}
      onComplete={handleComplete}
    />
  );
};

OnboardingDirectoryCareHome.displayName = 'OnboardingDirectoryCareHome';
export default OnboardingDirectoryCareHome;
