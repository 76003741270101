import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useEffect, useState } from 'react';
import styled from 'styled-components';
// components
import { Container } from "../../atoms";
import { TOS } from "../../molecules";
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
export const OnboardingTOS = toGenericOnboarding(({ setIsNextDisabled }) => {
    // state
    const [isConsent, setIsConsent] = useState(false);
    // effects
    useEffect(() => {
        setIsNextDisabled(!isConsent);
    }, [isConsent, setIsNextDisabled]);
    return (_jsx(Container, Object.assign({ size: 'md' }, { children: _jsx(Wrapper, { children: _jsx(TOS, { isConsent: isConsent, setIsConsent: setIsConsent }) }) })));
});
const Wrapper = styled.div `
  height: 100%;
  padding: ${(props) => props.theme.spacing[32]} 0;
`;
OnboardingTOS.displayName = 'OnboardingTOS';
export default toGenericOnboarding(OnboardingTOS);
