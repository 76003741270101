// theme
import { generateFont, generateWeightedFont } from './types';
export const typography = {
    heading: {
        '1xl': generateFont('DM Serif Display', 16, 1.5, 400),
        '2xl': generateFont('DM Serif Display', 20, 1.3, 400),
        '3xl': generateFont('DM Serif Display', 24, 1.2, 400),
        '4xl': generateFont('DM Serif Display', 32, 1.5, 400),
        '5xl': generateFont('DM Serif Display', 34, 1.2, 400),
        '6xl': generateFont('DM Serif Display', 40, 1.1, 400),
        '7xl': generateFont('DM Serif Display', 48, 1.1, 400),
        '8xl': generateFont('DM Serif Display', 60, 1.1, 400),
        '9xl': generateFont('DM Serif Display', 80, 1, 400),
    },
    body: {
        xxs: generateWeightedFont('Work Sans', 10, 1.5),
        xs: generateWeightedFont('Work Sans', 12, 1.5),
        sm: generateWeightedFont('Work Sans', 14, 1.5),
        md: generateWeightedFont('Work Sans', 16, 1.5),
        lg: generateWeightedFont('Work Sans', 18, 1.5),
        xl: generateWeightedFont('Work Sans', 24, 1.5),
        xxl: generateWeightedFont('Work Sans', 32, 1.5),
        xxxl: generateWeightedFont('Work Sans', 64, 1.5),
    },
    button: {
        sm: generateFont('Work Sans', 14, 1.3, 500),
        md: generateFont('Work Sans', 16, 1.4, 500),
        lg: generateFont('Work Sans', 18, 1.4, 500),
    },
};
export default typography;
