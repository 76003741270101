import api from '.';
import { ActionPlanCategory, ActionPlanItem, Message } from '@karehero/models';

export const actionPlanApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllActionPlan: builder.query<ActionPlanItem[], void>({
      query: () => '/action-plan',
      providesTags: ['ActionPlan'],
    }),
    getMemberActionPlan: builder.query<ActionPlanItem[], void>({
      query: () => '/action-plan/member',
      providesTags: ['ActionPlan'],
    }),
    getActionPlan: builder.query<ActionPlanItem, string>({
      query: (id: string) => `/action-plan/${id}`,
      providesTags: ['ActionPlan'],
    }),
    updateActionPlan: builder.mutation<void, ActionPlanItem>({
      query: (actionPlanItem) => ({
        url: `/action-plan`,
        method: 'PUT',
        body: actionPlanItem,
      }),
      invalidatesTags: ['ActionPlan', 'ActionPlanCategory'],
    }),
    getAllActionPlanCategory: builder.query<ActionPlanCategory[], void>({
      query: () => '/action-plan/category',
      providesTags: ['ActionPlanCategory'],
    }),
    markActionComplete: builder.mutation<void, string>({
      query: (actionID: string) => ({
        url: `/action-plan/${actionID}/complete`,
        method: 'POST',
      }),
      invalidatesTags: ['CareCircles'],
    }),
    getAllActionPlanFeedback: builder.query<
      { actionPlanItemID: string; isFeedback: boolean }[],
      void
    >({
      query: () => '/action-plan/feedback',
      providesTags: ['ActionPlanFeedback'],
    }),
    markActionFeedback: builder.mutation<void, string>({
      query: (actionID: string) => ({
        url: `/action-plan/${actionID}/feedback`,
        method: 'POST',
      }),
      invalidatesTags: ['CareCircles', 'ActionPlanFeedback'],
    }),
    generateActionPlan: builder.mutation<Message, void>({
      query: () => ({
        url: `/action-plan/generate`,
        method: 'POST',
      }),
      invalidatesTags: ['ActionPlan'],
    }),
    markActionDismissed: builder.mutation<void, string>({
      query: (actionID: string) => ({
        url: `/action-plan/${actionID}/dismiss`,
        method: 'POST',
      }),
      invalidatesTags: ['CareCircles'],
    }),
  }),
});

export const {
  useGetActionPlanQuery,
  useGetAllActionPlanQuery,
  useGetMemberActionPlanQuery,
  useUpdateActionPlanMutation,
  useGetAllActionPlanCategoryQuery,
  useMarkActionCompleteMutation,
  useGetAllActionPlanFeedbackQuery,
  useMarkActionFeedbackMutation,
  useGenerateActionPlanMutation,
  useMarkActionDismissedMutation,
} = actionPlanApi;

export default actionPlanApi;
