import { styled } from '../../styling';

const PositionFixed = styled.div`
  position: fixed;
  ${({ theme, top, bottom, left, right }) => `
    top: ${top >= 0 ? theme.spacing.units(top) : 'initial'};
    bottom: ${bottom >= 0 ? theme.spacing.units(bottom) : 'initial'};
    left: ${left >= 0 ? theme.spacing.units(left) : 'initial'};
    right: ${right >= 0 ? theme.spacing.units(right) : 'initial'};
  `};
`;

export default PositionFixed;
