// react
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

// components
import { CareCircleMemberInfo as CareCircleMemberInfoPage } from '@karehero/llama';

// hooks
import { useCareCircle } from 'hooks';

// models
import { CareCircleMember } from '@karehero/models';

// store
import { useGetAllRelationshipQuery } from 'store/api/relationship';

const CareCircleMemberInfo = () => {
  // hooks
  const { currentCareCircle: careCircle } = useCareCircle();
  const { accountID } = useParams();
  const { data: relationships } = useGetAllRelationshipQuery();

  // memos
  const careCircleMember = useMemo(() => {
    if (!careCircle) return;
    const { careCircleMembers = [] } = careCircle;
    return careCircleMembers.find(
      (member: CareCircleMember) => member.id === accountID,
    );
  }, [careCircle, accountID]);

  return (
    <CareCircleMemberInfoPage
      careCircle={careCircle}
      careCircleMember={careCircleMember}
      relationships={relationships || []}
    />
  );
};

export default CareCircleMemberInfo;
