import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Button, Container, Logo, TextIcon } from "../../atoms";
import { OnboardingActionType, toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
import { FloatingWithHeroTemplate } from "../../templates";
// assets
// @transform-path ../../../../../../src/assets/hero.svg
import hero from "../../../../../../src/assets/hero.svg";
/**
 * OnboardingHero renders the Hero screen for onboarding flows.
 */
export const OnboardingHero = toGenericOnboarding(({ config, currentIndex = 0, handleClick, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return (_jsx(FloatingWithHeroTemplate, { content: _jsx(Container, Object.assign({ size: 'md' }, { children: _jsxs(ContentContainer, { children: [_jsx(LogoContainer, { children: _jsx(Logo, { size: 'lg' }) }), _jsxs("div", { children: [_jsx(GreetingTitle, { children: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.title }), _jsxs(TextWrapper, { children: [((_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.text1) && (_jsx(TextIcon, { text: (_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.text1, centerText: !((_d = config[currentIndex]) === null || _d === void 0 ? void 0 : _d.text2), showIcon: !!((_e = config[currentIndex]) === null || _e === void 0 ? void 0 : _e.text2) })), ((_f = config[currentIndex]) === null || _f === void 0 ? void 0 : _f.text2) && (_jsx(TextIcon, { text: (_g = config[currentIndex]) === null || _g === void 0 ? void 0 : _g.text2, centerText: false, showIcon: true })), ((_h = config[currentIndex]) === null || _h === void 0 ? void 0 : _h.text3) && (_jsx(TextIcon, { text: (_j = config[currentIndex]) === null || _j === void 0 ? void 0 : _j.text3, centerText: false, showIcon: true }))] })] }), _jsx(Button, Object.assign({ ariaLabel: (_k = config[currentIndex]) === null || _k === void 0 ? void 0 : _k.ctaButtonLabel, onPress: () => handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.Next) }, { children: (_l = config[currentIndex]) === null || _l === void 0 ? void 0 : _l.ctaButtonLabel }))] }) })), hero: _jsx(HeroContainer, { children: _jsx("img", { src: hero, alt: 'hero' }) }) }));
});
const LogoContainer = styled.div `
  display: flex;
  justify-content: center;
`;
const ContentContainer = styled.div `
  padding: 0 ${(props) => props.theme.spacing[12]};
  box-sizing: border-box;

  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
const GreetingTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['6xl']};
  color: ${(props) => props.theme.color.primary[80]};
  margin-bottom: ${(props) => props.theme.spacing[48]};
  text-align: center;
`;
const TextWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
const HeroContainer = styled.div `
  display: flex;
  overflow: hidden;
  border-radius: 16px;
  height: 100%;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
OnboardingHero.displayName = 'OnboardingHero';
export default toGenericOnboarding(OnboardingHero);
