import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Button, LoadingTransition, Skeleton } from "..";
const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
/**
 * CallToActionWebinar is a container for the content of a Card.
 */
export const CallToActionWebinar = ({ date, time, location = 'Online', headerImageUrl, onClick, isLoading, }) => {
    // memos
    return (_jsxs(ContentItem, Object.assign({ onClick: () => onClick === null || onClick === void 0 ? void 0 : onClick('url') }, { children: [_jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(ContentHeader, { children: _jsx(Skeleton, { shape: 'header', isSquare: true }) }), content: _jsx(ContentHeader, { children: _jsx("img", { src: headerImageUrl, alt: 'header', onError: ({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src =
                                'https://cdn.sanity.io/images/x7r1t5o8/production/e8579e2a4c3766cf0cf99a24a9d0679d2d2bbeb4-5322x2365.jpg?h=180';
                        } }) }) }), _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsxs(SkeletonBody, { children: [_jsx(Skeleton, { shape: 'paragraph' }), _jsx(Skeleton, { shape: 'button' })] }), content: _jsxs(ContentBody, { children: [_jsx(ContentTitle, { children: "Book This Webinar" }), _jsxs(Details, { children: [_jsxs(DetailRow, { children: [_jsx(DetailTitle, { children: "Day:" }), _jsx(DetailField, { children: days[date === null || date === void 0 ? void 0 : date.getDay()] })] }), _jsxs(DetailRow, { children: [_jsx(DetailTitle, { children: "Date:" }), _jsx(DetailField, { children: date === null || date === void 0 ? void 0 : date.toLocaleDateString() })] }), _jsxs(DetailRow, { children: [_jsx(DetailTitle, { children: "Time:" }), _jsx(DetailField, { children: time })] }), _jsxs(DetailRow, { children: [_jsx(DetailTitle, { children: "Location:" }), _jsx(DetailField, { children: location })] })] }), _jsx(Button, Object.assign({ ariaLabel: 'Book this Webinar' }, { children: "Book this webinar" }))] }) })] })));
};
const Details = styled.div `
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  padding: ${(props) => props.theme.spacing[4]};
`;
const DetailRow = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`;
const DetailTitle = styled.div `
  font: ${(props) => props.theme.typography.body.sm.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const DetailField = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const ContentItem = styled.div `
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 310px;
  min-width: 310px;
  max-width: 310px;
  height: 445px;
  max-height: 445px;
  box-sizing: border-box;
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[1]};
  overflow: hidden;
  user-select: none;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
`;
const ContentHeader = styled.div `
  min-height: 180px;
  height: 180px;
  max-height: 180px;
  display: flex;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
const ContentBody = styled.div `
  padding: ${(props) => props.theme.spacing[24]}
    ${(props) => props.theme.spacing[36]};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]};
  max-height: 150px;
`;
const SkeletonBody = styled.div `
  padding: ${(props) => props.theme.spacing[24]}
    ${(props) => props.theme.spacing[36]};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[4]};
  max-height: 150px;
`;
const ContentTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
CallToActionWebinar.displayName = 'CallToActionWebinar';
export default CallToActionWebinar;
