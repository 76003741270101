import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState, useCallback } from 'react';
import styled from 'styled-components';
// components
import { Container, Title, RadioGroup } from "../../atoms";
import { FloatingActions } from "../../molecules";
/**
 * CarePlanChoice asks the user to choose between two care plans.
 */
export const CarePlanChoice = ({ className, toBack, toNext, navigate, }) => {
    // state
    const [value, setValue] = useState('residential');
    // methods
    const handleBack = useCallback(() => {
        navigate(toBack);
    }, [toBack]);
    const handleNext = useCallback(() => {
        navigate(`${toNext}/${value}`);
    }, [toNext, value]);
    return (_jsx(Container, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(Title, { children: "Choose a plan" }), _jsx(RadioGroup, { id: 'care-plan-choice', label: 'Which plan suits the customer best?', options: [
                        { label: 'Residential care plan', value: 'residential' },
                        { label: 'Care at home plan', value: 'home' },
                    ], value: value, onChange: setValue }), _jsx(FloatingActions, { actions: [
                        { label: 'Back', onPress: handleBack, variant: 'secondary' },
                        { label: 'Continue', onPress: handleNext },
                    ] })] }) })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  margin-top: ${(props) => props.theme.spacing[64]};
`;
CarePlanChoice.displayName = 'CarePlanChoice';
export default CarePlanChoice;
