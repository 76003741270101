import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { Container } from "../../atoms/Container/Container";
import { Logo } from "../../atoms/Logo/Logo";
import { Button, ButtonSocial, Divider, Title } from "../../atoms";
/**
 * Register is the page where the user can sign in.
 */
export const Register = ({ onGoogleRegister, onFacebookRegister, onAppleRegister, onEmailRegister, }) => {
    return (_jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(Logo, { size: 'md' }), _jsx(ContentWrapper, { children: _jsx(StyledTitle, { children: "Complete your registration" }) }), _jsx(ButtonContainer, { children: _jsxs(ButtonSocialContainer, { children: [onAppleRegister && (_jsx(ButtonSocial, Object.assign({ variant: 'apple', ariaLabel: 'register with Apple', onPress: onAppleRegister, isFullWidth: true }, { children: "Register with Apple" }))), onGoogleRegister && (_jsx(ButtonSocial, Object.assign({ variant: 'google', ariaLabel: 'register with Google', onPress: onGoogleRegister, isFullWidth: true }, { children: "Register with Google" }))), onFacebookRegister && (_jsx(ButtonSocial, Object.assign({ variant: 'facebook', ariaLabel: 'register with Facebook', onPress: onFacebookRegister, isFullWidth: true }, { children: "Register with Facebook" }))), (onGoogleRegister || onFacebookRegister || onAppleRegister) && (_jsx(Divider, {})), _jsx(Button, Object.assign({ variant: 'link', ariaLabel: 'register with email', isFullWidth: true, onPress: onEmailRegister }, { children: "Register with personal email" }))] }) })] }) })));
};
const StyledContainer = styled(Container) `
  background: ${(props) => props.theme.color.neutral[20]};
  overflow-y: auto;
  height: 100%;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing[8]};
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.spacing[32]} 0;
`;
const StyledTitle = styled(Title) `
  text-align: center;
  font: ${(props) => props.theme.typography.heading['3xl']};
  margin: 0;

  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.heading['5xl']};
    `)}
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[48]};
  margin-bottom: ${(props) => props.theme.spacing[64]};
`;
const ButtonSocialContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[12]};
`;
Register.displayName = 'Register';
export default Register;
