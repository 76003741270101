import { jsx as _jsx } from "react/jsx-runtime";
// lottie
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
// assets
// @transform-path ../../../../../../src/assets/animation/success.json
import successAnimation from "../../../../../../src/assets/animation/success.json";
/**
 * LottieAnimation displays a Lottie animation.
 */
export const LottieAnimation = ({ src = successAnimation, loop = true, autoplay = true, }) => {
    return _jsx(LottiePlayer, { autoplay: autoplay, loop: loop, src: src });
};
LottieAnimation.displayName = 'LottieAnimation';
export default LottieAnimation;
