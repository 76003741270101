import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Icon, Card } from "../../atoms";
/**
 * CallDetails renders the card with the details of the call.
 */
export const CallDetails = ({ callData }) => {
    return (_jsxs(Card, Object.assign({ color: 'blue', isBordered: false }, { children: [_jsx(ContentTitle, { children: "Call details" }), _jsx(Content, { children: _jsx(StyledTable, { children: callData === null || callData === void 0 ? void 0 : callData.map((row) => row.data !== '' && (_jsxs(StyledRow, { children: [_jsx("td", { children: _jsxs(IconWrapper, { children: [_jsx(StyledIcon, { icon: row.icon, fontSize: 22 }), _jsx(Title, { children: row.title })] }) }), _jsx("td", { children: _jsx(DataText, { children: row.data }) })] }, row.id))) }) })] }), 'card-content'));
};
const IconWrapper = styled.div `
  display: flex;
  align-items: center;
`;
const StyledIcon = styled(Icon) `
  color: ${(props) => props.theme.color.primary[80]};
  width: 24px;
  margin-right: ${(props) => props.theme.spacing[16]};
`;
const Title = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.heading['1xl']};
  font-weight: 600;
`;
const DataText = styled.div `
  color: ${(props) => props.theme.color.neutral[80]};
  font: ${(props) => props.theme.typography.body.md};
`;
const StyledTable = styled.table `
  display: flex;
  flex-direction: column;
`;
const StyledRow = styled.tr `
  padding-bottom: ${(props) => props.theme.spacing[8]};
`;
const LinkWrapper = styled.div `
  display: flex;
`;
const Content = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const ContentTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  margin-bottom: ${(props) => props.theme.spacing[12]};
  color: ${(props) => props.theme.color.neutral[90]};
`;
CallDetails.displayName = 'CallDetails';
export default CallDetails;
