// launchdarkly
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

// models
import { Account, LdContext } from '@karehero/models';

export const createLdContext = (account?: Account): LdContext => {
  if (!account) return defaultLdConfig.context;
  let name = `${account.firstName}`;
  if (account.lastName) {
    name += ` ${account.lastName}`;
  }
  return {
    kind: 'multi',
    user: {
      key: account.id,
      name: name,
      email: account.email,
      kindeEmail: account.kindeEmail,
    },
    organization: {
      key: account.organization.id,
      name: account.organization.name,
    },
  };
};

export const defaultLdConfig = {
  clientSideID:
    process.env.REACT_APP_LD_CLIENT_ID || '641c7a902af7951336242c93',
  context: {
    kind: 'multi',
    user: {
      key: 'unauthenticated_user',
      name: 'Unauthenticated user',
      email: '',
      kindeEmail: '',
    },
    organization: {
      key: 'unauthenticated_organization',
      name: 'Unauthenticated organization',
    },
  },
  options: {
    streaming: true,
  },
};

/**
 * getLdProvider returns a promise that resolves to a LaunchDarkly provider.
 */
export const getLdProvider = async () => {
  const reduxState = JSON.parse(localStorage.getItem('reduxState') || '{}');
  const ldContext = createLdContext(reduxState?.iam?.account);
  const ldConfig = {
    clientSideID: defaultLdConfig.clientSideID,
    context: {
      ...(ldContext || defaultLdConfig.context),
    },
    options: defaultLdConfig.options,
  };

  return await asyncWithLDProvider(ldConfig);
};
