import { useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '../../../styling';

let id = 0;
const getNextId = () => {
  id += 1;
  return id;
};
const toSnakeCase = (value) => `${value}`.replace(' ', '-');
const getIdFor = (...args) =>
  `${args.reduce(
    (string, arg) => `${string}-${toSnakeCase(arg)}`,
  )}-${getNextId()}`;

const StyledInput = styled.input`
  cursor: pointer;
  position: absolute;
  opacity: 0;
`;

const Container = styled.div`
  position: relative;
`;

const HiddenInput = ({
  type,
  name,
  value,
  checked,
  onChange,
  children,
  disabled,
}) => {
  const [focused, setFocused] = useState(false);
  const radioId = getIdFor(name, value);
  const handleChange = ({ target }) => onChange(target.value);

  return (
    <Container>
      <StyledInput
        type={type}
        name={name}
        value={value}
        checked={checked}
        id={radioId}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        disabled={disabled}
      />
      <label htmlFor={radioId}>{children(focused)}</label>
    </Container>
  );
};
HiddenInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

HiddenInput.defaultProps = {
  checked: false,
  disabled: false,
};

export default HiddenInput;
