import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
// components
import { ActionPlanCard } from "..";
import { CardContainer } from "../../templates";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import { theme } from "../../../theme";
export const ActionPlanCardGroup = ({ actionPlanItems, onDismiss, }) => {
    const [width] = useWindowSize();
    return (_jsx(CardContainer, Object.assign({ maxColumns: width < theme.breakpoint.lgValue ? 1 : 2, size: 'sm' }, { children: actionPlanItems === null || actionPlanItems === void 0 ? void 0 : actionPlanItems.map((card) => {
            var _a, _b, _c;
            return (_createElement(ActionPlanCard, Object.assign({ subTitle: (_a = card.actionPlanCategory) === null || _a === void 0 ? void 0 : _a.title, color: (_b = card.actionPlanCategory) === null || _b === void 0 ? void 0 : _b.color, icon: (_c = card.actionPlanCategory) === null || _c === void 0 ? void 0 : _c.icon }, card, { to: `/action-plan/${card.id}`, key: card.id, onDismiss: onDismiss, isModify: card.isModify })));
        }) })));
};
ActionPlanCardGroup.displayName = 'ActionPlanCardGroup';
export default ActionPlanCardGroup;
