// components
import { PoaSuccess as PoaSuccessPage } from '@karehero/llama';

/**
 * PoaSuccess renders the POA success screen.
 */
const PoaSuccess = () => {
  return <PoaSuccessPage />;
};

PoaSuccess.displayName = 'PoaSuccess';
export default PoaSuccess;
