import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
import { Checkbox } from "../../atoms";
/**
 * OnboardingDataConsent renders the DataConsent screen for onboarding flows.
 */
export const OnboardingDataConsent = toGenericOnboarding(({ setIsNextDisabled, }) => {
    const [isCheckbox1Checked, setIsCheckbox1Checked] = useState(false);
    const [isCheckbox2Checked, setIsCheckbox2Checked] = useState(false);
    const [isCheckbox3Checked, setIsCheckbox3Checked] = useState(false);
    useEffect(() => {
        setIsNextDisabled(!(isCheckbox1Checked && isCheckbox2Checked && isCheckbox3Checked));
    }, [isCheckbox1Checked, isCheckbox2Checked, isCheckbox3Checked]);
    return (_jsx(Wrapper, { children: _jsxs(Content, { children: [_jsx(Title, { children: "Data Consent" }), _jsx(SubTitle, { children: "Your information is secure, and you're in charge of who has access to it." }), _jsx(Text, { children: "Your information is secure, and you're in charge of who has access to it." }), _jsx(SemiBoldText, { children: "Data use" }), _jsxs(CheckboxWrapper, { children: [_jsx(Checkbox, { id: 'data-consent-authority', label: 'I have the authority to consent for those receiving care and will ensure they understand how their information will be used.', value: isCheckbox1Checked, onChange: () => setIsCheckbox1Checked(!isCheckbox1Checked) }), _jsx(Checkbox, { id: 'data-consent-agree', label: 'I agree to KareHero and its carers collecting, using and sharing the personal health information associated with this account to enable the ongoing delivery of effective care and support.', value: isCheckbox2Checked, onChange: () => setIsCheckbox2Checked(!isCheckbox2Checked) }), _jsx(Checkbox, { id: 'data-consent-understand', label: 'I understand that health information and other personal information associated with this account may be shared with medical professionals, family members or others where explicit consent is provided.', value: isCheckbox3Checked, onChange: () => setIsCheckbox3Checked(!isCheckbox3Checked) })] })] }) }));
});
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${(props) => props.theme.spacing[24]};
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: content;
  max-width: 520px;
  min-height: content;
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
  margin-bottom: ${(props) => props.theme.spacing[16]};
  text-align: center;

  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.heading['3xl']};
    `)}
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  margin-bottom: ${(props) => props.theme.spacing[24]};
  text-align: left;
  width: 100%;
`;
const SemiBoldText = styled.div `
  font: ${(props) => props.theme.typography.body.md.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
  margin-bottom: ${(props) => props.theme.spacing[12]};
  text-align: left;
  width: 100%;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['4xl']};
  color: ${(props) => props.theme.color.primary[80]};
  min-height: 60px;
  text-align: center;
`;
const CheckboxWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};

  ${(props) => props.theme.breakpoint.sm(css `
      gap: ${props.theme.spacing[32]};
    `)}
`;
OnboardingDataConsent.displayName = 'OnboardingDataConsent';
export default toGenericOnboarding(OnboardingDataConsent);
