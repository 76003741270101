// react
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useCareProfile } from 'hooks';
import { useSubmitCareProfileFormMutation } from 'store/api/careProfile';

// components
import {
  AttendanceAllowanceComplete as AttendanceAllowanceCompletePage,
  CareProfileRoot,
} from '@karehero/llama';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

/**
 * AttendanceAllowanceComplete is the dashboard page.
 */
const AttendanceAllowanceComplete = () => {
  // hooks
  const navigate = useNavigate();
  const { track } = useMixpanel();
  const { numCompleted, numTotal, careCircle, sections } = useCareProfile({
    categoryID: 'attendance-allowance',
  });
  const [submitCareProfileForm] = useSubmitCareProfileFormMutation();

  // methods
  const handleSubmit = () => {
    careCircle?.careProfile?.id &&
      submitCareProfileForm(careCircle?.careProfile?.id);
    navigate(`/attendance-allowance/success`);
  };

  // effects
  useEffect(() => {
    track(MixpanelEvent.AttendanceAllowanceComplete, {});
  }, [track]);

  return (
    <CareProfileRoot
      backLabel='Back'
      nextLabel='Submit form'
      limit={numTotal}
      progress={numCompleted}
      sections={sections}
      name={careCircle?.careRecipientAccount?.firstName}
      title={`${
        careCircle?.careRecipientAccount?.firstName || 'Your Loved One'
      }'s Attendance Allowance`}
      toExit='/care-profile?tab=3'
      onNext={handleSubmit}
      toPrevious='/care-profile?tab=3'
      isFormCompleted={numTotal !== numCompleted}
    >
      <AttendanceAllowanceCompletePage />
    </CareProfileRoot>
  );
};

export default AttendanceAllowanceComplete;
