import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
/**
 * Content is a container for content, it has a margin top.
 */
export const Content = ({ content }) => {
    return _jsx(StyledContent, { children: content });
};
const StyledContent = styled.div `
  padding: ${(props) => props.theme.spacing[24]} 0;
  ${(props) => props.theme.breakpoint.sm(css `
      padding: ${(props) => props.theme.spacing[32]} 0;
    `)}
`;
Content.displayName = 'Content';
export default Content;
