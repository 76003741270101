import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState } from 'react';
import styled from 'styled-components';
// components
import { Button, Input } from "../../atoms";
/**
 * ResetPasswordForm is a component that renders an email and password input field with
 * a submit button.
 */
export const ResetPasswordForm = ({ onSubmit }) => {
    const [password, setPassword] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(password);
    };
    return (_jsxs(StyledResetPasswordForm, Object.assign({ onSubmit: handleSubmit }, { children: [_jsx(StyledTopSection, { children: _jsx(StyledInputFields, { children: _jsx(Input, { ariaLabel: 'password input field', type: 'password', label: 'New Password', value: password, onChange: setPassword, placeholder: 'New Password' }) }) }), _jsx(StyledButton, Object.assign({ ariaLabel: 'sign in', type: 'submit' }, { children: "Reset Password" }))] })));
};
const StyledResetPasswordForm = styled.form `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  align-items: center;
`;
const StyledInputFields = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
`;
const StyledTopSection = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
`;
const StyledButton = styled(Button) `
  width: 100%;
`;
ResetPasswordForm.displayName = 'ResetPasswordForm';
export default ResetPasswordForm;
