// react
import { useEffect } from 'react';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// components
import { CannotBookCall as CannotBookCallPage } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';

/**
 * CannotBookCall shows the CannotBookCallPage component.
 */
const CannotBookCall = () => {
  // hooks
  const { careCircle } = useCareProfile();

  const { track } = useMixpanel();

  // effects
  useEffect(() => {
    track(MixpanelEvent.BookCallFailed, {});
  }, [track]);

  return <CannotBookCallPage careCircle={careCircle} />;
};

export default CannotBookCall;
