import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
// components
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
export const OnboardingComponent = toGenericOnboarding(({ config, currentIndex = 0, }) => {
    const component = useMemo(() => {
        var _a;
        return (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.component;
    }, [config, currentIndex]);
    return _jsx(_Fragment, { children: component });
});
OnboardingComponent.displayName = 'OnboardingComponent';
export default toGenericOnboarding(OnboardingComponent);
