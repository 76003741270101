// react
import { useNavigate } from 'react-router-dom';

// components
import { CarePlanApprove as CarePlanApprovePage } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';

// store
import { useApproveCarePlanMutation } from 'store/api/carePlan';

/**
 * CarePlanApprove shows the care plan complete page.
 */
const CarePlanApprove = () => {
  // hooks
  const { careCircle } = useCareProfile();
  const [approveCarePlan] = useApproveCarePlanMutation();
  const navigate = useNavigate();

  return (
    <CarePlanApprovePage
      onApprove={async () => {
        await approveCarePlan(careCircle?.id || '');
        navigate(`/care-profile?tab=3`);
      }}
    />
  );
};

export default CarePlanApprove;
