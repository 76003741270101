// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// store
import { useGetAllArticleLibraryQuery } from 'store/api/cms';
import {
  useBookmarkArticleMutation,
  useGetAllArticleBookmarkQuery,
  useLazyGetAllArticleByTagQuery,
  useLazySearchArticleQuery,
} from 'store/api/cms';
import { Article } from '@karehero/models';

// components
import {
  ArticleOverview as ArticleOverviewPage,
  QuickResult,
} from '@karehero/llama';

// launch darkly
import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * ArticleOverview shows the content library.
 */
const ArticleOverview = () => {
  // state
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<QuickResult[]>([]);
  const [filteredArticles, setFilteredArticles] = useState<
    Article[] | undefined
  >();
  const [isFilteredLoading, setIsFilteredLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isShowingResults, setIsShowingResults] = useState(false);

  // hooks
  const [searchParams] = useSearchParams();
  const { data: library } = useGetAllArticleLibraryQuery();
  const [searchArticle, { isLoading }] = useLazySearchArticleQuery();
  const [getArticlesByTag] = useLazyGetAllArticleByTagQuery();
  const [bookmarkArticle] = useBookmarkArticleMutation();
  const { data: articleBookmarks } = useGetAllArticleBookmarkQuery();
  const flags = useFlags();

  // methods
  const articlesToQuickResults = useCallback(
    (articles: Partial<Article>[], numResults: number) => {
      return (
        articles
          .map((a) => ({
            title: a.title || '',
            category: a?.tags?.length || -1 > 0 ? a?.tags?.[0]?.label : 'Care',
            to: `/library/${a.slug?.current}`,
          }))
          .slice(0, numResults) || []
      );
    },
    [],
  );

  // memos
  const suggestedArticles = useMemo(() => {
    if (!library) return [];
    return articlesToQuickResults(
      library.find((l) => l.title === 'Suggested for you')?.articles || [],
      5,
    );
  }, [library, articlesToQuickResults]);

  const filterChips = useMemo(() => {
    const filters = [
      'All',
      'Care needs',
      'Care funding',
      'Support',
      'Dementia',
      'Bereavement',
      'Finding care',
    ];

    return filters.map((f) => ({
      label: f,
      isSelected:
        (f === 'All' && !searchParams.get('filter')) ||
        searchParams.get('filter') === f,
      to: f === 'All' ? `/library` : `/library?filter=${f}`,
    }));
  }, [searchParams]);

  // effects
  useEffect(() => {
    setIsSearchLoading(true);
    const timeoutId = setTimeout(async () => {
      if (searchValue !== '') {
        const articles = await searchArticle(searchValue).unwrap();
        setSearchResults(articlesToQuickResults(articles, 5) || []);
      }
      setIsShowingResults(searchValue !== '');
      setIsSearchLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchValue, searchArticle, articlesToQuickResults]);

  useEffect(() => {
    setIsFilteredLoading(true);
    const tag = searchParams.get('filter');
    const timeoutId = setTimeout(() => {
      if (!tag) {
        setFilteredArticles(undefined);
        setIsFilteredLoading(false);
        return;
      }
      (async (tag: string) => {
        const articles = await getArticlesByTag(tag).unwrap();
        setFilteredArticles(articles);
        setIsFilteredLoading(false);
      })(tag);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [searchParams, getArticlesByTag]);

  return (
    <ArticleOverviewPage
      library={library}
      bookmarkedArticles={articleBookmarks}
      onBookmark={(article) => bookmarkArticle(article._id || '')}
      isLoading={!library || isFilteredLoading}
      searchValue={searchValue}
      onSearchChange={(value: string) => setSearchValue(value)}
      isSearchLoading={isLoading || isSearchLoading}
      quickResultsHeader={
        isSearchLoading
          ? 'Searching...'
          : isShowingResults
            ? 'Search results'
            : 'Suggested articles'
      }
      quickResults={isShowingResults ? searchResults : suggestedArticles}
      filterChips={filterChips}
      filteredArticles={filteredArticles}
      isFilteredLoading={!!searchParams.get('filter')}
      isOnboarding={false}
      toBookmarks='/library/bookmarks'
      isEasterEgg={
        false && process.env.NODE_ENV === 'development' && flags.easterEgg
      }
    />
  );
};

export default ArticleOverview;
