import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
/**
 * FloatingTemplate shows the content floating 20% away from the top of the
 * screen.
 */
export const FloatingTemplate = ({ content }) => {
    return (_jsx(FloatingContainer, { children: _jsx(FloatingContent, { children: content }) }));
};
const FloatingContainer = styled.div `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  height: 100%;
`;
const FloatingContent = styled.div `
  position: relative;
  top: 10%;
  width: 100%;
  padding-bottom: ${(props) => props.theme.spacing[32]};
`;
FloatingTemplate.displayName = 'FloatingTemplate';
export default FloatingTemplate;
