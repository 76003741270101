// react
import { createSlice } from '@reduxjs/toolkit';

export interface CareProfileState {}

export const careProfileSlice = createSlice({
  name: 'careProfile',
  initialState: {} as CareProfileState,
  reducers: {},
});

export default careProfileSlice.reducer;
