import { styled } from '../styling';

const Row = styled.div`
  display: flex;
  padding: 0;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  ${({ fullWidth }) => fullWidth && 'width: 100%;'};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'nowrap')};
  ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'};
  ${({ justifyCentre }) => justifyCentre && 'justify-content: center;'};
  ${({ alignCentre }) => alignCentre && 'align-items: center;'};
  ${({ alignBaseline }) => alignBaseline && 'align-items: baseline;'};
  ${({ grow }) => grow && 'flex-grow: 1;'};
`;

export default Row;
