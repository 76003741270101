// react
import { useNavigate, useParams } from 'react-router-dom';

// components
import { CareCirclePage } from '@karehero/llama';

// store
import { useGetCareCircleQuery } from 'store/api/careCircle';

/**
 * CareCircle is the dashboard page.
 */
const CareCircle = () => {
  // hooks
  const { careCircleId } = useParams();
  const { data: careCircle } = useGetCareCircleQuery(careCircleId || '');
  const navigate = useNavigate();

  return (
    <CareCirclePage careCircle={careCircle} onAdd={() => navigate('new')} />
  );
};

export default CareCircle;
