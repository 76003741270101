// react
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useOutlet } from 'react-router-dom';
import styled from 'styled-components';

// components
import {
  Container,
  DrawerTemplate,
  Link,
  NavBarMobile,
  NavDrawer,
  theme,
  Transition,
} from '@karehero/llama';

// store
import { dispatch, useSelector } from 'store';
import { isDrawerCollapsed, toggleDrawer } from 'store/slices/navigationSlice';

// hooks
import { useWindowSize } from 'hooks';

/**
 * RootOrganizationOwner is the main component of the application.
 */
const RootOrganizationOwner = () => {
  // refs
  const nodeRef = useRef(null);

  // hooks
  const currentOutlet = useOutlet();
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const isCollapsed = useSelector(isDrawerCollapsed);

  // memo
  const isMobile = useMemo(() => {
    return windowSize[0] <= theme.breakpoint.smValue;
  }, [windowSize]);

  const isHideUi = useMemo(
    () =>
      location.pathname.includes('prototype') ||
      location.pathname.includes('care-profile/') ||
      location.pathname.includes('care-plan/') ||
      location.pathname.includes('attendance-allowance/') ||
      location.pathname.includes('action-plan/') ||
      location.pathname.includes('/action-plan') ||
      location.pathname === '/book-call' ||
      location.pathname.includes('/directory') ||
      location.pathname.includes('/onboarding-questions') ||
      location.pathname === '/',
    [location],
  );

  // effects
  useEffect(() => {
    document
      .getElementById('drawer-template-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  const navVerticalProps = useMemo(() => {
    let links: Link[] = [
      {
        icon: 'paper-plane-top',
        label: 'Invite Portal',
        to: `/`,
      },
      {
        icon: 'book',
        label: 'Resources',
        to: `/resources`,
      },
    ];

    return {
      imgSrc: '',
      fallback: '',
      name: '',
      role: '',
      percentageComplete: 0,
      navigate,
      isCollapsed,
      onToggleCollapse: () => dispatch(toggleDrawer()),
      currentLink: location.pathname,
      links: links,
    };
  }, [isCollapsed, location.pathname, navigate]);

  return (
    <RootOrganizationOwnerWrapper>
      <DrawerTemplate
        isOverflowHidden={location.pathname.includes('prototype')}
        isNoPadding={isHideUi}
        drawer={
          !location.pathname.includes('/poa-success') &&
          !location.pathname.includes('attendance-allowance/') &&
          !location.pathname.includes('care-profile/') &&
          !location.pathname.includes('care-plan/') && (
            <NavDrawer {...navVerticalProps} isHideSummary />
          )
        }
        navBarMobile={
          isMobile &&
          !location.pathname.includes('/poa-success') &&
          !location.pathname.includes('attendance-allowance/') &&
          !location.pathname.includes('care-profile/') &&
          !location.pathname.includes('care-plan/') &&
          !location.pathname.includes('/onboarding-questions') && (
            <NavBarMobile
              links={navVerticalProps.links}
              currentPath={location.pathname}
            />
          )
        }
        content={
          isHideUi ? (
            <Transition path={location.pathname} ref={nodeRef}>
              {currentOutlet}
            </Transition>
          ) : (
            <Container size='xl' isInline>
              <Transition path={location.pathname} ref={nodeRef}>
                {currentOutlet}
              </Transition>
            </Container>
          )
        }
      />
    </RootOrganizationOwnerWrapper>
  );
};

const RootOrganizationOwnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

RootOrganizationOwner.displayName = 'RootOrganizationOwner';
export default RootOrganizationOwner;
