import { styled } from '../styling';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacing.units(1)};
  padding: ${({ theme }) => theme.spacing.units(3)};
  border: 1px solid ${({ theme }) => theme.colour.neutral.mid};
  background-color: ${({ theme }) => theme.colour.neutral.light};
  ${({ theme, dark }) =>
    dark && `background-color: ${theme.colour.neutral.mid}`};
`;

export default Card;
