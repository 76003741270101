import { generateBreakpoint } from './types';
export const breakpointValues = {
    xsValue: 0,
    smValue: 600,
    mdValue: 960,
    lgValue: 1200,
    xlValue: 1536,
};
export const breakpoint = Object.assign(Object.assign({}, breakpointValues), { xs: generateBreakpoint(breakpointValues.xsValue, 'fluid', 4, 16, 16), sm: generateBreakpoint(breakpointValues.smValue, 'fluid', 4, 24, 16), md: generateBreakpoint(breakpointValues.mdValue, 'fixed', 8, 'auto', 16), lg: generateBreakpoint(breakpointValues.lgValue, 'fixed', 12, 'auto', 16), xl: generateBreakpoint(breakpointValues.xlValue, 'fixed', 12, 'auto', 16) });
export default breakpoint;
