import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Column from '../components/primitives/Column';
import Heading from '../components/primitives/typography/Heading';

import Banner from '../features/search/Banner';
import SearchResultsWithControls from '../features/search/SearchResultsWithControls';

import useProviderSearch from '../hooks/useProviderSearch';
import { BrowserLocationContext } from '../context/BrowserLocationContext';
import { SearchResultsContext } from '../context/SearchResultsContext';
import { SearchContext } from '../context/SearchContext';

export default function Search() {
  const { search: location } = useContext(BrowserLocationContext);
  const { pristine } = useContext(SearchResultsContext);
  const [firstLoad, setFirstLoad] = useState(true);
  const { search } = useProviderSearch();
  const { setSearchTerm } = useContext(SearchContext);

  useEffect(() => {
    if (firstLoad && location && pristine) {
      setFirstLoad(false);

      setSearchTerm(location);
      search(location);
    }
  }, [firstLoad, setFirstLoad, search, setSearchTerm, location, pristine]);

  return (
    <>
      <Column>
        <Banner />
        <SearchResultsWithControls />
        <StyledHeading>Our providers are regulated by</StyledHeading>
        <Wrapper>
          <img
            src='/images/disclosure-scotland.png'
            alt='Disclosure Scotland'
          />
          <img src='/images/CQC.png' alt='Care Inspectorate' />
          <img
            src='/images/disclosure-and-barring-service.png'
            alt='Disclosure and Barring Service'
          />
          <img src='/images/care-inspectorate.png' alt='Care Inspectorate' />
        </Wrapper>
      </Column>
    </>
  );
}

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-top: 128px;
  margin-bottom: 64px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 32px;
  gap: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  & > img {
    object-fit: contain;
    height: 50px;
    max-height: 50px;
  }
`;
