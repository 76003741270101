import Spacer from '../../primitives/Spacer';
import { Container, Header, Body, Footer } from './components/Layout';
import Skeleton from '../../primitives/Skeleton';
import Column from '../../primitives/Column';

export default function ProviderCardSkeleton() {
  return (
    <Container>
      <Header>
        <Column grow>
          <Spacer>
            <Skeleton type='media' />
          </Spacer>
        </Column>
      </Header>
      <Spacer top>
        <Body>
          <Skeleton type='text' rows={6} />
        </Body>
      </Spacer>
      <Footer>
        <Skeleton type='text' rows={2} />
      </Footer>
    </Container>
  );
}
