import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle as faCircleSolid,
  faCouch,
  faHandHoldingHeart,
  faHandsHelping,
  faQuoteLeft,
  faQuoteRight,
  faStar,
  faUserNurse,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCheckSquare,
  faCircle,
  faDotCircle,
  faSquare,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBed,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faEnvelope,
  faHotel,
  faHouse,
  faImages,
  faMapMarker,
  faPhone,
  faPoundSign,
  faShieldAlt,
  faSlidersH,
  faTimes,
  faToilet,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';

import { styled } from '../styling';

export const Icon = FontAwesomeIcon;

export const ColourIcon = styled(Icon)`
  color: ${({ theme, colour }) => colour || theme.colour.primary};
`;

export const icons = {
  square: faSquare,
  squareChecked: faCheckSquare,
  circle: faCircle,
  circleFilled: faDotCircle,
  circleSolid: faCircleSolid,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  chevronUp: faChevronUp,
  chevronDown: faChevronDown,
  sliders: faSlidersH,
  close: faTimes,
  mapMarker: faMapMarker,
  checkCircle: faCheckCircle,
  poundSign: faPoundSign,
  email: faEnvelope,
  phone: faPhone,
  check: faCheck,
  star: faStar,
  handHoldingHeart: faHandHoldingHeart,
  couch: faCouch,
  nurse: faUserNurse,
  handHelping: faHandsHelping,
  quoteBegin: faQuoteLeft,
  quoteEnd: faQuoteRight,
  bed: faBed,
  hotel: faHotel,
  shield: faShieldAlt,
  toilet: faToilet,
  clock: faClock,
  images: faImages,
  house: faHouse,
  person: faUser,
};
