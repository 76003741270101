// react
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// store
import {
  useBookmarkArticleMutation,
  useGetAllArticleBookmarkQuery,
  useLazyGetArticleByIdQuery,
  useLazyGetArticleQuery,
} from 'store/api/cms';
import { Article as ArticleType } from '@karehero/models';

// components
import { ArticleContent as ArticleContentPage } from '@karehero/llama';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

/**
 * ArticleContent shows the content of an Article from Sanity.
 */
const ArticleContent = () => {
  // state
  const [article, setArticle] = useState<ArticleType>();

  // hooks
  const { track } = useMixpanel();
  const { articleId } = useParams();
  const [getArticle, { isLoading: isArticleLoading }] =
    useLazyGetArticleQuery();
  const [getArticleById] = useLazyGetArticleByIdQuery();
  const navigate = useNavigate();
  const [bookmarkArticle] = useBookmarkArticleMutation();
  const { data: articleBookmarks } = useGetAllArticleBookmarkQuery();

  // effects
  useEffect(() => {
    if (!articleId) {
      navigate(`/library`);
      return;
    }

    (async () => {
      // check if uuid
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      if (uuidRegex.test(articleId)) {
        const { data, error } = await getArticleById(articleId);
        if (error) {
          console.error(error);
          navigate(`/library`);
          return;
        }
        if (!data) return;
        const articleSlug = data.slug?.current || '';
        navigate(`/library/${articleSlug}`);
      }

      const { data, error } = await getArticle(articleId);
      if (error) {
        navigate(`/library`);
        return;
      }
      if (!data) return;
      setArticle(data);

      track(MixpanelEvent.ArticleViewed, {
        id: data._id,
        title: data.title,
        tags: data.tags,
      });
    })();
  }, [articleId, getArticle, getArticleById, navigate, track]);

  return (
    <ArticleContentPage
      content={article}
      isLoading={!article ? true : isArticleLoading}
      isBookmarked={
        !articleBookmarks?.find((a) => article?._id && a._id === article?._id)
      }
      onBookmark={() => article?._id && bookmarkArticle(article._id)}
    />
  );
};

export default ArticleContent;
