import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { Avatar, Icon } from "../../atoms";
// radix
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
/**
 * AccountInfo displays the account information.
 */
export const AccountInfo = ({ role, name, fallback, onSignOut, toAccountSettings, }) => {
    return (_jsxs(AccountInfoContainer, { children: [_jsxs(TextContainer, { children: [_jsx(Role, { children: role }), _jsx(Name, { children: name })] }), _jsxs(DropdownMenu.Root, { children: [_jsxs(DropdownMenuTrigger, { children: [_jsx(Avatar, { fallback: fallback, size: 'xs' }), _jsx(Icon, { icon: 'chevron-down', fontSize: 12 })] }), _jsx(DropdownMenu.Portal, { children: _jsxs(DropdownMenuContent, Object.assign({ align: 'end', sideOffset: 5 }, { children: [_jsxs(DropdownMenuLabel, { children: ["Signed in as ", name] }), _jsx(DropdownMenuSeparator, {}), toAccountSettings && (_jsx(DropdownMenuItem, Object.assign({ asChild: true }, { children: _jsxs(Link, Object.assign({ to: toAccountSettings }, { children: [_jsx(Icon, { icon: 'gear', fontSize: 16 }), _jsx("div", { children: "Settings" })] })) }))), _jsx(DropdownMenuSeparator, {}), _jsxs(DropdownMenuItem, Object.assign({ onSelect: onSignOut }, { children: [_jsx(Icon, { icon: 'arrow-right-from-bracket', fontSize: 16 }), _jsx("div", { children: "Sign Out" })] }))] })) })] })] }));
};
const AccountInfoContainer = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
const TextContainer = styled.div `
  display: none;
  ${(props) => props.theme.breakpoint.sm(css `
      display: flex;
      flex-direction: column;
      align-items: end;
    `)}
`;
const Role = styled.div `
  font: ${(props) => props.theme.typography.body.xs.regular};
  color: ${(props) => props.theme.color.neutral[70]};
`;
const Name = styled.div `
  font: ${(props) => props.theme.typography.body.sm.medium};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const DropdownMenuTrigger = styled(DropdownMenu.Trigger) `
  all: unset;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[4]};
  color: ${(props) => props.theme.color.neutral[90]};
  cursor: pointer;
`;
const DropdownMenuContent = styled(DropdownMenu.Content) `
  min-width: 280px;
  background: ${(props) => props.theme.color.neutral[10]};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing[8]} 0;
  box-shadow: ${(props) => props.theme.elevation[5]};
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  color: ${(props) => props.theme.color.neutral[90]};
  border: 1px solid ${(props) => props.theme.color.neutral[40]};

  &[data-side='top'] {
    animation-name: slideDownAndFade;
  }

  &[data-side='right'] {
    animation-name: slideLeftAndFade;
  }

  &[data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  &[data-side='left'] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
const DropdownMenuItem = styled(DropdownMenu.Item) `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  position: relative;
  padding: ${(props) => props.theme.spacing[8]}
    ${(props) => props.theme.spacing[16]};
  font: ${(props) => props.theme.typography.body.md.regular};
  user-select: none;
  outline: none;
  cursor: pointer;
  color: ${(props) => props.theme.color.neutral[90]};
  text-decoration: none;

  &[data-disabled] {
    color: var(--mauve8);
    pointer-events: none;
  }
  &[data-highlighted] {
    background: ${(props) => props.theme.color.neutral[30]};
  }
`;
const DropdownMenuLabel = styled(DropdownMenu.Label) `
  text-align: center;
  font: ${(props) => props.theme.typography.body.sm.regular};
  padding: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[16]};
`;
const DropdownMenuSeparator = styled(DropdownMenu.Separator) `
  height: 1px;
  background: ${(props) => props.theme.color.neutral[40]};
  margin: ${(props) => props.theme.spacing[8]} 0;
`;
AccountInfo.displayName = 'AccountInfo';
export default AccountInfo;
