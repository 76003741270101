import PropTypes from 'prop-types';

import { Icon, icons } from '../primitives/Icon';
import Row from '../primitives/Row';
import Button from '../primitives/Button';

export function MobileMenuToggle({ onClick }) {
  return (
    <Button round noChevron onClick={onClick}>
      <Row justifyCentre fullWidth>
        <Icon icon={icons.sliders} />
      </Row>
    </Button>
  );
}

export function MobileMenuClose({ onClick }) {
  return (
    <Button round noChevron onClick={onClick}>
      <Row justifyCentre fullWidth>
        <Icon icon={icons.close} />
      </Row>
    </Button>
  );
}

const withOnClick = {
  onClick: PropTypes.func.isRequired,
};

MobileMenuToggle.propTypes = withOnClick;
MobileMenuClose.propTypes = withOnClick;
