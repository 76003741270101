import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
// components
import { Avatar, Icon, Tooltip } from "../../atoms";
// hooks/
import { useWindowSize } from "../../../hooks";
// theme
import { theme } from "../../../theme";
var CareCircleSize;
(function (CareCircleSize) {
    CareCircleSize[CareCircleSize["Small"] = 250] = "Small";
    CareCircleSize[CareCircleSize["Medium"] = 350] = "Medium";
    CareCircleSize[CareCircleSize["Large"] = 450] = "Large";
})(CareCircleSize || (CareCircleSize = {}));
/**
 * CareCircle is a visual representation of the user's care circle.
 */
export const CareCircle = ({ onAdd, onPreview, members, careRecipientMember, }) => {
    var _a, _b;
    // refs
    const careCircleMembers = useRef(null);
    // hooks
    const [width] = useWindowSize();
    // memo
    const circleSize = useMemo(() => {
        if (width > theme.breakpoint.mdValue) {
            return CareCircleSize.Large;
        }
        else if (width > theme.breakpoint.smValue) {
            return CareCircleSize.Medium;
        }
        else {
            return CareCircleSize.Small;
        }
    }, [width]);
    const size = useMemo(() => {
        switch (circleSize) {
            case CareCircleSize.Small:
                return 'sm';
            case CareCircleSize.Medium:
                return 'md';
            case CareCircleSize.Large:
                return 'lg';
        }
    }, [circleSize]);
    const fontSize = useMemo(() => {
        switch (circleSize) {
            case CareCircleSize.Small:
                return 24;
            case CareCircleSize.Medium:
                return 36;
            case CareCircleSize.Large:
                return 48;
        }
    }, [circleSize]);
    const careRecipientAvatarSize = useMemo(() => {
        switch (circleSize) {
            case CareCircleSize.Small:
                return 'md';
            case CareCircleSize.Medium:
                return 'lg';
            case CareCircleSize.Large:
                return 'xl';
        }
    }, [circleSize]);
    // effects
    useEffect(() => {
        if (careCircleMembers.current) {
            const memberElements = careCircleMembers.current.children;
            const angle = (2 * Math.PI) / (memberElements.length + 1);
            const radius = circleSize / 2;
            const offset = (1 / 2) * Math.PI - angle;
            for (let i = 0; i < memberElements.length; i++) {
                const memberElement = memberElements[i];
                const width = memberElement.offsetWidth;
                const height = memberElement.offsetHeight;
                const x = radius * Math.cos(angle * i - offset) - width / 2;
                const y = radius * Math.sin(angle * i - offset) - height / 2;
                memberElement.style.transform = `translate(${x}px, ${y}px)`;
            }
        }
    }, [(_a = careCircleMembers === null || careCircleMembers === void 0 ? void 0 : careCircleMembers.current) === null || _a === void 0 ? void 0 : _a.children, members, circleSize]);
    return (_jsxs(Wrapper, { children: [_jsx(Label, { children: "Add new member" }), _jsxs(StyledCareCircle, Object.assign({ size: circleSize }, { children: [_jsx(Circle, {}), _jsx(Tooltip, Object.assign({ isSimple: false, content: _jsxs(_Fragment, { children: [_jsxs(TooltipTitle, { children: [((_b = careRecipientMember === null || careRecipientMember === void 0 ? void 0 : careRecipientMember.account) === null || _b === void 0 ? void 0 : _b.firstName) || '', ' ', (careRecipientMember === null || careRecipientMember === void 0 ? void 0 : careRecipientMember.account.lastName) || ''] }), _jsx(TooltipSubtitle, { children: "Care Recipient" })] }) }, { children: _jsx(Avatar, { size: careRecipientAvatarSize, fallback: careRecipientMember.fallback || '', src: careRecipientMember.imgSrc }) })), _jsx(CareCircleMembers, Object.assign({ ref: careCircleMembers }, { children: members.map((member) => {
                            var _a, _b;
                            return (_jsx("div", Object.assign({ onClick: member.onClick }, { children: _jsx(Tooltip, Object.assign({ isSimple: false, content: _jsxs(_Fragment, { children: [_jsxs(TooltipTitle, { children: [(_a = member === null || member === void 0 ? void 0 : member.account) === null || _a === void 0 ? void 0 : _a.firstName, " ", member === null || member === void 0 ? void 0 : member.account.lastName] }), _jsx(TooltipSubtitle, { children: (_b = member === null || member === void 0 ? void 0 : member.roles) === null || _b === void 0 ? void 0 : _b[0].name })] }) }, { children: _jsx(StyledAvatar, { onClick: () => onPreview === null || onPreview === void 0 ? void 0 : onPreview(Object.assign({}, member)), size: size, fallback: member.fallback, src: member.imgSrc }) })) }), member.id));
                        }) })), _jsx(StyledButton, Object.assign({ "aria-label": 'add new member', onClick: onAdd, size: size }, { children: _jsx(Icon, { icon: 'plus', fontSize: fontSize }) }))] }))] }));
};
const Wrapper = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Label = styled.div `
  text-align: center;
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.primary[80]};
`;
const StyledCareCircle = styled.div `
  position: relative;
  margin: ${(props) => props.theme.spacing[64]};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;
const Circle = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 99999px;
  background: ${(props) => props.theme.color.neutral[20]};
  border: 8px solid ${(props) => props.theme.color.neutral[30]};
  z-index: -1;
`;
const StyledButton = styled.button `
  all: unset;
  position: absolute;
  top: 0%;
  right: 50%;
  border-radius: 9999px;

  ${(props) => {
    let size = 48;
    switch (props.size) {
        case 'sm':
            size = 48;
            break;
        case 'md':
            size = 60;
            break;
        case 'lg':
            size = 80;
            break;
    }
    return `
      width: ${size}px;
      min-width: ${size}px;
      height: ${size}px;
      min-height: ${size}px;
    `;
}}

  background: ${(props) => props.theme.color.primary[30]};
  color: ${(props) => props.theme.color.neutral[100]};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  transition-duration: 0.2s;
  &:hover {
    transform: translate(50%, -50%) scale(1.1);
  }
`;
const CareCircleMembers = styled.div `
  position: absolute;
  & > * {
    position: absolute;
  }
`;
const StyledAvatar = styled(Avatar) `
  cursor: pointer;
  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;
const TooltipTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.primary[80]};
`;
const TooltipSubtitle = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
CareCircle.displayName = 'CareCircle';
export default CareCircle;
