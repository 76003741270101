import api from '.';
import { CarePlan } from '@karehero/models';

export const carePlanApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCarePlan: builder.query<CarePlan, string>({
      query: (id) => `/care-plan/care-circle/${id}`,
      providesTags: ['CarePlan'],
      keepUnusedDataFor: 0,
    }),
    createCarePlan: builder.mutation<CarePlan, Partial<CarePlan>>({
      query: (body) => ({
        url: '/care-plan',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CarePlan'],
    }),
    approveCarePlan: builder.mutation<CarePlan, string>({
      query: (id) => ({
        url: `/care-plan/care-circle/${id}/approve`,
        method: 'PUT',
      }),
      invalidatesTags: ['CarePlan'],
    }),
    requestChangesCarePlan: builder.mutation<
      CarePlan,
      {
        careCircleId: string;
        request: string;
      }
    >({
      query: (model) => ({
        url: `/care-plan/care-circle/${model.careCircleId}/request-changes`,
        method: 'POST',
        body: { request: model.request },
      }),
      invalidatesTags: ['CarePlan'],
    }),
  }),
});

export const {
  useGetCarePlanQuery,
  useCreateCarePlanMutation,
  useApproveCarePlanMutation,
  useRequestChangesCarePlanMutation,
} = carePlanApi;

export default carePlanApi;
