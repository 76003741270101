import { useContext } from 'react';

import ResultList from '../../components/features/SearchResultsList/SearchResultsList';
import Title from '../../components/primitives/typography/Title';
import Spacer from '../../components/primitives/Spacer';

import { SearchResultsContext } from '../../context/SearchResultsContext';
import { GlobalSettingsContext } from '../../context/GlobalSettingsContext';
import useViewProviderProfile from '../../hooks/useViewProviderProfile';
import useOnPageEvent from '../../hooks/useOnPageEvent';
import GetNextSearchResultsButton from './GetNextSearchResultsButton';
import SearchResultsListSkeleton from '../../components/features/SearchResultsList/SearchResultsListSkeleton';

export default function SearchResultsList() {
  const {
    loading,
    results,
    lastSearchTerm,
    error,
    haveSearchResults,
    searchReturnedNoResults,
    loadingMore,
    lastSearchCountryNotImplemented,
    getProviderByRegulatoryId,
  } = useContext(SearchResultsContext);

  const { onProviderCardClick } = useOnPageEvent();
  const { countryNotImplementedText } = useContext(GlobalSettingsContext);

  const { goToProviderProfile } = useViewProviderProfile();

  const logCardClickAndNavigate = (regulatoryId) => {
    const provider = getProviderByRegulatoryId(regulatoryId) || {};
    const { name, address, careType, ratings } = provider;
    onProviderCardClick({ regulatoryId, name, address, careType, ratings });
    goToProviderProfile(regulatoryId);
  };

  return (
    <>
      <Spacer bottom>
        <Title>
          {haveSearchResults && (
            <>Showing search results for &quot;{lastSearchTerm}&quot;</>
          )}
          {!loading &&
            searchReturnedNoResults &&
            !error &&
            !lastSearchCountryNotImplemented && (
              <>
                Sorry, we couldn&apos;t find any results for your search. Please
                try a less specific search term
              </>
            )}
          {lastSearchCountryNotImplemented && countryNotImplementedText}
          {error && <>Sorry, something went wrong, please try again</>}
        </Title>
      </Spacer>
      <ResultList
        results={results}
        loading={loading}
        onCardReadMoreClick={logCardClickAndNavigate}
      />
      {loadingMore && <SearchResultsListSkeleton />}
      {!error && haveSearchResults && results.length > 0 && (
        <GetNextSearchResultsButton />
      )}
    </>
  );
}
