import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// react-transition-group
import { CSSTransition, SwitchTransition } from 'react-transition-group';
// assets
// @transform-path ../../../../../../src/assets/keni/happy-left.svg
import happyLeft from "../../../../../../src/assets/keni/happy-left.svg";
// @transform-path ../../../../../../src/assets/keni/happy-right.svg
import happyRight from "../../../../../../src/assets/keni/happy-right.svg";
// @transform-path ../../../../../../src/assets/keni/happy-front.svg
import happyFront from "../../../../../../src/assets/keni/happy-front.svg";
// @transform-path ../../../../../../src/assets/keni/sad-left.svg
import sadLeft from "../../../../../../src/assets/keni/sad-left.svg";
// @transform-path ../../../../../../src/assets/keni/sad-right.svg
import sadRight from "../../../../../../src/assets/keni/sad-right.svg";
// @transform-path ../../../../../../src/assets/keni/sad-front.svg
import sadFront from "../../../../../../src/assets/keni/sad-front.svg";
// @transform-path ../../../../../../src/assets/keni/offline-right.svg
import offlineRight from "../../../../../../src/assets/keni/offline-right.svg";
const images = {
    'happy-left': happyLeft,
    'happy-right': happyRight,
    'happy-front': happyFront,
    'offline-right': offlineRight,
    'sad-left': sadLeft,
    'sad-right': sadRight,
    'sad-front': sadFront,
};
const speechBubbleGap = 10;
const speechBubbleAnimationSpeed = 300;
export const Keni = ({ className, emotion = 'happy', direction = 'front', speechConfig, keniWidth, }) => {
    // state
    const [speechBubbleHeight, setSpeechBubbleHeight] = useState(160);
    // refs
    const speechBubbleRef = useCallback((node) => {
        if (!node) {
            setSpeechBubbleHeight(0);
            return;
        }
        setSpeechBubbleHeight(node.getBoundingClientRect().height);
        if (speechConfig)
            return;
    }, [speechConfig]);
    return (_jsxs(Wrapper, Object.assign({ className: className, "$width": keniWidth, "$marginTop": speechConfig ? speechBubbleHeight : 0, "$speechBubbleGap": speechBubbleGap, "$speed": speechBubbleAnimationSpeed }, { children: [_jsx(SwitchTransition, { children: _jsx(CSSTransition, Object.assign({ classNames: 'bubble', timeout: speechBubbleAnimationSpeed, addEndListener: (node, done) => {
                        node.addEventListener('transitionend', done, false);
                    } }, { children: _jsx(_Fragment, { children: speechConfig && (_jsx(SpeechBubble, Object.assign({ ref: speechBubbleRef }, { children: speechConfig === null || speechConfig === void 0 ? void 0 : speechConfig.map((config) => {
                                var _a;
                                switch (config.type) {
                                    case 'text':
                                        return _jsx(Text, { children: config.text }, config.text);
                                    case 'strong':
                                        return _jsx(Strong, { children: config.text }, config.text);
                                    case 'list':
                                        return (_jsx(StyledUl, { children: (_a = config.list) === null || _a === void 0 ? void 0 : _a.map((item) => {
                                                return (_jsx(ListItem, { children: item.text }, item.text));
                                            }) }, config.text));
                                    default:
                                        return null;
                                }
                            }) }))) }) }), JSON.stringify(speechConfig || '{}')) }), _jsx("img", { id: 'keni', src: images[`${emotion}-${direction}`], alt: 'keni' })] })));
};
const Wrapper = styled.div `
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;

  user-select: none;

  padding-top: ${(props) => props.$marginTop + speechBubbleGap}px;
  transition-duration: ${(props) => props.$speed}ms;

  & > img {
    width: 100%;
    ${(props) => props.$width &&
    css `
        width: ${props.$width};
      `};
    object-fit: contain;
  }

  & .bubble-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  & .bubble-enter-active {
    opacity: 1;
    transform: scale(1);
    transition-duration: ${(props) => props.$speed}ms;
  }

  & .bubble-exit {
    opacity: 1;
    transform: scale(1);
  }

  & .bubble-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition-duration: ${(props) => props.$speed}ms;
  }
`;
const SpeechBubble = styled.div `
  position: absolute;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 350px;

  background: ${(props) => props.theme.color.neutral[10]};
  border-radius: 24px;
  padding: ${(props) => props.theme.spacing[24]};
  ${(props) => props.theme.spacing[24]};
  box-shadow: ${(props) => props.theme.elevation[1]};
  box-sizing: border-box;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    right: 30%;
    bottom: -20px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: rgba(16, 24, 40, 0.21);
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    right: 30%;
    bottom: -18px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: ${(props) => props.theme.color.neutral[10]};
    z-index: 3;
  }
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: center;
`;
const Strong = styled.div `
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: center;
`;
const StyledUl = styled.ul `
  padding-left: ${(props) => props.theme.spacing[20]};
  margin: 0;
  & > li {
    margin-bottom: ${(props) => props.theme.spacing[12]};
  }
`;
const ListItem = styled.li `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
Keni.displayName = 'Keni';
export default Keni;
