import { useContext } from 'react';
import PropTypes from 'prop-types';

import Column from '../../components/primitives/Column';

import { ProviderFilterContext } from '../../context/ProviderFilterContext';

import Spacer from '../../components/primitives/Spacer';
import Radio from '../../components/primitives/form/Radio';

export default function DistanceFilters({ disabled }) {
  const {
    distances,
    activeFilters: { currentDistance },
    setCurrentDistance,
  } = useContext(ProviderFilterContext);

  return (
    <Column>
      {distances.map((distance, i) => (
        <Spacer top={i > 0} key={distance}>
          <Radio
            name={distance}
            label={`${distance} miles`}
            value={distance}
            checked={currentDistance === distance}
            onChange={setCurrentDistance}
            disabled={disabled}
          />
        </Spacer>
      ))}
    </Column>
  );
}

DistanceFilters.propTypes = {
  disabled: PropTypes.bool,
};

DistanceFilters.defaultProps = {
  disabled: false,
};
