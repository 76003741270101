import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect } from 'react';
// components
import { Title } from "../../atoms";
/**
 * HubspotContactForm is a component that displays a Hubspot contact form.
 */
export const HubspotContactForm = (props) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        const link = document.createElement('link');
        link.href = 'https://assets.calendly.com/assets/external/widget.css';
        link.rel = 'stylesheet';
        const calendlyScript = document.createElement('script');
        calendlyScript.src = 'https://assets.calendly.com/assets/external/forms.js';
        calendlyScript.type = 'text/javascript';
        calendlyScript.async = true;
        /* const calendlyScript2 = document.createElement('script');
        calendlyScript2.type = 'text/javascript'; */
        // append all scripts
        document.body.appendChild(script);
        document.body.appendChild(link);
        document.body.appendChild(calendlyScript);
        // document.body.appendChild(calendlyScript2);
        script.addEventListener('load', () => {
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    portalId: '5461702',
                    formId: '2c2c8ff2-2647-4f58-8523-25cefa4fedf0',
                    target: '#hubspotForm',
                    region: 'na1',
                });
            }
        });
        calendlyScript.addEventListener('load', () => {
            // @ts-ignore
            if (Calendly) {
                // @ts-ignore
                Calendly.initHubspotForm({
                    id: '2c2c8ff2-2647-4f58-8523-25cefa4fedf0',
                    url: 'https://calendly.com/api/form_builder/forms/4cd1a1db-5559-445b-99fe-04918ae4d5be/submissions',
                    options: { text_color: '33475b', primary_color: 'ff8e9d' },
                });
            }
        });
    }, []);
    return (_jsxs("div", { children: [_jsx(Title, { children: "Care Expert Form" }), _jsx("div", { id: 'hubspotForm' })] }));
};
HubspotContactForm.displayName = 'HubspotContactForm';
export default HubspotContactForm;
