// react
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { CarePlanHome as CarePlanHomePage } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';

// store
import { CarePlan } from '@karehero/models';
import { useCreateCarePlanMutation } from 'store/api/carePlan';

/**
 * CarePlanHome shows the care plan at home editor.
 */
const CarePlanHome = () => {
  // state
  const [carePlan, setCarePlan] = useState<Partial<CarePlan>>({ type: 'home' });

  // hooks
  const navigate = useNavigate();
  const { careCircle } = useCareProfile();
  const [createCarePlan] = useCreateCarePlanMutation();

  // methods
  const handleSave = useCallback(async () => {
    const res = await createCarePlan({
      ...carePlan,
      homeCarersNeeded: parseInt(
        carePlan.homeCarersNeeded as unknown as string,
      ) as number,
      careCircleId: careCircle?.id,
    });
    if (!('data' in res)) return;

    navigate(`/care-plan/complete`);
  }, [navigate, careCircle, carePlan, createCarePlan]);

  return (
    <CarePlanHomePage
      value={carePlan}
      onChange={setCarePlan}
      onSave={handleSave}
      onCancel={() => navigate(`/care-plan/choice`)}
    />
  );
};

export default CarePlanHome;
