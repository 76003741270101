// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// store
import {
  useLazyCheckMigrationTokenQuery,
  useMigrateMemberMutation,
  useSetTokenMutation,
} from 'store/api/iam';
import { kindeClientId, kindeEnvironmentUrl } from 'store/api';

// components
import { OnboardingCarousel, OnboardingFlow } from '@karehero/llama';

// config
import { onboardingAccountMigration } from 'config/onboarding';

/**
 * OnboardingMemberAccountMigration takes the user through the onboarding flow
 * for migrating their account to Kinde.
 */
const OnboardingMemberAccountMigration = () => {
  // state
  const [token, setToken] = useState('');

  // hooks
  const [searchParams] = useSearchParams();
  const [setRemoteToken] = useSetTokenMutation();
  const [migrateMember] = useMigrateMemberMutation();
  const [checkMigrationToken] = useLazyCheckMigrationTokenQuery();
  const navigate = useNavigate();

  const handleMigrateMember = useCallback(
    async (token: string): Promise<boolean> => {
      const url = new URL(`https://${kindeEnvironmentUrl}/oauth2/auth`);
      const queryParams = new URLSearchParams({
        response_type: 'code',
        client_id: kindeClientId,
        redirect_uri: `${window.location.origin}/register/member?migration=true`,
        scope: 'openid profile email',
        prompt: 'create',
        /// random state
        state: 'i3Dd8bE1lX',
      });

      const res = await migrateMember({
        token,
      });
      if (!('data' in res)) {
        return false;
      }

      url.search = queryParams.toString();
      window.location.href = url.toString();

      return true;
    },
    [migrateMember],
  );

  const handleComplete = useCallback(async () => {
    const res = await handleMigrateMember(token);
    if (!res) {
      console.error('Failed to register member by domain');
      return false;
    }
    return true;
  }, [token, handleMigrateMember]);

  // effects
  useEffect(() => {
    const token = searchParams.get('token');
    if (!token) return;
    (async () => {
      const resCheck = await checkMigrationToken({ token });
      if ('error' in resCheck) {
        console.error("token doesn't exist");
        navigate('/sign-in');
        return;
      }

      const res = await setRemoteToken(token);
      if (!('data' in res)) {
        console.error('Failed to set token');
        return;
      }
      setToken(token);
    })();
  }, [searchParams, setRemoteToken, navigate, checkMigrationToken]);

  // memos
  const onboardingMemberRegisterConfig = useMemo(
    () => [
      {
        id: 'carousel',
        Component: OnboardingCarousel,
        config: onboardingAccountMigration,
      },
    ],
    [],
  );

  return (
    <OnboardingFlow
      config={onboardingMemberRegisterConfig}
      onComplete={handleComplete}
    />
  );
};

OnboardingMemberAccountMigration.displayName =
  'OnboardingMemberAccountMigration';
export default OnboardingMemberAccountMigration;
