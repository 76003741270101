import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { Button, LoadingTransition, Skeleton } from "..";
// assets
// @transform-path ../../../../../../src/assets/keni/call.svg
import keniCall from "../../../../../../src/assets/keni/call.svg";
/**
 * CallToAction displays a call to action.
 */
export const CallToAction = ({ title, text, action, onPress, isLoading = true, isMobile = false, to = '', }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    // effects
    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setIsLoaded(true);
        };
        img.src = keniCall;
    }, []);
    return isMobile ? (_jsxs(MobileWrapper, { children: [_jsxs(Content, { children: [_jsx(Title, Object.assign({ "$isMobile": true }, { children: title })), _jsx(MobileText, { children: _jsx(Text, Object.assign({ "$isMobile": true }, { children: text })) })] }), _jsx(Button, Object.assign({ onPress: onPress, ariaLabel: 'book a call' }, { children: action }))] })) : (_jsx(Wrapper, { children: _jsx(LoadingTransition, { isLoading: isLoading || !isLoaded, loading: _jsxs(_Fragment, { children: [_jsx(Skeleton, { shape: 'avatar' }), _jsx(Skeleton, { shape: 'subtitle' }), _jsx(Skeleton, { shape: 'paragraph' }), _jsx(Skeleton, { shape: 'button', isMargin: false })] }), content: _jsxs(_Fragment, { children: [_jsxs(Content, { children: [_jsx(KeniCall, { children: _jsx("img", { src: keniCall, alt: 'Keni Call' }) }), _jsx(Title, { children: title }), _jsx(Text, { children: text })] }), _jsx(Button, Object.assign({ to: to, ariaLabel: 'book a call' }, { children: action }))] }) }) }));
};
const MobileWrapper = styled.div `
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100%;
  display: flex;
  background: ${(props) => props.theme.color.neutral[10]};
  gap: ${(props) => props.theme.spacing[24]};
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
  border-bottom: none;
  align-items: center;
  box-shadow: ${(props) => props.theme.elevation[3]};
  padding: ${(props) => props.theme.spacing[36]};
  padding-top: ${(props) => props.theme.spacing[20]};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  flex-direction: row;
  ${(props) => props.theme.breakpoint.sm(css `
      flex-direction: row;
      bottom: 0;
    `)};
`;
const MobileText = styled.div `
  display: none;
  ${(props) => props.theme.breakpoint.sm(css `
      display: block;
    `)};
`;
const Wrapper = styled.div `
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  align-items: center;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
  box-shadow: ${(props) => props.theme.elevation[3]};
  padding: ${(props) => props.theme.spacing[36]};
  padding-top: ${(props) => props.theme.spacing[20]};
  border-radius: 8px;
`;
const Content = styled.div ``;
const KeniCall = styled.div `
  display: flex;
  justify-content: center;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.body.md.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: ${(props) => (props.$isMobile ? 'left' : 'center')};
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: ${(props) => (props.$isMobile ? 'left' : 'center')};
`;
CallToAction.displayName = 'CallToAction';
export default CallToAction;
