import { useState } from 'react';
import PropTypes from 'prop-types';

import { imageType } from '../types';
import { breakpoints, media, styled } from '../styling';
import { renderIf } from '../RenderIf';
import useKeyboardEventHandler from '../../hooks/useKeyboardEventHandler';

import Image from './Image';
import BackgroundImage from './BackgroundImage';
import Row from './Row';
import Spacer from './Spacer';
import { Icon, icons } from './Icon';
import Modal from './Modal';

const FullImage = styled(Image)`
  height: ${({ theme }) => theme.spacing.units(80)};

  ${media('<tablet')} {
    height: ${({ theme }) => theme.spacing.units(40)};
  }
  cursor: zoom-in;
`;

const LightboxImage = styled(Image)``;

const Thumbnail = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
`;

const ThumbnailContainer = styled.button`
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  margin-right: ${({ theme, isLast }) => (isLast ? 0 : theme.spacing.units(4))};
  outline-color: ${({ theme }) => theme.colour.primary};
  height: ${({ theme }) => theme.spacing.units(20)};
  width: ${({ theme }) => theme.spacing.units(20)};
  min-width: ${({ theme }) => theme.spacing.units(20)};

  ${media('<tablet')} {
    height: ${({ theme }) => theme.spacing.units(16)};
    width: ${({ theme }) => theme.spacing.units(16)};
    min-width: ${({ theme }) => theme.spacing.units(16)};
  }
`;

const ThumbnailRow = styled(Row)`
  overflow-x: auto;
`;

const FullImageContainer = styled.div`
  position: relative;
`;

const NextPrevIcon = styled(Icon)`
  font-size: ${({ theme }) => theme.spacing.units(8)};
  filter: drop-shadow(4px 2px 2px ${({ theme }) => theme.colour.neutral.dark});
`;

const PreviousButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-right: ${({ theme }) => theme.spacing.units(20)};
  width: ${({ theme }) => theme.spacing.units(30)};
  color: ${({ theme }) => theme.colour.neutral.light};
  outline-color: ${({ theme }) => theme.colour.primary};
  border: none;
  cursor: pointer;
  background: none;
  :hover {
    background: ${({ theme }) =>
      `linear-gradient(to left, transparent, ${theme.colour.neutral.dark})`};
  }
`;

const NextButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: ${({ theme }) => theme.spacing.units(30)};
  padding-left: ${({ theme }) => theme.spacing.units(20)};
  color: ${({ theme }) => theme.colour.neutral.light};
  outline-color: ${({ theme }) => theme.colour.primary};
  border: none;
  cursor: pointer;
  background: none;
  :hover {
    background: ${({ theme }) =>
      `linear-gradient(to right, transparent, ${theme.colour.neutral.dark})`};
  }
`;

const InvisibleLink = styled.a`
  position: absolute;
  opacity: 0;
  :focus {
    opacity: 1;
    z-index: 1;
  }
`;

const asPictureSources = ({ small, medium, large }) => [
  { src: large, minWidthPx: breakpoints.desktop },
  { src: medium, minWidthPx: breakpoints.tablet },
  { src: small, minWidthPx: 0 },
];

const skipLinkSelector = 'endofgallery';

export default function Gallery({ images = [] }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLightbox, setShowLightbox] = useState(false);
  const openLightbox = () => setShowLightbox(true);
  const closeLightbox = () => setShowLightbox(false);

  const imageCount = images.length;

  const RenderIfAtLeastOneImage = renderIf(imageCount >= 1);
  const RenderIfAtLeastTwoImages = renderIf(imageCount >= 2);

  const goToPreviousImage = () =>
    setCurrentIndex(
      currentIndex - 1 < 0 ? images.length - 1 : currentIndex - 1,
    );
  const goToNextImage = () =>
    setCurrentIndex(
      currentIndex + 1 > images.length - 1 ? 0 : currentIndex + 1,
    );

  const { onKeyboardEvent } = useKeyboardEventHandler({ Enter: openLightbox });

  if (!imageCount) {
    return <></>;
  }

  const currentImage = images[currentIndex];
  const currentImageSources = asPictureSources(currentImage);

  return (
    <>
      <RenderIfAtLeastOneImage>
        <InvisibleLink href={`#${skipLinkSelector}`} tabIndex='0'>
          skip gallery
        </InvisibleLink>
        <FullImageContainer>
          <RenderIfAtLeastTwoImages>
            <PreviousButton
              aria-label='View previous photo'
              name='prevous-image-selector'
              onClick={goToPreviousImage}
            >
              <NextPrevIcon icon={icons.chevronLeft} />
            </PreviousButton>
            <NextButton
              aria-label='View next photo'
              name='next-image-selector'
              onClick={goToNextImage}
            >
              <NextPrevIcon icon={icons.chevronRight} />
            </NextButton>
          </RenderIfAtLeastTwoImages>
          <FullImage
            tabIndex={0}
            onClick={openLightbox}
            onKeyDown={onKeyboardEvent}
            sources={currentImageSources}
            fallback={currentImage.medium}
            blurUpSrc={currentImage.tiny}
            alt=''
          />
        </FullImageContainer>
        <Modal
          open={showLightbox}
          onClickOutside={closeLightbox}
          onCloseClick={closeLightbox}
        >
          <LightboxImage
            sources={currentImageSources}
            fallback={currentImage.large}
            blurUpSrc={currentImage.tiny}
            alt=''
          />
        </Modal>
      </RenderIfAtLeastOneImage>
      <RenderIfAtLeastTwoImages>
        <Spacer bottom />
        <ThumbnailRow>
          {images.map(({ tiny, small: source }, i) => (
            <ThumbnailContainer
              aria-label={`View photo ${i + 1} of ${images.length}`}
              name='image-selector'
              type='button'
              key={tiny}
              onClick={() => setCurrentIndex(i)}
              isLast={i === imageCount - 1}
            >
              <Thumbnail source={source} alt='' />
            </ThumbnailContainer>
          ))}
        </ThumbnailRow>
      </RenderIfAtLeastTwoImages>
      <div id={skipLinkSelector} />
    </>
  );
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(imageType).isRequired,
};
