// editor
import { createFieldHelper, FieldType } from "../../organisms";
export const carePlanHomeFields = createFieldHelper({
    homeVisits: {
        label: 'Visits at a glance',
        field: {
            type: FieldType.BooleanTable,
            table: {
                title: 'Day',
                columns: ['AM', 'Lunch', 'PM', 'Evening', 'Night'],
                rows: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                ],
            },
        },
    },
    homeHelp: {
        label: 'Help needed at home',
        field: {
            type: FieldType.BooleanTable,
            table: {
                title: 'Type of help',
                columns: ['AM', 'Lunch', 'PM', 'Evening', 'Night'],
                rows: [
                    'Washing',
                    'Brushing teeth',
                    'Dressing',
                    'Toilet',
                    'Eating meals',
                    'Meal prep',
                    'Cleaning',
                    'Walks',
                ],
            },
        },
    },
    specialRequirements: {
        label: 'Special requirements',
        field: {
            type: FieldType.MultiSelect,
            options: [
                {
                    value: 'preference-at-home-a-garden',
                    label: 'A garden',
                },
                {
                    value: 'preference-at-home-accessibility',
                    label: 'Accessibility features (ramps, handrails)',
                },
                {
                    value: 'preference-at-home-24hr-care',
                    label: '24-hour care availability',
                },
                {
                    value: 'preference-at-home-regular-medication',
                    label: 'Regular medication management',
                },
                {
                    value: 'preference-at-home-medical-equipment',
                    label: 'Medical equipment and supplies',
                },
                {
                    value: 'preference-at-home-nutritious-meals',
                    label: 'Nutritious meals and dietary support',
                },
                {
                    value: 'preference-at-home-transportation',
                    label: 'Transportation services for appointments',
                },
                {
                    value: 'preference-at-home-physical-therapy',
                    label: 'Physical therapy or exercise programs',
                },
                {
                    value: 'preference-at-home-social-activities',
                    label: 'Social activities and companionship',
                },
                {
                    value: 'preference-at-home-pet-friendly',
                    label: 'Pet-friendly environment',
                },
                {
                    value: 'preference-at-home-privacy',
                    label: 'Privacy and personal space',
                },
                {
                    value: 'preference-at-home-cleanliness',
                    label: 'Regular cleaning and home maintenance',
                },
                {
                    value: 'preference-at-home-emergency-response',
                    label: 'Emergency response systems',
                },
                {
                    value: 'preference-at-home-counseling-services',
                    label: 'Counseling or mental health support',
                },
                {
                    value: 'preference-at-home-friendly-caregivers',
                    label: 'Friendly and well-trained caregivers',
                },
                {
                    value: 'preference-at-home-personal-hygiene',
                    label: 'Personal hygiene and grooming assistance',
                },
                {
                    value: 'preference-at-home-communication-tools',
                    label: 'Communication tools (e.g., phone, internet access)',
                },
                {
                    value: 'preference-at-home-peaceful-environment',
                    label: 'Peaceful and quiet environment',
                },
                {
                    value: 'preference-at-home-security',
                    label: 'Security measures (e.g., locks, alarms)',
                },
                {
                    value: 'preference-at-home-religious-accommodations',
                    label: 'Accommodations for religious or spiritual practices',
                },
                {
                    value: 'preference-at-home-cultural-sensitivity',
                    label: 'Cultural sensitivity and accommodations',
                },
            ],
        },
    },
    homeCarersNeeded: {
        label: 'Carers needed',
        field: {
            type: FieldType.Number,
        },
    },
    postcode: {
        label: 'Postcode',
        field: {
            type: FieldType.Text,
        },
    },
    startDate: {
        label: 'Date care would start',
        field: {
            type: FieldType.DateOfBirth,
        },
    },
});
