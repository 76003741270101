// react
import { useEffect, useState } from 'react';

/**
 * useWindowSize is a hook that returns the current window size.
 */
export const useWindowSize = () => {
  // state
  const [windowSize, setWindowSize] = useState([
    window?.innerWidth,
    window?.innerHeight,
  ]);

  // effect
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window?.innerWidth, window?.innerHeight]);
    };
    window?.addEventListener('resize', handleWindowResize);
    window?.addEventListener('orientationchange', handleWindowResize);
    return () => {
      window?.removeEventListener('resize', handleWindowResize);
      window?.removeEventListener('orientationchange', handleWindowResize);
    };
  }, []);

  return windowSize;
};
