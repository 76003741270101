// components
import { InvitePortal as InvitePortalPage } from '@karehero/llama';
import { useParams } from 'react-router-dom';

// store
import {
  useGetAllOrganizationAccountsAdminQuery,
  useGetOrganizationQuery,
  useInviteMembersToOrganizationAdminMutation,
  useAddInviteKeysToOrganizationAdminMutation,
} from 'store/api/organization';
import { newToast } from 'store/slices/toastSlice';

import { useDispatch } from 'react-redux';

/**
 * OrganizationMemberTable shows the accounts and invited members of an organization
 */
const OrganizationMemberTable = () => {
  // params
  const { organizationID } = useParams();

  // hooks
  const [inviteMembers] = useInviteMembersToOrganizationAdminMutation();
  const [addInviteKeys] = useAddInviteKeysToOrganizationAdminMutation();
  const dispatch = useDispatch();
  const { data: organization } = useGetOrganizationQuery(organizationID || '');
  const { data: accounts } = useGetAllOrganizationAccountsAdminQuery(
    organizationID || '',
  );

  return (
    <InvitePortalPage
      accounts={accounts}
      organization={organization}
      inviteMembers={(req) =>
        organizationID && inviteMembers({ ...req, organizationID })
      }
      addInviteKeys={(req) =>
        organizationID && addInviteKeys({ ...req, organizationID })
      }
      notifyToast={(req: { title: string; description: string }) =>
        dispatch(newToast(req))
      }
    />
  );
};

export default OrganizationMemberTable;
