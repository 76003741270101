import PropTypes from 'prop-types';

import { regulatorType } from '../../../types';
import Spacer from '../../../primitives/Spacer';
import TwoColumn from '../../../primitives/layout/TwoColumn';
import SubTitle from '../../../primitives/typography/SubTitle';
import Paragraph from '../../../primitives/typography/Paragraph';
import InlineText from '../../../primitives/typography/InlineText';
import Currency from '../../../primitives/Currency';
import Strong from '../../../primitives/typography/Strong';
import IconLabel from '../../../primitives/IconLabel';
import { icons } from '../../../primitives/Icon';
import { useThemeHook } from '../../../styling';

const FundingInfoDefault = ({ country, value }) => (
  <>
    <SubTitle>Funding for care in {country}</SubTitle>
    <Spacer top>
      <Paragraph>
        If your total savings and property assets are below{' '}
        <Strong>
          <Currency value={value} />
        </Strong>
        , then you may be able to access local authority funding towards{' '}
        <Strong>accommodation</Strong> costs, <Strong>personal care</Strong> and{' '}
        <Strong>nursing care</Strong>.
      </Paragraph>
    </Spacer>
    <Spacer top>
      <Paragraph>
        To qualify, you must first arrange a care needs assessment.
      </Paragraph>
    </Spacer>
  </>
);

const FundingInfoCqc = () => (
  <FundingInfoDefault country='England' value={23250} />
);
const FundingInfoRqia = () => (
  <FundingInfoDefault country='Northern Ireland' value={23250} />
);
const FundingInfoCiWales = () => (
  <FundingInfoDefault country='Wales' value={50000} />
);

const FundingInfoCi = () => {
  const { colour } = useThemeHook();

  return (
    <>
      <SubTitle>Funding for care in Scotland</SubTitle>
      <Spacer top>
        <Paragraph>
          Your care home costs could be partially funded by the council. To
          qualify for this, you must first arrange a care needs assessment.
        </Paragraph>
        <Spacer top>
          <IconLabel
            icon={icons.check}
            colour={colour.status.success}
            description={
              <InlineText>
                <Strong>Free personal and/or nursing care funding</Strong> is
                available to anyone aged 65 or over, regardless of their
                financial situation.
              </InlineText>
            }
          />
        </Spacer>
        <Spacer top>
          <IconLabel
            icon={icons.check}
            colour={colour.status.success}
            description={
              <InlineText>
                Funding towards <Strong>accommodation</Strong> costs is also
                available if your assets (property &amp; savings) are below{' '}
                <Strong>
                  <Currency value={27250} />
                </Strong>
              </InlineText>
            }
          />
        </Spacer>
      </Spacer>
    </>
  );
};

const fundingInfo = {
  ci: FundingInfoCi,
  cqc: FundingInfoCqc,
  rqia: FundingInfoRqia,
  ci_wales: FundingInfoCiWales,
};

const PriceKnown = ({ pricePerWeek }) => (
  <>
    <Paragraph>
      Prices for this home start at
      <br />
      <Strong>
        <Currency value={pricePerWeek} />
      </Strong>{' '}
      per week.
      <br />
    </Paragraph>
    <Spacer top />
    <Paragraph>
      The final price will depend on your individual requirements -{' '}
      <Strong>contact the care home for more details.</Strong>
    </Paragraph>
  </>
);

const PriceUnknown = () => (
  <Paragraph>
    Sorry, we don&apos;t have prices for this care home - please{' '}
    <Strong>contact the care home</Strong> for more details.
  </Paragraph>
);

export default function CareHomeFunding({ regulator, pricePerWeek }) {
  const FundingInfo = fundingInfo[regulator];
  const Price = pricePerWeek ? PriceKnown : PriceUnknown;
  return (
    <TwoColumn
      noPaddingTopBottom
      noPaddingLeftRight
      sidebar={
        <>
          <SubTitle>Prices</SubTitle>
          <Spacer top>
            <Price pricePerWeek={pricePerWeek} />
          </Spacer>
        </>
      }
      body={<FundingInfo />}
      sidebarPercent={50}
    />
  );
}

FundingInfoDefault.propTypes = {
  country: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

PriceKnown.propTypes = {
  pricePerWeek: PropTypes.number.isRequired,
};

CareHomeFunding.propTypes = {
  regulator: regulatorType,
  pricePerWeek: PropTypes.number,
};

CareHomeFunding.defaultProps = {
  regulator: undefined,
  pricePerWeek: undefined,
};
