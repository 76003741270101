import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
/**
 * CarouselIndicator shows dots where only one is highlighted.
 */
export const CarouselIndicator = ({ total, index }) => {
    const dots = useMemo(() => {
        let tmpArray = [];
        for (let i = 0; i < total; i++) {
            tmpArray.push(i);
        }
        return tmpArray;
    }, [total]);
    return (_jsx(Wrapper, { children: dots.map((dot) => (_jsx(IndicatorDot, { "$isHighlighted": dot === index }, dot))) }));
};
const Wrapper = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
`;
const IndicatorDot = styled.div `
  min-width: 7px;
  width: 7px;
  max-width: 7px;
  min-height: 7px;
  height: 7px;
  max-height: 7px;
  border-radius: 99999px;
  transition-duration: 0.2s;
  background: ${(props) => props.$isHighlighted
    ? props.theme.color.primary[60]
    : props.theme.color.neutral[40]};
`;
CarouselIndicator.displayName = 'CarouselIndicator';
export default CarouselIndicator;
