import { useState } from 'react';
import PropTypes from 'prop-types';

import { styled, media } from '../../styling';
import TwoColumn from './TwoColumn';
import PositionFixed from './PositionFixed';

const OnlyMobile = styled.div`
  ${media('>tablet')} {
    display: none;
  }
`;

const Drawer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.colour.neutral.mid};
  padding: ${({ theme }) => theme.spacing.units(2)};
  transform: translateX(${({ open }) => (open ? 0 : '100%')});
  transition: all 200ms ease-in-out;
  overflow-y: auto;
`;

export default function TwoColumnWithDrawer({
  sidebar,
  body,
  bodyPosition,
  sidebarPercent,
  renderDrawerToggle,
  renderDrawerContent,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenuOpen = () => setMenuOpen(!menuOpen);

  return (
    <>
      <TwoColumn
        sidebar={sidebar}
        body={body}
        bodyPosition={bodyPosition}
        sidebarPositionMobile='notrendered'
        sidebarPercent={sidebarPercent}
      />

      <OnlyMobile>
        {renderDrawerToggle(toggleMenuOpen)}
        <PositionFixed top={0} left={0}>
          <Drawer open={menuOpen}>{renderDrawerContent(toggleMenuOpen)}</Drawer>
        </PositionFixed>
      </OnlyMobile>
    </>
  );
}

TwoColumnWithDrawer.propTypes = {
  sidebar: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  bodyPosition: PropTypes.oneOf(['left', 'right']),
  renderDrawerToggle: PropTypes.func.isRequired,
  renderDrawerContent: PropTypes.func.isRequired,
  sidebarPercent: PropTypes.number,
};

TwoColumnWithDrawer.defaultProps = {
  bodyPosition: 'right',
  sidebarPercent: undefined,
};
