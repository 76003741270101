import PropTypes from 'prop-types';

import Row from '../../../primitives/Row';
import Spacer from '../../../primitives/Spacer';
import IconLabel from '../../../primitives/IconLabel';
import { styled, media } from '../../../styling';
import { icons } from '../../../primitives/Icon';

const FeatureContainer = styled.div`
  width: 50%;
  ${media('<tablet')} {
    width: 100%;
  }
`;

const pluralise = (count, plural, singular) =>
  count === 1 ? singular : plural;

export function FeatureItem({ featureItem: [key, value] }) {
  const featureMap = {
    bedCount: (beds) => ({
      label: `${beds} ${pluralise(beds, 'beds', 'bed')}`,
      icon: icons.bed,
      show: !!beds,
      disabled: false,
    }),
    doubleBedCount: (beds) => ({
      label: beds
        ? `${beds} ${pluralise(
            beds,
            'beds in double / twin rooms',
            'bed in a double / twin room',
          )}`
        : 'No double / twin rooms',
      icon: icons.bed,
      show: !!beds || beds === 0,
      disabled: beds === 0,
    }),
    enSuiteRoomCount: (enSuites) => ({
      label: enSuites
        ? `${enSuites} en-suite ${pluralise(enSuites, 'rooms', 'room')}`
        : 'No en-suite rooms',
      icon: icons.toilet,
      show: !!enSuites || enSuites === 0,
      disabled: enSuites === 0,
    }),
  }[key](value);

  return (
    featureMap.show && (
      <FeatureContainer>
        <Spacer doubleTop>
          <IconLabel
            icon={featureMap.icon}
            description={featureMap.label}
            disabled={featureMap.disabled}
          />
        </Spacer>
      </FeatureContainer>
    )
  );
}

FeatureItem.propTypes = {
  featureItem: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default function CareHomeFreatures({ careHomeFeatures }) {
  const { dateOpened, purposeBuilt, ...otherFeatures } = careHomeFeatures;

  return (
    <Row flexWrap>
      {Object.entries(otherFeatures).map((feature) => (
        <FeatureItem key={feature} featureItem={feature} />
      ))}

      {dateOpened && (
        <Spacer doubleTop>
          <IconLabel
            icon={icons.hotel}
            description={`${
              purposeBuilt ? 'Purpose built, opened' : 'Opened'
            } in ${new Date(dateOpened).getFullYear()}`}
          />
        </Spacer>
      )}
    </Row>
  );
}

CareHomeFreatures.propTypes = {
  careHomeFeatures: PropTypes.shape({
    bedCount: PropTypes.number,
    dateOpened: PropTypes.string,
    purposeBuilt: PropTypes.bool,
    doubleBedCount: PropTypes.number,
    enSuiteRoomCount: PropTypes.number,
  }).isRequired,
};
