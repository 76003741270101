// react
import styled from 'styled-components';

// third-party
import PropTypes from 'prop-types';

// components
import Card from '../../../primitives/Card';
import { Button } from '@karehero/llama';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

export default function CareEnquiriesCard({ phoneNumber }) {
  // hooks
  const { track } = useMixpanel();

  return (
    <StyledCard dark>
      <CardContent>
        <Header>
          <Title>Contact this home</Title>
          <HelperText>
            <strong>Call now</strong> for more details - staff are happy to
            answer any of your questions about care.
          </HelperText>
        </Header>
        <Button
          size='lg'
          iconLeft={'phone'}
          isRectangle
          to={`tel:${phoneNumber}`}
          onClick={() =>
            track(MixpanelEvent.DirectoryPhoneNumberClicked, {
              phoneNumber,
            })
          }
        >
          {phoneNumber}
        </Button>
      </CardContent>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  padding: ${(props) => props.theme.spacing[20]};
  border-radius: 16px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[20]};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[12]};
`;

const Title = styled.h4`
  font: ${(props) => props.theme.typography.body.xl.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
  margin: 0;
`;

const HelperText = styled.p`
  margin: 0;
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;

CareEnquiriesCard.propTypes = {
  providerName: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  fireTrackingEvent: PropTypes.func.isRequired,
  onEmailReveal: PropTypes.func,
  onPhoneReveal: PropTypes.func,
};

CareEnquiriesCard.defaultProps = {
  providerName: undefined,
  phoneNumber: undefined,
  email: undefined,
  onEmailReveal: () => {},
  onPhoneReveal: () => {},
};
