// components
import { CareProfile as CareProfilePage } from '@karehero/llama';

// hooks
import { useCareCircle } from 'hooks';

// launch darkly
import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * CareProfile is the dashboard page.
 */
const CareProfile = () => {
  // hooks
  const { currentCareCircle } = useCareCircle();
  const flags = useFlags();

  return (
    <CareProfilePage
      isCareCircle={flags.careCircleV1}
      isAttendanceAllowance={flags.attendanceAllowance}
      careRecipient={currentCareCircle?.careRecipientAccount}
    />
  );
};

export default CareProfile;
