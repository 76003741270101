import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
// component
import { ArticleCard, Carousel } from "../../atoms";
/**
 * ArticleSummary is a helper component that renders the library.
 */
export const ArticleSummary = ({ library, bookmarkedArticles, onBookmark, isLoading, }) => {
    if (!library || library.length === 0) {
        return null;
    }
    return (_jsx(_Fragment, { children: library.map((category) => {
            var _a, _b;
            return ((_a = category === null || category === void 0 ? void 0 : category.articles) === null || _a === void 0 ? void 0 : _a.length) !== 0 && (_jsx(Carousel, { title: category === null || category === void 0 ? void 0 : category.title, isLoading: isLoading, items: ((_b = category === null || category === void 0 ? void 0 : category.articles) === null || _b === void 0 ? void 0 : _b.map((article, i) => ({
                    id: (article === null || article === void 0 ? void 0 : article.title) || `${i}`,
                    renderItem: (_jsx(StyledArticleCard, { article: article, isBookmarked: !!(bookmarkedArticles === null || bookmarkedArticles === void 0 ? void 0 : bookmarkedArticles.find((a) => (a === null || a === void 0 ? void 0 : a._id) === (article === null || article === void 0 ? void 0 : article._id))), onBookmark: onBookmark, isLoading: isLoading })),
                }))) || [] }, category === null || category === void 0 ? void 0 : category.title));
        }) }));
};
const StyledArticleCard = styled(ArticleCard) `
  min-width: 250px;
  width: 250px;
  max-width: 250px;
`;
ArticleSummary.displayName = 'ArticleSummary';
export default ArticleSummary;
