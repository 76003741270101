import { styled } from '../../styling';

const Title = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colour.neutral.dark};
  font-weight: bold;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeading};
`;

export default Title;
