import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
/**
 * Banner displays a coloured banner containing text.
 */
export const Banner = ({ className, content, to }) => {
    const props = useMemo(() => {
        if (to) {
            return {
                as: Link,
                to,
                target: '_blank',
            };
        }
        return {};
    }, []);
    return (_jsx(Wrapper, Object.assign({ className: className }, props, { children: content })));
};
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: ${(props) => props.theme.spacing[8]};
  background: ${(props) => props.theme.color.primary[80]};
  padding: ${(props) => props.theme.spacing[12]};
  color: ${(props) => props.theme.color.neutral[10]};
  font: ${(props) => props.theme.typography.body.md.regular};
  & > div {
    text-align: center;
  }
`;
Banner.displayName = 'Banner';
export default Banner;
