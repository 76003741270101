import { styled } from '../../../styling';

const InvisibleLink = styled.a`
  position: absolute;

  :focus {
    z-index: 1;
  }
`;

export default InvisibleLink;
