import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ActiveProviderContext = createContext();

export const ActiveProviderProvider = ({ children }) => {
  const [activeProvider, setActiveProvider] = useState(undefined);

  const setProvider = (provider) => setActiveProvider(provider);
  const clearProvider = () => setActiveProvider(undefined);

  return (
    <ActiveProviderContext.Provider
      value={{ activeProvider, setProvider, clearProvider }}
    >
      {children}
    </ActiveProviderContext.Provider>
  );
};

ActiveProviderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
