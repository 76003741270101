import { useContext } from 'react';
import Search from './Search';
import Card from '../../components/primitives/Card';
import TwoColumnWithBackgroundImage from '../../components/primitives/layout/TwoColumnWithBackgroundImage';
import { GlobalSettingsContext } from '../../context/GlobalSettingsContext';

export default function Banner() {
  const { bannerImageUrl } = useContext(GlobalSettingsContext);

  return (
    <TwoColumnWithBackgroundImage
      image={bannerImageUrl}
      imagePosition='0 10%'
      sidebarPercent={40}
      sidebar={
        <Card>
          <Search />
        </Card>
      }
      body={<></>}
    />
  );
}
