import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
// components
import { Checkbox, BaseField } from "..";
/**
 * BooleanTable is an input component which allows users to modify boolean
 * values in a table format.
 *
 * Example value:
 * {
 *   "washing": {
 *     "morning": true,
 *     "afternoon": false,
 *     "evening": true,
 *   },
 *   "brushing-teeth": {
 *     "morning": false,
 *     "afternoon": false,
 *     "evening": true,
 *   },
 * }
 */
export const BooleanTable = ({ className, columns, rows, title, id, label, description, tooltip, isRequired, isHideTitle, value, onChange, }) => {
    // memo
    const valueObject = useMemo(() => JSON.parse(value || '{}'), [value]);
    // methods
    const handleCheckboxChange = useCallback((row, column, value) => {
        const newValue = Object.assign({}, valueObject);
        if (!newValue[row]) {
            newValue[row] = {};
        }
        newValue[row][column] = value;
        onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify(newValue));
    }, [valueObject, onChange]);
    return (_jsx(BaseField, Object.assign({ id: id, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(Title, { children: title }), columns.map((column) => (_jsx(ColumnHeader, { children: column }, column)))] }) }), _jsx("tbody", { children: rows.map((row) => (_jsxs(Row, { children: [_jsx(RowTitle, { children: row }), columns.map((column) => {
                                var _a;
                                return (_jsx(CheckboxWrapper, { children: _jsx(Checkbox, { id: `${id}-${row}-${column}`, value: (_a = valueObject === null || valueObject === void 0 ? void 0 : valueObject[row]) === null || _a === void 0 ? void 0 : _a[column], onChange: (value) => handleCheckboxChange(row, column, value) }, `${id}-${row}-${column}`) }, `${row}-${column}`));
                            })] }, row))) })] }) })));
};
const Table = styled.table `
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;
const Title = styled.th `
  padding-bottom: ${(props) => props.theme.spacing[12]};
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.primary[80]};
  text-align: left;
`;
const ColumnHeader = styled.th `
  padding: 0 ${(props) => props.theme.spacing[12]};
  padding-bottom: ${(props) => props.theme.spacing[12]};
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.neutral[100]};
  min-width: 100px;
  text-align: left;
`;
const RowTitle = styled.td `
  padding: ${(props) => props.theme.spacing[12]} 0;
  padding-right: ${(props) => props.theme.spacing[12]};
  border-top: 1px solid ${(props) => props.theme.color.neutral[40]};
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: left;
`;
const CheckboxWrapper = styled.td `
  padding: ${(props) => props.theme.spacing[12]} 0;
  border-top: 1px solid ${(props) => props.theme.color.neutral[40]};
  padding-left: ${(props) => props.theme.spacing[20]};
  & > * {
    width: min-content;
  }
`;
const Row = styled.tr `
  margin-top: ${(props) => props.theme.spacing[32]};
  padding-top: ${(props) => props.theme.spacing[32]};
`;
BooleanTable.displayName = 'BooleanTable';
export default BooleanTable;
