import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// assets
// @transform-path ../../../../../../src/assets/loader/keni.svg
import keni from "../../../../../../src/assets/loader/keni.svg";
// @transform-path ../../../../../../src/assets/easter-egg.gif
import easterEgg from "../../../../../../src/assets/easter-egg.gif";
/**
 * Loader shows a loading animation.
 */
export const Loader = ({ className, isEasterEgg }) => {
    return (_jsx(Wrapper, Object.assign({ className: className }, { children: isEasterEgg ? (_jsxs(_Fragment, { children: [_jsx(Circle, {}), _jsx(CircleCutout, {}), _jsx(EasterEggWrapper, {})] })) : (_jsxs(_Fragment, { children: [_jsx(Circle, {}), _jsx(CircleCutout, {}), _jsx(KeniWrapper, {})] })) })));
};
const Wrapper = styled.div `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing[64]};
`;
const Circle = styled.div `
  width: 161px;
  height: 161px;
  background: conic-gradient(
    rgba(149, 89, 225, 0),
    ${(props) => props.theme.color.surfaces.mediumPurple}
  );
  border-radius: 99999px;
  animation: circle 1.5s infinite linear;
  @keyframes circle {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
const CircleCutout = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 130px;
  background: ${(props) => props.theme.color.neutral[10]};
  border-radius: 99999px;
`;
const KeniWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const EasterEggWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 99999px;
  overflow: hidden;
  width: 130px;
  height: 130px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
Loader.displayName = 'Loader';
export default Loader;
