import { styled, styleHelpers } from '../../../styling';
import { ratingsType } from '../../../types';

import Column from '../../../primitives/Column';
import Row from '../../../primitives/Row';
import Spacer from '../../../primitives/Spacer';
import Strong from '../../../primitives/typography/Strong';
import InlineText from '../../../primitives/typography/InlineText';
import Card from '../../../primitives/Card';

const camelToSentence = (str) => str && str.replace(/([A-Z])/g, ' $1');
const { lighten } = styleHelpers;

const RatingRow = styled(Row)`
  padding: ${({ theme }) => theme.spacing.units(4)};
  background-color: ${({ theme }) => theme.colour.neutral.mid};
  border-bottom: 1px solid
    ${({ theme }) => lighten(0.6, theme.colour.neutral.dark)};

  :first-of-type,
  :last-of-type {
    border: none;
  }
`;

const RatingColouredRow = styled(RatingRow)`
  background-color: ${({ theme, rating }) =>
    theme.colour.feature.rating[rating]};
`;

const RatingPill = styled(Strong)`
  color: ${({ theme, rating }) =>
    rating ? theme.colour.feature.rating[rating] : theme.colour.neutral.dark};
  background-color: ${({ theme, rating }) =>
    rating
      ? lighten(0.6, theme.colour.feature.rating[rating])
      : theme.colour.neutral.light};
  padding: ${({ theme }) => theme.spacing.units(2)};
  border-radius: ${({ theme }) => theme.spacing.units(1)};
`;

export default function RegulatorsRatings({ ratings }) {
  const { overallRating, ...subRatings } = ratings;

  if (!overallRating || overallRating === 'notPublished') {
    return (
      <Card dark>
        <InlineText>
          This care provider is registered with the regulator but has not yet
          been inspected.
        </InlineText>
      </Card>
    );
  }

  return (
    <Column>
      <RatingColouredRow rating={ratings.overallRating}>
        <InlineText light>Overall:</InlineText>
        <Spacer left>
          <Strong light transform='capitalize'>
            {camelToSentence(ratings.overallRating) || 'Not assessed'}
          </Strong>
        </Spacer>
      </RatingColouredRow>
      {Object.entries(subRatings).map(([area, rating]) => (
        <RatingRow spaceBetween alignCentre key={area}>
          <InlineText transform='capitalize'>
            {camelToSentence(area) || 'Not assessed'}
          </InlineText>
          <RatingPill transform='capitalize' rating={rating}>
            {camelToSentence(rating) || 'Not assessed'}
          </RatingPill>
        </RatingRow>
      ))}
    </Column>
  );
}

RegulatorsRatings.propTypes = {
  ratings: ratingsType.isRequired,
};
