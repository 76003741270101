import PropTypes from 'prop-types';
import { ColourIcon, icons } from '../Icon';

import { iconType } from '../../types';
import { useThemeHook } from '../../styling';

import HiddenInput from './components/HiddenInput';
import LabelText from './components/LabelText';
import Container from './components/Container';

const Checkbox = ({
  name,
  value,
  label,
  checked,
  onChange,
  labelPosition,
  checkedIcon,
  uncheckedIcon,
  disabled,
}) => {
  const { colour } = useThemeHook();
  const iconColour = disabled ? colour.status.disabled : colour.primary;

  return (
    <HiddenInput
      type='checkbox'
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    >
      {(focused) => (
        <Container focused={focused} disabled={disabled}>
          {labelPosition === 'left' && (
            <LabelText focused={focused} title={label}>
              {label}
            </LabelText>
          )}
          {checked && <ColourIcon icon={checkedIcon} colour={iconColour} />}
          {!checked && <ColourIcon icon={uncheckedIcon} colour={iconColour} />}
          {labelPosition === 'right' && (
            <LabelText focused={focused} title={label} disabled={disabled}>
              {label}
            </LabelText>
          )}
        </Container>
      )}
    </HiddenInput>
  );
};

Checkbox.propTypes = {
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  labelPosition: PropTypes.oneOf(['left', 'right']),
  checkedIcon: iconType,
  uncheckedIcon: iconType,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  labelPosition: 'right',
  checkedIcon: icons.squareChecked,
  uncheckedIcon: icons.square,
  disabled: false,
};

export default Checkbox;
