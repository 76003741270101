import { useContext } from 'react';
import PropTypes from 'prop-types';

import Column from '../../components/primitives/Column';
import Checkbox from '../../components/primitives/form/Checkbox';

import { ProviderFilterContext } from '../../context/ProviderFilterContext';

import Spacer from '../../components/primitives/Spacer';

export default function HomeCareRateFilters({ disabled }) {
  const {
    homeCareHourlyRateRanges,
    activeFilters: { enabledHourlyRates },
    toggleHourlyRate,
  } = useContext(ProviderFilterContext);

  return (
    <Column>
      {homeCareHourlyRateRanges.map(({ label, value }, i) => (
        <Spacer top={i > 0} key={value}>
          <Checkbox
            name={label}
            label={label}
            value={value}
            checked={enabledHourlyRates[value]}
            onChange={toggleHourlyRate}
            disabled={disabled}
          />
        </Spacer>
      ))}
    </Column>
  );
}

HomeCareRateFilters.propTypes = {
  disabled: PropTypes.bool,
};

HomeCareRateFilters.defaultProps = {
  disabled: false,
};
