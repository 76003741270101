// react
import { useNavigate } from 'react-router-dom';

// components
import { CarePlanChoice as CarePlanChoicePage } from '@karehero/llama';

/**
 * CarePlanChoice shows the care plan complete page.
 */
const CarePlanChoice = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <CarePlanChoicePage
      toBack={`/care-profile`}
      toNext={`/care-plan`}
      navigate={navigate}
    />
  );
};

export default CarePlanChoice;
