import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
// components
import { BaseField, Button, Checkbox, Input, } from "..";
import { CardContainer } from "../../templates";
/**
 * MultiSelectBox allows the user to select multiple values from a list of
 * options in the form of chips.
 */
export const MultiSelectBox = ({ className, id, ariaLabel, label, description, tooltip, isRequired, options, value, maxColumns, checkboxSize, onChange, isCustom, variant = 'box', isDisabled = false, isHideTitle = false, }) => {
    // state
    const [customValue, setCustomValue] = useState('');
    const [isAddAnother, setIsAddAnother] = useState(false);
    // memo
    const valueArray = useMemo(() => JSON.parse(value || '[]'), [value]);
    const customValues = useMemo(() => {
        return valueArray.filter((val) => !options.map((option) => option.value).includes(val));
    }, [valueArray, options]);
    const sortedOptions = useMemo(() => options.sort((a, b) => ((a === null || a === void 0 ? void 0 : a.order) || 0) - ((b === null || b === void 0 ? void 0 : b.order) || 0)), [options]);
    // methods
    const handleBoxPress = useCallback((value) => {
        const index = valueArray.indexOf(value);
        if (index === -1) {
            onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify([...valueArray, value]));
        }
        else {
            const newValue = [...valueArray];
            newValue.splice(index, 1);
            onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify(newValue));
        }
    }, [valueArray, onChange]);
    const handleSaveCustomValue = useCallback(() => {
        if (customValue.trim() !== '' && !valueArray.includes(customValue)) {
            onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify([...valueArray, customValue]));
        }
        setIsAddAnother(false);
        setCustomValue('');
    }, [valueArray, customValue, onChange, options]);
    // effects
    useEffect(() => {
        const inputField = document.getElementById('addAnotherInput');
        if (inputField && isAddAnother) {
            const inputY = inputField.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: inputY, behavior: 'smooth' });
        }
    }, [isAddAnother]);
    return (_jsx(BaseField, Object.assign({ id: id, label: label, ariaLabel: ariaLabel, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsxs(Wrapper, Object.assign({ className: className }, { children: [sortedOptions.length + customValues.length > 0 && (_jsxs(CardContainer, Object.assign({ maxColumns: maxColumns !== null && maxColumns !== void 0 ? maxColumns : 2, size: 'sm', isPreventHeightMatch: true }, { children: [sortedOptions.map((option) => (_jsx(Checkbox, { id: `${id}-${option.value}`, label: option.label, variant: variant, size: checkboxSize !== null && checkboxSize !== void 0 ? checkboxSize : 'sm', value: valueArray.includes(option.value), onChange: () => handleBoxPress(option.value), isDisabled: isDisabled }, option.value))), customValues.map((customValue) => (_jsx(Checkbox, { id: `${id}-${customValue}`, label: customValue, variant: variant, size: checkboxSize !== null && checkboxSize !== void 0 ? checkboxSize : 'sm', value: valueArray.includes(customValue), onChange: () => handleBoxPress(customValue), isDisabled: isDisabled }, customValue)))] }))), isCustom && (_jsx(AddAnotherWrapper, { children: !isAddAnother ? (_jsx(Button, Object.assign({ ariaLabel: 'add another', iconLeft: 'plus', variant: 'secondary', onPress: () => setIsAddAnother(true), isDisabled: isDisabled }, { children: "Add another" }))) : (_jsxs(_Fragment, { children: [_jsx(Input, { id: 'addAnotherInput', ariaLabel: 'something else', placeholder: 'Something else', value: customValue, onChange: (val) => setCustomValue(val || ''), isDisabled: isDisabled, autoFocus: true }), _jsxs(ActionWrapper, { children: [_jsx(Button, Object.assign({ ariaLabel: 'save', variant: 'secondary', size: 'sm', type: 'submit', onPress: handleSaveCustomValue, isDisabled: isDisabled }, { children: "Save" })), _jsx(Button, Object.assign({ ariaLabel: 'cancel', variant: 'link', size: 'sm', onPress: () => {
                                            setIsAddAnother(false);
                                            setCustomValue('');
                                        } }, { children: "Cancel" }))] })] })) }))] })) })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
const AddAnotherWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
const ActionWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
MultiSelectBox.displayName = 'MultiSelectBox';
export default MultiSelectBox;
