import api from '.';
import {
  Message,
  Referral,
  ReferralSubmissionResponse,
} from '@karehero/models';

export const referralApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendExternalReferral: builder.mutation<
      ReferralSubmissionResponse,
      Referral
    >({
      query: (referral: Referral) => ({
        url: `/invite/referral/external`,
        method: 'POST',
        body: referral,
      }),
    }),
    sendInternalReferral: builder.mutation<Message, Referral>({
      query: (referral: Referral) => ({
        url: `/invite/referral/internal`,
        method: 'POST',
        body: referral,
      }),
      invalidatesTags: ['Accounts', 'InvitedMembers', 'OrganizationMembers'],
    }),
  }),
});

export const {
  useSendExternalReferralMutation,
  useSendInternalReferralMutation,
} = referralApi;

export default referralApi;
