import PropTypes from 'prop-types';

import CardContainer from './components/CardContainer';
import Column from '../../primitives/Column';
import ProviderCard from '../ProviderCard/ProviderCard';
import Spacer from '../../primitives/Spacer';
import { providerType } from '../../types';
import SearchResultsListSkeleton from './SearchResultsListSkeleton';

export default function SearchResultsList({
  results,
  loading,
  onCardReadMoreClick,
}) {
  const haveResults = !!results.length && !loading;

  return (
    <Column>
      {loading && <SearchResultsListSkeleton />}
      {haveResults && (
        <CardContainer>
          {results.map((r) => (
            <Spacer bottom right key={r.regulatoryId}>
              <ProviderCard
                provider={r}
                onReadMoreClick={onCardReadMoreClick}
              />
            </Spacer>
          ))}
        </CardContainer>
      )}
    </Column>
  );
}

SearchResultsList.propTypes = {
  results: PropTypes.arrayOf(providerType).isRequired,
  loading: PropTypes.bool.isRequired,
  onCardReadMoreClick: PropTypes.func.isRequired,
};
