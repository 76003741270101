import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { Button, Card, Logo, Title } from "../../atoms";
/**
 * CareReferralInfo renders the account settings page.
 */
export const CareReferralInfo = ({ onRefer }) => {
    return (_jsx(CardWrapper, { children: _jsxs(StyledCard, Object.assign({ color: 'blue', isTransparent: true, isBordered: false, isPaddingSm: true }, { children: [_jsxs(CardContent, { children: [_jsx(Logo, { size: 'md' }), _jsx(Title, Object.assign({ isNoMargin: true }, { children: "Care Simplified." })), _jsx(Description, { children: "Our care concierge service supports SJP clients and their families with professional advice on understanding, finding and funding care." }), _jsx(Description, { children: "Your client will benefit from:" }), _jsxs(StyledList, { children: [_jsxs("li", { children: ["1:1 ", _jsx("strong", { children: "unlimited access" }), " to a", ' ', _jsx("strong", { children: "dedicated care expert" })] }), _jsxs("li", { children: ["Comprehensive digital ", _jsx("strong", { children: "care assessment" })] }), _jsxs("li", { children: [_jsx("strong", { children: "Expert" }), " search for the", ' ', _jsx("strong", { children: "best care options" })] }), _jsxs("li", { children: ["Direct access to ", _jsx("strong", { children: "vetted, regulated care providers" })] }), _jsxs("li", { children: [_jsx("strong", { children: "Bespoke consultation" }), " on", ' ', _jsx("strong", { children: "non-regulated funding benefits eligiblity" })] }), _jsxs("li", { children: [_jsx("strong", { children: "Practical help" }), " applying for funding benefits"] })] })] }), _jsx(Button, Object.assign({ ariaLabel: 'refer-now', onPress: onRefer, size: 'sm', isRectangle: true }, { children: "Refer now" }))] }), 'card-content') }));
};
const StyledCard = styled(Card) `
  padding: ${(props) => props.theme.spacing[38]}
    ${(props) => props.theme.spacing[24]};
`;
const CardWrapper = styled.div `
  width: 100%;
  max-width: 100%;

  ${(props) => props.theme.breakpoint.sm(css `
      max-width: 500px;
    `)}
  margin: 0;
  padding: 0;
`;
const CardContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const StyledList = styled.ul `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]};
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[100]};
  margin-top: 0;
  padding-top: 0;
`;
CareReferralInfo.displayName = 'CareReferralInfo';
export default CareReferralInfo;
