import PropTypes from 'prop-types';

import IconLabel from '../../../primitives/IconLabel';
import { useThemeHook, styled, media } from '../../../styling';
import Row from '../../../primitives/Row';
import { icons } from '../../../primitives/Icon';
import { makeRatingIconsData } from '../../makeRatingIconsData';
import Currency from '../../../primitives/Currency';
import Spacer from '../../../primitives/Spacer';
import { careTypeType } from '../../../types';

const SummaryItemContainer = styled.div`
  width: 50%;
  ${media('<tablet')} {
    width: 100%;
  }
`;

export function SummaryItem({ summaryItem: [key, value], careType }) {
  const theme = useThemeHook();

  const isCareHome = careType === 'careHome';
  const isHomeCare = careType === 'homeCare';
  const ratingIconsData = makeRatingIconsData(theme);

  const summaryMap = {
    rating: (rating) => ({
      label: rating && ratingIconsData[rating].label,
      icon: rating && ratingIconsData[rating].icon,
      colour: rating && ratingIconsData[rating].colour,
      description: "Regulator's rating",
      show: !!rating,
    }),
    pricePerWeek: (price) => ({
      label: price && <Currency value={price} />,
      description: 'Weekly starting price',
      icon: icons.poundSign,
      show: !!price && isCareHome,
    }),
    bedCount: (beds) => ({
      label: `${beds} beds`,
      description: 'Maximum occupancy',
      icon: icons.bed,
      show: !!beds,
    }),
    dateOpened: (date) => ({
      label: date && new Date(date).getFullYear(),
      description: 'Date opened',
      icon: icons.hotel,
      show: !!date,
    }),
    availability: (available) => ({
      label: { careHome: 'Vacancies', homeCare: 'Accepting clients' }[careType],
      description: {
        careHome: available ? 'Current availability' : 'No rooms this week',
      }[careType],
      icon: available ? icons.check : icons.close,
      colour: available
        ? theme.colour.status.success
        : theme.colour.status.neutral,
      show: isCareHome || !!available,
    }),
    covidResponse: (prepared) => ({
      label: 'Covid-19 response',
      description: prepared
        ? 'See Covid-19 response details below'
        : 'No information provided. Contact for more details.',
      icon: icons.shield,
      colour: prepared
        ? theme.colour.status.success
        : theme.colour.status.warning,
      show: isCareHome,
    }),
    hourlyRate: (rate) => ({
      label: rate && <Currency value={rate} />,
      description: 'Starting hourly rate',
      icon: icons.poundSign,
      show: !!rate && isHomeCare,
    }),
    managerName: (name) => ({
      label: name,
      description: 'Agency manager',
      icon: icons.person,
      show: !!name && isHomeCare,
    }),
    minimumVisitTime: (label) => ({
      label,
      description: 'Minimum visit time',
      icon: icons.clock,
      show: !!label && isHomeCare,
    }),
  }[key](value);

  return (
    summaryMap.show && (
      <SummaryItemContainer>
        <Spacer doubleTop>
          <IconLabel
            icon={summaryMap.icon}
            label={summaryMap.label}
            description={summaryMap.description}
            colour={summaryMap.colour && summaryMap.colour}
          />
        </Spacer>
      </SummaryItemContainer>
    )
  );
}

SummaryItem.propTypes = {
  summaryItem: PropTypes.arrayOf(PropTypes.any).isRequired,
  careType: careTypeType.isRequired,
};

export default function Summary({ summaryItems, careType }) {
  return (
    <Row flexWrap>
      {Object.entries(summaryItems).map((item) => (
        <SummaryItem key={item} summaryItem={item} careType={careType} />
      ))}
    </Row>
  );
}

Summary.propTypes = {
  summaryItems: PropTypes.shape({
    rating: PropTypes.string,
    pricePerWeek: PropTypes.number,
    bedCount: PropTypes.number,
    dateOpened: PropTypes.string,
    availability: PropTypes.bool,
    hourlyRate: PropTypes.number,
    managerName: PropTypes.string,
    minimumVisitTime: PropTypes.string,
  }).isRequired,
  careType: careTypeType.isRequired,
};
