import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useMemo, useRef } from 'react';
import styled from 'styled-components';
// radix
import * as RadixTooltipOnboarding from '@radix-ui/react-tooltip';
/**
 * TooltipOnboarding is a component that displays a tooltip on hover.
 */
export const TooltipOnboarding = ({ children, index, title, content, current, total, setCurrent, onComplete, }) => {
    var _a;
    // refs
    const radixContent = useRef(null);
    // memos
    const isOpen = useMemo(() => current === index, [current]);
    const cutOutRect = useMemo(() => {
        if (!radixContent.current) {
            return {
                top: 0,
                left: 0,
                width: 0,
                height: 0,
            };
        }
        const rect = radixContent.current.getBoundingClientRect();
        const cutOutRect = {
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height,
        };
        return cutOutRect;
    }, [radixContent.current, (_a = radixContent.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()]);
    return (_jsxs(_Fragment, { children: [_jsx(RadixTooltipOnboarding.Provider, { children: _jsxs(RadixTooltipOnboarding.Root, Object.assign({ delayDuration: 0, open: isOpen }, { children: [_jsx(RadixTooltipOnboarding.Trigger, Object.assign({ asChild: true }, { children: _jsx(TooltipOnboardingButton, Object.assign({ ref: radixContent }, { children: children })) })), _jsx(RadixTooltipOnboarding.Portal, { children: _jsxs(RadixTooltipOnboardingContent, Object.assign({ sideOffset: 20 }, { children: [_jsx(Title, { children: title }), _jsx(Content, { children: content }), _jsx(Separator, {}), _jsxs(Actions, { children: [_jsx(Button, Object.assign({ onClick: () => current > 0 && setCurrent((prev) => prev - 1), disabled: current === 0 }, { children: "Back" })), _jsxs(Progress, { children: [current + 1, " of ", total] }), _jsx(Button, Object.assign({ onClick: () => setCurrent((prev) => {
                                                    if (prev !== total - 1)
                                                        return prev + 1;
                                                    onComplete === null || onComplete === void 0 ? void 0 : onComplete();
                                                    return -1;
                                                }) }, { children: current === total - 1 ? 'Finish' : 'Next' }))] }), _jsx(RadixTooltipOnboardingArrow, { width: 16, height: 13 })] })) })] })) }), isOpen && _jsx(Background, { padding: 16, cutOutRect: cutOutRect })] }));
};
const Background = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    border-left: ${(props) => props.cutOutRect.left - props.padding}px solid
      rgba(0, 0, 0, 0.2);
    border-top: ${(props) => props.cutOutRect.top - props.padding}px solid
      rgba(0, 0, 0, 0.2);
    border-right: 100vw solid rgba(0, 0, 0, 0.2);
    border-bottom: 100% solid rgba(0, 0, 0, 0.2);
    width: ${(props) => props.cutOutRect.width + props.padding * 2}px;
    height: ${(props) => props.cutOutRect.height + props.padding * 2}px;
  }
`;
const TooltipOnboardingButton = styled.div `
  all: unset;
`;
const RadixTooltipOnboardingContent = styled(RadixTooltipOnboarding.Content) `
  width: 400px;
  max-width: calc(100vw - ${(props) => props.theme.spacing[16]});
  margin-left: ${(props) => props.theme.spacing[8]};
  border-radius: 8px;
  background: ${(props) => props.theme.color.accent.violetLight};
  padding: ${(props) => props.theme.spacing[32]}
    ${(props) => props.theme.spacing[12]};
  color: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[3]};
  font: ${(props) => props.theme.typography.body.sm.regular};
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='delayed-open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }
  &[data-state='delayed-open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  &[data-state='delayed-open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  &[data-state='delayed-open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.body.lg.semibold};
  text-align: center;
`;
const Content = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  text-align: center;
`;
const Separator = styled.div `
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
  margin-top: ${(props) => props.theme.spacing[8]};
  margin-bottom: ${(props) => props.theme.spacing[16]};
`;
const Actions = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Progress = styled.div `
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font: ${(props) => props.theme.typography.body.xs.regular};
  color: ${(props) => props.theme.color.neutral[10]};
`;
const Button = styled.button `
  all: unset;
  border-radius: 3px;
  background: ${(props) => props.theme.color.primary[80]};
  border: 1px solid ${(props) => props.theme.color.neutral[10]};
  color: ${(props) => props.theme.color.neutral[10]};
  padding: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[20]};

  cursor: pointer;
  transition-duration: 200ms;
  &:hover {
    background: ${(props) => props.theme.color.primary[70]};
  }

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
`;
const RadixTooltipOnboardingArrow = styled(RadixTooltipOnboarding.Arrow) `
  fill: ${(props) => props.theme.color.accent.violetLight};
`;
TooltipOnboarding.displayName = 'TooltipOnboarding';
export default TooltipOnboarding;
