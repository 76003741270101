// components
import { ActionPlanAll as ActionPlanAllPage } from '@karehero/llama';

// hooks
import { useActionPlan } from 'hooks/useActionPlan';

/**
 * ActionPlanAll shows all action plan items.
 */
const ActionPlanAll = () => {
  // hooks
  const { allActionPlanItems } = useActionPlan();

  return <ActionPlanAllPage actionPlanItems={allActionPlanItems || []} />;
};

export default ActionPlanAll;
