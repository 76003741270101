import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// assets
// @transform-path ../../../../../../src/assets/csat/1.svg
import csat1 from "../../../../../../src/assets/csat/1.svg";
// @transform-path ../../../../../../src/assets/csat/2.svg
import csat2 from "../../../../../../src/assets/csat/2.svg";
// @transform-path ../../../../../../src/assets/csat/3.svg
import csat3 from "../../../../../../src/assets/csat/3.svg";
// @transform-path ../../../../../../src/assets/csat/4.svg
import csat4 from "../../../../../../src/assets/csat/4.svg";
// @transform-path ../../../../../../src/assets/csat/5.svg
import csat5 from "../../../../../../src/assets/csat/5.svg";
/**
 * CSAT component displays a 5-star rating system.
 */
export const CSAT = ({ className, label = 'How helpful was this?', onPress, }) => {
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [label && _jsx(LabelContainer, { children: label }), _jsxs(FeedbackOptionGroup, { children: [_jsx(FeedbackOption, Object.assign({ onClick: () => onPress === null || onPress === void 0 ? void 0 : onPress(1) }, { children: _jsx("img", { src: csat1, alt: '1 out of 5' }) })), _jsx(FeedbackOption, Object.assign({ onClick: () => onPress === null || onPress === void 0 ? void 0 : onPress(2) }, { children: _jsx("img", { src: csat2, alt: '2 out of 5' }) })), _jsx(FeedbackOption, Object.assign({ onClick: () => onPress === null || onPress === void 0 ? void 0 : onPress(3) }, { children: _jsx("img", { src: csat3, alt: '3 out of 5' }) })), _jsx(FeedbackOption, Object.assign({ onClick: () => onPress === null || onPress === void 0 ? void 0 : onPress(4) }, { children: _jsx("img", { src: csat4, alt: '4 out of 5' }) })), _jsx(FeedbackOption, Object.assign({ onClick: () => onPress === null || onPress === void 0 ? void 0 : onPress(5) }, { children: _jsx("img", { src: csat5, alt: '5 out of 5' }) }))] })] })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[12]};
`;
const LabelContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const FeedbackOptionGroup = styled.div `
  display: flex;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[8]};
  width: 100%;
`;
const size = 40;
const FeedbackOption = styled.div `
  cursor: pointer;
  width: ${size}px;
  height: ${size}px;
  min-width: ${size}px;
  min-height: ${size}px;
  max-width: ${size}px;
  max-height: ${size}px;

  transition-duration: 200ms;
  &:hover {
    transform: scale(1.2);
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
CSAT.displayName = 'CSAT';
export default CSAT;
