// react
import { createSlice } from '@reduxjs/toolkit';

export interface NavigationState {
  isDrawerCollapsed: boolean;
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    isDrawerCollapsed: false,
  } as NavigationState,
  reducers: {
    toggleDrawer: (state) => {
      state.isDrawerCollapsed = !state.isDrawerCollapsed;
    },
  },
});

export const { toggleDrawer } = navigationSlice.actions;
export const isDrawerCollapsed = (state: any) =>
  state.navigation.isDrawerCollapsed;

export default navigationSlice.reducer;
