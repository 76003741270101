// react
import { useCallback, useEffect, useMemo, useState } from 'react';

// hooks
import {
  useCreateChatMutation,
  useGetChatQuery,
  useSendChatMessageMutation,
} from 'store/api/chat';

// models
import { ChatMessage } from '@karehero/models';

export const useChat = () => {
  // state
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [sentMessages, setSentMessages] = useState<ChatMessage[]>([]);

  // hooks
  const { data: chat, error: chatError } = useGetChatQuery();
  const [createChat] = useCreateChatMutation();
  const [sendChatMessage] = useSendChatMessageMutation();

  // methods
  const sendMessage = useCallback(
    async (message: string) => {
      if (!message) return;

      setIsChatLoading(true);
      const randomID = Math.random().toString(36);
      setSentMessages((prev) => [
        ...prev,
        {
          id: randomID,
          role: 'user',
          content: message,
        },
      ]);

      if (!chat?.id) return;
      await sendChatMessage({ chatID: chat.id, content: message });

      setSentMessages((prev) => prev.filter((m) => m.id !== randomID));
      setIsChatLoading(false);
    },
    [chat, sendChatMessage],
  );

  // memos
  const messages = useMemo(() => {
    return [...(chat?.messages || []), ...sentMessages];
  }, [chat, sentMessages]);

  // effects
  useEffect(() => {
    if (chatError && 'status' in chatError && chatError.status === 404) {
      createChat();
    }
  }, [createChat, chatError]);

  return {
    chat,
    messages,
    isChatLoading,
    sendMessage,
    createChat,
  };
};
