import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Button, Container, Icon, Title } from "../../atoms";
// assets
// @transform-path ../../../../../../src/assets/onboarding/conciergeTeam.jpg
import conciergeTeam from "../../../../../../src/assets/onboarding/conciergeTeam.jpg";
import { OnboardingActionType, toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
export const OnboardingActivatedStart = toGenericOnboarding(({ config, currentIndex = 0, handleClick, }) => {
    var _a, _b, _c, _d, _e, _f;
    return (_jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(Title, Object.assign({ isCenter: true }, { children: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.title })), _jsxs(ContentContainer, { children: [_jsxs(Body, { children: [_jsx(ImageWrapper, { children: _jsx(Icon, { fontSize: 32, icon: 'hand-holding-heart' }) }), _jsx(BodyWrapper, { children: _jsx(BodyTextWrapper, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.text.map((text) => (_jsx(Text, { children: text }, text))) }) })] }), _jsxs(Actions, { children: [_jsx(Button, Object.assign({ size: 'lg', ariaLabel: (_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.ctaButtonLabel, onPress: () => handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.Next), isFullWidth: true }, { children: (_d = config[currentIndex]) === null || _d === void 0 ? void 0 : _d.ctaButtonLabel })), ((_e = config[currentIndex]) === null || _e === void 0 ? void 0 : _e.onSkip) && (_jsx(Button, Object.assign({ size: 'lg', ariaLabel: 'Skip For Now', variant: 'link', onPress: (_f = config[currentIndex]) === null || _f === void 0 ? void 0 : _f.onSkip }, { children: "Skip For Now" })))] })] })] }) })));
});
const StyledContainer = styled(Container) `
  background: ${(props) => props.theme.color.cards.yellow.secondary};
  height: unset;
  flex: 1;
`;
const BodyTextWrapper = styled.div `
  display: grid;
  gap: ${(props) => props.theme.spacing[16]};
`;
const BodyWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[32]};
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[24]};
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${(props) => props.theme.spacing[24]}
    ${(props) => props.theme.spacing[12]};
  padding-bottom: ${(props) => props.theme.spacing[48]};
  gap: ${(props) => props.theme.spacing[20]};
`;
const ImageWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  background: ${(props) => props.theme.color.cards.green.tertiary};
  border-radius: 99999px;
  color: ${(props) => props.theme.color.cards.yellow.secondary};
`;
const ContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  flex: 1;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[100]};
  text-align: center;
  @media (min-width: 546px) {
    font: ${(props) => props.theme.typography.body.md.regular};
  }
`;
const Actions = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;
`;
OnboardingActivatedStart.displayName = 'OnboardingActivatedStart';
export default toGenericOnboarding(OnboardingActivatedStart);
