import { styled } from '../styling';

const BackgroundImage = styled.div`
  background-image: url('${({ source }) => source}');
  background-position: ${({ position }) => position || 'initial'};
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
`;

export default BackgroundImage;
