import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, media, breakpoints } from '../../styling';

const mobileSidebarPositions = {
  above: {
    sidebar: {
      row: 1,
      column: 1,
    },
    body: {
      row: 2,
      column: 1,
    },
  },
  below: {
    sidebar: {
      row: 2,
      column: 1,
    },
    body: {
      row: 1,
      column: 1,
    },
  },
  notrendered: {
    sidebar: {
      row: 0, // these values don't matter
      column: 0, // as sidebar is not rendered
    },
    body: {
      row: 1,
      column: 1,
    },
  },
};

const makeLayouts = (sidebarPercent) => ({
  left: {
    desktop: {
      sidebar: {
        row: 1,
        column: 2,
      },
      body: {
        row: 1,
        column: 1,
      },
      columns: `100fr ${sidebarPercent}%`,
      rows: '100fr',
    },
    mobile: {
      ...mobileSidebarPositions,
      columns: '100fr',
      rows: '100fr 100%',
    },
  },
  right: {
    desktop: {
      sidebar: {
        row: 1,
        column: 1,
      },
      body: {
        row: 1,
        column: 2,
      },
      columns: `${sidebarPercent}% 100fr`,
      rows: '100fr',
    },
    mobile: {
      ...mobileSidebarPositions,
      columns: '100fr',
      rows: '100fr',
    },
  },
});

const Container = styled.div`
  padding: ${({ theme }) => `${theme.spacing.units(6)}`};

  ${media('<tablet')} {
    padding: ${({ theme }) => `${theme.spacing.units(1)}`};
  }
  ${({ noPaddingTopBottom }) =>
    noPaddingTopBottom &&
    `
    padding-top: 0;
    padding-bottom: 0;
  `}

  ${({ noPaddingLeftRight }) =>
    noPaddingLeftRight &&
    `
    padding-left: 0;
    padding-right: 0;
  `}
`;

const Grid = styled.div`
  display: -ms-grid;
  display: grid;
  grid-gap: ${({ theme, noGridGap }) =>
    noGridGap ? 0 : theme.spacing.units(4)};

  ${media('>tablet')} {
    -ms-grid-columns: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.columns};
    -ms-grid-rows: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.rows};
    grid-template-columns: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.columns};
    grid-template-rows: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.rows};
  }

  ${media('<=tablet')} {
    -ms-grid-columns: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].mobile.columns};
    -ms-grid-rows: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].mobile.rows};
    grid-template-columns: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].mobile.columns};
    grid-template-rows: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].mobile.rows};
  }
`;

const Sidebar = styled.div`
  ${media('>tablet')} {
    -ms-grid-column: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.sidebar.column};
    -ms-grid-row: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.sidebar.row};
    grid-column: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.sidebar.column};
    grid-row: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.sidebar.row};
  }
  ${media('<=tablet')} {
    -ms-grid-column: ${({ bodyPosition, sidebarPositionMobile, layouts }) =>
      layouts[bodyPosition].mobile[sidebarPositionMobile].sidebar.column};
    -ms-grid-row: ${({ bodyPosition, sidebarPositionMobile, layouts }) =>
      layouts[bodyPosition].mobile[sidebarPositionMobile].sidebar.row};
    grid-column: ${({ bodyPosition, sidebarPositionMobile, layouts }) =>
      layouts[bodyPosition].mobile[sidebarPositionMobile].sidebar.column};
    grid-row: ${({ bodyPosition, sidebarPositionMobile, layouts }) =>
      layouts[bodyPosition].mobile[sidebarPositionMobile].sidebar.row};
  }
  overflow: hidden;
`;

const Body = styled.div`
  ${media('>tablet')} {
    -ms-grid-column: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.body.column};
    -ms-grid-row: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.body.row};
    grid-column: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.body.column};
    grid-row: ${({ bodyPosition, layouts }) =>
      layouts[bodyPosition].desktop.body.row};
  }
  ${media('<=tablet')} {
    -ms-grid-column: ${({ bodyPosition, sidebarPositionMobile, layouts }) =>
      layouts[bodyPosition].mobile[sidebarPositionMobile].body.column};
    -ms-grid-row: ${({ bodyPosition, sidebarPositionMobile, layouts }) =>
      layouts[bodyPosition].mobile[sidebarPositionMobile].body.row};
    grid-column: ${({ bodyPosition, sidebarPositionMobile, layouts }) =>
      layouts[bodyPosition].mobile[sidebarPositionMobile].body.column};
    grid-row: ${({ bodyPosition, sidebarPositionMobile, layouts }) =>
      layouts[bodyPosition].mobile[sidebarPositionMobile].body.row};
  }
  overflow: hidden;
`;

export default function TwoColumn({
  sidebar,
  body,
  bodyPosition,
  sidebarPositionMobile,
  sidebarPercent,
  noPaddingTopBottom,
  noPaddingLeftRight,
}) {
  const layouts = makeLayouts(sidebarPercent);

  const [currentWindowWidth, setCurrentWindowWidth] = useState(
    window.innerWidth,
  );

  const renderSidebar =
    sidebarPositionMobile !== 'notrendered' ||
    currentWindowWidth >= breakpoints.tablet;

  window.onresize = () => setCurrentWindowWidth(window.innerWidth);

  return (
    <Container
      noPaddingTopBottom={noPaddingTopBottom}
      noPaddingLeftRight={noPaddingLeftRight}
    >
      <Grid
        layouts={layouts}
        bodyPosition={bodyPosition}
        sidebarPositionMobile={sidebarPositionMobile}
        noGridGap={sidebarPercent === 0 || sidebarPercent === 100}
      >
        <Sidebar
          layouts={layouts}
          bodyPosition={bodyPosition}
          sidebarPositionMobile={sidebarPositionMobile}
        >
          {renderSidebar && sidebar}
        </Sidebar>
        <Body
          layouts={layouts}
          bodyPosition={bodyPosition}
          sidebarPositionMobile={sidebarPositionMobile}
        >
          {body}
        </Body>
      </Grid>
    </Container>
  );
}

TwoColumn.propTypes = {
  sidebar: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  bodyPosition: PropTypes.oneOf(['left', 'right']),
  sidebarPositionMobile: PropTypes.oneOf(['above', 'below', 'notrendered']),
  sidebarPercent: PropTypes.number,
  noPaddingTopBottom: PropTypes.bool,
  noPaddingLeftRight: PropTypes.bool,
};

TwoColumn.defaultProps = {
  bodyPosition: 'right',
  sidebarPositionMobile: 'above',
  sidebarPercent: 30,
  noPaddingTopBottom: false,
  noPaddingLeftRight: false,
};
