import PropTypes from 'prop-types';

import IconLabel from '../../../primitives/IconLabel';
import { icons } from '../../../primitives/Icon';
import { useThemeHook, styled, media } from '../../../styling';
import Row from '../../../primitives/Row';
import Spacer from '../../../primitives/Spacer';

const CareServiceTypeContainer = styled.div`
  width: 50%;
  ${media('<tablet')} {
    width: 100%;
  }
`;

function CareServiceType({ label }) {
  const { colour } = useThemeHook();

  const serviceTypes = {
    Palliative: {
      icon: icons.handHoldingHeart,
      description:
        'Care designed to provide quality support and comfort for people living with life-limiting conditions.',
    },
    Residential: {
      icon: icons.couch,
      description:
        'Accommodation, meals, and assistance with personal care and medication.',
    },
    Dementia: {
      icon: icons.handHelping,
      description:
        'Specialist care designed to meet the challenges faced by people living with dementia.',
    },
    Nursing: {
      icon: icons.nurse,
      description:
        'Medical care from a qualified nurse, and treatment for residents with illness or injuries.',
    },
  };

  const type = serviceTypes[label];

  if (!type) {
    return <></>;
  }

  return (
    <CareServiceTypeContainer>
      <Spacer doubleTop>
        <IconLabel
          icon={type.icon}
          label={label}
          description={type.description}
          colour={colour.primary}
        />
      </Spacer>
    </CareServiceTypeContainer>
  );
}

CareServiceType.propTypes = {
  label: PropTypes.node.isRequired,
};

export default function CareServiceTypes({ serviceTypes }) {
  return (
    <Row flexWrap>
      {serviceTypes.map((serviceType) => (
        <CareServiceType key={serviceType} label={serviceType} />
      ))}
    </Row>
  );
}

CareServiceTypes.propTypes = {
  serviceTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
