// store
import {
  useBookmarkArticleMutation,
  useGetAllArticleBookmarkQuery,
} from 'store/api/cms';
import { Article } from '@karehero/models';

// components
import { ArticleBookmarks as ArticleBookmarksPage } from '@karehero/llama';

/**
 * ArticleBookmarks shows the content library.
 */
const ArticleBookmarks = () => {
  // hooks
  const { data: articleBookmarks } = useGetAllArticleBookmarkQuery();
  const [bookmarkArticle] = useBookmarkArticleMutation();

  return (
    <ArticleBookmarksPage
      articles={articleBookmarks}
      onBookmark={(article: Partial<Article>) =>
        bookmarkArticle(article._id || '')
      }
      isLoading={false}
      toNavigateBack='/library'
    />
  );
};

export default ArticleBookmarks;
