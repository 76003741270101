import { useContext } from 'react';

import useEventTracking from '../../hooks/useEventTracking';
import useOnPageEvent from '../../hooks/useOnPageEvent';
import { ActiveProviderContext } from '../../context/ActiveProviderContext';
import { GlobalSettingsContext } from '../../context/GlobalSettingsContext';
import { SearchResultsContext } from '../../context/SearchResultsContext';
import { BrowserLocationContext } from '../../context/BrowserLocationContext';

import Profile from '../../components/features/ProviderProfile/Profile';
import ProfileError from './ProfileError';

export default function ProviderProfile() {
  const { activeProvider } = useContext(ActiveProviderContext);
  const { googleMapsApiKey } = useContext(GlobalSettingsContext);
  const { buildSearchPath } = useContext(BrowserLocationContext);
  const { lastSearchTerm, lastSearchRegulator } =
    useContext(SearchResultsContext);
  const { fireTrackingEvent } = useEventTracking();
  const { onEmailReveal, onPhoneReveal } = useOnPageEvent();

  return activeProvider ? (
    <Profile
      provider={activeProvider}
      googleMapsApiKey={googleMapsApiKey}
      lastSearchTerm={lastSearchTerm}
      buildSearchPath={buildSearchPath}
      lastSearchRegulator={lastSearchRegulator}
      fireTrackingEvent={fireTrackingEvent}
      onEmailReveal={onEmailReveal}
      onPhoneReveal={onPhoneReveal}
    />
  ) : (
    <ProfileError />
  );
}
