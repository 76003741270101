// store
import { useSelector } from 'store';

// components
import { WelcomeActions as WelcomeActionsPage } from '@karehero/llama';

/**
 * WelcomeActions is the dashboard page.
 */
const WelcomeActions = () => {
  // hooks
  const account = useSelector((state) => state.iam.account);

  // memos
  const isAdmin = account?.accountRole.name === 'KareHero Administrator';

  return (
    <WelcomeActionsPage
      firstName={account?.firstName || 'User'}
      toManageCareCircles={'/admin/care-circle/manage'}
      toManageAccounts={isAdmin ? '/admin/account/manage' : undefined}
      toManageOrganizations={isAdmin ? '/admin/organization/manage' : undefined}
      toManageActionPlan={isAdmin ? '/admin/action-plan' : undefined}
      toManageInvitedMembers={
        isAdmin ? '/admin/invited-member/manage' : undefined
      }
    />
  );
};

export default WelcomeActions;
