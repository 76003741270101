/*
 * An implementation of The Haversine Formula
 * https://en.wikipedia.org/wiki/Haversine_formula
 */

const toRad = (num) => (num * Math.PI) / 180;

const useDistanceCalculation = () => (start) => (end) => {
  const globeRadiusMiles = 3960;

  const differenceLat = toRad(end.lat - start.lat);
  const differenceLng = toRad(end.lng - start.lng);

  const lat1 = toRad(start.lat);
  const lat2 = toRad(end.lat);

  const a =
    Math.sin(differenceLat / 2) * Math.sin(differenceLat / 2) +
    Math.sin(differenceLng / 2) *
      Math.sin(differenceLng / 2) *
      Math.cos(lat1) *
      Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return globeRadiusMiles * c;
};

export default useDistanceCalculation;
