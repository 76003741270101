// react
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

// components
import { Button, Container, Settings as SettingsPage } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';
import { useLazyVersionQuery } from 'store/api/iam';

/**
 * Settings component for the Settings page.
 */
const Settings = () => {
  // state
  const [apiVersion, setApiVersion] = useState<string>('unknown');

  // hooks
  const account = useSelector(getAccount);
  const [getApiVersion] = useLazyVersionQuery();

  // memos
  const homePath = useMemo(() => {
    if (
      account.accountRole.name !== 'KareHero Administrator' &&
      account.accountRole.name !== 'Care Concierge'
    ) {
      return `/`;
    }
    return '/admin';
  }, [account]);

  // effects
  useEffect(() => {
    getApiVersion().then((res) => {
      if ('error' in res) {
        console.error(res.error);
        return;
      }
      setApiVersion(res.data?.message || 'unknown');
    });
  }, [getApiVersion]);

  return (
    <Container size='lg'>
      <Wrapper>
        <Button
          ariaLabel='back'
          iconLeft={'chevron-left'}
          variant='secondary'
          to={homePath}
        >
          Back
        </Button>
        <SettingsPage
          account={account}
          appVersion={process.env.REACT_APP_BUILD_VERSION || 'local'}
          apiVersion={apiVersion}
        />
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[32]};
  height: 100%;
  padding: ${(props) => props.theme.spacing[24]}
    ${(props) => props.theme.spacing[12]};
`;

export default Settings;
