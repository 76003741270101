var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// react-aria
import { useLabel } from 'react-aria';
// components
import Tooltip from "../Tooltip/Tooltip";
import Label from "../Label/Label";
import SupportingText from "../SupportingText/SupportingText";
import { Icon } from "../Icon/Icon";
/**
 * BaseField is a wrapper for a form field.
 */
export const BaseField = (_a) => {
    var { id = 'form-field', ariaLabel, label, tooltip, description, isRequired, isHideTitle, children, className } = _a, props = __rest(_a, ["id", "ariaLabel", "label", "tooltip", "description", "isRequired", "isHideTitle", "children", "className"]);
    // hooks/
    const { labelProps } = useLabel(Object.assign(Object.assign({}, props), { id, 'aria-label': ariaLabel || label }));
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsxs(Header, { children: [label && !isHideTitle && (_jsx(StyledLabel, Object.assign({ id: id, text: label }, labelProps, { isRequired: isRequired, "aria-labelledby": id }))), tooltip && !isHideTitle && (_jsx(Tooltip, Object.assign({ content: tooltip }, { children: _jsx(TooltipIcon, { children: _jsx(Icon, { icon: 'question-circle', fontSize: 16 }) }) })))] }), children, description && _jsx(StyledSupportingText, { text: description })] })));
};
const Wrapper = styled.div `
  width: 100%;
`;
const Header = styled.div `
  display: flex;
  justify-content: space-between;
`;
const StyledLabel = styled(Label) `
  margin-bottom: ${(props) => props.theme.spacing[4]};
`;
const StyledSupportingText = styled(SupportingText) `
  margin-top: ${(props) => props.theme.spacing[4]};
`;
const TooltipIcon = styled.div `
  color: ${(props) => props.theme.color.neutral[70]};
`;
BaseField.componentName = 'BaseField';
export default BaseField;
