import { useContext } from 'react';

import Radio from '../../components/primitives/form/Radio';
import Row from '../../components/primitives/Row';
import { CareTypeContext } from '../../context/CareTypeContext';

export default function CareTypePicker() {
  const { careType, setCareHome, setHomeCare } = useContext(CareTypeContext);

  return (
    <Row>
      <Radio
        name='careType'
        value='careHome'
        label='Care Home'
        checked={careType === 'careHome'}
        onChange={setCareHome}
      />
      <Radio
        name='careType'
        value='homeCare'
        label='Care-at-home'
        checked={careType === 'homeCare'}
        onChange={setHomeCare}
      />
    </Row>
  );
}
