import PropTypes from 'prop-types';

import { styled } from '../styling';
import { Icon, icons } from './Icon';
import Row from './Row';
import Spacer from './Spacer';

const Btn = styled.button`
  height: 100%;
  min-height: ${({ theme }) => theme.spacing.units(8)};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.units(2)};
  background: ${({ theme, disabled }) =>
    disabled ? theme.colour.status.disabled : theme.color.primary[30]};
  color: ${({ theme }) => theme.color.neutral[20]};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ fullWidth }) => fullWidth && 'width: 100%;'};
  font: ${({ theme }) => theme.typography.body.sm.medium};
  padding-left: ${({ theme }) => theme.spacing[12]};
  padding-right: ${({ theme }) => theme.spacing[12]};

  ${({ round, theme }) =>
    round &&
    `
    border-radius: 50%;
    width: ${theme.spacing.units(10)};
    height: ${theme.spacing.units(10)};
  `};

  &:focus,
  &:hover {
    background: ${({ theme }) => theme.color.primary[60]};
  }
`;

export default function Button({
  onClick,
  disabled,
  children,
  fullWidth,
  round,
  noChevron,
  className,
  title,
}) {
  return (
    <Btn
      disabled={disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      round={round}
      className={className}
      title={title}
    >
      <Row spaceBetween alignCentre>
        {children}
        {noChevron || (
          <Spacer left>
            <Icon icon={icons.chevronRight} />
          </Spacer>
        )}
      </Row>
    </Btn>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  round: PropTypes.bool,
  noChevron: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  fullWidth: false,
  round: false,
  noChevron: false,
  className: undefined,
  title: undefined,
};
