import { styled } from '../../styling';

const InlineText = styled.span`
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colour.neutral.dark};
  ${({ theme, primary }) => primary && `color: ${theme.colour.primary}`};
  ${({ theme, secondary }) => secondary && `color: ${theme.color.primary[90]}`};
  ${({ theme, light }) => light && `color: ${theme.colour.neutral.light}`};
  ${({ theme, disabled }) =>
    disabled && `color: ${theme.colour.status.disabled}`};
  font-family: ${({ theme }) => theme.typography.fontFamilyBody};
  text-transform: ${({ transform }) => transform || 'initial'};
  ${({ noWrap }) => noWrap && 'white-space: nowrap'};
`;

export default InlineText;
