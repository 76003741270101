import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// radix
import * as RadixAvatar from '@radix-ui/react-avatar';
// components
import { Icon } from "../Icon";
/**
 * Avatar shows an image of the user.
 */
export const Avatar = ({ className, size = 'sm', src, fallback, icon, onClick, }) => {
    // memos
    const iconSize = useMemo(() => {
        switch (size) {
            case 'xs':
                return 16;
            case 'sm':
                return 20;
            case 'md':
                return 24;
            case 'lg':
                return 32;
            case 'xl':
                return 48;
            default:
                return 24;
        }
    }, [size]);
    return (_jsx(AvatarRoot, Object.assign({ className: className, size: size, onClick: onClick }, { children: icon ? (_jsx(Icon, { icon: icon, fontSize: iconSize })) : (_jsxs(_Fragment, { children: [_jsx(AvatarImage, { src: src, alt: 'avatar' }), _jsx(AvatarFallback, Object.assign({ size: size, delayMs: 0 }, { children: fallback }))] })) })));
};
const AvatarRoot = styled(RadixAvatar.Root) `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  border-radius: 9999px;
  background: ${(props) => props.theme.color.surfaces.lightPlum};
  color: ${(props) => props.theme.color.accent.moccasin};
  ${(props) => {
    switch (props.size) {
        case 'xs':
            return css `
          min-width: 40px;
          min-height: 40px;
          width: 40px;
          height: 40px;
          max-width: 40px;
          max-height: 40px;
        `;
        case 'sm':
            return css `
          min-width: 48px;
          min-height: 48px;
          width: 48px;
          height: 48px;
          max-width: 48px;
          max-height: 48px;
        `;
        case 'md':
            return css `
          min-width: 64px;
          min-height: 64px;
          width: 64px;
          height: 64px;
          max-width: 64px;
          max-height: 64px;
        `;
        case 'lg':
            return css `
          min-width: 112px;
          min-height: 112px;
          width: 112px;
          height: 112px;
          max-width: 112px;
          max-height: 112px;
        `;
        case 'xl':
            return css `
          min-width: 192px;
          min-height: 192px;
          width: 192px;
          height: 192px;
          max-width: 192px;
          max-height: 192px;
        `;
    }
}}
`;
const AvatarImage = styled(RadixAvatar.Image) `
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;
const AvatarFallback = styled(RadixAvatar.Fallback) `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => {
    switch (props.size) {
        case 'xs':
            return css `
          font: ${props.theme.typography.body.md.regular};
        `;
        case 'sm':
            return css `
          font: ${props.theme.typography.body.lg.regular};
        `;
        case 'md':
            return css `
          font: ${props.theme.typography.body.xl.regular};
        `;
        case 'lg':
            return css `
          font: ${props.theme.typography.body.xxl.regular};
        `;
        case 'xl':
            return css `
          font: ${props.theme.typography.body.xxxl.regular};
        `;
    }
}}
`;
Avatar.displayName = 'Avatar';
export default Avatar;
