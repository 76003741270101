// components
import {
  Background,
  FloatingActions,
  Instruction,
  theme,
} from '@karehero/llama';

/**
 * CareConciergeReferralEmailConfirmation asks an organization owner to confirm
 * their email address.
 */
const CareConciergeReferralEmailConfirmation = () => {
  return (
    <Background color={theme.color.neutral[20]}>
      <Instruction
        title='Almost there!'
        subTitle="Please verify it's you by clicking the link in your email to register for your account."
        instruction='Complete your referral by heading over to your SJP email inbox!'
      />

      <FloatingActions
        actions={[
          {
            label: 'Done',
            to: '/sign-in',
            variant: 'secondary',
          },
        ]}
      />
    </Background>
  );
};

export default CareConciergeReferralEmailConfirmation;
