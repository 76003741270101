import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Button } from "../../atoms";
/**
 * BackButton navigates back in the browser history.
 */
export const BackButton = ({ className, size = 'md', variant = 'secondary', }) => {
    return (_jsx(Wrapper, Object.assign({ className: className }, { children: _jsx(Button, Object.assign({ ariaLabel: 'back', variant: variant, onPress: () => window.history.back(), iconLeft: 'chevron-left', size: size }, { children: "Back" })) })));
};
const Wrapper = styled.div `
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
BackButton.displayName = 'BackButton';
export default BackButton;
