import { styled, media } from '../../../styling';
import Row from '../../../primitives/Row';

import Card from '../../../primitives/Card';

export const Container = styled(Card)`
  display: grid;
  display: -ms-grid;
  -ms-grid-rows: ${({ theme: { spacing } }) =>
    `${spacing.units(40)} 1fr ${spacing.units(8)}`};
  grid-template-rows: ${({ theme: { spacing } }) =>
    `${spacing.units(40)} 1fr ${spacing.units(8)}`};
  grid-row-gap: ${({ theme }) => theme.spacing.units(2)};

  ${media('>tablet')} {
    width: ${({ theme }) => theme.spacing.units(80)};
    min-height: ${({ theme }) => theme.spacing.units(80)};
    height: 100%;
  }

  ${media('<=tablet')} {
    width: 100%;
  }
`;

export const Header = styled(Row)`
  -ms-grid-row: 1;
  grid-row: 1;
  position: relative;
`;

export const Body = styled(Row)`
  -ms-grid-row: 2;
  grid-row: 2;
`;

export const Footer = styled(Row)`
  -ms-grid-row: 3;
  grid-row: 3;
`;

export const CareServiceTypeContainer = styled(Row)`
  ${media('>=tablet')} {
    max-width: ${({ theme }) => theme.spacing.units(80)};
  }
`;
