import { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';

import { careTypeType } from '../../components/types';

import Column from '../../components/primitives/Column';
import Spacer from '../../components/primitives/Spacer';
import Card from '../../components/primitives/Card';
import Title from '../../components/primitives/typography/Title';

import SortOrderFilter from './SortOrderFilter';
import CareProvidedFilters from './CareProvidedFilters';
import DistanceFilters from './DistanceFilters';
import RegulatorsRatingFilters from './RegulatorsRatingFilters';
import HomeCareDurationFilters from './HomeCareDurationFilters';
import HomeCareRateFilters from './HomeCareRateFilters';

import { ProviderFilterContext } from '../../context/ProviderFilterContext';
import useProviderSearch from '../../hooks/useProviderSearch';

const makeFilterCards = (careType, error) => ({
  sorting: {
    title: 'Sort results by',
    component: SortOrderFilter,
    show: true,
  },
  distance: {
    title: 'Distance from location',
    component: DistanceFilters,
    show: true,
  },
  care: {
    title: 'Care provided',
    component: CareProvidedFilters,
    show: careType === 'careHome',
  },
  ratings: {
    title: 'Regulators rating',
    component: RegulatorsRatingFilters,
    show: !error,
  },
  rates: {
    title: 'Starting hourly rate',
    component: HomeCareRateFilters,
    show: careType === 'homeCare',
  },
  durations: {
    title: "I'm looking for visits of",
    component: HomeCareDurationFilters,
    show: careType === 'homeCare',
  },
});

export default function SearchControlsSidebar({
  currentCareType,
  searchError,
  searchOnFilterChange,
}) {
  const { search } = useProviderSearch();
  const { activeFilters } = useContext(ProviderFilterContext);
  const [lastActiveFilters, setLastLastActiveFilters] = useState(activeFilters);

  const checkFilterEquality = () => equals(lastActiveFilters, activeFilters);
  const filtersAreEqual = useMemo(checkFilterEquality, [checkFilterEquality]);

  useEffect(() => {
    if (!searchOnFilterChange) {
      return;
    }

    if (filtersAreEqual) {
      return;
    }

    setLastLastActiveFilters(activeFilters);
    search();
  }, [
    filtersAreEqual,
    activeFilters,
    setLastLastActiveFilters,
    search,
    searchOnFilterChange,
  ]);

  const filterCards = makeFilterCards(currentCareType, searchError);

  return (
    <Column>
      {Object.entries(filterCards).map(
        ([key, { show, title, component: Component }], i) =>
          show && (
            <Spacer top={i > 0} key={key}>
              <Card>
                <Title>{title}</Title>
                <Spacer top>
                  <Component disabled={searchError} />
                </Spacer>
              </Card>
            </Spacer>
          ),
      )}
      <Spacer bottom />
    </Column>
  );
}

SearchControlsSidebar.propTypes = {
  currentCareType: careTypeType.isRequired,
  searchError: PropTypes.bool.isRequired,
  searchOnFilterChange: PropTypes.bool,
};

SearchControlsSidebar.defaultProps = {
  searchOnFilterChange: false,
};
