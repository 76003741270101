import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { OfflineContent } from "../../molecules/OfflineContent/OfflineContent";
/**
 * OfflineOverlay is a page to block user when no internet connection.
 */
export const OfflineOverlay = ({}) => {
    return (_jsx(StyledOfflineOverlay, { children: _jsx(OfflineContent, {}) }));
};
const StyledOfflineOverlay = styled.div `
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 9999999999999;
  background-color: ${(props) => props.theme.color.neutral[10]};
`;
OfflineOverlay.displayName = 'OfflineOverlay';
export default OfflineOverlay;
