import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useEffect } from 'react';
import styled from 'styled-components';
/**
 * Outgrow is a component that displays a Outgrow quiz form.
 */
export const Outgrow = ({ calculatorUrl }) => {
    useEffect(() => {
        const outgrowScript = document.createElement('script');
        outgrowScript.src = '//dyv6f9ner1ir9.cloudfront.net/assets/js/nloader.js';
        outgrowScript.type = 'text/javascript';
        outgrowScript.async = true;
        outgrowScript.addEventListener('load', () => {
            const initScript = document.createElement('script');
            initScript.innerHTML = "initIframe('outgrowCalculator');";
            document.body.appendChild(initScript);
        });
        document.body.appendChild(outgrowScript);
    }, []);
    return (_jsx(StyledOutgrow, { id: 'outgrowCalculator', "data-title": 'Funding Calculator', "data-url": calculatorUrl, "data-width": '100%' }));
};
const StyledOutgrow = styled.div `
  max-height: fit-content;
`;
Outgrow.displayName = 'Outgrow';
export default Outgrow;
