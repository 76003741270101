// react
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

// components
import { AttendanceAllowanceSuccess as AttendanceAllowanceSuccessPage } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';
import { generatePdf } from 'pdf/attendanceAllowance';

/**
 * AttendanceAllowanceSuccess is the page displayed when the user has successfully submitted the Attendance Allowance form.
 */
const AttendanceAllowanceSuccess = () => {
  // hooks
  const navigate = useNavigate();

  const { careCircle } = useCareProfile({
    categoryID: 'attendance-allowance',
  });

  // methods
  const handlePrintPdf = useCallback(() => {
    if (careCircle?.careProfile?.id) {
      generatePdf(careCircle?.careProfile?.id);
    }
  }, [careCircle]);

  return (
    <AttendanceAllowanceSuccessPage
      toBack={() => navigate('/care-profile?tab=3')}
      handlePrintPdf={handlePrintPdf}
      name={careCircle?.careRecipientAccount?.firstName ?? 'Your Loved One'}
    />
  );
};

export default AttendanceAllowanceSuccess;
