const spacingUnit = 0.25;
export const spacing = {
    2: '2px',
    4: '4px',
    8: '8px',
    12: '12px',
    16: '16px',
    20: '20px',
    24: '24px',
    32: '32px',
    36: '36px',
    48: '48px',
    56: '56px',
    64: '64px',
    72: '72px',
    80: '80px',
    96: '96px',
    128: '128px',
    192: '192px',
    unit: spacingUnit,
    units: (multiple) => `${multiple * spacingUnit}rem`,
};
export default spacing;
