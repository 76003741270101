import { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';

import { ProviderFilterContext } from './ProviderFilterContext';

export const CareTypeContext = createContext();

export const CareTypeProvider = ({ children }) => {
  const { setCurrentSortOrder } = useContext(ProviderFilterContext);
  const [careType, setCareType] = useState('careHome');

  const setSortOrderToRelevance = () => setCurrentSortOrder('relevance');
  const changeCareType = compose(setSortOrderToRelevance, setCareType);

  const setCareHome = () => changeCareType('careHome');
  const setHomeCare = () => changeCareType('homeCare');

  return (
    <CareTypeContext.Provider value={{ careType, setCareHome, setHomeCare }}>
      {children}
    </CareTypeContext.Provider>
  );
};

CareTypeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
