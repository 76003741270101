import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// component
import { LoadingTransition, ServiceCard, Title } from "../../atoms";
import { CardContainer } from "../../templates";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import theme from "../../../theme";
const iconMap = {
    bell: 'gear',
    dollar: 'sterling-sign',
    users: 'users',
    helpCircle: 'circle-question',
    activity: 'heart-pulse',
    home: 'house',
    clipboard: 'clipboard',
};
/**
 * RenderServices is a helper component that renders the the services.
 */
const RenderServices = ({ services, isLoading, toBookCall, }) => {
    // hooks/
    const [width] = useWindowSize();
    // memos
    const maxColumns = useMemo(() => {
        if (width < theme.breakpoint.lgValue)
            return 1;
        if (width < theme.breakpoint.xlValue)
            return 2;
        return 3;
    }, [width]);
    const cardSize = useMemo(() => {
        return width < theme.breakpoint.mdValue ? 'sm' : 'md';
    }, [width]);
    if (!services || services.length === 0)
        return null;
    return (_jsx(_Fragment, { children: _jsx(CardContainer, Object.assign({ maxColumns: maxColumns, isPreventHeightMatch: true }, { children: services.map((service) => (_jsx(ServiceCard, { title: service.title, description1: service.description1, description2: service.description2, icon: iconMap[service.icon], isLoading: isLoading, size: cardSize, toBookCall: toBookCall, toLearnMore: service.slug.current }, service.title))) })) }));
};
/**
 * ServiceOverview is a page that displays all of the services that
 * are available to the user.
 */
export const ServiceOverview = ({ services, isLoading, toBookCall, }) => {
    // memos
    const dummyServices = useMemo(() => {
        let services = [];
        for (let i = 0; i < 6; i++) {
            services.push({
                _id: `service_${i}`,
                slug: {
                    current: `service_${i}`,
                    _type: 'slug',
                },
                title: `Service ${i}`,
                description1: `Description for Service ${i}`,
                description2: `Optional Description for Service ${i}`,
                icon: 'bell',
            });
        }
        return services;
    }, []);
    return (_jsxs(StyledServiceOverview, { children: [_jsxs(PageHeader, { children: [_jsx(Title, Object.assign({ isNoMargin: true }, { children: "Care Services" })), _jsx(Description, { children: "This is where you\u2019ll find the services that are available to you." })] }), _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Content, { children: _jsx(RenderServices, { services: dummyServices, isLoading: true, toBookCall: toBookCall }) }), content: _jsx(Content, { children: _jsx(RenderServices, { services: services, isLoading: false, toBookCall: toBookCall }) }) })] }));
};
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;
const PageHeader = styled.div ``;
const Content = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[32]};
  flex-wrap: wrap;
`;
const StyledServiceOverview = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
  padding-bottom: ${(props) => props.theme.spacing[48]};
`;
ServiceOverview.displayName = 'ServiceOverview';
export default ServiceOverview;
