import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// components
import { Title } from "../../atoms";
import { BackButton } from "../../molecules";
/**
 * CareCircleMemberInfo renders the account settings page.
 */
export const CareCircleMemberInfo = ({ careCircle, careCircleMember, relationships, }) => {
    var _a;
    // memos
    const careRecipientName = useMemo(() => {
        var _a, _b, _c;
        let name = `${((_a = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount) === null || _a === void 0 ? void 0 : _a.firstName) || ''}`;
        if ((_b = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount) === null || _b === void 0 ? void 0 : _b.lastName) {
            name += ` ${((_c = careCircle === null || careCircle === void 0 ? void 0 : careCircle.careRecipientAccount) === null || _c === void 0 ? void 0 : _c.lastName) || ''}`;
        }
        return name;
    }, [careCircle]);
    const memberName = useMemo(() => {
        var _a, _b, _c;
        let name = `${((_a = careCircleMember === null || careCircleMember === void 0 ? void 0 : careCircleMember.account) === null || _a === void 0 ? void 0 : _a.firstName) || ''}`;
        if ((_b = careCircleMember === null || careCircleMember === void 0 ? void 0 : careCircleMember.account) === null || _b === void 0 ? void 0 : _b.lastName) {
            name += ` ${((_c = careCircleMember === null || careCircleMember === void 0 ? void 0 : careCircleMember.account) === null || _c === void 0 ? void 0 : _c.lastName) || ''}`;
        }
        return name;
    }, [careCircleMember]);
    const careRecipientRelationship = useMemo(() => relationships === null || relationships === void 0 ? void 0 : relationships.find((r) => r.id === (careCircleMember === null || careCircleMember === void 0 ? void 0 : careCircleMember.careRecipientAccountRelationshipId)), [careCircleMember]);
    return (_jsxs(Wrapper, { children: [_jsx(BackButton, {}), _jsxs(Title, { children: [memberName || 'Care recipient', "'s Profile"] }), _jsxs(AccountWrapper, { children: [_jsxs(Field, { children: [_jsx(SubTitle, { children: "Name" }), _jsx(HelperText, { children: memberName })] }), _jsxs(Field, { children: [_jsx(SubTitle, { children: "Email address" }), _jsx(HelperText, { children: (_a = careCircleMember === null || careCircleMember === void 0 ? void 0 : careCircleMember.account) === null || _a === void 0 ? void 0 : _a.email })] }), _jsxs(Field, { children: [_jsxs(SubTitle, { children: ["Relationship to ", careRecipientName] }), _jsx(HelperText, { children: (careRecipientRelationship === null || careRecipientRelationship === void 0 ? void 0 : careRecipientRelationship.name) || 'No relationship' })] })] })] }));
};
// styles
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${(props) => props.theme.spacing[16]};
`;
const AccountWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[48]};
`;
const Field = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
  align-items: flex-start;
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const HelperText = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;
CareCircleMemberInfo.displayName = 'CareCircleMemberInfo';
export default CareCircleMemberInfo;
