import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Button } from "../../atoms";
import { ActionPlanCardGroup } from "../../molecules";
/**
 * ActionPlanAll shows the list of action plan items.
 */
export const ActionPlanAll = ({ actionPlanItems }) => {
    return (_jsxs(_Fragment, { children: [_jsx(Header, { children: _jsxs(HeaderTop, { children: [_jsx(Button, Object.assign({ ariaLabel: 'Back', variant: 'secondary', iconLeft: 'chevron-left', onPress: () => window.history.back() }, { children: "Back" })), _jsx(Title, { children: "All actions" })] }) }), _jsx(Body, { children: _jsx(ActionPlanCardGroup, { actionPlanItems: actionPlanItems }) })] }));
};
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[24]};
  background: ${(props) => props.theme.color.custom.fadeDown};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
const HeaderTop = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  align-items: flex-start;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.neutral[10]};
`;
const Body = styled.div `
  padding: ${(props) => props.theme.spacing[24]};
`;
ActionPlanAll.displayName = 'ActionPlanAll';
export default ActionPlanAll;
