import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import LoadingServiceContent from './LoadingServiceContent';
import { Button, CallToAction, LoadingTransition, Skeleton, } from "../../atoms";
import { SanityContent } from "../../organisms";
// utils
import { buildImageUrl } from "../../../utils/sanity";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import { theme } from "../../../theme";
/**
 * ServiceContent displays the content for a single article.
 */
export const ServiceContent = ({ content, isLoading = true, navigate, backPath, onAction, }) => {
    var _a, _b, _c, _d;
    // hooks
    const [width] = useWindowSize();
    // memo
    const isCtaMobile = useMemo(() => width < theme.breakpointValues.lgValue, [width]);
    const headerImageUrl = useMemo(() => buildImageUrl(content === null || content === void 0 ? void 0 : content.headerImageUrl, 800), [content]);
    // methods
    const onActionPress = () => {
        var _a;
        if ((_a = content === null || content === void 0 ? void 0 : content.callToActionCard) === null || _a === void 0 ? void 0 : _a.buttonLink) {
            navigate === null || navigate === void 0 ? void 0 : navigate(content.callToActionCard.buttonLink);
        }
        else {
            onAction === null || onAction === void 0 ? void 0 : onAction();
        }
    };
    return (_jsxs(StyledServiceContentPage, { children: [_jsx("div", { children: _jsx(Button, Object.assign({ ariaLabel: 'back', iconLeft: 'chevron-left', variant: 'secondary', onPress: () => window.history.back() }, { children: "Back" })) }), _jsxs(StyledServiceContent, { children: [_jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Skeleton, { shape: 'header-image', isRounded: false, isMargin: false }), content: _jsx(HeaderImage, { children: _jsx("img", { src: headerImageUrl, alt: content === null || content === void 0 ? void 0 : content.title, onError: ({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        'https://cdn.sanity.io/images/x7r1t5o8/production/e8579e2a4c3766cf0cf99a24a9d0679d2d2bbeb4-5322x2365.jpg?h=800';
                                } }) }) }), _jsxs(Wrapper, { children: [_jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(LoadingServiceContent, {}), content: _jsxs(ServiceContentWrapper, { children: [_jsx(Title, { children: content === null || content === void 0 ? void 0 : content.title }), _jsx(SanityContent, { content: content === null || content === void 0 ? void 0 : content.content })] }) }), _jsx(CallToAction, { isLoading: isLoading, title: ((_a = content === null || content === void 0 ? void 0 : content.callToActionCard) === null || _a === void 0 ? void 0 : _a.title) || 'Questions about care?', text: ((_b = content === null || content === void 0 ? void 0 : content.callToActionCard) === null || _b === void 0 ? void 0 : _b.subTitle) ||
                                    'Our friendly care experts will provide guidance and help you explore your funding and benefit options, book a call now!', action: ((_c = content === null || content === void 0 ? void 0 : content.callToActionCard) === null || _c === void 0 ? void 0 : _c.buttonText) || 'Book a call', onPress: onActionPress, isMobile: isCtaMobile, to: (_d = content === null || content === void 0 ? void 0 : content.callToActionCard) === null || _d === void 0 ? void 0 : _d.buttonLink })] })] })] }));
};
const StyledServiceContentPage = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[12]};
`;
const StyledServiceContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[48]};
`;
const Wrapper = styled.div `
  display: flex;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[128]};
`;
const ServiceContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[192]};
  ${(props) => props.theme.breakpoint.lg(css `
      margin-bottom: ${props.theme.spacing[32]};
    `)};
`;
const HeaderImage = styled.div `
  border-radius: 8px;
  overflow: hidden;
  height: 180px;
  ${(props) => props.theme.breakpoint.sm(css `
      height: 300px;
    `)}
  display: flex;
  align-items: center;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['7xl']};
  color: ${(props) => props.theme.color.primary[80]};
  margin-bottom: ${(props) => props.theme.spacing[32]};
`;
ServiceContent.displayName = 'ServiceContent';
export default ServiceContent;
