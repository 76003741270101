// react
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// components
import { Loading as LoadingPage } from '@karehero/llama';

// store
import { useCreateByInviteMutation } from 'store/api/iam';
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';

enum Status {
  Pending = 'pending',
  Fail = 'fail',
  Success = 'success',
}

/**
 * Authenticate is a component that renders the authentication page.
 */
const Register = () => {
  // state
  const [isRequested, setIsRequested] = useState(false);
  const [status, setStatus] = useState(Status.Pending);

  // hooks
  const account = useSelector(getAccount);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [createByInvite] = useCreateByInviteMutation();

  // effects
  useEffect(() => {
    const code = searchParams.get('code');
    if (!code) return navigate('/');
    if (isRequested) return;
    setIsRequested((prev) => {
      if (prev) return true;
      (async () => {
        const res = await createByInvite({ code });
        if ('data' in res) {
          setStatus(Status.Success);
        } else {
          setStatus(Status.Fail);
        }
      })();
      return true;
    });
  }, [searchParams, navigate, isRequested, createByInvite]);

  useEffect(() => {
    switch (status) {
      case Status.Success:
        if (!account) return;
        switch (account.accountRole.name) {
          case 'KareHero Administrator':
            return navigate('/admin');
          case 'Organization Owner':
            return navigate('/');
          case 'Member':
            return navigate('/');
          default:
            return navigate('/');
        }
      case Status.Fail:
        return navigate('/sign-in');
    }
  }, [status, account, navigate]);

  return <LoadingPage />;
};

export default Register;
