// components
import { OnboardingRadioConfigItem } from '@karehero/llama';

export const onboardingRadioConfig: OnboardingRadioConfigItem[] = [
  {
    id: 'keni3',
    title: 'What type of care does {careRecipient} need?',
    subTitle: 'This will help us find the right type of care provider',
    options: [
      {
        text: 'Care home or care-at-home',
        subText: 'Looked after in accommodation or at home',
        to: '/directory/at-home',
      },
      /* {
        text: 'Care home',
        subText: 'Looked after in accommodation',
        to: '/directory/care-home',
      },
      {
        text: 'Care-at-home',
        subText: 'Home carers will come to your own home',
        to: '/directory/at-home',
      }, */
      {
        text: 'Home help',
        subText: 'Help with tasks around the home',
        to: '/library/help-at-home',
      },
      {
        text: "I don't know",
        subText: "We'll help you assess which option is best",
        to: '/service/i-dont-know',
      },
    ],
    buttonText: 'Next',
  },
];
