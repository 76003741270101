import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Button, Dialog, Icon } from "../../atoms";
/**
 * AppUpdateDialog is a popup dialog.
 */
export const AppUpdateDialog = ({ isDialogVisible, onUpdate, setIsDialogVisible, }) => {
    return (_jsx(Dialog, Object.assign({ title: 'New update available', description: 'A newer version of the app is now available. Update now for the latest features and improvements!', isOpen: isDialogVisible }, { children: _jsxs(Wrapper, { children: [_jsx(Button, Object.assign({ ariaLabel: 'Not now', type: 'button', variant: 'secondary', onPress: () => {
                        setIsDialogVisible(false);
                    } }, { children: "Not now" })), _jsxs(Button, Object.assign({ ariaLabel: 'refresh', type: 'submit', onPress: onUpdate }, { children: [_jsx(SpacedIcon, { icon: 'arrows-rotate' }), "Update"] }))] }) })));
};
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
const SpacedIcon = styled(Icon) `
  margin-right: 10px;
`;
AppUpdateDialog.displayName = 'AppUpdateDialog';
export default AppUpdateDialog;
