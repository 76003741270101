// react
import { useNavigate } from 'react-router-dom';

// store
import { useGetAllWebinarQuery } from 'store/api/cms';
import { Webinar } from '@karehero/models';

// components
import { WebinarOverview as WebinarOverviewPage } from '@karehero/llama';

/**
 * WebinarOverview shows the webinars cards.
 */
const WebinarOverview = () => {
  // hooks
  const { data: webinars } = useGetAllWebinarQuery();
  const navigate = useNavigate();

  return (
    <WebinarOverviewPage
      webinars={webinars}
      onClick={(webinar: Partial<Webinar>) => {
        navigate(`/webinar/${webinar._id}`);
      }}
      isLoading={!webinars}
    />
  );
};

export default WebinarOverview;
