// buildImageUrl is a helper function to build the sanity image url from the
// imageUrl.
export const buildImageUrl = (imageUrl, height) => {
    return `${imageUrl}?${height ? `h=${height}` : ``}`;
};
// buildFileUrl is a helper function to build the sanity file url from the
// file asset ref.
export const buildFileUrl = (fileRef) => {
    const splitFileRef = fileRef.split('-');
    const processedFileRef = `${splitFileRef[1]}.${splitFileRef[2]}`;
    return `https://cdn.sanity.io/files/x7r1t5o8/production/${processedFileRef}`;
};
/*
// sanity
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(
  createClient({
    projectId: 'x7r1t5o8',
    dataset: 'production',
    useCdn: true,
    apiVersion: '2023-05-03',
  }),
);

// buildImageUrl is a helper function to build the sanity image url from the
// imageUrl.
export const buildImageUrl = (source?: any, height?: number) => {
  if (!source) return 'https://via.placeholder.com/150';
  let image = builder.image(source.asset._ref);
  // if (height) image = image.height(height);
  image = image.width(1200).height(300);
  return image.url();
};
*/
