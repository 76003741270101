// react
import { useOutlet } from 'react-router-dom';
import styled from 'styled-components';

/**
 * RootAdmin is the main component of the application.
 */
const RootAdmin = () => {
  // hooks
  const currentOutlet = useOutlet();

  return <RootAdminWrapper>{currentOutlet}</RootAdminWrapper>;
};

const RootAdminWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

RootAdmin.displayName = 'RootAdmin';
export default RootAdmin;
