import PropTypes from 'prop-types';

export default function Currency({ value, includeDecimals }) {
  const lengthBeforePoint = `${value}`.split('.')[0].length;
  return Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumSignificantDigits: includeDecimals
      ? lengthBeforePoint + 2
      : lengthBeforePoint,
  }).format(value);
}

Currency.propTypes = {
  value: PropTypes.number.isRequired,
  includeDecimals: PropTypes.bool,
};

Currency.defaultProps = {
  includeDecimals: false,
};
