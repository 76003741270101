import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Button } from "../../atoms";
/**
 * JourneyFiller gives the user a brief summary of the action they just
 * performed as well as a button to progress to the next stage.
 */
export const JourneyFiller = ({ title, description, actionLabel, onAction, }) => {
    return (_jsxs(StyledJourneyFiller, { children: [_jsx(Title, { children: title }), _jsx(Description, { children: description }), _jsx(Button, Object.assign({ ariaLabel: actionLabel, onPress: onAction, iconRight: 'long-arrow-right', size: 'md' }, { children: actionLabel }))] }));
};
const StyledJourneyFiller = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  width: 100%;
  align-items: center;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['4xl']};
  color: ${(props) => props.theme.color.primary[80]};
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
JourneyFiller.displayName = 'JourneyFiller';
export default JourneyFiller;
