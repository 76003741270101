import CardContainer from './components/CardContainer';
import Spacer from '../../primitives/Spacer';
import ProviderCardSkeleton from '../ProviderCard/ProviderCardSkeleton';

export default function SearchResultsListSkeleton() {
  const repeat = (count) => (fn) =>
    Array.from(Array(count))
      .map((_, i) => i)
      .map(fn);
  const doTenTimes = repeat(10);

  return (
    <CardContainer>
      {doTenTimes((r) => (
        <Spacer bottom right key={r}>
          <ProviderCardSkeleton />
        </Spacer>
      ))}
    </CardContainer>
  );
}
