import { useContext, useEffect } from 'react';

import ProviderProfile from '../features/profile/ProviderProfile';

import { ActiveProviderContext } from '../context/ActiveProviderContext';
import useProviderSearch from '../hooks/useProviderSearch';
import { BrowserLocationContext } from '../context/BrowserLocationContext';

export default function Profile() {
  const { provider } = useContext(BrowserLocationContext);
  const { profileByRegulatoryId } = useProviderSearch();
  const { activeProvider } = useContext(ActiveProviderContext);

  useEffect(() => {
    if (!activeProvider) {
      profileByRegulatoryId(provider);
    }
  }, [activeProvider, profileByRegulatoryId, provider]);

  return <ProviderProfile />;
}
