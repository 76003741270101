import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
/**
 * CardColor renders a card with a color.
 */
export const CardColor = ({ className, children, color = 'blue', isBordered = true, }) => {
    return (_jsx(Wrapper, Object.assign({ className: className, "$isBordered": isBordered, "$color": typeof color === 'string' ? color : 'blue' }, { children: children })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[32]};
  align-items: flex-start;
  background: ${(props) => props.theme.color.cards[props.$color].primary};
  border: ${(props) => props.$isBordered
    ? `1px solid ${props.theme.color.cards[props.$color].secondary}`
    : 'none'};
  color: ${(props) => props.theme.color.neutral[80]};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing[32]};
`;
CardColor.displayName = 'CardColor';
export default CardColor;
