import PropTypes from 'prop-types';

import InlineText from '../../../primitives/typography/InlineText';
import Row from '../../../primitives/Row';
import Spacer from '../../../primitives/Spacer';
import { ColourIcon, icons } from '../../../primitives/Icon';

export default function BackLink({
  buildSearchPath,
  location,
  haveSearchTerm,
}) {
  const linkText = haveSearchTerm
    ? 'Back to search results'
    : 'See more options in  this area';
  return (
    <a href={buildSearchPath(location)} tabIndex={0}>
      <Row>
        <Spacer right>
          <ColourIcon icon={icons.chevronLeft} />
        </Spacer>
        <InlineText primary>{linkText}</InlineText>
      </Row>
    </a>
  );
}

BackLink.propTypes = {
  buildSearchPath: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  haveSearchTerm: PropTypes.bool,
};

BackLink.defaultProps = {
  haveSearchTerm: false,
};
