import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { css } from 'styled-components';
/**
 * FloatingWithHeroTemplate shows the content floating 20% away from the top of the
 * screen.
 */
export const FloatingWithHeroTemplate = ({ content, hero, }) => {
    return (_jsxs(StyledFloatingWithHeroTemplate, { children: [_jsx(FloatingWithHeroContainer, { children: _jsx(FloatingWithHeroContent, { children: content }) }), _jsx(HeroWrapper, { children: _jsx(HeroContainer, { children: hero }) })] }));
};
const StyledFloatingWithHeroTemplate = styled.div `
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  ${(props) => props.theme.breakpoint.md(css `
      flex-direction: row;
    `)}
`;
const HeroWrapper = styled.div `
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: none;

  ${(props) => props.theme.breakpoint.sm(css `
      display: block;
    `)}

  ${(props) => props.theme.breakpoint.md(css `
      height: 100%;
      flex: 1;
      padding: ${props.theme.spacing[16]};
    `)}
`;
const HeroContainer = styled.div `
  ${(props) => props.theme.breakpoint.sm(css `
      transform: translateY(-35%);
    `)}

  ${(props) => props.theme.breakpoint.md(css `
      transform: none;
      border-radius: 16px;
      height: 100%;
      overflow: hidden;
    `)}
`;
const FloatingWithHeroContainer = styled.div `
  width: 100%;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: ${(props) => props.theme.spacing[36]} 0;

  height: 100%;

  ${(props) => props.theme.breakpoint.sm(css `
      position: absolute;
      bottom: 0;
      height: 70vh;
      padding: ${props.theme.spacing[36]};

      border-radius: 24px 24px 0 0;
      box-shadow: ${props.theme.elevation[6]};

      background: ${props.theme.color.neutral[10]};
    `)}

  ${(props) => props.theme.breakpoint.md(css `
      position: relative;
      top: 0;
      left: 0;

      height: 100%;
      flex: 1;

      background: none;
      box-shadow: none;
      border-radius: 0;
    `)}
`;
const FloatingWithHeroContent = styled.div `
  position: relative;
  top: 0;

  ${(props) => props.theme.breakpoint.sm(css ``)}

  ${(props) => props.theme.breakpoint.md(css `
      top: 20%;
      transform: none;
    `)}
`;
FloatingWithHeroTemplate.displayName = 'FloatingWithHeroTemplate';
export default FloatingWithHeroTemplate;
