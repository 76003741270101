import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';

export default function Skeleton({ ready, rows, type, children }) {
  return (
    <ReactPlaceholder ready={ready} rows={rows} type={type}>
      {children}
    </ReactPlaceholder>
  );
}

Skeleton.propTypes = {
  ready: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.oneOf(['text', 'media', 'textRow']),
  children: PropTypes.node,
};

Skeleton.defaultProps = {
  ready: false,
  rows: 3,
  type: 'text',
  children: undefined,
};
