import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { Avatar, Button } from "../../atoms";
/**
 * OrganizationPreview is a preview of an organization.
 */
export const OrganizationPreview = ({ imageSrc, name, seatLimits, onClose, toViewMembers, onViewMembers, toViewTiers, onViewTiers, toEditOrganization, onEditOrganization, isActionsDisabled = false, }) => {
    const labelItem = useCallback((label, value) => (_jsxs(LabelItem, { children: [_jsx(Label, { children: label }), _jsx(Value, { children: value })] })), []);
    return (_jsxs(StyledProfilePreview, { children: [_jsx(Avatar, { src: imageSrc, fallback: 'ORG', size: 'lg' }), _jsxs(InformationContainer, { children: [_jsx(Main, { children: _jsx(Name, { children: name }) }), _jsxs(SectionActionContainer, { children: [_jsxs(Section, { children: [name && labelItem('Name', name), seatLimits === null || seatLimits === void 0 ? void 0 : seatLimits.map((seatLimit) => {
                                        return [
                                            labelItem(`${seatLimit.tier.name} Limit`, seatLimit.isUnlimitedSeats
                                                ? 'Unlimited'
                                                : (seatLimit.seatLimit || 0).toString()),
                                        ];
                                    })] }), !isActionsDisabled && (_jsxs(ActionGroup, { children: [_jsx(Button, Object.assign({ ariaLabel: 'view members', variant: 'primary', iconRight: 'long-arrow-right', to: toViewMembers, onPress: onViewMembers }, { children: "View members" })), _jsx(Button, Object.assign({ ariaLabel: 'view tiers', variant: 'primary', iconRight: 'long-arrow-right', to: toViewTiers, onPress: onViewTiers }, { children: "View Tiers" })), _jsx(Button, Object.assign({ ariaLabel: 'edit organization', variant: 'primary', iconRight: 'long-arrow-right', to: toEditOrganization, onPress: onEditOrganization }, { children: "Edit organization" })), _jsx(Button, Object.assign({ ariaLabel: 'close', variant: 'secondary', onPress: onClose }, { children: "Close" }))] }))] })] })] }));
};
const StyledProfilePreview = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[20]};

  ${(props) => props.theme.breakpoint.sm(css `
      flex-direction: row;
      gap: ${props.theme.spacing[36]};
    `)}
`;
const InformationContainer = styled.div `
  display: flex;
  flex-direction: column;
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
  gap: ${(props) => props.theme.spacing[24]};
`;
const SectionActionContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[72]};
`;
const Main = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacing[20]};
  gap: ${(props) => props.theme.spacing[4]};
`;
const Name = styled.div `
  font: ${(props) => props.theme.typography.heading['5xl']};
  color: ${(props) => props.theme.color.primary[80]};
  white-space: nowrap;
`;
const Section = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[20]};
`;
const LabelItem = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]};
  border-spacing: 0;
`;
const Label = styled.div `
  font: ${(props) => props.theme.typography.body.md.bold};
  padding-right: ${(props) => props.theme.spacing[32]};
  color: ${(props) => props.theme.color.neutral[90]};
  white-space: nowrap;
`;
const Value = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  white-space: nowrap;
`;
const ActionGroup = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
OrganizationPreview.displayName = 'OrganizationPreview';
export default OrganizationPreview;
