// react
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { CareConciergeReferral as CareConciergeReferralPage } from '@karehero/llama';
import { useSendExternalReferralMutation } from 'store/api/referral';

// models
import { Referral } from '@karehero/models';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

const CareConciergeReferral = () => {
  // hooks
  const [sendExternalReferral] = useSendExternalReferralMutation();
  const navigate = useNavigate();
  const { track } = useMixpanel();

  // effects
  useEffect(() => {
    track(MixpanelEvent.ExternalPartnerReferralFormViewed, {});
  }, [track]);

  // callbacks
  const handleRefer = useCallback(
    async (referral: Referral) => {
      const res = await sendExternalReferral(referral);

      if ('error' in res) {
        track(MixpanelEvent.ExternalPartnerReferralFormErrored, {
          partnerEmail: referral?.referrer?.email,
        });
        return;
      }

      track(MixpanelEvent.ExternalPartnerReferralFormSubmitted, {
        isExistingPartner: res.data.isExistingPartner,
        partnerEmail: referral?.referrer?.email,
      });

      if (res.data.isExistingPartner) {
        const message = encodeURIComponent(
          'It appears that this email is already associated with an existing account. Please sign in to complete your referral.',
        );
        navigate(`/sign-in?message=${message}`);
      } else {
        navigate('/referral/confirm-email');
      }
    },
    [sendExternalReferral, track, navigate],
  );

  return (
    <CareConciergeReferralPage
      onRefer={handleRefer}
      videoPlaybackID={
        process.env.REACT_APP_KAREHERO_APP_DEMO_VIDEO_PLAYBACK_ID
      }
      videoThumbnailTime={parseFloat(
        process.env.REACT_APP_KAREHERO_APP_DEMO_VIDEO_THUMBNAIL_TIME || '0.0',
      )}
    />
  );
};

export default CareConciergeReferral;
