import PropTypes from 'prop-types';

import { styled } from '../../styling';

const SelectBox = styled.select`
  width: 100%;
  cursor: pointer;
  height: ${({ theme }) => theme.spacing.units(8)};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colour.status.disabled : theme.colour.primary};
  outline-color: ${({ theme, disabled }) =>
    disabled ? theme.colour.status.disabled : theme.colour.primary};
  ${({ disabled }) => disabled && 'cursor: not-allowed'}
`;

export default function Select({
  name,
  onChange,
  items = [],
  value,
  getLabelFromItem,
  getValueFromItem,
  disabled,
}) {
  const handleChange = ({ target }) => onChange(target.value);

  return (
    <SelectBox
      name={name}
      onChange={handleChange}
      value={value}
      disabled={disabled}
      aria-label={name}
    >
      {items.map((item) => (
        <option key={getValueFromItem(item)} value={getValueFromItem(item)}>
          {getLabelFromItem(item)}
        </option>
      ))}
    </SelectBox>
  );
}

Select.propTypes = {
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.string,
  getLabelFromItem: PropTypes.func,
  getValueFromItem: PropTypes.func,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  value: undefined,
  getLabelFromItem: (x) => x,
  getValueFromItem: (x) => x,
  disabled: false,
};
