import { styled } from '../../styling';

const Paragraph = styled.p`
  margin: 0;
  font-size: 1rem;
  color: ${({ theme }) => theme.colour.neutral.dark};
  font-family: ${({ theme }) => theme.typography.fontFamilyBody};
  ${({ theme, largeLineHeight }) =>
    largeLineHeight && `line-height: ${theme.spacing.units(6)}`};
`;

export default Paragraph;
