import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { Card, Container } from "../../atoms";
/**
 * Instruction renders an instruction to the user.
 */
export const Instruction = ({ className, children, title, subTitle, instruction, }) => {
    return (_jsx(StyledContainer, Object.assign({ size: 'md' }, { children: _jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsxs(Header, { children: [title && _jsx(Title, { children: title }), subTitle && _jsx(SubTitle, { children: subTitle })] }), children, _jsx(Card, Object.assign({ color: 'green' }, { children: _jsx(StyledText, { children: instruction }) }))] })) })));
};
const StyledContainer = styled(Container) `
  overflow-y: auto;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[24]};
  padding: ${(props) => props.theme.spacing[32]} 0;
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[24]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['4xl']};
  color: ${(props) => props.theme.color.primary[80]};
  text-align: center;
  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.heading['6xl']};
      text-align: left;
    `)};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: center;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.body.xl.regular};
    `)}
`;
const StyledText = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: center;
`;
Instruction.displayName = 'Instruction';
export default Instruction;
