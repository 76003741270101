// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// store
import { dispatch } from 'store';
import { clearAuth } from 'store/slices/iamSlice';
import {
  useRegisterMemberByDomainMutation,
  useRegisterMemberByInviteMutation,
  useSetTokenMutation,
} from 'store/api/iam';

// components
import {
  Loading,
  OnboardingActionType,
  OnboardingEditor,
  OnboardingFlow,
  OnboardingKeni,
  Register,
} from '@karehero/llama';
import { FieldType } from '@karehero/llama/src/components/organisms/EditorFields/fieldHelper';

// hooks
import { useKinde } from 'hooks';

// config
import {
  onboardingKeniConfirmEmailConfig,
  onboardingKeniConfirmMigrationConfig,
} from 'config/onboarding';

// validation
const validateEmail = (value?: string) => /\S+@\S+\.\S+/.test(value || '');

/**
 * OnboardingMemberRegister takes the user through the registration flow.
 */
const OnboardingMemberRegister = () => {
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState<{ value: string }>();
  const [token, setToken] = useState('');
  const [isMigration, setIsMigration] = useState(false);

  // hooks
  const [searchParams] = useSearchParams();
  const [setRemoteToken] = useSetTokenMutation();
  const [registerMemberByDomain] = useRegisterMemberByDomainMutation();
  const [registerMemberByInvite] = useRegisterMemberByInviteMutation();
  const { signIn, register, ConnectionID } = useKinde();
  const navigate = useNavigate();

  // methods
  const clear = useCallback(() => {
    // localStorage
    const state = localStorage.getItem('reduxState');
    const reduxState = JSON.parse(state || '{}');
    delete reduxState.iam;
    localStorage.setItem('reduxState', JSON.stringify(reduxState));

    // redux
    dispatch(clearAuth);
  }, []);

  const handleRegisterMemberByDomain =
    useCallback(async (): Promise<boolean> => {
      const res = await registerMemberByDomain({
        email: email?.value || '',
      });
      if ('error' in res) {
        if ('status' in res.error && res.error.status === 409) {
          signIn();
        }
        if ('status' in res.error && res.error.status === 410) {
          setIsMigration(true);
          return true;
        }
        return false;
      }
      return true;
    }, [registerMemberByDomain, email, signIn]);

  const handleRegisterMemberByInvite = useCallback(
    async (connectionID?: string) => {
      register(connectionID);
    },
    [register],
  );

  // effects
  useEffect(() => {
    const token = searchParams.get('token');
    if (!token) {
      setIsLoading(false);
      return;
    }
    (async () => {
      const res = await setRemoteToken(token);
      if (!('data' in res)) {
        console.error('Failed to set token');
        setIsLoading(false);
        return;
      }
      setToken(token);
      setIsLoading(false);
    })();
  }, [searchParams, setRemoteToken]);

  useEffect(() => {
    (async () => {
      if (!token) return;

      clear();

      const res = await registerMemberByInvite({
        token,
      });
      if (
        !('data' in res) &&
        'status' in res.error &&
        res.error.status === 404
      ) {
        navigate('/sign-in');
        return;
      }
    })();
  }, [registerMemberByInvite, clear, navigate, token]);

  // memos
  const onboardingMemberRegisterConfig = useMemo(
    () => [
      {
        id: 'register',
        Component: OnboardingEditor,
        onBeforeNext: handleRegisterMemberByDomain,
        config: [
          {
            id: 'register-new-account',
            title: 'Register new account',
            subTitle:
              'KareHero is currently an invite-only platform. If you have not received an email invite from us, enter your work email to get started.',
            value: email,
            onChange: setEmail,
            fieldDef: {
              label: 'Work email address',
              field: {
                type: FieldType.Email,
              },
              validation: validateEmail,
            },
            actions: [
              {
                label: 'Back',
                onClick: OnboardingActionType.To,
                to: '/sign-in',
              },
              {
                label: 'Next',
                onClick: OnboardingActionType.Next,
              },
            ],
          },
        ],
      },
      {
        id: 'keni',
        Component: OnboardingKeni,
        config: isMigration
          ? onboardingKeniConfirmMigrationConfig
          : onboardingKeniConfirmEmailConfig,
      },
    ],
    [email, isMigration, handleRegisterMemberByDomain],
  );

  const isProduction = useMemo(
    () =>
      process.env.REACT_APP_BUILD_ENVIRONMENT === 'production'
        ? true
        : undefined,
    [],
  );

  return !token && !isLoading ? (
    <OnboardingFlow
      config={onboardingMemberRegisterConfig}
      onComplete={() => {}}
    />
  ) : isLoading ? (
    <Loading />
  ) : (
    <Register
      onGoogleRegister={
        isProduction &&
        (() => handleRegisterMemberByInvite(ConnectionID.GOOGLE))
      }
      onFacebookRegister={
        isProduction &&
        (() => handleRegisterMemberByInvite(ConnectionID.FACEBOOK))
      }
      onAppleRegister={
        isProduction && (() => handleRegisterMemberByInvite(ConnectionID.APPLE))
      }
      onEmailRegister={() => handleRegisterMemberByInvite()}
    />
  );
};

OnboardingMemberRegister.displayName = 'OnboardingMemberRegister';
export default OnboardingMemberRegister;
