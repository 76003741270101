import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { Icon } from "../../atoms";
import styled from 'styled-components';
/**
 * AttendanceAllowanceComplete tells the user that they have completed their care plan.
 */
export const AttendanceAllowanceComplete = ({ className, }) => {
    return (_jsxs(Wrapper, { children: [_jsxs(IconContainer, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { icon: 'clipboard-check', fontSize: 42 }) }), _jsx(Title, { children: "Almost done!" })] }), _jsx(CareProfileCard, Object.assign({ color: 'blue' }, { children: _jsx(CareProfileCardBody, { children: _jsxs(CardContent, { children: [_jsx(CardTitle, { children: "Final Check Before Submitting" }), _jsx(CardBody, { children: _jsxs(StyledList, { children: [_jsx("li", { children: "Please double check your answers to make sure that all the information is correct. Once submitted, we will print and post this form to you." }), _jsx("li", { children: "If want to talk through any part of your application before submitting, please contact our care experts via your Help tab (your answers are safely saved)." })] }) })] }) }) }))] }));
};
const StyledList = styled.ul `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing[16]};
  gap: ${(props) => props.theme.spacing[16]};
`;
const IconContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[12]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const IconWrapper = styled.div `
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.color.success[40]};
`;
const CareProfileCard = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[32]};
  align-items: flex-start;
  background: ${(props) => props.theme.color.cards[props.color].primary};
  color: ${(props) => props.theme.color.neutral[80]};
  border-radius: 8px;
`;
const CareProfileCardBody = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[32]};
  flex: 1;
  padding: ${(props) => props.theme.spacing[8]};
`;
const CardContent = styled.div `
  display: flex;
  flex-direction: column;
`;
const CardTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  padding: ${(props) => props.theme.spacing[12]};
`;
const CardBody = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
`;
AttendanceAllowanceComplete.displayName = 'AttendanceAllowanceComplete';
export default AttendanceAllowanceComplete;
