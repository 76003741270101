// react
import { useMemo } from 'react';

// hooks
import { useCareProfile, usePermissions } from 'hooks';

// store
import {
  useGetAllActionPlanCategoryQuery,
  useGetAllActionPlanFeedbackQuery,
  useGetAllActionPlanQuery,
  useGetMemberActionPlanQuery,
  useMarkActionCompleteMutation,
  useMarkActionDismissedMutation,
  useMarkActionFeedbackMutation,
} from 'store/api/actionPlan';
import { useLazyGetActionPlanSanityQuery } from 'store/api/cms';
import { getAccount } from 'store/slices/iamSlice';
import { useSelector } from 'store';

// models
import { ActionPlanItem } from '@karehero/models';

// launch darkly
import { useFlags } from 'launchdarkly-react-client-sdk';

const zencoURL = 'https://online.zenco.com/start/karehero/landing';

export const useActionPlan = () => {
  // hooks
  const account = useSelector(getAccount);
  const { careCircleMember } = useCareProfile();
  const { data: actionPlanCategories } = useGetAllActionPlanCategoryQuery();
  const { data: memberActionPlanItems, refetch: refetchMemberActionPlanItems } =
    useGetMemberActionPlanQuery(undefined, {
      skip: account.accountRole.name !== 'Member',
    });
  const { data: allActionPlanItemsData } = useGetAllActionPlanQuery();
  const { data: actionPlanFeedback } = useGetAllActionPlanFeedbackQuery(
    undefined,
    {
      skip: account.accountRole.name !== 'Member',
    },
  );
  const [markActionComplete] = useMarkActionCompleteMutation();
  const [dismissAction] = useMarkActionDismissedMutation();
  const [markActionFeedback] = useMarkActionFeedbackMutation();
  const [getActionPlanSanity] = useLazyGetActionPlanSanityQuery();
  const flags = useFlags();
  const { actionPlan } = usePermissions();

  // methods
  const actionPlanItems = useMemo<ActionPlanItem[]>(() => {
    const completedActionIds: { [key: string]: boolean } = {};
    const dismissedActionIds: { [key: string]: boolean } = {};
    const feedbackActionIds: { [key: string]: boolean } = {};

    careCircleMember?.completedActions?.forEach((action) => {
      completedActionIds[action.id] = true;
    });

    actionPlanFeedback?.forEach((action) => {
      feedbackActionIds[action.actionPlanItemID] = true;
    });

    const orderedActionPlanItemsData = memberActionPlanItems
      ? [...memberActionPlanItems].sort((a, b) => Math.sign(a.order - b.order))
      : [];

    return (
      orderedActionPlanItemsData.map((action) => {
        const category = actionPlanCategories?.find(
          (x) => x.id === action.actionPlanCategoryID,
        );

        memberActionPlanItems?.forEach((action) => {
          dismissedActionIds[action.id] = action.isDismiss || false;
        });

        let route = action.route;
        if (flags.zencoPoa && action.id === 'apply-for-power-of-attorney') {
          route = zencoURL;
        }

        return {
          ...action,
          route,
          subTitle: category?.title || '',
          color: category?.color || 'blue',
          to: `/action-plan/${action.id}`,
          icon: category?.icon || 'star',
          isComplete: completedActionIds[action.id] || false,
          isDismiss: dismissedActionIds[action.id],
          isFeedback: feedbackActionIds[action.id] || false,
          isModify: actionPlan.isModify,
        };
      }) || []
    );
  }, [
    careCircleMember,
    actionPlanCategories,
    memberActionPlanItems,
    actionPlanFeedback,
    flags,
    actionPlan,
  ]);

  const allActionPlanItems = useMemo<ActionPlanItem[]>(() => {
    const completedActionIds: { [key: string]: boolean } = {};
    const feedbackActionIds: { [key: string]: boolean } = {};

    careCircleMember?.completedActions?.forEach((action) => {
      completedActionIds[action.id] = true;
    });

    actionPlanFeedback?.forEach((action) => {
      feedbackActionIds[action.actionPlanItemID] = true;
    });

    const orderedActionPlanItemsData = allActionPlanItemsData
      ? [...allActionPlanItemsData].sort((a, b) => Math.sign(a.order - b.order))
      : [];

    return (
      orderedActionPlanItemsData?.map((action) => {
        const category = actionPlanCategories?.find(
          (x) => x.id === action.actionPlanCategoryID,
        );

        let route = action.route;
        if (flags.zencoPoa && action.id === 'apply-for-power-of-attorney') {
          route = zencoURL;
        }

        return {
          ...action,
          route,
          subTitle: category?.title || '',
          color: category?.color || 'blue',
          to: `/action-plan/${action.id}`,
          icon: category?.icon || 'star',
          isComplete: completedActionIds[action.id] || false,
          isFeedback: feedbackActionIds[action.id] || false,
          isModify: actionPlan.isModify,
        };
      }) || []
    );
  }, [
    careCircleMember,
    actionPlanCategories,
    allActionPlanItemsData,
    actionPlanFeedback,
    flags,
    actionPlan,
  ]);

  const actionPlanItemsPersonalized = useMemo<ActionPlanItem[]>(() => {
    return actionPlanItems?.filter((action) => !action.isDismiss);
  }, [actionPlanItems]);

  const actionPlanItemsTodo = useMemo<ActionPlanItem[]>(() => {
    return actionPlanItems?.filter(
      (action) => !action.isDismiss && !action.isComplete,
    );
  }, [actionPlanItems]);

  const markActionDismissed = useMemo(
    () => async (actionID: string) => {
      await dismissAction(actionID);
      refetchMemberActionPlanItems();
    },
    [dismissAction, refetchMemberActionPlanItems],
  );

  return {
    actionPlanItems,
    actionPlanItemsTodo,
    actionPlanItemsPersonalized,
    actionPlanCategories,
    allActionPlanItems,
    getActionPlanSanity,
    markActionComplete,
    markActionDismissed,
    markActionFeedback,
  };
};
