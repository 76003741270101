import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState } from 'react';
import styled, { css } from 'styled-components';
// components
import { Container } from "../../atoms/Container/Container";
import { Logo } from "../../atoms/Logo/Logo";
import { Button, Input, Title } from "../../atoms";
/**
 * SignInEmail is the page where the user can sign in.
 */
export const SignInEmail = ({ onSubmit }) => {
    // state
    const [email, setEmail] = useState('');
    return (_jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(Logo, { size: 'md' }), _jsx(ContentWrapper, { children: _jsx(StyledTitle, { children: "Sign in" }) }), _jsxs(ButtonContainer, { children: [_jsxs(SignInForm, Object.assign({ onSubmit: (e) => {
                                e.preventDefault();
                                onSubmit(email);
                            } }, { children: [_jsx(Input, { ariaLabel: 'email', placeholder: 'Email', value: email, onChange: setEmail }), _jsx(Button, Object.assign({ ariaLabel: 'sign in', isFullWidth: true, type: 'submit' }, { children: "Sign in" }))] })), _jsx(Button, Object.assign({ variant: 'link', ariaLabel: 'register', isFullWidth: true, to: '/sign-in' }, { children: "Sign in using another method" }))] })] }) })));
};
const StyledContainer = styled(Container) `
  background: ${(props) => props.theme.color.neutral[20]};
  overflow-y: auto;
  height: 100%;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing[8]};
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.spacing[32]} 0;
`;
const StyledTitle = styled(Title) `
  text-align: center;
  font: ${(props) => props.theme.typography.heading['3xl']};
  margin: 0;

  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.heading['5xl']};
    `)}
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[48]};
  margin-bottom: ${(props) => props.theme.spacing[64]};
`;
const SignInForm = styled.form `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[12]};
`;
SignInEmail.displayName = 'SignInEmail';
export default SignInEmail;
