var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useRef, useCallback } from 'react';
import styled from 'styled-components';
// components
import { Button } from "../../atoms";
/**
 * UploadFileButton allows the user to upload a local file, accepting a
 * callback for the file content.
 */
export const UploadFileButton = (_a) => {
    var { variant = 'secondary', isFullWidth = true, onPress, onChange, children } = _a, props = __rest(_a, ["variant", "isFullWidth", "onPress", "onChange", "children"]);
    // refs
    const uploadRef = useRef(null);
    // methods
    const handleUploadCsv = useCallback(() => {
        if (!(uploadRef === null || uploadRef === void 0 ? void 0 : uploadRef.current))
            return;
        uploadRef.current.value = '';
        uploadRef.current.click();
        onPress === null || onPress === void 0 ? void 0 : onPress();
    }, [uploadRef, onPress]);
    const handleOnChange = useCallback((e) => {
        var _a;
        if (!((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.files))
            return;
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => reader.result && (onChange === null || onChange === void 0 ? void 0 : onChange(reader.result)), false);
        if (file) {
            reader.readAsText(file);
        }
    }, [onChange]);
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: variant, onPress: handleUploadCsv, isFullWidth: isFullWidth }, props, { children: children })), _jsx(HiddenInput, { type: 'file', accept: '.csv', ref: uploadRef, onChange: handleOnChange })] }));
};
const HiddenInput = styled.input `
  display: none;
`;
UploadFileButton.componentName = 'UploadFileButton';
export default UploadFileButton;
