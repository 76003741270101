import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
// components
import { Button } from "../../atoms/Button/Button";
import { EditorFields } from "../EditorFields/EditorFields";
/**
 * validationAsString converts a validation array to a string.
 */
export const validationAsString = (validations) => {
    if (!validations) {
        return undefined;
    }
    const validationStrings = validations.map((validation) => {
        let valueString;
        switch (typeof validation.value) {
            case 'string':
                valueString = validation.value;
                break;
            case 'number':
                valueString = validation.value.toString();
                break;
            case 'boolean':
                valueString = validation.value ? 'true' : 'false';
                break;
            case 'object':
                valueString = validation.value === null ? 'null' : '';
                break;
            case 'undefined':
                valueString = '';
                break;
            default:
                throw new Error('Unsupported validation value type');
        }
        return `${validation.type}:${valueString}`;
    });
    return validationStrings.join(',');
};
/**
 * validationStringToMethod converts a validation string to a validation method.
 */
export const validationStringToMethod = (validationsString) => {
    if (!validationsString) {
        return () => true;
    }
    const validations = validationsString.split(',');
    return (value) => {
        return validations.every((validationString) => {
            if (!value)
                return true;
            const v = validationString.split(':');
            const method = v[0];
            const arg = v[1];
            // only used in date validations
            let argDate = new Date(arg);
            if (arg === 'today') {
                argDate = new Date();
                argDate.setHours(0, 0, 0, 0);
            }
            switch (method) {
                case 'min-length':
                    return String(value).length >= Number(arg);
                case 'max-length':
                    return String(value).length <= Number(arg);
                case 'min':
                    return Number(value) >= Number(arg);
                case 'max':
                    return Number(value) <= Number(arg);
                case 'password':
                    return (String(value).length >= 8 &&
                        String(value).match(/[0-9]/) &&
                        String(value).match(/[a-z]/) &&
                        String(value).match(/[A-Z]/));
                case 'email':
                    return String(value)
                        .toLowerCase()
                        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                case 'before':
                    return new Date(String(value)) < argDate;
                case 'after':
                    return new Date(String(value)) > argDate;
                case 'before-or-on':
                    return new Date(String(value)) <= argDate;
                case 'after-or-on':
                    return new Date(String(value)) >= argDate;
                case 'nhs-number':
                    return String(value).match(/^[0-9]{10}$/);
                case 'national-insurance-number':
                    return String(value).match(/^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$/);
                case 'phone':
                    return String(value).match(/^\+?[0-9]{10,15}$/);
                case 'sjp-partner-code':
                    return String(value).match(/^[0-9]{6}[A-z]{1}$/);
                case 'name':
                    return String(value).match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/);
                default:
                    return true;
            }
        });
    };
};
/**
 * Editor enables users to input information which will be updated in the
 * database.
 */
export const Editor = ({ onSave, onCancel, saveLabel = 'Save', cancelLabel = 'Cancel', isWithArrows = false, isActionsDisabled = false, value, onChange, fields, isDisabled = false, isFloatingActions = true, isForm = true, isSaveDisabled = false, }) => {
    // methods
    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        onSave === null || onSave === void 0 ? void 0 : onSave();
    }, [onSave]);
    const EditorComponent = useMemo(() => (_jsxs(_Fragment, { children: [_jsx(EditorFields, { fields: fields, value: value, onChange: onChange, isDisabled: isDisabled }), isFloatingActions && _jsx(ActionButtonsPlaceholder, {}), !isActionsDisabled && (_jsxs(EditorActions, Object.assign({ "$isFloatingActions": isFloatingActions }, { children: [_jsx(Button, Object.assign({ ariaLabel: cancelLabel, onPress: onCancel, variant: 'secondary', iconLeft: isWithArrows ? 'long-arrow-left' : undefined }, { children: cancelLabel })), _jsx(Button, Object.assign({ type: 'submit', ariaLabel: saveLabel, 
                        // onPress={onSave}
                        iconRight: isWithArrows ? 'long-arrow-right' : undefined, isDisabled: isSaveDisabled }, { children: saveLabel }))] })))] })), [
        fields,
        value,
        onChange,
        isFloatingActions,
        isActionsDisabled,
        isWithArrows,
        cancelLabel,
        saveLabel,
        onCancel,
    ]);
    return isForm ? (_jsx(StyledEditor, Object.assign({ onSubmit: handleSubmit }, { children: EditorComponent }))) : (_jsx(StyledEditorDiv, { children: EditorComponent }));
};
const StyledEditor = styled.form `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[32]};
  width: 100%;
`;
const StyledEditorDiv = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[32]};
  width: 100%;
`;
const EditorActions = styled.div `
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;

  ${(props) => !props.$isFloatingActions &&
    css `
      & > button {
        flex: 1;
      }
    `};
  ${(props) => props.$isFloatingActions &&
    css `
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      gap: ${props.theme.spacing[16]};
      padding: ${props.theme.spacing[16]} 0;
      border-top: 1px solid ${props.theme.color.neutral[50]};
      background: ${props.theme.color.neutral[10]};
    `}
`;
const ActionButtonsPlaceholder = styled.div `
  width: 100%;
  height: 75px;
  min-height: 75px;
  max-height: 75px;
`;
Editor.displayName = 'Editor';
export default Editor;
