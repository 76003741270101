import { jsx as _jsx } from "react/jsx-runtime";
// components
import ActionPlanCardGroup from "../../molecules/ActionPlanCardGroup/ActionPlanCardGroup";
import { Accordion } from "../../atoms";
import { validateIcon } from "../../atoms/Icon";
// theme
import { theme } from "../../../theme";
export const ActionPlanAccordion = ({ actionPlanItems, categories, onDismiss, }) => {
    return (_jsx(Accordion, { items: categories === null || categories === void 0 ? void 0 : categories.filter((category) => actionPlanItems === null || actionPlanItems === void 0 ? void 0 : actionPlanItems.some((card) => card.actionPlanCategoryID === category.id)).map((category) => {
            let item = {
                id: category.id,
                label: category.title,
                content: (_jsx(ActionPlanCardGroup, { actionPlanItems: actionPlanItems === null || actionPlanItems === void 0 ? void 0 : actionPlanItems.filter((card) => card.actionPlanCategoryID === category.id), onDismiss: onDismiss })),
                icon: validateIcon(category.icon),
                primaryColor: theme.color.actionPlanCards[category.color].primary,
                secondaryColor: theme.color.actionPlanCards[category.color].secondary,
            };
            return item;
        }) }));
};
ActionPlanAccordion.displayName = 'ActionPlanAccordion';
export default ActionPlanAccordion;
