import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
import { Container, Input, Title } from "../../atoms";
import { FloatingTemplate } from "../../templates";
/**
 * OnboardingRegister renders the Register screen for onboarding flows.
 */
export const OnboardingRegister = toGenericOnboarding(({ config, currentIndex, setIsNextDisabled, }) => {
    var _a, _b;
    if (!config)
        return null;
    return (_jsx(FloatingTemplate, { content: _jsx(Container, { children: _jsxs(Content, { children: [_jsx(Title, { children: "Let's create your account" }), _jsx(Input, { label: 'Create your password', value: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.value, onChange: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.onChange, type: 'password', validate: (value) => {
                            const isValid = ((value && value.length) || 0) >= 8;
                            setIsNextDisabled(!isValid);
                            return isValid;
                        } })] }) }) }));
});
const Content = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
OnboardingRegister.displayName = 'OnboardingRegister';
export default toGenericOnboarding(OnboardingRegister);
