import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
// components
import { Button, Card, Checkbox, CSAT, Dialog, LottieAnimation, } from "../../atoms";
import { SanityContent } from "../../organisms";
import { Icon, validateIcon } from "../../atoms/Icon";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import { theme } from "../../../theme";
// assets
// @transform-path ../../../../../../src/assets/animation/success.json
import successAnimation from "../../../../../../src/assets/animation/success.json";
/**
 * ActionPlanContent shows the content of the action plan along with a
 * "Get Started" button and a "Mark as complete" checkbox which will trigger
 * a celebration animation when checked.
 */
export const ActionPlanContent = ({ className, actionPlanItem, categories, headerImageUrl, sanityContent, isHideBack, onComplete, onCSAT, }) => {
    // state
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    // hooks
    const [width] = useWindowSize();
    // memos
    const category = useMemo(() => {
        return categories === null || categories === void 0 ? void 0 : categories.find((x) => x.id === (actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.actionPlanCategoryID));
    }, [categories, actionPlanItem]);
    const isCSAT = useMemo(() => {
        return (actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.isComplete) && !(actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.isFeedback);
    }, [actionPlanItem]);
    return (_jsxs(_Fragment, { children: [_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsxs(Image, Object.assign({ color: (category === null || category === void 0 ? void 0 : category.color) || 'grey' }, { children: [headerImageUrl && _jsx("img", { src: headerImageUrl, alt: '' }), !isHideBack && (_jsx(Button, Object.assign({ ariaLabel: 'Back', variant: 'secondary', iconLeft: 'chevron-left', onPress: () => window.history.back() }, { children: "Back" })))] })), _jsxs(Header, { children: [_jsx(StyledTitle, { children: actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.title }), _jsxs(Category, { children: [(category === null || category === void 0 ? void 0 : category.icon) && (_jsx(IconWrapper, Object.assign({ color: (category === null || category === void 0 ? void 0 : category.color) || 'grey' }, { children: _jsx(Icon, { icon: validateIcon(category === null || category === void 0 ? void 0 : category.icon), fontSize: 16 }) }))), _jsx(StyledSubTitle, { children: actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.subTitle })] })] }), _jsx(Content, { children: _jsx(SanityContent, { content: sanityContent }) }), _jsx(Footer, { children: _jsxs(FooterWrapper, { children: [_jsx(Button, Object.assign({ variant: 'primary', ariaLabel: 'get started', to: actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.route, isFullWidth: true }, { children: (actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.isComplete) ? 'View' : 'Get Started' })), (actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.isModify) && (_jsx(CompletedCheckbox, { children: _jsx(Checkbox, { label: (actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.isComplete)
                                            ? 'Completed'
                                            : 'Mark as complete', size: 'md', variant: 'circle', value: actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.isComplete, onChange: () => {
                                            if (!actionPlanItem || actionPlanItem.isComplete)
                                                return;
                                            setIsDialogOpen(true);
                                            onComplete === null || onComplete === void 0 ? void 0 : onComplete(actionPlanItem === null || actionPlanItem === void 0 ? void 0 : actionPlanItem.id);
                                        } }) })), isCSAT && (_jsx(StyledCard, Object.assign({ elevation: 0, isPaddingSm: width < theme.breakpoint.smValue }, { children: _jsx(CSAT, { onPress: onCSAT }) })))] }) })] })), _jsx(Dialog, Object.assign({ variant: 'sheet', direction: 'bottom', isOpen: isDialogOpen, setIsOpen: setIsDialogOpen }, { children: _jsxs(Celebration, { children: [_jsxs(CelebrationHeader, { children: [_jsx(AnimationContainer, { children: _jsx(LottieAnimation, { src: successAnimation, loop: true, autoplay: true }) }), _jsx(CelebrationIconWrapper, { children: _jsx(Icon, { icon: 'party-horn', fontSize: 72 }) })] }), _jsx(CelebrationText, { children: "Nice work on completing your action!" }), isHideBack && (_jsx(StyledButton, Object.assign({ size: 'md', isFullWidth: true, ariaLabel: 'See my next actions', to: '/action-plan' }, { children: "See my next actions" })))] }) }))] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  height: 100%;
`;
const Image = styled.div `
  position: relative;
  background: ${(props) => props.theme.color.actionPlanCards[props.color].secondary};
  color: ${(props) => props.theme.color.actionPlanCards[props.color].primary};
  height: 100px;
  padding: ${(props) => props.theme.spacing[24]};
  ${(props) => props.theme.breakpoint.sm(css `
      height: 150px;
    `)}

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]};
  padding-left: ${(props) => props.theme.spacing[24]};
  padding-right: ${(props) => props.theme.spacing[24]};
`;
const Content = styled.div `
  overflow-y: auto;
  padding-left: ${(props) => props.theme.spacing[24]};
  padding-right: ${(props) => props.theme.spacing[24]};
`;
const Footer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[16]};
  flex: 1;
  padding: ${(props) => props.theme.spacing[24]};
  padding-top: ${(props) => props.theme.spacing[16]};
  position: sticky;
  bottom: 0;
  background: ${(props) => props.theme.color.neutral[10]};
`;
const StyledTitle = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.md.medium};
`;
const Category = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[4]};
`;
const StyledSubTitle = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const IconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[4]};
  border-radius: 99999px;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  background: ${(props) => props.theme.color.actionPlanCards[props.color].secondary};
  color: ${(props) => props.theme.color.actionPlanCards[props.color].primary};
`;
const CompletedCheckbox = styled.div `
  display: flex;
  justify-content: center;
`;
const Celebration = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[48]} 0;
`;
const CelebrationText = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.lg.regular};
  text-align: center;
`;
const CelebrationHeader = styled.div `
  height: 200px;
  width: 200px;
  position: relative;
`;
const CelebrationIconWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) => props.theme.color.primary[70]};
`;
const AnimationContainer = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 180%;
  width: 180%;
  position: absolute;
`;
const FooterWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;
  max-width: 400px;
  justify-content: center;
  align-items: center;
`;
const StyledCard = styled(Card) `
  margin-top: ${(props) => props.theme.spacing[8]};
  width: 100%;
`;
const StyledButton = styled(Button) `
  max-width: 400px;
`;
ActionPlanContent.displayName = 'ActionPlanContent';
export default ActionPlanContent;
