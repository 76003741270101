import PropTypes from 'prop-types';

import { styled } from '../../../styling';

const Container = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.color.neutral[40]};
  color: ${({ theme }) => theme.color.neutral[90]};
  font: ${({ theme }) => theme.typography.body.sm.regular};
  border-radius: 99999px;
  text-transform: ${({ transform }) => transform || 'initial'};
  padding: ${({ theme }) => theme.spacing[8]}
    ${({ theme }) => theme.spacing[12]};
`;

export default function CareProvidedLabel({ label }) {
  return <Container>{label}</Container>;
}

CareProvidedLabel.propTypes = {
  label: PropTypes.string.isRequired,
};
