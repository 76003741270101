import { styled } from '../../../styling';
import InlineText from '../../typography/InlineText';

const LabelText = styled(InlineText)`
  margin: 0 ${({ theme }) => theme.spacing.units(2)};
  ${({ theme, focused }) => focused && `color: ${theme.colour.primary};`}
  :hover {
    ${({ theme }) => `color: ${theme.colour.primary};`}
  }

  ${({ theme, disabled }) =>
    disabled &&
    `
    color: ${theme.colour.status.disabled};

    :hover {
      color: ${theme.colour.status.disabled};
    }
  `}
`;

export default LabelText;
