import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// component
import { ArticleCard, LoadingTransition, Title } from "../../atoms";
import { ArticleSummary } from "../../organisms";
import { CardContainer } from "../../templates";
// theme
import theme from "../../../theme";
// hooks
import { useWindowSize } from "../../../hooks";
/**
 * ArticleList is a page that displays all of the content that available
 * to the user.
 */
export const ArticleList = ({ articles, isLoading, organizationName, }) => {
    // hooks
    const [width] = useWindowSize();
    // memos
    const dummyArticles = useMemo(() => {
        let dummyArticles = [];
        for (let i = 0; i < 32; i++) {
            dummyArticles.push({ title: `Article ${i}` });
        }
        return dummyArticles;
    }, []);
    const dummyLibrary = useMemo(() => {
        let dummyLibrary = [];
        for (let i = 0; i < 4; i++) {
            dummyLibrary.push({
                title: `Category ${i}`,
                articles: dummyArticles,
            });
        }
        return dummyLibrary;
    }, [dummyArticles]);
    const maxColumns = useMemo(() => {
        if (width < theme.breakpoint.mdValue)
            return 1;
        if (width < theme.breakpoint.lgValue)
            return 2;
        if (width < theme.breakpoint.xlValue)
            return 3;
        return 4;
    }, [width]);
    return (_jsxs(StyledArticleList, { children: [_jsx(MainHeader, { children: _jsx(Header, { children: _jsxs(Title, Object.assign({ isNoMargin: true }, { children: [organizationName, " Resources"] })) }) }), _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Content, { children: _jsx(ArticleSummary, { library: dummyLibrary, isLoading: true }) }), content: _jsx(CardContainer, Object.assign({ maxColumns: maxColumns }, { children: articles && articles.length > 0 ? (articles === null || articles === void 0 ? void 0 : articles.map((article, i) => (_jsx(_Fragment, { children: _jsx(ArticleCard, { article: article, isLoading: isLoading }, i) })))) : (_jsx("div", { children: "No articles found" })) })) })] }));
};
const StyledArticleList = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
  padding-bottom: ${(props) => props.theme.spacing[8]};
  padding-left: ${(props) => props.theme.spacing[4]};
  padding-right: ${(props) => props.theme.spacing[4]};
  overflow-x: visible;
  ${(props) => props.theme.breakpoint.sm(css `
      overflow-x: hidden;
    `)}
`;
const MainHeader = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
  width: 100%;
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
`;
const Content = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[32]};
  flex-wrap: wrap;
`;
ArticleList.displayName = 'ArticleList';
export default ArticleList;
