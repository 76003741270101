// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

// components
import { ActionPlanContent as ActionPlanContentPage } from '@karehero/llama';

// hooks
import { useActionPlan } from 'hooks/useActionPlan';

// models
import { SanityActionPlan } from '@karehero/models';

// store
import { useDispatch } from 'react-redux';
import { newToast } from 'store/slices/toastSlice';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

/**
 * ActionPlanContent shows the content library.
 */
const ActionPlanContent = () => {
  // state
  const [actionPlanItemSanity, setActionPlanItemSanity] = useState<
    Partial<SanityActionPlan>
  >({});
  const [isTracked, setIsTracked] = useState(false);

  // hooks
  const { isIdentified, track } = useMixpanel();
  const {
    allActionPlanItems: actionPlanItems,
    actionPlanCategories,
    markActionComplete,
    markActionFeedback,
    getActionPlanSanity,
  } = useActionPlan();
  const { actionPlanItemId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // memos
  const actionPlanItem = useMemo(() => {
    return actionPlanItems?.find((item) => item.id === actionPlanItemId);
  }, [actionPlanItems, actionPlanItemId]);

  // methods
  const handleFeedback = useCallback(
    (rating: number) => {
      (async () => {
        track(MixpanelEvent.ActionPlanItemCSAT, { actionPlanItem, rating });

        if (!actionPlanItem) return;
        const res = await markActionFeedback(actionPlanItem.id);
        if ('error' in res) {
          console.error(res.error);
          return;
        }
        dispatch(
          newToast({
            title: 'Feedback submitted',
            description: 'Thank you for your feedback',
            variant: 'success',
          }),
        );
      })();
    },
    [track, actionPlanItem, markActionFeedback, dispatch],
  );

  // effects
  useEffect(() => {
    (async () => {
      if (!actionPlanItem || !actionPlanItem.sanitySlug) return;
      const res = await getActionPlanSanity(actionPlanItem?.sanitySlug);
      if (!('data' in res) || !res.data) return;
      setActionPlanItemSanity(res.data);
    })();
  }, [
    getActionPlanSanity,
    actionPlanItemId,
    actionPlanItem,
    actionPlanItem?.sanitySlug,
  ]);

  useEffect(() => {
    if (!actionPlanItem || !track || isTracked || !isIdentified) return;
    setIsTracked((prev) => {
      if (prev) return prev;
      track(MixpanelEvent.ActionPlanItemViewed, { actionPlanItem });
      return true;
    });
  }, [actionPlanItem, track, isTracked, isIdentified]);

  return (
    <ActionPlanContentPage
      actionPlanItem={actionPlanItem}
      categories={actionPlanCategories}
      headerImageUrl={actionPlanItemSanity.headerImageUrl}
      sanityContent={actionPlanItemSanity.content}
      isHideBack={searchParams.get('isHideBack') === 'true'}
      onComplete={markActionComplete}
      onCSAT={handleFeedback}
    />
  );
};

export default ActionPlanContent;
