// react
import styled from 'styled-components';

/**
 * IFrame is the dashboard page.
 */
const IFrame = ({ src }: IFrameProps) => {
  // hooks
  return (
    <Wrapper>
      <StyledIFrame title='figma example' src={src} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% + 121px);
  overflow: hidden;
  margin-top: -60px;
`;

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
`;

interface IFrameProps {
  src: string;
}

export default IFrame;
