import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
// components
import { Card, Container, Icon } from "../../atoms";
import { BackButton } from "../../molecules";
/**
 * CannotBookCall renders the failure screen when a non-primary care circle member
 * tries to book a call.
 */
export const CannotBookCall = ({ careCircle }) => {
    // hooks
    const navigate = useNavigate();
    const { firstName = '', lastName = '' } = (careCircle === null || careCircle === void 0 ? void 0 : careCircle.primaryCaregiverAccount) || {};
    const caregiverName = `${firstName} ${lastName}`.trim();
    return (_jsxs(MainWrapper, { children: [_jsx(BackButton, {}), _jsx(Container, Object.assign({ size: 'md' }, { children: _jsxs(Wrapper, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { icon: 'circle-xmark', fontSize: 48, type: 'fas' }) }), _jsx(SubTitle, { children: "Unable to book call" }), _jsx(Card, Object.assign({ color: 'blue', elevation: 0, isBordered: false, isPaddingSm: true }, { children: _jsx(CardContent, { children: _jsxs(Content, { children: ["Please contact ", caregiverName, " to book a call with your care experts."] }) }) }))] }) }))] }));
};
const MainWrapper = styled.div `
  margin-bottom: ${(props) => props.theme.spacing[56]};
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
const IconWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99999px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  color: ${(props) => props.theme.color.danger[40]};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const CardContent = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Content = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  font: ${(props) => props.theme.typography.body.md.medium};
`;
CannotBookCall.displayName = 'CannotBookCall';
export default CannotBookCall;
