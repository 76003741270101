import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Chip } from "../../atoms";
/**
 * ChipGroup is a component that displays a group of chips.
 */
export const ChipGroup = ({ className, chips }) => {
    if (!chips)
        return null;
    return (_jsx(Wrapper, Object.assign({ className: className }, { children: chips === null || chips === void 0 ? void 0 : chips.map((chip, i) => (_jsx(Link, Object.assign({ to: chip.to || '/' }, { children: _jsx(Chip, Object.assign({}, chip)) }), i))) })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing[8]};
  flex-wrap: wrap;
`;
ChipGroup.displayName = 'ChipGroup';
export default ChipGroup;
