var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// radix
import * as RadixSelect from '@radix-ui/react-select';
// components
import { BaseField } from "../BaseField/BaseField";
import { Icon } from "../Icon/Icon";
/**
 * Select allows the user to select a value from a list of options.
 */
export const Select = (_a) => {
    var { className, id, label, description, tooltip, value, onChange, options, placeholder = 'Select', ariaLabel, isDisabled = false, isRequired = false, isHideTitle = false } = _a, props = __rest(_a, ["className", "id", "label", "description", "tooltip", "value", "onChange", "options", "placeholder", "ariaLabel", "isDisabled", "isRequired", "isHideTitle"]);
    // state
    const [isOpen, setIsOpen] = useState(false);
    // refs
    const contentRef = useRef(null);
    useEffect(() => {
        const node = contentRef.current;
        if (!node)
            return;
        node.addEventListener('touchend', (e) => e.preventDefault());
        return () => {
            node.removeEventListener('touchend', (e) => e.preventDefault());
        };
    }, [contentRef.current, open]);
    return (_jsx(BaseField, Object.assign({ id: id, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsxs(RadixSelect.Root, Object.assign({ open: isOpen, onOpenChange: setIsOpen, disabled: isDisabled, value: value, onValueChange: onChange }, { children: [_jsxs(StyledSelectTrigger, Object.assign({ "aria-label": ariaLabel, onPointerDown: (e) => e.preventDefault(), onClick: () => setIsOpen((prev) => !prev) }, { children: [_jsx(StyledSelectValue, { children: _jsx(RadixSelect.Value, { placeholder: placeholder }) }), _jsx(StyledSelectIcon, { children: _jsx(Icon, { icon: isOpen ? 'chevron-up' : 'chevron-down', fontSize: 16 }) })] })), _jsx(RadixSelect.Portal, { children: _jsx(StyledSelectContent, Object.assign({ position: 'popper', ref: contentRef }, { children: _jsx(Scroll, { children: _jsx(RadixSelect.Viewport, { children: options
                                    .sort((a, b) => (a.order || 0) - (b.order || 0))
                                    .map((option) => (_jsxs(StyledSelectItem, Object.assign({ value: option.value }, { children: [_jsx(StyledSelectItemText, { children: option.label }), _jsx(StyledSelectItemIndicator, { children: _jsx(Icon, { icon: 'check', fontSize: 16 }) })] }), option.value))) }) }) })) })] })) })));
};
const StyledSelectTrigger = styled(RadixSelect.Trigger) `
  // default styles
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  min-width: 100px;

  border-radius: 8px;
  width: 100%;
  font: ${(props) => props.theme.typography.body.md.regular};
  background: ${(props) => props.theme.color.neutral[10]};
  color: ${(props) => props.theme.color.neutral[90]};
  border: 1px solid ${(props) => props.theme.color.neutral[60]};
  padding: ${(props) => props.theme.spacing[12]};
  cursor: pointer;

  min-height: 48px;
  max-height: 48px;

  &:hover {
    background: ${(props) => props.theme.color.neutral[20]};
  }

  &:focus {
    outline: 3px solid ${(props) => props.theme.color.primary[20]};
  }

  &:disabled {
    background: ${(props) => props.theme.color.neutral[30]};
    color: ${(props) => props.theme.color.neutral[50]};
    pointer-events: none;
  }
`;
const StyledSelectValue = styled.div `
  // default styles
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;
const StyledSelectIcon = styled(RadixSelect.Icon) `
  // default styles
  display: flex;
  align-items: center;
`;
const StyledSelectContent = styled(RadixSelect.Content) `
  width: var(--radix-select-trigger-width);

  z-index: 5000001;
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[3]};
  border-radius: 8px;
  overflow: hidden;
  margin-top: ${(props) => props.theme.spacing[4]};
`;
const Scroll = styled.div `
  padding: ${(props) => props.theme.spacing[16]} 0;
  max-height: var(--radix-select-content-available-height);
  overflow: auto;
`;
const StyledSelectItem = styled(RadixSelect.Item) `
  // default styles
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};

  font: ${(props) => props.theme.typography.body.md.regular};
  padding: ${(props) => props.theme.spacing[12]};
  color: ${(props) => props.theme.color.neutral[90]};
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
    background: ${(props) => props.theme.color.neutral[30]};
  }
`;
const StyledSelectItemText = styled(RadixSelect.ItemText) `
  // default
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;
const StyledSelectItemIndicator = styled(RadixSelect.ItemIndicator) `
  // default styles
  display: flex;
  align-items: center;
`;
const ScrollUpButton = styled(RadixSelect.ScrollUpButton) `
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.color.neutral[90]};
`;
const ScrollDownButton = styled(RadixSelect.ScrollDownButton) `
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.color.neutral[90]};
`;
Select.componentName = 'Select';
export default Select;
