// react
import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// hooks
import { useOnboarding } from 'hooks';

// components
import { OnboardingFlow } from '@karehero/llama';

/**
 * OnboardingQuestions takes the user through the onboarding questions.
 */
const OnboardingQuestions = () => {
  // hooks
  const navigate = useNavigate();
  const setSearchParams = useSearchParams()[1];
  const {
    onboardingQuestionsConfig,
    updateIsOnboardingQuestionsCompleted,
    incompleteQuestionsConfig,
  } = useOnboarding();

  // methods
  // set search params
  const handleNext = useCallback(
    (configID: string, subConfigID: string) => {
      setSearchParams({ configID, subConfigID });
    },
    [setSearchParams],
  );

  // complete
  const handleComplete = useCallback(async () => {
    await updateIsOnboardingQuestionsCompleted();
    navigate('/book-call');
    window.location.reload();
  }, [navigate, updateIsOnboardingQuestionsCompleted]);

  // memos
  const config = useMemo(
    () => [...incompleteQuestionsConfig, ...onboardingQuestionsConfig],
    [onboardingQuestionsConfig, incompleteQuestionsConfig],
  );

  return (
    <OnboardingFlow
      config={config}
      onNext={handleNext}
      onComplete={handleComplete}
    />
  );
};

OnboardingQuestions.displayName = 'OnboardingQuestions';
export default OnboardingQuestions;
