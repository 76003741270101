import { useContext } from 'react';
import useMakeApiRequest from './useMakeApiRequest';
import { GlobalSettingsContext } from '../../context/GlobalSettingsContext';

export default function useMakeProfileRequest() {
  const { get } = useMakeApiRequest();
  const { apiAddress } = useContext(GlobalSettingsContext);

  const makeProfileRequest = (regulatoryId) => {
    const uri = `profiles/${regulatoryId}`;
    return get(`${apiAddress}/${uri}`);
  };
  return [makeProfileRequest];
}
