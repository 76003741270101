import api from '.';
import { AccountRole } from '@karehero/models';

export const accountRoleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllAccountRole: builder.query<AccountRole[], void>({
      query: () => '/account-role',
      providesTags: ['AccountRoles'],
    }),
    getAccountRole: builder.query<AccountRole, string>({
      query: (id) => `/account-role/${id}`,
      providesTags: ['AccountRoles'],
    }),
  }),
});

export const { useGetAllAccountRoleQuery, useGetAccountRoleQuery } =
  accountRoleApi;

export default accountRoleApi;
