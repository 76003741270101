// Given a subquestion and the answer of its parent, this checks whether the subquestion is relevant
export const isSubQuestionRelevant = (subQuestion, parentAnswer) => {
    let conditionValue = subQuestion.conditionValue;
    switch (subQuestion.conditionOperator) {
        case 'equal':
            return parentAnswer === conditionValue;
        case 'not-equal':
            return parentAnswer !== conditionValue;
        case 'contains':
            return parentAnswer.includes(conditionValue);
        case 'not-contains':
            return !parentAnswer.includes(conditionValue);
        case 'greater-than':
            return conditionValue === undefined || parentAnswer > conditionValue;
        case 'less-than':
            return conditionValue === undefined || parentAnswer < conditionValue;
        case 'greater-than-or-equal':
            return conditionValue === undefined || parentAnswer >= conditionValue;
        case 'less-than-or-equal':
            return conditionValue === undefined || parentAnswer <= conditionValue;
        default:
            return true;
    }
};
export const listAllSubQuestions = (questions) => {
    const subQuestions = questions.reduce((acc, question) => {
        return [...acc, ...(question.careProfileSubQuestions || [])];
    }, []);
    if (subQuestions.length === 0)
        return [...questions];
    return [...questions, ...listAllSubQuestions(subQuestions)];
};
export const listAllQuestionsOfSubCategory = (subCategory) => {
    var _a;
    return (_a = subCategory === null || subCategory === void 0 ? void 0 : subCategory.careProfileForms) === null || _a === void 0 ? void 0 : _a.reduce((acc, form) => [
        ...acc,
        ...listAllSubQuestions(form.careProfileQuestions),
    ], []);
};
export const questionArrayToMap = (questions) => {
    return questions.reduce((acc, question) => {
        return Object.assign(Object.assign({}, acc), { [question.id]: question });
    }, {});
};
