import PropTypes from 'prop-types';

import IconLabel from '../../../primitives/IconLabel';
import { icons } from '../../../primitives/Icon';
import { useThemeHook } from '../../../styling';
import Column from '../../../primitives/Column';
import Spacer from '../../../primitives/Spacer';
import Strong from '../../../primitives/typography/Strong';
import Paragraph from '../../../primitives/typography/Paragraph';

const covidMapLabel = {
  covidPpeAvailable: 'We have personal protective equipment (PPE) available',
  covidDistancedVisits: 'We are able to support socially distanced visits',
  covidControlTraining: 'Our staff are trained in infection control',
  covidNewResidentIsolation:
    'New residents are asked to self-isolate for their safety',
};

export function CovidItem({ covidResponseItem: [key, value], colour }) {
  const covidLabel = covidMapLabel[key];
  return (
    value && (
      <Spacer top>
        <IconLabel
          icon={icons.check}
          description={covidLabel}
          colour={colour}
        />
      </Spacer>
    )
  );
}

CovidItem.propTypes = {
  covidResponseItem: PropTypes.arrayOf(PropTypes.any).isRequired,
  colour: PropTypes.string.isRequired,
};

export default function CovidResponse({
  covidResponse,
  name,
  hasCovidResponse,
}) {
  const theme = useThemeHook();
  const { covidCustomFields, ...covidDefaultFields } = covidResponse;

  if (!hasCovidResponse) {
    return (
      <Column>
        <Spacer top />
        <Paragraph>
          Sorry, we do not have any information on response to COVID-19.
          <Strong> Please contact the home directly for more details.</Strong>
        </Paragraph>
      </Column>
    );
  }

  return (
    hasCovidResponse && (
      <Column flexWrap>
        {Object.entries(covidDefaultFields).map((item) => (
          <CovidItem
            key={item}
            covidResponseItem={item}
            colour={theme.colour.primary}
          />
        ))}

        {covidCustomFields.map(
          (customItem) =>
            customItem && (
              <Spacer key={customItem} top>
                <IconLabel
                  icon={icons.check}
                  description={customItem}
                  colour={theme.colour.primary}
                />
              </Spacer>
            ),
        )}

        <Spacer top />
        <Paragraph>
          <Strong>Looking for more details?</Strong> Contact {name} to find out
          more.
        </Paragraph>
      </Column>
    )
  );
}

CovidResponse.propTypes = {
  covidResponse: PropTypes.shape({
    covidPpeAvailable: PropTypes.bool,
    covidDistancedVisits: PropTypes.bool,
    covidControlTraining: PropTypes.bool,
    covidNewResidentIsolation: PropTypes.bool,
    covidCustomFields: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  name: PropTypes.string.isRequired,
  hasCovidResponse: PropTypes.bool.isRequired,
};
