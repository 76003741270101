// store
import { useSelector } from 'store';
import {
  getCurrentCareCircle,
  getCurrentCareCircleMember,
} from 'store/slices/careCircleSlice';

// models
import { CareCircle, CareCircleMember } from '@karehero/models';

export const useCareCircle = () => {
  // state
  const currentCareCircle: CareCircle = useSelector(getCurrentCareCircle);
  const currentCareCircleMember: CareCircleMember = useSelector(
    getCurrentCareCircleMember,
  );

  return {
    currentCareCircle,
    currentCareCircleMember,
  };
};
