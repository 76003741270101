import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const SearchResultsContext = createContext();

const reducer = (state, { type, payload }) =>
  ({
    setLoading: () => ({ ...state, loading: payload }),
    setResults: () => ({ ...state, results: payload }),
    setError: () => ({ ...state, error: payload, results: [] }),
    setLastSearchTerm: () => ({ ...state, lastSearchTerm: payload }),
    setLastSearchLocation: () => ({ ...state, lastSearchLocation: payload }),
    setLastSearchRegulator: () => ({ ...state, lastSearchRegulator: payload }),
    setLastSearchPageNumber: () => ({
      ...state,
      lastSearchPageNumber: payload,
    }),
    appendResults: () => ({
      ...state,
      results: [...state.results, ...payload],
    }),
    setLoadingMore: () => ({ ...state, loadingMore: payload }),
    setLastSearchCountryNotImplemented: () => ({
      ...state,
      lastSearchCountryNotImplemented: payload,
    }),
  })[type]();

export const SearchResultsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    error: false,
    results: [],
    lastSearchTerm: undefined,
    lastSearchLocation: undefined,
    lastSearchPageNumber: undefined,
    lastSearchRegulator: undefined,
    lastSearchCountryNotImplemented: false,
    loadingMore: false,
  });

  const byRegulatoryId = (id) => (item) => item.regulatoryId === id;

  const setLoading = () => dispatch({ type: 'setLoading', payload: true });
  const setNotLoading = () => dispatch({ type: 'setLoading', payload: false });
  const setResults = (res) => dispatch({ type: 'setResults', payload: res });
  const appendResults = (res) =>
    dispatch({ type: 'appendResults', payload: res });
  const setHasError = () => dispatch({ type: 'setError', payload: true });
  const setNoError = () => dispatch({ type: 'setError', payload: false });
  const setLastSearchTerm = (term) =>
    dispatch({ type: 'setLastSearchTerm', payload: term });
  const setLastSearchLocation = (location) =>
    dispatch({ type: 'setLastSearchLocation', payload: location });
  const setLastSearchRegulator = (regulator) =>
    dispatch({ type: 'setLastSearchRegulator', payload: regulator });
  const setLastSearchPageNumber = (pageNumber) =>
    dispatch({ type: 'setLastSearchPageNumber', payload: pageNumber });
  const getProviderByRegulatoryId = (regulatoryId) =>
    state.results.find(byRegulatoryId(regulatoryId));
  const setLoadingMore = () =>
    dispatch({ type: 'setLoadingMore', payload: true });
  const setNotLoadingMore = () =>
    dispatch({ type: 'setLoadingMore', payload: false });
  const setLastSearchCountryNotImplemented = () =>
    dispatch({ type: 'setLastSearchCountryNotImplemented', payload: true });
  const clearLastSearchCountryNotImplemented = () =>
    dispatch({ type: 'setLastSearchCountryNotImplemented', payload: false });

  const haveSearchResults =
    !!state.results.length && !state.loading && !state.error;
  const searchReturnedNoResults = !!state.lastSearchTerm && !haveSearchResults;
  const pristine = state.lastSearchTerm === undefined;

  const stateValue = {
    ...state,
    setLoading,
    setNotLoading,
    setResults,
    appendResults,
    setLastSearchTerm,
    setHasError,
    setNoError,
    getProviderByRegulatoryId,
    haveSearchResults,
    searchReturnedNoResults,
    pristine,
    setLastSearchLocation,
    setLastSearchRegulator,
    setLastSearchPageNumber,
    setLoadingMore,
    setNotLoadingMore,
    setLastSearchCountryNotImplemented,
    clearLastSearchCountryNotImplemented,
  };

  return (
    <SearchResultsContext.Provider value={stateValue}>
      {children}
    </SearchResultsContext.Provider>
  );
};

SearchResultsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
