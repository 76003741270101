import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// assets
// @transform-path ../../../../../../src/assets/logo.svg
import logo from "../../../../../../src/assets/logo.svg";
// @transform-path ../../../../../../src/assets/logo-k.svg
import logoSymbol from "../../../../../../src/assets/logo-k.svg";
// @transform-path ../../../../../../src/assets/logo-light.svg
import logoLight from "../../../../../../src/assets/logo-light.svg";
// @transform-path ../../../../../../src/assets/logo-k-light.svg
import logoSymbolLight from "../../../../../../src/assets/logo-k-light.svg";
/**
 * Logo displays the KareHero logo.
 */
export const Logo = ({ size = 'md', isLight = false, isSymbol = false, }) => {
    return (_jsx(StyledLogo, Object.assign({ size: size }, { children: isSymbol ? (_jsx("img", { src: isLight ? logoSymbolLight : logoSymbol, alt: 'logo' })) : (_jsx("img", { src: isLight ? logoLight : logo, alt: 'logo' })) })));
};
const StyledLogo = styled.div `
  & > img {
    object-fit: contain;
    ${(props) => {
    switch (props.size) {
        case 'sm':
            return `
            min-height: 16px;
            max-height: 16px;
          `;
        case 'md':
            return `
            min-height: 23px;
            max-height: 23px;
          `;
        case 'lg':
            return `
            min-height: 36px;
            max-height: 36px;
          `;
        case 'xl':
            return `
            min-height: 48px;
            max-height: 48px;
          `;
    }
}};
  }
`;
Logo.displayName = 'Logo';
export default Logo;
