import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { Avatar, Button } from "../../atoms";
/**
 * ProfilePreview is a preview of a user's profile.
 */
export const ProfilePreview = ({ imageSrc, fallback, name, role, tier, careCircle, relationship, email, kindeEmail, landlineNumber, mobileNumber, address, postcode, onClose, toViewFullProfile, onViewFullProfile, actions, isActionsDisabled = false, }) => {
    const labelItem = useCallback((label, value) => (_jsxs(LabelItem, { children: [_jsx(Label, { children: label }), _jsx(Value, { children: value })] })), []);
    return (_jsxs(StyledProfilePreview, { children: [_jsxs(Body, { children: [_jsx(Avatar, { src: imageSrc, fallback: fallback, size: 'lg' }), _jsxs(InformationContainer, { children: [_jsxs(Main, { children: [_jsx(Name, { children: name }), role && _jsx(Role, { children: role })] }), _jsxs(Section, { children: [tier && labelItem('Tier', tier), careCircle && labelItem('Care circle', careCircle), relationship && labelItem('Relationship', relationship), email && labelItem('Email', email), kindeEmail && labelItem('Kinde Email', kindeEmail), !kindeEmail && labelItem('Kinde Email', 'Not migrated to Kinde'), landlineNumber && labelItem('Landline number', landlineNumber), mobileNumber && labelItem('Mobile number', mobileNumber), address && labelItem('Address', address), postcode && labelItem('Postcode', postcode)] })] })] }), actions && actions.length > 0 && (_jsx(ActionGroup, { children: actions === null || actions === void 0 ? void 0 : actions.map((action) => (_jsx(Button, Object.assign({ ariaLabel: action.label, variant: 'primary', onPress: action.onPress }, { children: action.label })))) })), !isActionsDisabled && (_jsxs(ActionGroup, { children: [_jsx(Button, Object.assign({ ariaLabel: 'close', variant: 'secondary', onPress: onClose }, { children: "Close" })), _jsx(Button, Object.assign({ ariaLabel: 'view full profile', variant: 'primary', iconRight: 'long-arrow-right', to: toViewFullProfile, onPress: onViewFullProfile }, { children: "View full profile" }))] }))] }));
};
const StyledProfilePreview = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[20]};

  ${(props) => props.theme.breakpoint.sm(css `
      gap: ${props.theme.spacing[48]};
    `)}
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[20]};

  ${(props) => props.theme.breakpoint.sm(css `
      flex-direction: row;
      gap: ${props.theme.spacing[36]};
    `)}
`;
const InformationContainer = styled.div `
  display: flex;
  flex-direction: column;
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
  gap: ${(props) => props.theme.spacing[24]};
`;
const Main = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacing[20]};
  gap: ${(props) => props.theme.spacing[4]};
`;
const Name = styled.div `
  font: ${(props) => props.theme.typography.heading['5xl']};
  color: ${(props) => props.theme.color.primary[80]};
  white-space: nowrap;
`;
const Role = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[100]};
  white-space: nowrap;
`;
const Section = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[20]};
`;
const LabelItem = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]};
  border-spacing: 0;
`;
const Label = styled.div `
  font: ${(props) => props.theme.typography.body.md.bold};
  padding-right: ${(props) => props.theme.spacing[32]};
  color: ${(props) => props.theme.color.neutral[90]};
  white-space: nowrap;
`;
const Value = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  white-space: nowrap;
`;
const ActionGroup = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
ProfilePreview.displayName = 'ProfilePreview';
export default ProfilePreview;
