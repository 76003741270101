import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// hooks
import { useWindowSize } from "../../../hooks/useWindowSize";
/**
 * CardContainer is a container for multiple cards.
 */
export const CardContainer = ({ children, maxColumns = 2, size, isPreventHeightMatch = false, }) => {
    // state
    const currentWidth = useRef(0);
    // refs
    const styledCardContainerRef = useRef(null);
    // hooks
    const [width] = useWindowSize();
    // effects
    useEffect(() => {
        if (isPreventHeightMatch)
            return;
        if (currentWidth.current !== width && styledCardContainerRef.current) {
            const cards = styledCardContainerRef.current.children;
            for (let i = 0; i < cards.length; i++) {
                const card = cards[i];
                card.style.height = '';
            }
        }
        // ensure all cards are the same height
        if (styledCardContainerRef.current) {
            const cards = styledCardContainerRef.current.children;
            let maxHeight = 0;
            for (let i = 0; i < cards.length; i++) {
                const card = cards[i];
                maxHeight = Math.max(maxHeight, card.offsetHeight);
            }
            for (let i = 0; i < cards.length; i++) {
                const card = cards[i];
                card.style.height = `${maxHeight}px`;
            }
        }
    }, [isPreventHeightMatch, width, children]);
    return (_jsx(StyledCardContainer, Object.assign({ ref: styledCardContainerRef, "$maxColumns": maxColumns, size: size }, { children: children })));
};
const StyledCardContainer = styled.div `
  width: 100%;
  display: grid;
  grid-gap: ${(props) => {
    switch (props.size) {
        case 'xs':
            return `${props.theme.spacing[4]} ${props.theme.spacing[8]}`;
        case 'sm':
            return `${props.theme.spacing[8]} ${props.theme.spacing[12]}`;
        case 'md':
            return `${props.theme.spacing[16]} ${props.theme.spacing[12]}`;
        default:
            return `${props.theme.spacing[24]} ${props.theme.spacing[32]}`;
    }
}};

  ${(props) => {
    let gridTemplateAreas = ``;
    for (let i = 1; i <= props.$maxColumns; i++) {
        gridTemplateAreas += `${i} `;
    }
    return css `
      grid-template-areas: '${gridTemplateAreas}';
      grid-template-columns: repeat(${props.$maxColumns}, 1fr);
    `;
}}

  & > * {
    width: 100%;
  }
`;
CardContainer.displayName = 'CardContainer';
export default CardContainer;
