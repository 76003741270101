import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// radix
import * as RadixSlider from '@radix-ui/react-slider';
// components
import { BaseField } from "..";
/**
 * Slider allows the user to select a value from a range.
 */
export const Slider = ({ id, label, description, tooltip, isRequired, min = 0, max = 100, step = 1, isDisabled = false, isSegmented = false, isHideTitle = false, segmentLabels, value, onChange, }) => {
    // memos
    const segments = useMemo(() => {
        if (!isSegmented)
            return undefined;
        const segments = [];
        for (let i = min; i <= max; i += step) {
            const left = ((i - min) / (max - min)) * 100;
            segments.push(_jsx(Segment, { left: left, isOn: !!value && value >= i * step }, i));
        }
        return segments;
    }, [isSegmented, min, max, step, value]);
    const segmentLabelComponents = useMemo(() => {
        if (!segmentLabels)
            return undefined;
        const segments = [];
        let counter = 0;
        for (let i = min; i <= max; i += step) {
            const left = ((i - min) / (max - min)) * 100;
            segments.push(_jsx(SegmentLabel, Object.assign({ left: left, isStrong: value === i * step }, { children: segmentLabels[counter] }), i));
            counter++;
        }
        return segments;
    }, [min, max, step, value, segmentLabels]);
    return (_jsx(BaseField, Object.assign({ id: id, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsxs(StyledSliderRoot, Object.assign({ value: value ? [value] : [0], onValueChange: (val) => onChange === null || onChange === void 0 ? void 0 : onChange(val[0]), min: min, max: max, step: step, disabled: isDisabled, isLabelled: !!segmentLabels, "aria-label": 'Volume' }, { children: [_jsx(StyledSliderTrack, Object.assign({ "$isDisabled": isDisabled }, { children: _jsx(StyledSliderRange, { "$isDisabled": isDisabled }) })), _jsxs(SegmentWrapper, { children: [segments, segmentLabelComponents] }), _jsx(StyledSliderThumb, Object.assign({ "$isDisabled": isDisabled }, { children: _jsx(StyledSliderThumbInner, { "$isDisabled": isDisabled }) })), !segments && _jsx(Value, { children: value })] })) })));
};
const StyledSliderRoot = styled(RadixSlider.Root) `
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  height: 44px;
  ${(props) => props.isLabelled &&
    css `
      margin-top: ${props.theme.spacing[48]};
    `}
`;
const StyledSliderTrack = styled(RadixSlider.Track) `
  background: ${(props) => props.theme.color.neutral[40]};
  margin: 0 22px;
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 10px;

  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[40]};
    `}
`;
const StyledSliderRange = styled(RadixSlider.Range) `
  position: absolute;
  background: ${(props) => props.theme.color.primary[40]};
  border-radius: 9999px;
  height: 100%;

  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[50]};
    `}
`;
const StyledSliderThumb = styled(RadixSlider.Thumb) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[1]};
  border-radius: 9999px;

  &:hover {
    outline: 4px solid ${(props) => props.theme.color.primary[20]};
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.color.primary[50]};
    outline: 4px solid ${(props) => props.theme.color.primary[20]};
  }

  ${(props) => props.$isDisabled &&
    css `
      pointer-events: none;
    `}
`;
const StyledSliderThumbInner = styled.div `
  min-width: 8px;
  min-height: 8px;
  max-width: 8px;
  max-height: 8px;
  background: ${(props) => props.theme.color.primary[40]};
  border-radius: 9999px;
  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[50]};
    `}
`;
const SegmentWrapper = styled.div `
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  height: 100%;
  margin: 0 22px;
  box-sizing: border-box;
`;
const Segment = styled.div `
  position: absolute;
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  border-radius: 99999px;
  left: ${(props) => props.left}%;
  transform: translateX(-50%);
  background: ${(props) => props.isOn ? props.theme.color.primary[40] : props.theme.color.neutral[40]};
  };
`;
const SegmentLabel = styled.div `
  position: absolute;
  font: ${(props) => props.theme.typography.body.sm[props.isStrong ? 'semibold' : 'regular']};
  color: ${(props) => props.theme.color.neutral[80]};
  left: ${(props) => props.left}%;
  top: -28px;
  transform: translateX(-50%);
`;
const Value = styled.div `
  position: absolute;
  top: -${(props) => props.theme.spacing[2]};
  right: 0;
  transform: translateY(-100%);
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: right;
`;
Slider.displayName = 'Slider';
export default Slider;
