import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
// components
import { Label, Select } from "../../atoms";
import { BaseField } from "../../atoms/BaseField/BaseField";
/**
 * DateOfBirth is an input field for a user to input their date of birth.
 */
export const DateOfBirth = ({ className, id, label, description, tooltip, isRequired, isHideTitle, value, onChange, isDisabled, }) => {
    // state
    const [dayValue, setDayValue] = useState();
    const [monthValue, setMonthValue] = useState();
    const [yearValue, setYearValue] = useState();
    // memo
    const dayOptions = useMemo(() => {
        let numDays = 31;
        if (monthValue) {
            const month = parseInt(monthValue);
            if (month === 1) {
                if (yearValue && parseInt(yearValue) % 4 === 0) {
                    numDays = 29;
                }
                else {
                    numDays = 28;
                }
            }
            else if ([3, 5, 8, 10].includes(month)) {
                numDays = 30;
            }
        }
        const options = [];
        for (let i = 1; i <= numDays; i++) {
            options.push({
                label: i.toString(),
                value: i.toString(),
            });
        }
        return options;
    }, [monthValue, yearValue]);
    const monthOptions = useMemo(() => {
        const options = [];
        for (let i = 0; i < 12; i++) {
            options.push({
                label: new Date(0, i).toLocaleString('default', { month: 'long' }),
                value: i.toString(),
            });
        }
        return options;
    }, []);
    const yearOptions = useMemo(() => {
        const options = [];
        for (let i = 1900; i <= new Date().getFullYear(); i++) {
            options.push({
                label: i.toString(),
                value: i.toString(),
            });
        }
        return options.reverse();
    }, []);
    const dateValue = useMemo(() => {
        if (!value)
            return;
        const date = new Date(value);
        setDayValue(date.getDate().toString());
        setMonthValue(date.getMonth().toString());
        setYearValue(date.getFullYear().toString());
        return {
            day: date.getDate().toString(),
            month: date.getMonth().toString(),
            year: date.getFullYear().toString(),
        };
    }, [value]);
    const date = useMemo(() => {
        if (!dayValue || !monthValue || !yearValue)
            return null;
        const date = new Date(parseInt(yearValue), parseInt(monthValue), parseInt(dayValue));
        return date;
    }, [yearValue, monthValue, dayValue]);
    // effects
    useEffect(() => {
        if (!date)
            return;
        if (date.toISOString() === value)
            return;
        onChange === null || onChange === void 0 ? void 0 : onChange(date.toISOString());
    }, [date, onChange]);
    return (_jsx(BaseField, Object.assign({ id: id, label: label, description: description, isHideTitle: isHideTitle, tooltip: tooltip, isRequired: isRequired }, { children: _jsxs(Wrapper, { children: [_jsx(Label, { text: 'Day', id: 'day' }), _jsx(Select, { ariaLabel: 'day', id: 'day', options: dayOptions, value: dateValue === null || dateValue === void 0 ? void 0 : dateValue.day, onChange: setDayValue, isDisabled: isDisabled }), _jsx(Label, { text: 'Month', id: 'month' }), _jsx(Select, { ariaLabel: 'month', id: 'month', options: monthOptions, value: dateValue === null || dateValue === void 0 ? void 0 : dateValue.month, onChange: setMonthValue, isDisabled: isDisabled }), _jsx(Label, { text: 'Year', id: 'year' }), _jsx(Select, { ariaLabel: 'year', id: 'year', options: yearOptions, value: dateValue === null || dateValue === void 0 ? void 0 : dateValue.year, onChange: setYearValue, isDisabled: isDisabled })] }) })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};

  & > * {
    flex: 1;
  }
`;
DateOfBirth.displayName = 'DateOfBirth';
export default DateOfBirth;
