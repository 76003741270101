// react
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// components
import { Calendly as BookCallPage } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';
import { useGenerateCalendlyLinkMutation } from 'store/api/calendly';

const DEFAULT_CALENDLY_URL =
  'https://calendly.com/care-concierge-kh/care-concierge-initial-call';

/**
 * BookCall shows the BookCallPage component.
 */
const BookCall = () => {
  // hooks
  const { track } = useMixpanel();
  const location = useLocation();
  const account = useSelector(getAccount);

  const [generateCalendlyLink, { isLoading, data: calendlyLinkResponse }] =
    useGenerateCalendlyLinkMutation();

  // effects
  useEffect(() => {
    track(MixpanelEvent.BookCallViewed, {});
  }, [track]);

  useEffect(() => {
    generateCalendlyLink();
  }, [generateCalendlyLink]);

  return (
    <BookCallPage
      calendlyCallDetails={{
        ...location.state,
        name: account.firstName || '',
        kindeEmail: account.kindeEmail,
      }}
      isLoading={isLoading}
      baseURL={calendlyLinkResponse?.meetingUrl || DEFAULT_CALENDLY_URL}
    />
  );
};

export default BookCall;
