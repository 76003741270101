var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Icon } from "../Icon/Icon";
// react-calendar
import ReactCalendar from 'react-calendar';
/**
 * Calendar is a date picker component, provided by react-calendar.
 *
 * Please see: https://github.com/wojtekmaj/react-calendar for more information.
 */
export const Calendar = (_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(Wrapper, Object.assign({ className: className }, { children: _jsx(ReactCalendar, Object.assign({}, props, { prevLabel: _jsx(StyledIcon, { icon: 'chevron-left', type: 'fas' }), nextLabel: _jsx(StyledIcon, { icon: 'chevron-right', type: 'fas' }), tileContent: ({ date, view }) => {
                switch (view) {
                    case 'month':
                        return _jsx(Tile, { children: date.getDate() });
                    default:
                        return null;
                }
            } })) })));
};
const Tile = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: ${(props) => props.theme.color.custom.calendarCell};
  text-align: center;
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[100]};
  border-radius: 99999px;
  transition-duration: 0.1s;
`;
const Wrapper = styled.div `
  max-width: 600px;
  background: ${(props) => props.theme.color.neutral[10]};

  // navigation
  .react-calendar__navigation {
    display: flex;
    gap: ${(props) => props.theme.spacing[8]};

    .react-calendar__navigation__label {
      all: unset;
      order: 1;
      font: ${(props) => props.theme.typography.body.sm.semibold};
      color: ${(props) => props.theme.color.neutral[90]};
    }

    .react-calendar__navigation__prev2-button {
      display: none;
      order: 2;
    }

    .react-calendar__navigation__prev-button {
      all: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      order: 3;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .react-calendar__navigation__next-button {
      all: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      order: 4;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .react-calendar__navigation__next2-button {
      display: none;
      order: 5;
    }
  }

  // view container
  .react-calendar__viewContainer {
    display: flex;
    flex-direction: column;
  }

  // tile container
  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    justify-items: center;
  }

  // tile
  button.react-calendar__tile {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40px;
    cursor: pointer;
    padding: ${(props) => props.theme.spacing[8]} 0;
  }

  // tile content
  button.react-calendar__tile abbr {
    display: none;
  }

  // tile hover
  button.react-calendar__tile:hover ${Tile} {
    background: ${(props) => props.theme.color.primary[30]};
    color: ${(props) => props.theme.color.neutral[10]};
  }

  // weekdays
  .react-calendar__month-view__weekdays {
    padding-top: ${(props) => props.theme.spacing[16]};
    padding-bottom: ${(props) => props.theme.spacing[8]};
  }

  .react-calendar__month-view__weekdays__weekday {
    color: ${(props) => props.theme.color.neutral[90]};
    font: ${(props) => props.theme.typography.body.xs.regular};
    text-align: center;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
  }
`;
const StyledIcon = styled(Icon) `
  color: ${(props) => props.theme.color.neutral[90]};
`;
Calendar.displayName = 'Calendar';
export default Calendar;
