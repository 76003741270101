import { useContext } from 'react';
import styled from 'styled-components';

import SearchResultsList from './SearchResultsList';
import SearchControlsSidebar from './SearchControlsSidebar';
import SearchButton from './SearchButton';

import {
  MobileMenuClose,
  MobileMenuToggle,
} from '../../components/features/MobileMenuToggles';
import PositionFixed from '../../components/primitives/layout/PositionFixed';
import TwoColumnWithDrawer from '../../components/primitives/layout/TwoColumnWithDrawer';
import Column from '../../components/primitives/Column';
import Row from '../../components/primitives/Row';

import { SearchResultsContext } from '../../context/SearchResultsContext';
import { CareTypeContext } from '../../context/CareTypeContext';

const MobileControls = styled.div`
  position: fixed;
  top: 73px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

export default function SearchResultsWithControls() {
  const { pristine, error } = useContext(SearchResultsContext);
  const { careType } = useContext(CareTypeContext);

  const disableFilters = error;

  const renderDrawerToggle = (onToggle) => (
    <PositionFixed top={22} right={8}>
      <MobileMenuToggle onClick={onToggle} />
    </PositionFixed>
  );
  const renderDrawerContent = (onToggle) => (
    <MobileControls>
      <Column>
        <Row reverse>
          <MobileMenuClose onClick={onToggle} />
        </Row>
        <SearchControlsSidebar
          searchError={disableFilters}
          currentCareType={careType}
        />
        <SearchButton
          text='Update search'
          onSearchClick={onToggle}
          disabled={disableFilters}
        />
      </Column>
    </MobileControls>
  );

  const sidebar = (
    <Column>
      <SearchControlsSidebar
        searchOnFilterChange
        searchError={disableFilters}
        currentCareType={careType}
      />
    </Column>
  );

  return (
    pristine || (
      <TwoColumnWithDrawer
        body={<SearchResultsList />}
        sidebar={sidebar}
        bodyPosition='right'
        sidebarPercent={26}
        renderDrawerToggle={renderDrawerToggle}
        renderDrawerContent={renderDrawerContent}
      />
    )
  );
}
