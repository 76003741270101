import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// editor
import { Container, Title } from "../../atoms";
import { Editor } from "../../organisms";
// config
import { carePlanResidentialFields as fields } from "./config";
/**
 * CarePlanResidential shows an editor for the care at home plan.
 */
export const CarePlanResidential = ({ className, value, onChange, onSave, onCancel, }) => {
    return (_jsx(Container, Object.assign({ size: 'md' }, { children: _jsxs(Wrapper, { children: [_jsx(Title, { children: "Care at home plan" }), _jsx(Editor, { fields: fields, value: value, onChange: onChange, onSave: onSave, onCancel: onCancel, isWithArrows: true })] }) })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacing[64]};
`;
CarePlanResidential.displayName = 'CarePlanResidential';
export default CarePlanResidential;
