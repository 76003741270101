import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// components
import { ArticleCard, Button, Title } from "../../atoms";
import { CardContainer } from "../../templates";
// hooks/
import { useWindowSize } from "../../../hooks";
// theme
import theme from "../../../theme";
/**
 * ArticleFiltered shows a featured article and a list of articles.
 */
export const ArticleBookmarks = ({ articles, isLoading, onBookmark, toNavigateBack, }) => {
    // hooks/
    const [width] = useWindowSize();
    // memos
    const maxColumns = useMemo(() => {
        if (width < theme.breakpoint.mdValue)
            return 1;
        if (width < theme.breakpoint.lgValue)
            return 2;
        if (width < theme.breakpoint.xlValue)
            return 3;
        return 4;
    }, [width]);
    return (_jsxs(Wrapper, { children: [_jsx("div", { children: _jsx(Button, Object.assign({ ariaLabel: 'Back', variant: 'secondary', iconLeft: 'chevron-left', to: toNavigateBack, isInline: true }, { children: "Back" })) }), _jsx(Title, Object.assign({ isNoMargin: true }, { children: "My Bookmarks" })), (articles === null || articles === void 0 ? void 0 : articles.length) === 0 && _jsx(NoData, { children: "No bookmarks yet" }), _jsx(CardContainer, Object.assign({ maxColumns: maxColumns }, { children: articles === null || articles === void 0 ? void 0 : articles.map((article, i) => (_jsx(ArticleCard, { article: article, onBookmark: onBookmark, isLoading: isLoading, isBookmarked: true }, i))) }))] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.theme.spacing[32]};
`;
const NoData = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: ${(props) => props.theme.color.neutral[80]};
  background: ${(props) => props.theme.color.neutral[30]};
  border-radius: 8px;
  font: ${(props) => props.theme.typography.body.md.regular};
`;
ArticleBookmarks.displayName = 'ArticleBookmarks';
export default ArticleBookmarks;
