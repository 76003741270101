import { jsx as _jsx } from "react/jsx-runtime";
import { InviteMembersForm } from "..";
const isValid = (value) => {
    return /\S+@\S+\.\S+/.test(value || '');
};
/**
 * InviteMembersByEmailForm is a component that allows members to be invited by email input field or
 * a CSV file of emails.
 */
export const InviteMembersByEmailForm = ({ organization, onSubmit, }) => {
    // does this need memo?
    return (_jsx(InviteMembersForm, { organization: organization, onSubmit: onSubmit, isValid: isValid, inputType: 'email', inviteKeyName: 'email' }));
};
InviteMembersByEmailForm.displayName = 'InviteMembersByEmailForm';
export default InviteMembersByEmailForm;
