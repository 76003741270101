import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback } from 'react';
import styled from 'styled-components';
// components
import { Icon } from "..";
/**
 * CopyText is a component that displays text that can be copied.
 */
export const CopyText = ({ className, value, onCopy }) => {
    // methods
    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(value || '');
        onCopy === null || onCopy === void 0 ? void 0 : onCopy();
    }, []);
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsx(CopyValue, { children: value }), _jsx(IconButton, Object.assign({ onClick: handleCopy }, { children: _jsx(Icon, { icon: 'copy' }) }))] })));
};
const Wrapper = styled.div `
  display: flex;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[8]};
  border: 1px solid ${(props) => props.theme.color.neutral[50]};
  background: ${(props) => props.theme.color.neutral[20]};
  color: ${(props) => props.theme.color.neutral[90]};
  border-radius: 4px;
`;
const CopyValue = styled.div `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${(props) => props.theme.spacing[8]};
  padding-right: 0;
`;
const IconButton = styled.button `
  all: unset;
  cursor: pointer;
  padding: ${(props) => props.theme.spacing[8]};
  padding-left: 0;
`;
CopyText.displayName = 'CopyText';
export default CopyText;
