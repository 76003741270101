// editor
import { createFieldHelper, FieldType } from "../../organisms";
export const carePlanResidentialFields = createFieldHelper({
    residentialTypeOfCare: {
        label: 'Type of care',
        field: {
            type: FieldType.MultiSelectBox,
            options: [
                {
                    value: 'type-of-care-residential',
                    label: 'Residential',
                },
                {
                    value: 'type-of-care-residential-dementia',
                    label: 'Residential dementia',
                },
                {
                    value: 'type-of-care-nursing',
                    label: 'Nursing',
                },
                {
                    value: 'type-of-care-nursing-dementia',
                    label: 'Nursing dementia',
                },
            ],
        },
    },
    postcode: {
        label: 'Postcode',
        field: {
            type: FieldType.Text,
        },
    },
    startDate: {
        label: 'Date care would start',
        field: {
            type: FieldType.DateOfBirth,
        },
    },
    specialRequirements: {
        label: 'Special requirements',
        field: {
            type: FieldType.MultiSelect,
            options: [
                {
                    value: 'preference-at-home-a-garden',
                    label: 'A garden',
                },
                {
                    value: 'preference-at-home-accessibility',
                    label: 'Accessibility features (ramps, handrails)',
                },
                {
                    value: 'preference-at-home-24hr-care',
                    label: '24-hour care availability',
                },
                {
                    value: 'preference-at-home-regular-medication',
                    label: 'Regular medication management',
                },
                {
                    value: 'preference-at-home-medical-equipment',
                    label: 'Medical equipment and supplies',
                },
                {
                    value: 'preference-at-home-nutritious-meals',
                    label: 'Nutritious meals and dietary support',
                },
                {
                    value: 'preference-at-home-transportation',
                    label: 'Transportation services for appointments',
                },
                {
                    value: 'preference-at-home-physical-therapy',
                    label: 'Physical therapy or exercise programs',
                },
                {
                    value: 'preference-at-home-social-activities',
                    label: 'Social activities and companionship',
                },
                {
                    value: 'preference-at-home-pet-friendly',
                    label: 'Pet-friendly environment',
                },
                {
                    value: 'preference-at-home-privacy',
                    label: 'Privacy and personal space',
                },
                {
                    value: 'preference-at-home-cleanliness',
                    label: 'Regular cleaning and home maintenance',
                },
                {
                    value: 'preference-at-home-emergency-response',
                    label: 'Emergency response systems',
                },
                {
                    value: 'preference-at-home-counseling-services',
                    label: 'Counseling or mental health support',
                },
                {
                    value: 'preference-at-home-friendly-caregivers',
                    label: 'Friendly and well-trained caregivers',
                },
                {
                    value: 'preference-at-home-personal-hygiene',
                    label: 'Personal hygiene and grooming assistance',
                },
                {
                    value: 'preference-at-home-communication-tools',
                    label: 'Communication tools (e.g., phone, internet access)',
                },
                {
                    value: 'preference-at-home-peaceful-environment',
                    label: 'Peaceful and quiet environment',
                },
                {
                    value: 'preference-at-home-security',
                    label: 'Security measures (e.g., locks, alarms)',
                },
                {
                    value: 'preference-at-home-religious-accommodations',
                    label: 'Accommodations for religious or spiritual practices',
                },
                {
                    value: 'preference-at-home-cultural-sensitivity',
                    label: 'Cultural sensitivity and accommodations',
                },
            ],
        },
    },
});
