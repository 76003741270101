// Replica of the enums in api/store/seed.go
export var MemberRole;
(function (MemberRole) {
    MemberRole["PrimaryCaregiver"] = "Primary Caregiver";
    MemberRole["CareRecipient"] = "Care Recipient";
    MemberRole["AccountHolder"] = "Account Holder";
    MemberRole["LegalRepresentative"] = "Legal Representative";
    MemberRole["CareCircleMember"] = "Care Circle Member";
    MemberRole["Employer"] = "Employer";
    MemberRole["KareHeroExpert"] = "KareHero Expert";
    MemberRole["KareHeroTechnician"] = "KareHero Technician";
    MemberRole["ProfessionalCaregiver"] = "Professional Caregiver";
})(MemberRole || (MemberRole = {}));
