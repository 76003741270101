// components
import { Help as HelpPage } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';

/**
 * Help shows the care plan at home editor.
 */
const Help = () => {
  // hooks
  const { careCircle } = useCareProfile();

  return <HelpPage careCircleId={careCircle?.id} />;
};

export default Help;
