// react
import { useMemo } from 'react';

export const useApp = () => {
  const normalizedUserAgent = useMemo(
    () => navigator.userAgent.toLowerCase(),
    [],
  );

  const isApp = useMemo(
    () =>
      // @ts-ignore
      window.navigator.standalone ||
      window.matchMedia('(display-mode: standalone)').matches,
    [],
  );

  const isApple = useMemo(
    () =>
      /ip(ad|hone|od)/.test(normalizedUserAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1),
    [normalizedUserAgent],
  );

  const isAndroid = useMemo(
    () => /android/.test(normalizedUserAgent),
    [normalizedUserAgent],
  );

  const isMobile = useMemo(() => isApple || isAndroid, [isApple, isAndroid]);

  const isMobileBrowser = useMemo(() => isMobile && !isApp, [isMobile, isApp]);

  const isDesktop = useMemo(() => !isMobile, [isMobile]);

  return {
    isApple,
    isAndroid,
    isApp,
    isMobile,
    isMobileBrowser,
    isDesktop,
  };
};
