import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Button, Card, Dialog, Icon } from "../../atoms";
export const ActionPlanCard = ({ id, icon, title, color = 'blue', subTitle, isComplete, to, onDismiss, isModify, }) => {
    // state
    const [isDismissDialogOpen, setIsDismissDialogOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(ContentItem, Object.assign({ to: to, color: color }, { children: _jsxs(StyledCard, { children: [_jsx(CardHeader, Object.assign({ color: color }, { children: icon && _jsx(Icon, { icon: icon, fontSize: 18 }) })), _jsxs(CardBody, { children: [_jsx(BodyTitle, { children: title }), _jsx(BodyText, { children: subTitle })] }), (isComplete || onDismiss) && (_jsx(CardFooter, { children: isModify && (_jsx(IconWrapper, Object.assign({ "$isComplete": isComplete, onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    !isComplete && setIsDismissDialogOpen(true);
                                } }, { children: _jsx(Icon, { fontSize: 16, type: isComplete ? 'fal' : 'fal', icon: isComplete ? 'check' : 'times' }) }))) }))] }) })), _jsx(Dialog, Object.assign({ isOpen: isDismissDialogOpen, setIsOpen: setIsDismissDialogOpen, isCloseButton: false, title: 'Are you sure you want to dismiss this action?', description: 'Dismissed actions can still be viewed in the all actions section.' }, { children: _jsx(ActionButtonsWrapper, { children: _jsxs(ActionButtons, { children: [_jsx(Button, Object.assign({ ariaLabel: 'dismiss action', variant: 'primary', onPress: () => {
                                    onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss(id);
                                    setIsDismissDialogOpen(false);
                                } }, { children: "Dismiss action" })), _jsx(Button, Object.assign({ ariaLabel: 'cancel', variant: 'secondary', onPress: () => setIsDismissDialogOpen(false) }, { children: "Cancel" }))] }) }) }))] }));
};
const ContentItem = styled(Link) `
  color: ${(props) => { var _a; return (_a = props.theme.color.actionPlanCards[props.color]) === null || _a === void 0 ? void 0 : _a.primary; }};
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;
const StyledCard = styled(Card) `
  gap: 0;
  border: 0;
  padding: 0;
  height: 100%;
  display: flex;
  margin: ${(props) => props.theme.spacing[2]};
  background: none;
`;
const CardHeader = styled.div `
  width: 17%;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0 0 8px;
  background: ${(props) => { var _a; return (_a = props.theme.color.actionPlanCards[props.color]) === null || _a === void 0 ? void 0 : _a.secondary; }};
  color: ${(props) => { var _a; return (_a = props.theme.color.actionPlanCards[props.color]) === null || _a === void 0 ? void 0 : _a.primary; }};
  margin-right: ${(props) => props.theme.spacing[16]};
`;
const CardBody = styled.div `
  width: 70%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[8]};
  padding: ${(props) => props.theme.spacing[12]} 0;
`;
const BodyTitle = styled.div `
  color: ${(props) => props.theme.color.neutral[100]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const BodyText = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const CardFooter = styled.div `
  width: 13%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 ${(props) => props.theme.spacing[16]};
`;
const IconWrapper = styled.button `
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99999px;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  width: 26px;
  min-width: 26px;
  max-width: 26px;
  color: ${(props) => props.$isComplete
    ? props.theme.color.neutral[10]
    : props.theme.color.neutral[70]};

  background: ${(props) => props.$isComplete && props.theme.color.success[50]};

  transition: background 0.2s;
  ${(props) => !props.$isComplete &&
    css `
      cursor: pointer;
      &:hover {
        background: ${props.theme.color.neutral[30]};
      }
    `}
`;
const ActionButtonsWrapper = styled.div `
  display: flex;
  justify-content: center;
`;
const ActionButtons = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  max-width: 300px;
  width: 100%;
`;
ActionPlanCard.displayName = 'ActionPlanCard';
export default ActionPlanCard;
