import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { BaseField } from "../BaseField/BaseField";
/**
 * ReadOnlyField allows you to add a read only field to your form.
 */
export const ReadOnlyField = ({ id, label, description, value, isRequired = false, isHideTitle = false, }) => {
    return (_jsx(BaseField, Object.assign({ id: id, label: label, description: description, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsx(StyledReadOnly, { children: value }) })));
};
const StyledReadOnly = styled.div `
  display: flex;
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  padding: ${(props) => props.theme.spacing[12]} 0;
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[50]};
`;
ReadOnlyField.displayName = 'ReadOnlyField';
export default ReadOnlyField;
