import { styled } from '../styling';

const Spacer = styled.div`
  margin: 0;
  ${({ theme, top, bottom, left, right, doubleTop }) => `
    ${top && `margin-top: ${theme.spacing.units(4)}`};
    ${doubleTop && `margin-top: ${theme.spacing.units(8)}`};
    margin-bottom: ${bottom ? theme.spacing.units(4) : 'initial'};
    margin-left: ${left ? theme.spacing.units(4) : 'initial'};
    margin-right: ${right ? theme.spacing.units(4) : 'initial'};
  `};
`;

export default Spacer;
