import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// components
import { Card, Title } from "../../atoms";
import { createFieldHelper, FieldType, } from "../EditorFields/fieldHelper";
import { EditorFields } from "../EditorFields/EditorFields";
// theme
import { breakpointValues } from "../../../theme/breakpoint";
// hooks
import { useValidator, useWindowSize } from "../../../hooks";
const nameFields = createFieldHelper({
    firstName: {
        label: 'First Name',
        placeholder: 'Alan',
        isRequired: true,
        field: {
            type: FieldType.Text,
        },
        validationRules: [
            {
                type: 'name',
            },
            {
                type: 'min-length',
                value: 2,
            },
        ],
    },
    lastName: {
        label: 'Last Name',
        placeholder: 'Bloggs',
        isRequired: true,
        field: {
            type: FieldType.Text,
        },
        validationRules: [
            {
                type: 'name',
            },
            {
                type: 'min-length',
                value: 2,
            },
        ],
    },
});
const contactFields = createFieldHelper({
    email: {
        label: 'Email',
        description: 'Use your @sjpp.co.uk email',
        placeholder: 'alan@sjpp.co.uk',
        isRequired: true,
        field: {
            type: FieldType.Email,
        },
        validationRules: [
            {
                type: 'email',
            },
        ],
    },
    phoneNumber: {
        label: 'Phone Number',
        placeholder: '07123456789',
        isRequired: true,
        field: {
            type: FieldType.Text,
        },
        validationRules: [
            {
                type: 'phone',
            },
        ],
    },
});
const otherPartnerDetails = createFieldHelper({
    partnerCode: {
        label: 'What is your SJP partner code?',
        placeholder: '123456A',
        isRequired: true,
        field: {
            type: FieldType.Text,
        },
        validationRules: [
            {
                type: 'sjp-partner-code',
            },
        ],
    },
    ltcLicense: {
        label: 'Refer LTC License',
        isRequired: true,
        field: {
            type: FieldType.Select,
            options: [
                {
                    value: 'No licence or CF Only',
                    label: 'No Licence or CF Only',
                },
                {
                    value: 'SJP Diploma in LTC',
                    label: 'SJP Diploma in LTC',
                },
                {
                    value: 'SOLLA',
                    label: 'SOLLA',
                },
            ],
        },
    },
});
/**
 * SjpPartnerForm renders the account settings page.
 */
export const SjpPartnerForm = ({ value, setValue, setIsValid, }) => {
    // hooks
    const [width] = useWindowSize();
    const { createSetIsValid } = useValidator(setIsValid);
    // memos
    const isMobile = useMemo(() => width < breakpointValues.mdValue, [width]);
    return (_jsxs(Wrapper, { children: [_jsx(Title, Object.assign({ isNoMargin: true }, { children: "Tell us about yourself" })), _jsx(Card, Object.assign({ color: 'red', isBordered: false, isPaddingSm: true }, { children: _jsxs(FieldWrapper, { children: [_jsx(EditorFields, { fields: nameFields, value: value, onChange: (f) => setValue(Object.assign(Object.assign({}, value), f)), isRow: !isMobile, setIsValid: createSetIsValid('nameFields') }), _jsx(EditorFields, { fields: contactFields, value: value, onChange: (f) => setValue(Object.assign(Object.assign({}, value), f)), isRow: !isMobile, setIsValid: createSetIsValid('contactFields') }), _jsx(EditorFields, { fields: otherPartnerDetails, value: value, onChange: (f) => setValue(Object.assign(Object.assign({}, value), f)), isRow: !isMobile, setIsValid: createSetIsValid('otherPartnerDetails') })] }) }))] }));
};
// styles
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.theme.spacing[16]};
`;
const FieldWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
SjpPartnerForm.displayName = 'SjpPartnerForm';
export default SjpPartnerForm;
