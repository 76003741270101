import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// components
import { Button, Container, ProgressBar, Title } from "../../atoms";
/**
 * CareProfileHeader shows the current progress of the care plan.
 */
export const CareProfileHeader = ({ className, limit, progress, toBack, title, onPrintPdf, name, onOpenSections, }) => {
    const headerTitle = useMemo(() => {
        return title || `${name || 'Your Loved One'}'s Personalised Care Plan`;
    }, [name, title]);
    return (_jsx(Wrapper, Object.assign({ className: className }, { children: _jsx(Container, Object.assign({ size: 'md' }, { children: _jsxs(Body, { children: [_jsxs(BackRow, { children: [_jsx(Button, Object.assign({ ariaLabel: 'back', variant: 'secondary', iconLeft: 'chevron-left', to: toBack, size: 'sm' }, { children: "Back" })), onPrintPdf && (_jsx(PrintWrapper, { children: _jsx(Button, Object.assign({ ariaLabel: 'print care plan', variant: 'link', onPress: onPrintPdf, iconLeft: 'print', size: 'md' }, { children: "Print Care Plan" })) })), _jsx(Button, Object.assign({ ariaLabel: 'sections', variant: 'primary', iconLeft: 'list', onPress: onOpenSections, size: 'sm' }, { children: "Sections" }))] }), _jsxs(Content, { children: [_jsx(StyledTitle, { children: headerTitle }), _jsx(StyledProgressBar, { label: 'Progress', limit: limit || 1, progress: progress })] })] }) })) })));
};
const Wrapper = styled.div `
  position: relative;
  background: ${(props) => props.theme.color.neutral[10]};
  padding: ${(props) => props.theme.spacing[24]} 0;
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[30]};
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
const BackRow = styled.div `
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[12]};
`;
const StyledProgressBar = styled(ProgressBar) `
  width: 100%;
`;
const PrintWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: ${(props) => props.theme.spacing[16]};
`;
const StyledTitle = styled(Title) `
  margin: 0;
  text-align: center;
  font: ${(props) => props.theme.typography.heading['3xl']};
  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.heading['4xl']};
    `)}
`;
CareProfileHeader.displayName = 'CareProfileHeader';
export default CareProfileHeader;
