import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Container, Input, ProgressBar, RadioGroup } from "../../atoms";
import { OnboardingActionType, toGenericOnboarding, } from "../../pages";
// theme
import { css } from 'styled-components';
/**
 * OnboardingDirectory renders the carousel onboarding views.
 */
export const OnboardingDirectory = toGenericOnboarding(({ config, currentIndex = 0, handleClick, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return (_jsx(StyledContainer, Object.assign({ size: 'md' }, { children: _jsxs(Wrapper, { children: [_jsx(ProgressBar, { limit: config.length, progress: currentIndex + 1 }), _jsxs(Body, { children: [_jsx(Title, { children: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.title }), _jsx(Text, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.text }), _jsx("form", Object.assign({ onSubmit: (e) => {
                                e.preventDefault();
                                handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.Next);
                            } }, { children: ((_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.type) === 'input' && (_jsx(Input, { label: 'Location', value: (_d = config[currentIndex]) === null || _d === void 0 ? void 0 : _d.value, onChange: (_e = config[currentIndex]) === null || _e === void 0 ? void 0 : _e.onChange })) })), ((_f = config[currentIndex]) === null || _f === void 0 ? void 0 : _f.type) === 'radio' && (_jsx(RadioGroup, { value: (_g = config[currentIndex]) === null || _g === void 0 ? void 0 : _g.value, onChange: (_h = config[currentIndex]) === null || _h === void 0 ? void 0 : _h.onChange, options: [
                                { label: 'Residential', value: 'residential' },
                                { label: 'Nursing', value: 'nursing' },
                                { label: 'Pallative', value: 'pallative' },
                                { label: 'Dementia', value: 'dementia' },
                                { label: 'Not sure', value: 'not-sure' },
                            ] }))] })] }) })));
});
const StyledContainer = styled(Container) `
  height: auto;
  flex: 1;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${(props) => props.theme.spacing[64]};
  gap: ${(props) => props.theme.spacing[24]};

  ${(props) => props.theme.breakpoint.md(css `
      align-items: center;
      gap: ${props.theme.spacing[64]};
    `)}
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
  width: 100%;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.primary[80]};
  width: 100%;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.heading['6xl']};
    `)}
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.body.xl.regular};
    `)}
`;
OnboardingDirectory.displayName = 'OnboardingDirectory';
export default toGenericOnboarding(OnboardingDirectory);
