// react
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// store
import { useUpdateMemberMutation } from 'store/api/careCircle';
import { useGenerateActionPlanMutation } from 'store/api/actionPlan';
import { useGetAllRelationshipQuery } from 'store/api/relationship';
import { useGetAllPersonalizationQuery } from 'store/api/personalization';
import {
  useUpdateCareCircleNamesMutation,
  useUpdateIsOnboardedMutation,
  useUpdateIsOnboardingQuestionsCompletedMutation,
} from 'store/api/iam';

// models
import { CareCircleMember } from '@karehero/models';

// helpers
import { FieldType } from '@karehero/llama/src/components/organisms/EditorFields/fieldHelper';

// hooks
import { useCareCircle, useCareProfileQuestions } from 'hooks';

// components
import {
  OnboardingActionType,
  OnboardingActivatedStart,
  OnboardingCarousel,
  OnboardingEditor,
  OnboardingLoading,
  OnboardingLoadingDone,
  OnboardingTOS,
} from '@karehero/llama';

// config
import {
  onboardingActivatedStartConfig,
  onboardingLoadingConfig,
  onboardingLoadingDoneConfig,
  onboardingMemberCarouselConfig,
} from 'config/onboarding';

const validateNotEmpty = (value?: string) =>
  ((value && value.length) ||
    (typeof value === 'boolean' && value !== undefined) ||
    0) > 0;

export const useOnboarding = () => {
  // hooks
  const [updateCareCircleNames] = useUpdateCareCircleNamesMutation();
  const [updateMember] = useUpdateMemberMutation();
  const [generateActionPlan] = useGenerateActionPlanMutation();
  const { data: relationshipOptions } = useGetAllRelationshipQuery();
  const { data: personalizationOptions } = useGetAllPersonalizationQuery();
  const { currentCareCircleMember } = useCareCircle();
  const [updateIsOnboarded] = useUpdateIsOnboardedMutation();
  const [updateIsOnboardingQuestionsCompleted] =
    useUpdateIsOnboardingQuestionsCompletedMutation();
  const navigate = useNavigate();

  // states
  const [caregiverFirstName, setCaregiverFirstName] = useState<{
    value: string;
  }>();
  const [careRecipientFirstName, setCareRecipientFirstName] = useState<{
    value: string;
  }>();
  const [relationship, setRelationship] = useState<{ value: string }>({
    value: '',
  });
  const [personalization, setPersonalization] = useState<{ value: string }>({
    value: '[]',
  });

  // memos
  const careProfileQuestionsOptions = useMemo(
    () => ({
      questionIDs: [
        'care-recipient-is-over-65',
        'care-recipient-medical-conditions',
        'care-recipient-location',
        'care-recipient-fund-type',
        'care-recipient-receive-attendance-allowance',
        'care-recipient-power-of-attorney',
      ],
      isSkipAnswers: true,
      skipSubQuestionIds: ['care-recipient-power-of-attorney'],
    }),
    [],
  );

  const {
    careCircleId,
    createManyAnswer,
    questions,
    answers,
    setAnswers,
    answersRequest,
  } = useCareProfileQuestions(careProfileQuestionsOptions);

  // methods
  // update care circle names
  const handleUpdateCareCircleNames =
    useCallback(async (): Promise<boolean> => {
      const res = await updateCareCircleNames({
        caregiverFirstName: caregiverFirstName?.value || '',
        careRecipientFirstName: careRecipientFirstName?.value || '',
      });
      if (!('data' in res)) {
        console.error('Failed to update account', res.error);
        return false;
      }

      return true;
    }, [caregiverFirstName, careRecipientFirstName, updateCareCircleNames]);

  // personalization
  const handleUpdatePersonalization =
    useCallback(async (): Promise<boolean> => {
      const res = await updateMember({
        ...(currentCareCircleMember as CareCircleMember),
        careCircleId: careCircleId || '',
        personalization: JSON.parse(personalization.value).map((x: any) => ({
          id: x,
        })),
      });
      if (!('data' in res)) {
        console.error('Failed to update personalization', res.error);
        return false;
      }

      return true;
    }, [personalization, currentCareCircleMember, careCircleId, updateMember]);

  const handleUpdateCareProfile = useCallback(async (): Promise<boolean> => {
    const res = await createManyAnswer(answersRequest);
    if (!('data' in res)) {
      console.error('Failed to update care profile', res.error);
      return false;
    }

    return true;
  }, [answersRequest, createManyAnswer]);

  // generate action plan
  const handleGenerateActionPlan = useCallback(async () => {
    const res = await generateActionPlan();
    if (!('data' in res)) {
      console.error('Failed to generate action plan', res.error);
      return false;
    }

    return true;
  }, [generateActionPlan]);

  const handleUpdateRelationship = useCallback(async (): Promise<boolean> => {
    const res = await updateMember({
      ...(currentCareCircleMember as CareCircleMember),
      careCircleId: careCircleId || '',
      careRecipientAccountRelationshipId: relationship.value,
    });
    if (!('data' in res)) {
      console.error('Failed to update relationship', res.error);
      return false;
    }

    return true;
  }, [relationship, currentCareCircleMember, updateMember, careCircleId]);

  // memos
  const introConfig = useMemo(
    () => [
      {
        id: 'carousel',
        Component: OnboardingCarousel,
        config: onboardingMemberCarouselConfig,
      },
      {
        id: 'tos',
        Component: OnboardingTOS,
        config: [
          {
            id: '1',
            isIgnoreProgress: true,
            actions: [
              {
                label: 'Decline',
                onClick: OnboardingActionType.Back,
              },
              {
                label: 'I accept',
                onClick: OnboardingActionType.Next,
              },
            ],
          },
        ],
      },
      {
        id: 'care-giver-first-name',
        Component: OnboardingEditor,
        onBeforeNext: handleUpdateCareCircleNames,
        isProgressBar: true,
        config: [
          {
            id: '1',
            title: "What's your name?",
            subTitle: 'This is so we know what to call you in your account.',
            value: caregiverFirstName,
            onChange: setCaregiverFirstName,
            fieldDef: {
              label: 'Your First Name',
              field: {
                type: FieldType.Text,
              },
              validation: validateNotEmpty,
            },
          },
        ],
      },
      {
        id: 'what-can-we-help-you-with',
        Component: OnboardingEditor,
        onBeforeNext: handleUpdatePersonalization,
        isProgressBar: true,
        config: [
          {
            id: '1',
            title: 'What can we help you with?',
            subTitle:
              'Select as many as you need. Don’t worry if you’re not sure, we’ll help you work out next steps.',
            value: personalization,
            onChange: setPersonalization,
            fieldDef: {
              field: {
                type: FieldType.MultiSelectBox,
                options: personalizationOptions?.map((x) => ({
                  value: x.id,
                  label: x.label,
                })),
                size: 'sm',
                maxColumns: 1,
                isCustom: true,
              },
              validation: validateNotEmpty,
            },
          },
        ],
      },
      {
        id: 'activated-start',
        Component: OnboardingActivatedStart,
        config: [
          {
            ...onboardingActivatedStartConfig[0],
            onSkip: async () => {
              await updateIsOnboarded();
              navigate('/');
              window.location.reload();
            },
          },
        ],
      },
    ],
    [
      handleUpdatePersonalization,
      setCaregiverFirstName,
      caregiverFirstName,
      handleUpdateCareCircleNames,
      updateIsOnboarded,
      navigate,
      personalization,
      personalizationOptions,
    ],
  );

  const incompleteQuestionsConfig = useMemo(
    () => [
      {
        id: 'activated-start',
        Component: OnboardingActivatedStart,
        config: [
          {
            id: '1',
            title: 'Personalise Your Journey',
            text: [
              'Before scheduling a call with a care expert, we need to get to know you better.',
              'We’ll ask you a few questions about the loved one you want support for.',
              'Your answers will give you a bespoke action plan that solves your needs.',
            ],
            ctaButtonLabel: 'Personalise Now',
            actions: null,
            isIgnoreProgress: true,
          },
        ],
      },
    ],
    [],
  );

  const onboardingQuestionsConfig = useMemo(
    () => [
      {
        id: 'who-are-you-caring-for',
        Component: OnboardingEditor,
        onBeforeNext: handleUpdateRelationship,
        isProgressBar: true,
        config: [
          {
            id: '1',
            title: 'Who are you caring for?',
            value: relationship,
            onChange: setRelationship,
            fieldDef: {
              label:
                'The person you are caring for or thinking about caring for in the future',
              field: {
                type: FieldType.Select,
                options: relationshipOptions?.map((x) => ({
                  value: x.id,
                  label: x.name,
                })),
              },
              validation: validateNotEmpty,
            },
          },
        ],
      },
      {
        id: 'care-recipient-first-name',
        Component: OnboardingEditor,
        onBeforeNext: handleUpdateCareCircleNames,
        isProgressBar: true,
        config: [
          {
            id: '1',
            title: `What is your ${
              relationshipOptions?.find((r) => r.id === relationship.value)
                ?.name || 'loved one'
            }'s name?`,
            subTitle: 'This is so we know how to refer to them.',
            value: careRecipientFirstName,
            onChange: setCareRecipientFirstName,
            fieldDef: {
              label: 'Their first name',
              field: {
                type: FieldType.Text,
              },
              validation: validateNotEmpty,
            },
          },
        ],
      },
      {
        id: 'care-profile-questions',
        Component: OnboardingEditor,
        onBeforeNext: async () => {
          let res = await handleUpdateCareProfile();
          if (!res) {
            console.error('Failed to update care profile');
            return false;
          }

          res = await handleGenerateActionPlan();
          if (!res) {
            console.error('Failed to generate action plan');
            return false;
          }
          return true;
        },
        isProgressBar: true,
        config: [
          ...Object.keys(questions.fields)
            .filter(
              (questionId) =>
                questionId !== 'care-recipient-types-of-power-of-attorney',
            )
            .map((questionId) => ({
              id: questionId,
              title: questions.fields[questionId].label,
              subTitle: questions.fields[questionId].supportText,
              tooltip: questions.fields[questionId].tooltip,
              value: { value: answers[questionId] },
              onChange: (value: any) => {
                setAnswers({
                  ...answers,
                  [questionId]: value.value,
                });
              },
              fieldDef: {
                ...questions.fields[questionId],
                isHideTitle: true,
                validation: ['care-recipient-medical-conditions'].includes(
                  questionId,
                )
                  ? () => true
                  : validateNotEmpty,
                isRequired: false,
              },
            })),
        ],
      },
    ],
    [
      careRecipientFirstName,
      relationship,
      relationshipOptions,
      handleUpdateCareCircleNames,
      handleUpdateRelationship,
      handleUpdateCareProfile,
      handleGenerateActionPlan,
      answers,
      questions,
      setAnswers,
    ],
  );

  const loadingConfig = useMemo(
    () => [
      {
        id: 'loading',
        Component: OnboardingLoading,
        config: onboardingLoadingConfig,
        onStart: (handleClick: (action: OnboardingActionType) => void) =>
          setTimeout(
            () => handleClick(OnboardingActionType.Next),
            2000 + Math.random() * 1000,
          ),
      },
      {
        id: 'loading-done',
        Component: OnboardingLoadingDone,
        config: onboardingLoadingDoneConfig,
      },
    ],
    [],
  );

  return {
    introConfig,
    onboardingQuestionsConfig,
    incompleteQuestionsConfig,
    loadingConfig,
    updateIsOnboarded,
    updateIsOnboardingQuestionsCompleted,
  };
};
