import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { BaseField } from "../BaseField/BaseField";
/**
 * Date allows the user to select a date.
 */
export const Date = ({ id, label, description, tooltip, isRequired, isHideTitle, value, onChange, validate = () => true, }) => {
    // state
    const [isError, setIsError] = useState(false);
    // effects
    useEffect(() => {
        setIsError(!validate(value));
    }, [value, validate]);
    return (_jsx(BaseField, Object.assign({ id: id, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsx(StyledDate, { type: 'date', value: value, onChange: (e) => onChange(e.target.value), "$isError": isError }) })));
};
const StyledDate = styled.input `
  all: unset;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.neutral[90]};
  background: ${(props) => props.theme.color.neutral[10]};
  padding: ${(props) => props.theme.spacing[12]};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.color.neutral[60]};
  min-height: 48px;
  max-height: 48px;

  // error styles
  ${(props) => props.$isError &&
    css `
      border: 1px solid ${props.theme.color.danger[40]};
      background: ${props.theme.color.neutral[30]};
      &:focus-within {
        border: 1px solid ${props.theme.color.danger[40]};
      }
    `}
`;
Date.displayName = 'Date';
export default Date;
