// react
import { useEffect, useMemo, useState } from 'react';

// components
import { validationStringToMethod } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { useGetCareCircleQuery } from 'store/api/careCircle';
import { useGetCarePlanQuery } from 'store/api/carePlan';
import {
  useCreateManyAnswerMutation,
  useGetAllAnswerQuery,
  useLazyGetQuestionsQuery,
} from 'store/api/careProfile';
import { getCurrentCareCircleId } from 'store/slices/careCircleSlice';

// models
import {
  CareCircleMember,
  CareProfileAnswer,
  CareProfileQuestion,
} from '@karehero/models';

interface CareProfileQuestionsOptions {
  questionIDs?: string[];
  isSkipAnswers?: boolean;
  skipSubQuestionIds?: string[];
}

export const useCareProfileQuestions = ({
  questionIDs,
  isSkipAnswers,
  skipSubQuestionIds,
}: CareProfileQuestionsOptions) => {
  // state
  const [answers, setAnswers] = useState<any>({});
  const [careProfileQuestions, setCareProfileQuestions] = useState<
    CareProfileQuestion[]
  >([]);
  const [careCircleMember, setCareCircleMember] = useState<CareCircleMember>();
  const [answersRequest, setAnswersRequest] = useState<CareProfileAnswer[]>([]);

  // hooks
  const careCircleId = useSelector(getCurrentCareCircleId);
  const { data: careCircle } = useGetCareCircleQuery(careCircleId, {
    skip: !careCircleId,
  });
  const [getQuestions] = useLazyGetQuestionsQuery();
  const [createManyAnswer] = useCreateManyAnswerMutation();

  const { data: careProfileAnswersObj } = useGetAllAnswerQuery(
    careCircle?.careProfile?.id || '',
    { skip: !careCircle?.careProfile?.id },
  );

  const careProfileAnswers = useMemo(
    () => careProfileAnswersObj && Object.values(careProfileAnswersObj),
    [careProfileAnswersObj],
  );

  const { data: carePlan } = useGetCarePlanQuery(careCircle?.id || '', {
    pollingInterval: 10 * 60 * 1000,
    skip: !careCircle?.id,
  });

  // memos
  const skipSubQuestions: { [b: string]: boolean } = useMemo(
    () =>
      (skipSubQuestionIds || []).reduce(
        (acc, id) => ({ ...acc, [id]: true }),
        {},
      ),
    [skipSubQuestionIds],
  );

  const questions = useMemo(() => {
    let fields: any = {};
    const assignField = (question: CareProfileQuestion) => {
      let table = {};
      // @ts-ignore
      if (question.fieldTable) {
        // @ts-ignore
        const splitTable = question.fieldTable?.split(';');
        const title = splitTable[0];
        const columns = splitTable[1].split(',');
        const rows = splitTable[2].split(',');
        table = {
          title: title,
          columns: columns,
          rows: rows,
        };
      }

      fields[question.id] = {
        label: question.label.replace(
          '{careRecipient}',
          careCircle?.careRecipientAccount?.firstName || 'your loved one',
        ),
        supportText: question?.supportText?.replace(
          '{careRecipient}',
          careCircle?.careRecipientAccount?.firstName || 'your loved one',
        ),
        isRequired: question.isRequired,
        placeholder: question.placeholder,
        tooltip: question.tooltip,
        order: question.order,
        validation: validationStringToMethod(question.validation as string),
        field: {
          type: question.fieldType,
          size: 'sm',
          options: question.fieldOptions
            ?.map((option) => ({
              value: option.id,
              label: option.label,
              order: option.order,
            }))
            .sort((a, b) => a.order - b.order),
          table: table,
          isCustom: question.isCustom,
        },
      };
    };

    careProfileQuestions?.forEach((question) => {
      assignField(question);

      const answerValue = answers?.[question.id];
      const createSubQuestions = (
        question: CareProfileQuestion,
        answerValue: any,
      ) => {
        if (answerValue !== undefined) {
          if (answerValue === true) answerValue = 'true';
          if (answerValue === false) answerValue = 'false';

          question.careProfileSubQuestions?.forEach((subQuestion) => {
            let conditionValue = subQuestion.conditionValue;

            switch (subQuestion.conditionOperator) {
              case 'equal':
                if (answerValue === conditionValue) break;
                return;
              case 'not-equal':
                if (answerValue !== conditionValue) break;
                return;
              case 'contains':
                if (answerValue.includes(conditionValue as string)) {
                  break;
                }
                return;
              case 'not-contains':
                if (answerValue.includes(conditionValue as string)) {
                  break;
                }
                return;
              case 'greater-than':
                if (conditionValue === undefined) break;
                if (answerValue > conditionValue) break;
                return;
              case 'less-than':
                if (conditionValue === undefined) break;
                if (answerValue < conditionValue) break;
                return;
              case 'greater-than-or-equal':
                if (conditionValue === undefined) break;
                if (answerValue >= conditionValue) break;
                return;
              case 'less-than-or-equal':
                if (conditionValue === undefined) break;
                if (answerValue <= conditionValue) break;
                return;
            }

            assignField(subQuestion);

            const subQuestionAnswer = answers?.[subQuestion.id];
            if (subQuestionAnswer !== undefined) {
              createSubQuestions(subQuestion, subQuestionAnswer);
            }
          });
        }
      };

      if (skipSubQuestions[question.id]) return;

      createSubQuestions(question, answerValue);
    });

    return {
      id: 'care-profile-questions',
      title: 'Questions',
      isComplete: careProfileQuestions?.every(
        (question) =>
          (answers?.[question.id] !== undefined ||
            question.isRequired === false) &&
          answers?.[question.id] !== '',
      ),
      fields: fields,
    };
  }, [careProfileQuestions, careCircle, answers, skipSubQuestions]);

  // effects
  useEffect(() => {
    const fetchCareProfileQuestions = async () => {
      if (!questionIDs) return;
      const res = await getQuestions(questionIDs);
      if (!res.data) return;
      let sortedQuestions = [...res.data];
      sortedQuestions.sort(
        (a, b) => questionIDs.indexOf(a.id) - questionIDs.indexOf(b.id),
      );
      setCareProfileQuestions(sortedQuestions);
    };
    fetchCareProfileQuestions();
  }, [getQuestions, questionIDs, careCircle]);

  useEffect(() => {
    if (!careCircle) return;
    const member = careCircle.careCircleMembers.find(
      (member) => member.account.id === careCircle?.primaryCaregiverAccount.id,
    );
    setCareCircleMember(member);
  }, [careCircle]);

  useEffect(() => {
    if (!careProfileAnswers) return;
    if (isSkipAnswers) return;

    const careAnswers: any = {};
    careProfileAnswers.forEach((answer) => {
      let forms = answer?.careProfileQuestion?.careProfileForms;
      if (!forms) {
        // @ts-ignore
        const parentId = answer?.careProfileQuestion?.parentId;
        const careProfileAnswersParent = careProfileAnswers.find(
          (answer) => answer.careProfileQuestionId === parentId,
        );
        if (!careProfileAnswersParent) return;
        forms = careProfileAnswersParent?.careProfileQuestion?.careProfileForms;
        if (!forms) return;
      }

      careAnswers[answer.careProfileQuestionId] = answer.value;
    });

    setAnswers?.(careAnswers);
  }, [careProfileAnswers, setAnswers, isSkipAnswers]);

  useEffect(() => {
    if (!careCircle?.careProfile?.id) return;

    const careProfileAnswers: CareProfileAnswer[] = Object.keys(answers).map(
      (questionId) => {
        const value = answers[questionId];
        return {
          careProfileId: careCircle?.careProfile?.id || '',
          careProfileQuestionId: questionId,
          value: value,
        };
      },
    );
    setAnswersRequest(careProfileAnswers);
  }, [answers, careCircle?.careProfile?.id]);

  return {
    questions,
    answers,
    setAnswers,
    answersRequest,
    createManyAnswer,
    careCircle,
    careCircleId,
    carePlan,
    careCircleMember,
  };
};
