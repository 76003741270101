import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { media, styled, styleHelpers } from '../styling';
import useKeyboardEventHandler from '../../hooks/useKeyboardEventHandler';
import Button from './Button';
import { Icon, icons } from './Icon';
import Row from './Row';

const { transparentize } = styleHelpers;

const ClickOutsideOverlay = styled.div`
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) =>
    transparentize(0.15, theme.colour.neutral.dark)};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  z-index: 1;
`;

const Container = styled.div`
  max-height: 90vh;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colour.neutral.dark};
  background-color: ${({ theme }) => theme.colour.neutral.light};
  border-radius: ${({ theme }) => theme.spacing.units(0.5)};
  margin: ${({ theme }) => theme.spacing.units(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  width: 90%;

  ${media('>tablet')} {
    max-width: 80%;
  }
`;

const ContentContainer = styled.div`
  overflow: hidden;
  width: 100%;
  max-height: 90vh;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.units(-5)};
  right: ${({ theme }) => theme.spacing.units(-5)};
`;

export default function Modal({
  open,
  onClickOutside,
  onCloseClick,
  children,
}) {
  const clickOutsideElement = useRef();
  const { onKeyboardEvent } = useKeyboardEventHandler({
    Escape: onClickOutside,
  });

  const focusClickOutsideElement = () => clickOutsideElement?.current?.focus();
  useEffect(focusClickOutsideElement, [focusClickOutsideElement]);

  useEffect(() => {
    const preventScroll = () => {
      document.documentElement.scrollTop = 0;
    };

    if (open) {
      window.onscroll = preventScroll;
    } else {
      window.onscroll = undefined;
    }
  }, [open]);

  return (
    open && (
      <ClickOutsideOverlay
        ref={clickOutsideElement}
        tabIndex={0}
        onClick={onClickOutside}
        onKeyDown={onKeyboardEvent}
      >
        <Container onClick={(e) => e.stopPropagation()}>
          <ContentContainer>{children}</ContentContainer>
          {onCloseClick && (
            <CloseButton round noChevron onClick={onCloseClick}>
              <Row justifyCentre fullWidth>
                <Icon icon={icons.close} />
              </Row>
            </CloseButton>
          )}
        </Container>
      </ClickOutsideOverlay>
    )
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  onClickOutside: PropTypes.func,
  onCloseClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  open: false,
  onClickOutside: () => {},
  onCloseClick: undefined,
};
