// react
import { createSlice } from '@reduxjs/toolkit';

export interface CmsState {}

export const cmsSlice = createSlice({
  name: 'cms',
  initialState: {} as CmsState,
  reducers: {},
});

export default cmsSlice.reducer;
