// react
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// components
import { BookingDetails, BookingDetailsState } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';

// store
import { dispatch } from 'store';
import { useInviteCareCircleMutation } from 'store/api/careCircle';
import { useGetAllRelationshipQuery } from 'store/api/relationship';

// models
import { CareCircleMemberInvite } from '@karehero/models';
import { newToast } from 'store/slices/toastSlice';

/**
 * BookCallDetails shows the BookingDetails component.
 */
const BookCallDetails = () => {
  // hooks
  const navigate = useNavigate();
  const { track } = useMixpanel();
  const { careCircle } = useCareProfile();
  const [inviteCareCircle] = useInviteCareCircleMutation();
  const { data: relationships } = useGetAllRelationshipQuery();

  // memos
  const relationshipOptions = useMemo(
    () =>
      relationships?.map((relationship) => ({
        value: relationship.id,
        label: relationship.name,
      })),
    [relationships],
  );

  // effects
  useEffect(() => {
    track(MixpanelEvent.BookCallViewed, {});
  }, [track]);

  // methods
  const handleOnSubmit = useCallback(
    (data: BookingDetailsState) => {
      navigate('/book-call', { state: data });
    },
    [navigate],
  );

  const handleInvite = useCallback(
    async (careCircleMemberInvite: CareCircleMemberInvite) => {
      try {
        const res = await inviteCareCircle(careCircleMemberInvite).unwrap();
        dispatch(newToast({ title: 'Email invite sent', variant: 'success' }));
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    [inviteCareCircle],
  );

  if (!careCircle) return null;
  return (
    <BookingDetails
      careCircle={careCircle}
      onSubmit={handleOnSubmit}
      onInvite={handleInvite}
      relationshipOptions={relationshipOptions || []}
    />
  );
};

export default BookCallDetails;
