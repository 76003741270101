import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * Icon displays an icon.
 */
export const Icon = ({ className, icon, size, fontSize, isFlip, type = 'fal', }) => {
    return (_jsx(StyledFontAwesomeIcon, { className: className, icon: [type, icon], flip: isFlip ? 'horizontal' : undefined, size: size, fontSize: fontSize }));
};
const StyledFontAwesomeIcon = styled(FontAwesomeIcon) `
  font-size: ${(props) => props.fontSize}px;
`;
Icon.displayName = 'Icon';
export default Icon;
