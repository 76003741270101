// react
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// hooks
import { useCareProfile, useCareProfileQuestions } from 'hooks';

// components
import {
  AttendanceAllowanceHero as AttendanceAllowanceHeroPage,
  CareProfileRoot,
  EligibilityCalculator,
} from '@karehero/llama';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

/**
 * AttendanceAllowanceStart is the dashboard page.
 */
const AttendanceAllowanceStart = () => {
  // hooks
  const { numCompleted, numTotal, careCircle, sections } = useCareProfile({
    categoryID: 'attendance-allowance',
  });
  const navigate = useNavigate();
  const { track } = useMixpanel();
  const careProfileQuestionsOptions = useMemo(
    () => ({
      questionIDs: [
        'care-recipient-location',
        'care-recipient-is-over-65',
        'care-recipient-health-condition',
        'care-recipient-previous-supervision',
        'care-recipient-terminally-ill',
        'care-recipient-help-needed',
      ],
    }),
    [],
  );
  const { createManyAnswer, questions, answers, setAnswers, answersRequest } =
    useCareProfileQuestions(careProfileQuestionsOptions);

  // callbacks
  const onEligibilityStatusChange = useCallback(
    (status: string) => {
      if (status !== 'incomplete') {
        track(MixpanelEvent.AttendanceAllowanceEligibilityResult, { status });
      }
    },
    [track],
  );

  const onEligibilitySubmit = useCallback(() => {
    track(MixpanelEvent.AttendanceAllowanceEligibilitySubmitted, {});
  }, [track]);

  const handleUpdateCareProfile = useCallback(async (): Promise<boolean> => {
    const res = await createManyAnswer(answersRequest);
    if (!('data' in res)) {
      console.error('Failed to update care profile', res.error);
      return false;
    }

    onEligibilitySubmit();

    return true;
  }, [answersRequest, onEligibilitySubmit, createManyAnswer]);

  return (
    <StyledCareProfileRoot
      nextLabel='Start form'
      limit={numTotal}
      progress={numCompleted}
      sections={sections}
      name={careCircle?.careRecipientAccount?.firstName}
      title={`${
        careCircle?.careRecipientAccount?.firstName || 'Your Loved One'
      }'s Attendance Allowance`}
      toExit='/care-profile?tab=3'
      onNext={() =>
        navigate(
          `/attendance-allowance/attendance-allowance-general-information`,
        )
      }
      toPrevious='/care-profile?tab=3'
    >
      <AttendanceAllowanceHeroPage
        careRecipientName={
          careCircle?.careRecipientAccount?.firstName || 'your loved one'
        }
        eligibilityCalculator={
          <WrapperEligibilityCalculator>
            <EligibilityCalculator
              questions={questions}
              answers={answers}
              setAnswers={setAnswers}
              onEligibilityStatusChange={onEligibilityStatusChange}
              onUpdateCareProfile={handleUpdateCareProfile}
            />
            <Banner>Eligibility calculator</Banner>
          </WrapperEligibilityCalculator>
        }
      />
    </StyledCareProfileRoot>
  );
};

const StyledCareProfileRoot = styled(CareProfileRoot)`
  overflow: hidden;
`;

const WrapperEligibilityCalculator = styled.div`
  position: relative;
  padding-top: ${(props) => props.theme.spacing[12]};
`;

const Banner = styled.div`
  background: ${(props) => props.theme.color.primary[80]};
  color: ${(props) => props.theme.color.neutral[10]};
  font: ${(props) => props.theme.typography.body.xxs.medium};
  padding: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[8]};
  position: absolute;
  top: 0;
  left: 3%;
  transform: translateX(-3%);
  border-radius: 0 0 6px 6px;
`;

export default AttendanceAllowanceStart;
