import { useContext } from 'react';
import PropTypes from 'prop-types';

import Column from '../../components/primitives/Column';
import Radio from '../../components/primitives/form/Radio';

import { ProviderFilterContext } from '../../context/ProviderFilterContext';

import Spacer from '../../components/primitives/Spacer';

export default function HomeCareDurationFilters({ disabled }) {
  const {
    homeCareVisitDurations,
    activeFilters: { enabledCareVisitDuration },
    setCurrentCareVisitDuration,
  } = useContext(ProviderFilterContext);

  return (
    <Column>
      {homeCareVisitDurations.map(({ label, value }, i) => (
        <Spacer top={i > 0} key={value}>
          <Radio
            name={label}
            label={label}
            value={value}
            checked={enabledCareVisitDuration === value}
            onChange={setCurrentCareVisitDuration}
            disabled={disabled}
          />
        </Spacer>
      ))}
    </Column>
  );
}

HomeCareDurationFilters.propTypes = {
  disabled: PropTypes.bool,
};

HomeCareDurationFilters.defaultProps = {
  disabled: false,
};
