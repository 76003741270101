import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { Keni } from "../../atoms";
import styled from 'styled-components';
/**
 * OfflineContent is a component that displays a OfflineContent.
 */
export const OfflineContent = ({}) => {
    return (_jsxs(Wrapper, { children: [_jsx(StyledKeni, { emotion: 'offline', direction: 'right' }), _jsx(Title, { children: "No Connection" }), _jsxs(SubTitle, { children: ["It looks like you\u2019re offline. Please check your internet connection", ' '] })] }));
};
const StyledKeni = styled(Keni) `
  max-width: 112px;
  margin-bottom: ${(props) => props.theme.spacing[20]};
`;
const Wrapper = styled.div `
  height: 100%;
  display: flex;
  max-height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[32]};
`;
const Title = styled.div `
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.heading['4xl']};
`;
const SubTitle = styled.div `
  text-align: center;
  margin-top: ${(props) => props.theme.spacing[32]};
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
OfflineContent.displayName = 'OfflineContent';
export default OfflineContent;
