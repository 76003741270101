import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useState } from 'react';
import styled from 'styled-components';
// components
import { Button, Container, Input, Label, RadioGroup, TextArea, ToggleSelect, } from "../../atoms";
import { BackButton, CareCircleMemberSelector } from "../../molecules";
export const BookingDetails = ({ relationshipOptions, careCircle, onSubmit, onInvite, }) => {
    var _a;
    // state
    const [eventDetails, setEventDetails] = useState({
        callType: 'google-meet',
    });
    // methods
    const handleSubmit = useCallback(() => {
        if (!onSubmit)
            return;
        onSubmit({
            callType: eventDetails.callType || '',
            phoneNumber: eventDetails.phoneNumber || '',
            careCircleMembers: eventDetails.careCircleMembers || [],
            reasonForCall: eventDetails.reasonForCall || '',
            note: eventDetails.note || '',
        });
    }, [eventDetails, onSubmit]);
    return (_jsxs(Container, Object.assign({ size: 'md' }, { children: [_jsx(BackButton, { size: 'sm' }), _jsx(SubContainer, { children: _jsx(ToggleSelect, { id: 'call-type', label: 'Select call type', isRequired: true, options: [
                        { label: 'Google meet', value: 'google-meet', icon: 'video' },
                        { label: 'Phone call', value: 'phone-call', icon: 'phone' },
                    ], onChange: (value) => {
                        setEventDetails((prev) => (Object.assign(Object.assign({}, prev), { callType: value })));
                    }, value: eventDetails.callType }) }), _jsx(SubContainer, { children: _jsx(Input, { label: 'Phone number', id: 'phone-number', placeholder: 'Phone number', isRequired: true, size: 'md', onChange: (value) => {
                        setEventDetails((prev) => (Object.assign(Object.assign({}, prev), { phoneNumber: value })));
                    }, value: eventDetails.phoneNumber || '' }) }), careCircle && (_jsx(SubContainer, { children: _jsx(CareCircleMemberSelector, { id: 'care-circle-member-selector', label: 'Care circle members', description: 'Select the care circle members you want to invite to this call', maxColumns: 1, relationshipOptions: relationshipOptions, careCircle: careCircle, onInvite: onInvite, value: (_a = eventDetails.careCircleMembers) === null || _a === void 0 ? void 0 : _a.join(','), onChange: (value) => {
                        setEventDetails((prev) => (Object.assign(Object.assign({}, prev), { careCircleMembers: value.split(',') })));
                    } }) })), _jsxs(SubContainer, { children: [_jsx(SubHeadingContainer, { children: _jsx(Label, { text: 'Reason for call', id: 'reason-for-call' }) }), _jsx(RadioGroup, { options: [
                            { label: 'Help to find care', value: '1' },
                            { label: 'Help to fund care', value: '2' },
                            {
                                label: 'Understanding my loved one’s care needs',
                                value: '3',
                            },
                            {
                                label: 'Information around hospital discharge',
                                value: '4',
                            },
                            {
                                label: 'Help with respite care',
                                value: '5',
                            },
                            {
                                label: 'Help with power of attorney',
                                value: '6',
                            },
                            { label: "I don’t know/I'm not sure", value: '7' },
                        ], variant: 'default', maxColumns: 1, onChange: (value) => {
                            setEventDetails((prev) => (Object.assign(Object.assign({}, prev), { reasonForCall: value })));
                        }, value: eventDetails.reasonForCall })] }), _jsx(SubContainer, { children: _jsx(TextArea, { label: 'Please share anything else that will help prepare for our meeting', id: 'note', placeholder: 'Write a note', onChange: (value) => {
                        setEventDetails((prev) => (Object.assign(Object.assign({}, prev), { note: value })));
                    }, value: eventDetails.note }) }), _jsx(Button, Object.assign({ ariaLabel: 'Book a call', variant: 'primary', onPress: handleSubmit, isFullWidth: true }, { children: "Book a call" }))] })));
};
const SubContainer = styled.div `
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const SubHeadingContainer = styled.div `
  display: flex;
  align-items: center;
  padding-bottom: ${(props) => props.theme.spacing[8]};
`;
BookingDetails.displayName = 'BookingDetails';
export default BookingDetails;
