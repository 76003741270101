import { useContext } from 'react';

import { SearchResultsContext } from '../context/SearchResultsContext';
import { ActiveProviderContext } from '../context/ActiveProviderContext';
import { BrowserLocationContext } from '../context/BrowserLocationContext';

export default function useViewProviderProfile() {
  const { goToProvider } = useContext(BrowserLocationContext);
  const { getProviderByRegulatoryId } = useContext(SearchResultsContext);
  const { setProvider, clearProvider } = useContext(ActiveProviderContext);

  const goToProviderProfile = (regulatoryId) => {
    const provider = getProviderByRegulatoryId(regulatoryId);

    if (!provider) {
      return;
    }

    setProvider(provider);
    goToProvider(regulatoryId);
  };

  return {
    goToProviderProfile,
    clearProvider,
  };
}
