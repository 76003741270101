// fonts
import '@fontsource/dm-serif-display/400.css';
import '@fontsource/work-sans/100.css';
import '@fontsource/work-sans/200.css';
import '@fontsource/work-sans/300.css';
import '@fontsource/work-sans/400.css';
import '@fontsource/work-sans/500.css';
import '@fontsource/work-sans/600.css';
import '@fontsource/work-sans/700.css';
import '@fontsource/work-sans/800.css';
import '@fontsource/work-sans/900.css';
// theme definitions
import typography from './typography';
import color from './color';
import breakpoint, { breakpointValues } from './breakpoint';
import spacing from './spacing';
import elevation from './elevation';
// styled-components
import { css } from 'styled-components';
// directory
import directoryTheme from './directory';
export const theme = Object.assign(Object.assign({}, directoryTheme), { typography,
    color,
    breakpoint,
    breakpointValues,
    spacing,
    elevation, 
    // helpers
    pressed: (pressedCss) => {
        return css `
      ${pressedCss}
      &:hover,
          &:active {
        ${pressedCss}
      }
    `;
    }, innerBorder: (border, borderRadius) => {
        return (border &&
            css `
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: ${border};
          ${borderRadius && `border-radius: ${borderRadius}px`};
        }
      `);
    } });
export default theme;
