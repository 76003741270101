// react
import { useCallback, useEffect, useState } from 'react';

// store
import { useLazyVersionQuery } from 'store/api/iam';

export const useAppUpdate = () => {
  // states
  const [newVersion, setNewVersion] = useState('');
  const [initialRender, setInitialRender] = useState(true);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  // hooks
  const [getApiVersion] = useLazyVersionQuery();

  // methods
  const updateApp = useCallback(() => {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    window.location.reload();
  }, []);

  const postVersionToServiceWorker = useCallback((version: string) => {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'CACHE_VERSION',
        payload: version,
      });
    }
  }, []);

  const addValueToCache = useCallback(async () => {
    getApiVersion().then((res) => {
      if ('error' in res) {
        console.error(res.error);
        return;
      }
      setNewVersion(res.data?.message || 'unknown');
    });
  }, [setNewVersion, getApiVersion]);

  // effects
  useEffect(() => {
    addValueToCache();

    const intervalID = setInterval(
      () => {
        addValueToCache();
        setInitialRender(false);
        postVersionToServiceWorker(newVersion);
      },
      1000 * 60 * 60 * 4, // 4 hours
    );

    return () => {
      clearTimeout(intervalID);
    };
  }, [addValueToCache, postVersionToServiceWorker, newVersion]);

  useEffect(() => {
    if (navigator.serviceWorker.controller) {
      caches.open('version-cache').then((cache: any) => {
        cache.match('version').then((response: any) => {
          if (response) {
            response.text().then((version: any) => {
              if (version !== newVersion) {
                setIsDialogVisible(true);
              }
            });
          }
        });
      });
    }
  }, [newVersion, initialRender]);

  return {
    isDialogVisible,
    setIsDialogVisible,
    updateApp,
  };
};
