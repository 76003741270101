import { styled } from '../../../styling';
import { Icon, icons } from '../../../primitives/Icon';
import Column from '../../../primitives/Column';
import InlineText from '../../../primitives/typography/InlineText';
import Spacer from '../../../primitives/Spacer';

const LargeIcon = styled(Icon)`
  font-size: ${({ theme }) => theme.spacing.units(16)};
  color: ${({ theme }) => theme.colour.neutral.dark};
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colour.neutral.mid};
`;

export default function EmptyGalleryPlaceholder() {
  return (
    <Container>
      <Column alignCentre>
        <LargeIcon icon={icons.images} />
        <Spacer top />
        <InlineText>This care provider hasn&apos;t added any images</InlineText>
      </Column>
    </Container>
  );
}
