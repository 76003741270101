import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// mux
import MuxPlayer from '@mux/mux-player-react';
// components
import { Button, Card, Container, SupportingText, Title, } from "../../atoms";
import { CareReferralInfo, FeesDescription, GdprConsent, SjpClientForm, SjpPartnerForm, } from "../../organisms";
import { ReferralProcess } from "../../molecules";
// hooks
import { useValidator } from "../../../hooks";
// assets
// @transform-path ../../../../../../src/assets/referral/header.jpg
import headerImage from "../../../../../../src/assets/referral/header.jpg";
// validators
const isClientValid = (c) => {
    return Object.keys(c).length === 6;
};
const isPartnerValid = (c) => {
    return Object.keys(c).length === 6;
};
const isGdprValid = (c) => {
    return JSON.parse((c === null || c === void 0 ? void 0 : c.gdprSelections) || '[]').length === 2;
};
const isFeesValid = (c) => {
    return (c === null || c === void 0 ? void 0 : c.feeSelection) !== undefined;
};
/**
 * CareConciergeReferral renders the account settings page.
 */
export const CareConciergeReferral = ({ onRefer, setIsValid, videoPlaybackID, videoThumbnailTime = 0.0, }) => {
    // states
    const [client, setClient] = useState({});
    const [partner, setPartner] = useState({});
    const [gdpr, setGdpr] = useState({});
    const [fees, setFees] = useState({});
    // hooks
    const { createSetIsValid, isValid: isValidationValid } = useValidator(setIsValid);
    // memos
    const referral = useMemo(() => {
        return {
            referrer: {
                firstName: partner.firstName,
                lastName: partner.lastName,
                email: partner.email,
                contactNumber: partner.phoneNumber,
                partnerCode: partner.partnerCode,
                referLtcCode: partner.ltcLicense,
                hsLegalBasis: gdpr.gdprSelections,
            },
            referral: {
                firstName: client.firstName,
                lastName: client.lastName,
                email: client.email,
                phone: client.phoneNumber,
                discountCode: fees.discountCode,
                feePaid: fees.feeSelection,
                personReceivingCare: client.nameOfPersonReceivingCare,
                careSituationContext: client.contextAboutSituation,
            },
        };
    }, [client, partner, gdpr, fees]);
    const isValid = useMemo(() => isClientValid(client) &&
        isPartnerValid(partner) &&
        isGdprValid(gdpr) &&
        isFeesValid(fees), [client, partner, gdpr, fees]);
    return (_jsxs(Wrapper, { children: [_jsxs(Header, { children: [_jsx("img", { src: headerImage, alt: 'header' }), _jsx(InfoWrapper, { children: _jsx(Container, Object.assign({ size: 'xl' }, { children: _jsx(CareReferralInfo, { onRefer: () => {
                                    var _a;
                                    (_a = document
                                        .querySelector('[id="sjp-client-form"]')) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
                                } }) })) })] }), _jsxs(Page, { children: [videoPlaybackID && (_jsx(Container, Object.assign({ size: 'sm' }, { children: _jsx(Content, { children: _jsx(StyledMuxPlayer, { playbackId: videoPlaybackID, thumbnailTime: videoThumbnailTime }) }) }))), _jsx(Container, Object.assign({ size: 'xl' }, { children: _jsxs(ContentWrapper, { children: [_jsxs(Content, { children: [_jsx(Title, Object.assign({ isNoMargin: true }, { children: "How your referral works" })), _jsxs(Description, { children: ["See Frequently Asked Questions from other SJP Partners", ' ', _jsx(StyledInternalLink, Object.assign({ target: '_blank', rel: 'noopener noreferrer', to: '/docs/sjp/KareHero-FAQ.pdf' }, { children: "here" })), ". Please contact us at sjp@karehero.com for further questions."] }), _jsx(ReferralProcess, {})] }), _jsx("div", Object.assign({ id: 'sjp-client-form' }, { children: _jsx(SjpClientForm, { value: client, setValue: setClient, setIsValid: createSetIsValid('client') }) })), _jsx(SjpPartnerForm, { value: partner, setValue: setPartner, setIsValid: createSetIsValid('partner') }), _jsx(FeesDescription, { value: fees, setValue: setFees }), _jsx(GdprConsent, { value: gdpr, setValue: setGdpr }), _jsxs(Content, { children: [_jsx(Card, Object.assign({ color: 'info', isBordered: false, isPaddingSm: true }, { children: _jsx(StyledSupportingText, { text: `Once you click 'Submit', head to your SJP email inbox for your personal invite to track your client's progress via your Partner Portal`, size: 'md' }) })), _jsx("div", { children: _jsx(Button, Object.assign({ ariaLabel: 'refer-now', onPress: () => onRefer(referral), isDisabled: !isValid || !isValidationValid, size: 'sm', isRectangle: true }, { children: "Submit" })) })] })] }) }))] })] }));
};
// styles
const Wrapper = styled.div `
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 320px;
  gap: ${(props) => props.theme.spacing[36]};
  padding-bottom: ${(props) => props.theme.spacing[32]};
`;
const InfoWrapper = styled.div `
  padding: ${(props) => props.theme.spacing[16]};
  ${(props) => props.theme.breakpoint.sm(css `
      padding: ${(props) => props.theme.spacing[24]};
    `)}
  ${(props) => props.theme.breakpoint.md(css `
      padding: ${(props) => props.theme.spacing[48]};
    `)}
`;
const Page = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
`;
const Description = styled.div `
  text-align: left;
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Header = styled.div `
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  & > img {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
const StyledInternalLink = styled(Link) `
  color: ${(props) => props.theme.color.cards.red.tertiary};
  font: ${(props) => props.theme.typography.body.sm.medium};
  text-decoration: underline;
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.theme.spacing[16]};
`;
const StyledSupportingText = styled(SupportingText) `
  width: 100%;
  color: ${(props) => props.theme.color.neutral[100]};
`;
const StyledMuxPlayer = styled(MuxPlayer) `
  border-radius: 8px;
  overflow: hidden;
  ${(props) => props.theme.breakpoint.md(css `
      margin: auto;
      max-width: 100%;
    `)}
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
`;
CareConciergeReferral.displayName = 'CareConciergeReferral';
export default CareConciergeReferral;
