import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
// mux
import MuxPlayer from '@mux/mux-player-react';
// portabletext
import { PortableText } from '@portabletext/react';
// utils
import { buildFileUrl, buildImageUrl } from "../../../utils/sanity";
const ptComponents = {
    marks: {
        link: (props) => (_jsx(StyledLink, Object.assign({ href: props.value.href, target: '_blank', rel: 'noopener noreferrer' }, { children: props.children }))),
    },
    block: {
        h1: (props) => _jsx(Heading1, { children: props.children }),
        h2: (props) => _jsx(Heading2, { children: props.children }),
        h3: (props) => _jsx(Heading3, { children: props.children }),
        h4: (props) => _jsx(Heading4, { children: props.children }),
        h5: (props) => _jsx(Heading5, { children: props.children }),
        h6: (props) => _jsx(Heading6, { children: props.children }),
    },
    types: {
        image: (props) => {
            var _a, _b;
            return (_jsx(BlockImage, { children: _jsx("img", { src: buildImageUrl((_b = (_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.asset) === null || _b === void 0 ? void 0 : _b.url), alt: 'related' }) }));
        },
        'mux.video': (props) => {
            var _a, _b;
            return (_jsx(StyledMuxPlayer, { playbackId: (_b = (_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.asset) === null || _b === void 0 ? void 0 : _b.playbackId }));
        },
        file: (props) => {
            var _a, _b, _c;
            return (_jsx("a", Object.assign({ href: buildFileUrl((_b = (_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.asset) === null || _b === void 0 ? void 0 : _b._ref), target: '_blank' }, { children: (_c = props === null || props === void 0 ? void 0 : props.value) === null || _c === void 0 ? void 0 : _c.title })));
        },
    },
    list: {
        number: (props) => {
            let type = '1';
            let parentValue = undefined;
            const level = props.value.level;
            parentValue = 10;
            return (_jsx(OL, Object.assign({ type: type, "$level": level, "$parentValue": parentValue }, { children: props.children })));
        },
    },
};
const OL = styled.ol `
  ${(props) => {
    switch (props.$level) {
        case 1:
        case 2:
            return css `
          padding-left: 0;
        `;
        default:
            return css `
          padding-left: ${props.theme.spacing[20]};
        `;
    }
}}

  list-style-type: none;
  counter-reset: section;

  ${(props) => {
    let type;
    let font = props.theme.typography.body.md.regular;
    let content = '';
    let beforeWidth = props.theme.spacing[32];
    switch (props.$level) {
        case 1:
            font = props.theme.typography.body.md.bold;
            content = `counter(section) ". "`;
            break;
        case 2:
            content = `counters(section, ".") ' '`;
            break;
        case 3:
            content = `'('counter(section, lower-alpha)') '`;
            break;
        case 4:
            type = 'lower-roman';
            break;
        default:
            font = props.theme.typography.body.md.regular;
            type = undefined;
            content = '';
            break;
    }
    return css `
      & > li {
        margin: ${(props) => props.theme.spacing[8]} 0;
        list-style-type: ${type};
        position: relative;
        padding-left: ${beforeWidth};
      }
      & > li:before {
        counter-increment: section;
        content: ${content};
        font: ${font};

        display: inline-block;
        position: absolute;
        left: -${props.theme.spacing[8]};
        width: ${beforeWidth};
        text-align: right;
      }
    `;
}}}
`;
export const SanityContent = ({ content }) => {
    // hooks
    const location = useLocation();
    // memos
    const ptDynamicComponents = useMemo(() => {
        return Object.assign(Object.assign({}, ptComponents), { marks: Object.assign(Object.assign({}, ptComponents.marks), { internalLink: (props) => (_jsx(StyledInternalLink, Object.assign({ to: `/library/${props.value.slug.current}` }, { children: props.children }))) }) });
    }, [location]);
    return (_jsx(Wrapper, { children: content && (_jsx(PortableText, { value: content, components: ptDynamicComponents })) }));
};
const Wrapper = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const StyledMuxPlayer = styled(MuxPlayer) `
  border-radius: 8px;
  overflow: hidden;
`;
const Heading1 = styled.h1 `
  font: ${(props) => props.theme.typography.heading['7xl']};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Heading2 = styled.h2 `
  font: ${(props) => props.theme.typography.body.xxl.medium};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Heading3 = styled.h3 `
  font: ${(props) => props.theme.typography.body.xl.medium};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Heading4 = styled.h4 `
  font: ${(props) => props.theme.typography.body.lg.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Heading5 = styled.h5 `
  font: ${(props) => props.theme.typography.body.md.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Heading6 = styled.h6 `
  font: ${(props) => props.theme.typography.body.sm.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const StyledLink = styled.a `
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.body.md.medium};
  text-decoration: underline;
`;
const StyledInternalLink = styled(Link) `
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.body.md.medium};
  text-decoration: underline;
`;
const BlockImage = styled.div `
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  margin-top: ${(props) => props.theme.spacing[16]};
  margin-bottom: ${(props) => props.theme.spacing[36]};
  & > img {
    width: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
SanityContent.displayName = 'SanityContent';
export default SanityContent;
