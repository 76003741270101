import { useContext } from 'react';
import { compose, prop, curry, ifElse } from 'ramda';

import { mapSearchPayloadToProviderList } from './data/dataHelpers';
import useMakeSearchRequest from './data/useMakeSearchRequest';
import useMakeProfileRequest from './data/useMakeProfileRequest';

import { SearchResultsContext } from '../context/SearchResultsContext';
import { ProviderFilterContext } from '../context/ProviderFilterContext';
import { ActiveProviderContext } from '../context/ActiveProviderContext';
import { CareTypeContext } from '../context/CareTypeContext';
import { BrowserLocationContext } from '../context/BrowserLocationContext';
import useDistanceCalculation from './useDistanceCalculation';
import useOnPageEvent from './useOnPageEvent';
import { SearchContext } from '../context/SearchContext';

export default function useProviderSearch() {
  const { onSearch } = useOnPageEvent();
  const [makeSearchRequest] = useMakeSearchRequest();
  const [makeProfileRequest] = useMakeProfileRequest();
  const { careType } = useContext(CareTypeContext);
  const { setProvider } = useContext(ActiveProviderContext);
  const { goToSearch } = useContext(BrowserLocationContext);
  const { searchTerm } = useContext(SearchContext);
  const {
    regulatorRatings,
    homeCareHourlyRateRanges,
    activeFilters: {
      enabledCareProvidedFilters,
      currentDistance,
      enabledRegulatorRatings,
      enabledSortOrder,
      enabledCareVisitDuration,
      enabledHourlyRates,
    },
  } = useContext(ProviderFilterContext);

  const distanceCalculator = useDistanceCalculation();
  const {
    setLoading,
    setNotLoading,
    setResults,
    appendResults,
    setLastSearchTerm,
    setHasError,
    setNoError,
    setLastSearchLocation,
    setLastSearchRegulator,
    setLastSearchPageNumber,
    lastSearchPageNumber,
    lastSearchTerm,
    lastSearchLocation,
    setLoadingMore,
    setNotLoadingMore,
    lastSearchRegulator,
    setLastSearchCountryNotImplemented,
    clearLastSearchCountryNotImplemented,
  } = useContext(SearchResultsContext);

  const onError = (term) => () => {
    setHasError();
    setNotLoading();
    setLastSearchTerm(term);
  };

  const convertItemsToProviderList = curry((location, regulator, items) =>
    mapSearchPayloadToProviderList(items, {
      calcDistance: distanceCalculator(location),
      regulator,
    }),
  );

  const convertResultDataAndSetState = compose(
    setResults,
    convertItemsToProviderList,
  );

  const useApiResults = ({ items, location, regulator }) => {
    convertResultDataAndSetState(location, regulator, items);
    setLastSearchLocation(location);
    setLastSearchRegulator(regulator);
  };

  const search = (term = searchTerm) => {
    const handleError = onError(term);

    clearLastSearchCountryNotImplemented();
    setNoError();
    setLoading();
    setLastSearchTerm(term);
    setLastSearchPageNumber(1);
    goToSearch(term);

    // eslint-disable-next-line camelcase
    const searchedCountryImplemented = ({ implemented_country }) =>
      !!implemented_country;

    const maybeSearchedCountryImplemented = ifElse(
      searchedCountryImplemented,
      useApiResults,
      setLastSearchCountryNotImplemented,
    );

    const ratings = regulatorRatings[lastSearchRegulator];
    const enabledRatings =
      ratings?.reduce((acc, { weighting, label }) => {
        if (enabledRegulatorRatings[weighting]) acc.push(label);
        return acc;
      }, []) || [];
    const hourlyRates =
      homeCareHourlyRateRanges?.reduce((acc, { value, label }) => {
        if (enabledHourlyRates[value]) acc.push(label);
        return acc;
      }, []) || [];

    const careHomeEventData = {
      searchTerm,
      careType,
      currentDistance,
      enabledSortOrder,
      enabledCareProvidedFilters,
      enabledRatings,
    };
    const homeCareEventData = {
      searchTerm,
      careType,
      currentDistance,
      enabledSortOrder,
      enabledRatings,
      enabledCareVisitDuration,
      hourlyRates,
    };
    const searchData =
      careType === 'careHome' ? careHomeEventData : homeCareEventData;

    if (searchTerm) {
      onSearch(searchData);
    }

    makeSearchRequest({
      careType,
      searchTerm: term,
      currentDistance,
      enabledCareProvidedFilters,
      enabledRegulatorRatings,
      enabledSortOrder,
      enabledCareVisitDuration,
      enabledHourlyRates,
    })
      .then(prop('data'))
      .then(maybeSearchedCountryImplemented)
      .then(setNotLoading)
      .catch(handleError);
  };

  const getNextSearchResults = () => {
    const nextPage = lastSearchPageNumber + 1;

    const storeNewPageNumber = () => setLastSearchPageNumber(nextPage);
    const storeOldPageNumber = () =>
      setLastSearchPageNumber(lastSearchPageNumber);
    const convertItems = convertItemsToProviderList(
      lastSearchLocation,
      lastSearchRegulator,
    );
    const appendNextProviders = compose(
      appendResults,
      convertItems,
      prop('items'),
      prop('data'),
    );

    setLoadingMore();
    makeSearchRequest({
      careType,
      searchTerm: lastSearchTerm,
      currentDistance,
      enabledCareProvidedFilters,
      page: nextPage,
      enabledRegulatorRatings,
      enabledSortOrder,
      enabledCareVisitDuration,
      enabledHourlyRates,
    })
      .then(appendNextProviders)
      .then(storeNewPageNumber)
      .then(setNotLoadingMore)
      .catch(storeOldPageNumber);
  };

  const convertItemToProvider = (item) =>
    mapSearchPayloadToProviderList([item], { regulator: item.regulator })[0];

  const useProfileApiResults = (item) => {
    compose(setProvider, convertItemToProvider)(item);
    setLastSearchRegulator(item.regulator);
  };

  const profileByRegulatoryId = (regulatoryId) => {
    makeProfileRequest(regulatoryId)
      .then(prop('data'))
      .then(prop('item'))
      .then(useProfileApiResults)
      .catch((err) => err);
  };

  return { search, getNextSearchResults, profileByRegulatoryId };
}
