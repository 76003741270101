// components
import { CarePlanComplete as CarePlanCompletePage } from '@karehero/llama';

/**
 * CarePlanComplete shows the care plan complete page.
 */
const CarePlanComplete = () => {
  return <CarePlanCompletePage to='/admin/care-circle/manage' />;
};

export default CarePlanComplete;
