import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Button, Container, Input } from "../../atoms";
/**
 * CarePlanRequestChanges shows the care plan is complete.
 */
export const CarePlanRequestChanges = ({ className, onRequestChanges, onBookCall, value, onChange, }) => {
    return (_jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(Title, { children: "Your Personalised Care Plan" }), _jsx(SubTitle, { children: "What would you like to change on your care plan?" }), _jsx(Paragraph, { children: "Please tell us exactly what you are wanting to change. Be as specific as possible so we can adjust this to fit your needs better. Once we recive your message we will work to adjust your plan and contact you with an updated version." }), _jsx(Input, { value: value, onChange: onChange }), _jsx(Button, Object.assign({ ariaLabel: 'send', variant: 'primary', onPress: onRequestChanges }, { children: "Send" })), _jsx(Paragraph, { children: "Alternatively if you would prefer to go through this with us in person and sort his together then you can book a call back with our care expert team." }), _jsx(Button, Object.assign({ ariaLabel: 'Book a call back', variant: 'secondary', onPress: onBookCall }, { children: "Book a call back" }))] }) })));
};
const StyledContainer = styled(Container) `
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  margin-top: ${(props) => props.theme.spacing[64]};
  gap: ${(props) => props.theme.spacing[32]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['4xl']};
  color: ${(props) => props.theme.color.primary[80]};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const SubSubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Paragraph = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
CarePlanRequestChanges.displayName = 'CarePlanRequestChanges';
export default CarePlanRequestChanges;
