// react
import { useParams } from 'react-router-dom';

// components
import { OrganizationTierTable as OrganizationTierTablePage } from '@karehero/llama';

// store
import {
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from 'store/api/organization';
import { useGetAllTierQuery } from 'store/api/tier';

/**
 * OrganizationTierTable is shows the limits and usage of enabled tiers for an organization.
 */
const OrganizationTierTable = ({
  isEditable = true,
}: {
  isEditable?: boolean;
}) => {
  // params
  const { organizationID } = useParams();

  // hooks
  const { data: organization } = useGetOrganizationQuery(organizationID || '');
  const [updateOrganization] = useUpdateOrganizationMutation();
  const { data: tiers } = useGetAllTierQuery();

  return (
    <OrganizationTierTablePage
      isEditable={isEditable}
      organization={organization}
      tiers={tiers}
      updateOrganization={updateOrganization}
    />
  );
};

export default OrganizationTierTable;
