import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// @transform-path ../../../../../../src/assets/dashboard/respite.png
import respite from "../../../../../../src/assets/dashboard/respite.png";
// @transform-path ../../../../../../src/assets/dashboard/funding.png
import funding from "../../../../../../src/assets/dashboard/funding.png";
// @transform-path ../../../../../../src/assets/dashboard/find.png
import find from "../../../../../../src/assets/dashboard/find.png";
// @transform-path ../../../../../../src/assets/dashboard/expert.png
import expert from "../../../../../../src/assets/dashboard/expert.png";
const imageMap = {
    respite,
    funding,
    find,
    expert,
};
const imageColorMap = {
    respite: {
        background: '#e5e7f7',
        color: '#044dba',
    },
    funding: {
        background: '#bbddd1',
        color: '#215852',
    },
    find: {
        background: '#ded4ec',
        color: '#813c8d',
    },
    expert: {
        background: '#f9d6d9',
        color: '#d45d6e',
    },
};
/**
 * CardImage renders a card with a color.
 */
export const CardImage = ({ className, title, subtitle, image = 'respite', to, }) => {
    return (_jsxs(Wrapper, Object.assign({ className: className, image: image, background: imageColorMap[image].background, to: to }, { children: [_jsxs(Body, { children: [_jsx(Title, Object.assign({ color: imageColorMap[image].color }, { children: title })), _jsx(Subtitle, { children: subtitle })] }), _jsx(Image, { src: imageMap[image], alt: 'respite' }), _jsx(Overlay, {})] })));
};
const Overlay = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  transition-duration: 0.2s;
`;
const Wrapper = styled(Link) `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${(props) => props.theme.spacing[32]};
  border-radius: 8px;
  overflow: hidden;
  min-height: 190px;
  height: 100%;
  max-height: 190px;
  box-shadow: ${(props) => props.theme.elevation[2]};
  text-decoration: none;

  background: ${(props) => props.background || props.theme.color.neutral[10]};
  color: ${(props) => props.theme.color.neutral[90]};

  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.05);
    box-shadow: ${(props) => props.theme.elevation[4]};
  }

  &:hover ${Overlay} {
    opacity: 0;
  }
`;
const Body = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 140px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[8]};
  flex: 1;
  z-index: 1;

  padding: ${(props) => props.theme.spacing[24]};
  ${(props) => props.theme.breakpoint.sm(css `
      padding-top: ${(props) => props.theme.spacing[48]};
      padding-bottom: ${(props) => props.theme.spacing[48]};
    `)}
`;
const Title = styled.div `
  color: ${(props) => props.color || props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.heading['2xl']};
  ${(props) => props.theme.breakpoint.sm(css `
      font: ${(props) => props.theme.typography.heading['3xl']};
    `)}
`;
const Subtitle = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  ${(props) => props.theme.breakpoint.sm(css `
      font: ${(props) => props.theme.typography.body.md.regular};
    `)}
`;
const Image = styled.img `
  height: 100%;
  object-fit: scale-down;
`;
CardImage.displayName = 'CardImage';
export default CardImage;
