import { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { compose } from 'ramda';

const { history } = window;

export const BrowserLocationContext = createContext();

export const BrowserLocationProvider = ({ children }) => {
  const parseRouterStateFromQuerystring = () => {
    const { search, provider } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    return {
      search,
      provider,
      haveSearchTerm: !!search,
      haveProviderId: !!provider,
    };
  };

  const defaultState = parseRouterStateFromQuerystring();

  const [routerState, setRouterState] = useState(defaultState);

  const buildProfilePath = (id) => `?provider=${id}`;
  const buildSearchPath = (term) => `?search=${term}`;

  const pushState = (url) => history.pushState({}, '', url);

  const updateStateFromQuerystring = compose(
    setRouterState,
    parseRouterStateFromQuerystring,
  );
  const goTo = compose(updateStateFromQuerystring, pushState);

  const goToHome = () => goTo('/');
  const goToSearch = (term) => goTo(buildSearchPath(term));
  const goToProvider = (regulatorId) => goTo(buildProfilePath(regulatorId));

  // The popstate event is fired on browser back button
  // We don't care what the event says, we just re-parse
  // the querystring and update state
  window.onpopstate = updateStateFromQuerystring;

  const value = {
    ...routerState,
    goToHome,
    goToSearch,
    goToProvider,
    buildSearchPath,
    buildProfilePath,
  };

  return (
    <BrowserLocationContext.Provider value={value}>
      {children}
    </BrowserLocationContext.Provider>
  );
};

BrowserLocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
