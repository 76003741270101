import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { OnboardingActionType, toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
import { Container, Input, Title } from "../../atoms";
import { FloatingTemplate } from "../../templates";
/**
 * OnboardingRegisterEmail renders the Register screen for onboarding flows.
 */
export const OnboardingRegisterEmail = toGenericOnboarding(({ config, currentIndex, handleClick, setIsNextDisabled, }) => {
    var _a, _b;
    if (!config)
        return null;
    return (_jsx(FloatingTemplate, { content: _jsx(Container, Object.assign({ size: 'md' }, { children: _jsxs(Content, { children: [_jsx(Title, { children: "Let's check we know who you are" }), _jsx(SubTitle, { children: "We\u2019re available via your employer, please enter your work email address" }), _jsx("form", Object.assign({ onSubmit: (e) => {
                            e.preventDefault();
                            handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.Next);
                        } }, { children: _jsx(Input, { label: 'Work email address', value: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.value, onChange: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.onChange, type: 'email', validate: (value) => {
                                // check is email
                                const isValid = /\S+@\S+\.\S+/.test(value || '');
                                setIsNextDisabled(!isValid);
                                return isValid;
                            } }) }))] }) })) }));
});
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  padding-bottom: ${(props) => props.theme.spacing[24]};
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
OnboardingRegisterEmail.displayName = 'OnboardingRegisterEmail';
export default toGenericOnboarding(OnboardingRegisterEmail);
