// pdfmake
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { ContentCanvas, TDocumentDefinitions } from 'pdfmake/interfaces';

// @ts-ignore
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const createPdf = (docDefinition: TDocumentDefinitions) => {
  return pdfMake.createPdf(docDefinition);
};

export const background = (color: string = '#fcf9f5') => {
  const canvas: ContentCanvas = {
    canvas: [
      {
        type: 'rect',
        x: 0,
        y: 0,
        w: 595.28,
        h: 841.89,
        color: color,
      },
    ],
  };
  return canvas;
};

export const pageSize = {
  width: 595.28,
  height: 841.89,
};
