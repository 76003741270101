// react
import { useCallback, useMemo } from 'react';

// hooks
import { useCareProfile } from 'hooks';

// components
import { BackButton, OnboardingFlow, OnboardingRadio } from '@karehero/llama';

// config
import { onboardingRadioConfig } from 'config/careServices';

/**
 * OnboardingCareServices takes the user through the onboarding flow for a care concierge.
 */
const OnboardingCareServices = () => {
  // hooks
  const { careCircle } = useCareProfile();

  // methods
  const handleComplete = useCallback(() => {}, []);

  // memos
  const onboardingCareServicesConfig = useMemo(
    () => [
      {
        id: 'radio',
        Component: OnboardingRadio,
        config: onboardingRadioConfig.map((configItem) => {
          return {
            ...configItem,
            title: configItem.title?.replace(
              '{careRecipient}',
              careCircle?.careRecipientAccount?.firstName || 'your loved one',
            ),
            options: configItem.options,
            actions: [],
          };
        }),
      },
    ],
    [careCircle],
  );

  return (
    <>
      <BackButton />
      <OnboardingFlow
        config={onboardingCareServicesConfig}
        onComplete={handleComplete}
      />
    </>
  );
};

OnboardingCareServices.displayName = 'OnboardingCareServices';
export default OnboardingCareServices;
