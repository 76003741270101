import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// qr code
import { QRCodeSVG } from 'qrcode.react';
// components
import { Button } from "../../atoms";
// assets
// @transform-path ../../../../../../src/assets/app/phone.png
import phoneImage from "../../../../../../src/assets/app/phone.png";
// @transform-path ../../../../../../src/assets/logo-k.svg
import logoImage from "../../../../../../src/assets/logo-k.svg";
/**
 * AppStore signposts the user to download the app from the App Store.
 */
export const AppStore = ({ storeUrl }) => {
    return (_jsxs(Wrapper, { children: [_jsxs(Body, { children: [_jsx(AppIcon, { children: _jsx("img", { src: logoImage, alt: 'app icon' }) }), _jsxs(Content, { children: [_jsx(Title, { children: "We have an App for this!" }), _jsx(Subtitle, { children: "To download the app, scan the QR code or click on the button below." }), _jsx(Subtitle, { children: "Available for iPhone and Android devices." })] })] }), _jsxs(Footer, { children: [_jsx(Image, { children: _jsx("img", { src: phoneImage, alt: 'phone' }) }), _jsxs(Cta, { children: [_jsx(QRCodeWrapper, { children: _jsx(QRCodeSVG, { value: storeUrl, size: 130 }) }), _jsx(Button, Object.assign({ to: storeUrl, ariaLabel: 'Download on the App Store', size: 'lg' }, { children: "Go to store" }))] })] })] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: ${(props) => props.theme.spacing[32]};
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-top: ${(props) => props.theme.spacing[32]};
  overflow: hidden;
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: ${(props) => props.theme.spacing[32]};
  padding: 0 ${(props) => props.theme.spacing[12]};
  max-width: 500px;
`;
const AppIcon = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  background: ${(props) => props.theme.color.custom.logoBackground};
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.elevation[3]};

  & img {
    width: 50px;
    height: 50px;
  }
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
`;
const Title = styled.div `
  color: ${(props) => props.theme.color.neutral[100]};
  text-align: center;
  font: ${(props) => props.theme.typography.heading['3xl']};
  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.heading['4xl']};
    `)}
`;
const Subtitle = styled.div `
  color: ${(props) => props.theme.color.neutral[100]};
  font: ${(props) => props.theme.typography.body.lg.regular};
  text-align: center;
`;
const Cta = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[32]};
`;
const QRCodeWrapper = styled.div `
  display: flex;
  padding: ${(props) => props.theme.spacing[12]};
  background: ${(props) => props.theme.color.neutral[10]};
`;
const Image = styled.div `
  display: none;

  width: 100%;
  max-width: 400px;
  height: 100%;
  position: relative;
  align-items: flex-start;

  ${(props) => props.theme.breakpoint.sm(css `
      display: flex;
    `)}

  & > img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;
const Footer = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[32]};
  padding-bottom: ${(props) => props.theme.spacing[32]};
  overflow: hidden;
  ${(props) => props.theme.breakpoint.sm(css `
      padding-bottom: 0;
    `)}
`;
AppStore.displayName = 'AppStore';
export default AppStore;
