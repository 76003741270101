import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState } from 'react';
import styled from 'styled-components';
// components
import { Button, Input } from "../../atoms";
/**
 * ForgotPasswordForm is a component that renders an email and password input field with
 * a submit button.
 */
export const ForgotPasswordForm = ({ onSubmit, toSignIn, }) => {
    const [email, setEmail] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(email);
    };
    return (_jsxs(StyledForgotPasswordForm, Object.assign({ onSubmit: handleSubmit }, { children: [_jsx(StyledTopSection, { children: _jsx(StyledInputFields, { children: _jsx(Input, { ariaLabel: 'email input field', type: 'email', label: 'Email', value: email, onChange: setEmail, placeholder: 'Email' }) }) }), _jsx(ForgotPassword, { children: _jsx(Button, Object.assign({ ariaLabel: 'sign in', variant: 'link', size: 'sm', to: toSignIn }, { children: "I know my password" })) }), _jsx(StyledButton, Object.assign({ ariaLabel: 'sign in', type: 'submit' }, { children: "Forgot Password" }))] })));
};
const StyledForgotPasswordForm = styled.form `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  align-items: center;
`;
const StyledInputFields = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
`;
const StyledTopSection = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
`;
const StyledButton = styled(Button) `
  width: 100%;
`;
const ForgotPassword = styled.div `
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;
ForgotPasswordForm.displayName = 'ForgotPasswordForm';
export default ForgotPasswordForm;
