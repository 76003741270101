import { useContext } from 'react';

import { GlobalSettingsContext } from '../context/GlobalSettingsContext';
import useMakeApiRequest from './data/useMakeApiRequest';

export default function useEventTracking() {
  const { trackingApiAddress } = useContext(GlobalSettingsContext);
  const { post } = useMakeApiRequest();

  const fireTrackingEvent = ({ name, properties }) =>
    post(trackingApiAddress, {
      name,
      properties: {
        ...properties,
      },
    });

  return {
    fireTrackingEvent,
  };
}
