import PropTypes from 'prop-types';

export function renderIf(enabled) {
  const Flag = ({ children }) => (enabled ? children : <></>);

  Flag.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return Flag;
}

export default function RenderIfEnabled({ enabled, children }) {
  const Flag = renderIf(enabled);
  return <Flag>{children}</Flag>;
}

RenderIfEnabled.propTypes = {
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
