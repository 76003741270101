import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Button, Container, Keni } from "../../atoms";
/**
 * CarePlanApprove shows the care plan is complete.
 */
export const CarePlanApprove = ({ className, onApprove, }) => {
    return (_jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(Title, { children: "Your Personalised Care Plan" }), _jsx(SubTitle, { children: "Congratulations!" }), _jsx(SubSubTitle, { children: "Our care expert team will contact you with 72 hours with an in depth list of care options." }), _jsxs("div", { children: [_jsx(SubTitle, { children: "Important" }), _jsx(Paragraph, { children: "We will not pass your personal information or contact details on to any care agencies." }), _jsx(Paragraph, { children: "By clicking agree, you confirm that you are happy with the contents of this care plan, and have authority to act on behalf of the individual named in the care plan." })] }), _jsx(Button, Object.assign({ ariaLabel: 'i agree', variant: 'primary', onPress: onApprove }, { children: "I agree" })), _jsx(Keni, {})] }) })));
};
const StyledContainer = styled(Container) `
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  margin-top: ${(props) => props.theme.spacing[64]};
  gap: ${(props) => props.theme.spacing[32]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['4xl']};
  color: ${(props) => props.theme.color.primary[80]};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const SubSubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Paragraph = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
CarePlanApprove.displayName = 'CarePlanApprove';
export default CarePlanApprove;
