import { styled, media } from '../../../styling';
import Row from '../../../primitives/Row';

const CardContainer = styled(Row)`
  flex-wrap: wrap;
  ${media('<=tablet')} {
    flex-direction: column;
  }
`;

export default CardContainer;
