import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// components
import LoadingWebinarContent from './LoadingWebinarContent';
import { LoadingTransition, Skeleton, Button, CallToActionWebinar, DateIcon, } from "../../atoms";
import { SanityContent } from "../../organisms";
// utils
import { buildImageUrl } from "../../../utils/sanity";
/**
 * WebinarContent displays the content for a single webinar.
 */
export const WebinarContent = ({ content, isLoading = true, navigate, backPath, }) => {
    // memo
    const headerImageUrl = useMemo(() => buildImageUrl(content === null || content === void 0 ? void 0 : content.headerImageUrl, 800), [content]);
    const callToActionImageUrl = useMemo(() => buildImageUrl(content === null || content === void 0 ? void 0 : content.callToActionImageUrl, 800), [content]);
    const duration = useMemo(() => {
        const startHour = parseInt((content === null || content === void 0 ? void 0 : content.startTime.split(':')[0]) || '');
        const startMinutes = parseInt((content === null || content === void 0 ? void 0 : content.startTime.split(':')[1]) || '');
        const endHour = parseInt((content === null || content === void 0 ? void 0 : content.endTime.split(':')[0]) || '');
        const endMinutes = parseInt((content === null || content === void 0 ? void 0 : content.endTime.split(':')[1]) || '');
        let markup = '';
        if (endHour - startHour > 0)
            markup += `${endHour - startHour} hours`;
        if (endHour - startHour > 0 && endMinutes - startMinutes > 0)
            markup += ' and ';
        if (endMinutes - startMinutes > 0)
            markup += `${endMinutes - startMinutes} minutes`;
        return markup;
    }, [content]);
    return (_jsxs(StyledWebinarContentPage, { children: [_jsx("div", { children: _jsx(Button, Object.assign({ ariaLabel: 'Back', variant: 'link', iconLeft: 'chevron-left', onPress: () => navigate === null || navigate === void 0 ? void 0 : navigate(backPath || ''), isInline: true }, { children: "Back" })) }), _jsxs(StyledWebinarContent, { children: [_jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Skeleton, { shape: 'header-image', isRounded: false, isMargin: false }), content: _jsxs(HeaderWrapper, { children: [_jsx(HeaderImage, { children: _jsx("img", { src: headerImageUrl, alt: content === null || content === void 0 ? void 0 : content.title, onError: ({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                                'https://cdn.sanity.io/images/x7r1t5o8/production/e8579e2a4c3766cf0cf99a24a9d0679d2d2bbeb4-5322x2365.jpg?h=800';
                                        } }) }), _jsx(IconWrapper, { children: _jsx(DateIcon, { date: new Date((content === null || content === void 0 ? void 0 : content.date) || ''), size: 'md' }) })] }) }), _jsxs(Wrapper, { children: [_jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(LoadingWebinarContent, {}), content: _jsxs(WebinarContentWrapper, { children: [_jsx(Title, { children: content === null || content === void 0 ? void 0 : content.title }), _jsxs(Speaker, { children: ["Speaker: ", content === null || content === void 0 ? void 0 : content.speaker] }), _jsxs(Duration, { children: [_jsx(Strong, { children: "Duration:" }), " ", duration] }), _jsxs(DateTime, { children: [_jsxs(DateText, { children: [_jsx(Strong, { children: "Date:" }), new Date((content === null || content === void 0 ? void 0 : content.date) || '').toDateString()] }), _jsxs(DateText, { children: [_jsx(Strong, { children: "Time: " }), " ", content === null || content === void 0 ? void 0 : content.startTime, " -", ' ', content === null || content === void 0 ? void 0 : content.endTime] })] }), _jsx(SanityContent, { content: content === null || content === void 0 ? void 0 : content.content })] }) }), _jsx(CallToActionWebinar, { isLoading: isLoading, date: new Date((content === null || content === void 0 ? void 0 : content.date) || ''), time: (content === null || content === void 0 ? void 0 : content.startTime) || '', headerImageUrl: callToActionImageUrl })] })] })] }));
};
const StyledWebinarContentPage = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[12]};
`;
const StyledWebinarContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[48]};
`;
const Wrapper = styled.div `
  display: flex;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[64]};
`;
const WebinarContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const HeaderWrapper = styled.div `
  position: relative;
`;
const HeaderImage = styled.div `
  border-radius: 8px;
  overflow: hidden;
  height: 300px;
  display: flex;
  align-items: center;
  & > img {
    width: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
const IconWrapper = styled.div `
  position: absolute;
  top: 245px;
  left: 12px;
  width: 80px;
  height: 80px;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['7xl']};
  color: ${(props) => props.theme.color.primary[80]};
  margin-bottom: ${(props) => props.theme.spacing[32]};
`;
const Speaker = styled.div `
  font: ${(props) => props.theme.typography.body.xs.medium};
  color: ${(props) => props.theme.color.neutral[90]};
  margin-bottom: ${(props) => props.theme.spacing[4]};
`;
const Duration = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  margin-bottom: ${(props) => props.theme.spacing[12]};
  font: ${(props) => props.theme.typography.body.xs.regular};
  color: ${(props) => props.theme.color.neutral[70]};
`;
const DateTime = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[32]};
  padding-top: ${(props) => props.theme.spacing[16]};
  padding-bottom: ${(props) => props.theme.spacing[16]};
  margin-bottom: ${(props) => props.theme.spacing[32]};
  border-top: 1px solid ${(props) => props.theme.color.neutral[40]};
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[40]};
`;
const DateText = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  font: ${(props) => props.theme.typography.body.sm.regular};
`;
const Strong = styled.div `
  font: ${(props) => props.theme.typography.body.sm.semibold};
`;
WebinarContent.displayName = 'WebinarContent';
export default WebinarContent;
