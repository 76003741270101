import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { Fragment } from 'react';
import styled from 'styled-components';
// components
import { Skeleton } from "../../atoms";
export const LoadingServiceContent = () => {
    return (_jsx(Wrapper, { children: [1, 2, 3].map((n) => (_jsxs(Fragment, { children: [_jsx(Skeleton, { shape: 'title' }), _jsx(Skeleton, { shape: 'paragraph' }), _jsx(Skeleton, { shape: 'subtitle' }), _jsx(Skeleton, { shape: 'paragraph' })] }, n))) }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export default LoadingServiceContent;
