import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo, useState } from 'react';
// theme
import styled from 'styled-components';
// components
import { Button, Divider, Input, RadioGroup, SupportingText, } from "../../atoms";
import { Table } from '../Table/Table';
import { UploadFileButton } from "../../molecules";
// tanstack
import { createColumnHelper } from '@tanstack/react-table';
// columns
const columnHelper = createColumnHelper();
/**
 * InviteMembersForm is a component that renders password and confirm password input field with
 * a submit button.
 */
export const InviteMembersForm = ({ organization, inputType, inviteKeyName, onSubmit, isValid, }) => {
    // state
    const [memberDetails, setMemberDetails] = useState([]);
    const [selectedTier, setSelectedTier] = useState('');
    const [newMember, setNewMember] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingInviteKey, setEditingInviteKey] = useState('');
    const initialInviteKey = editingIndex !== null ? memberDetails[editingIndex].inviteKey : '';
    // callbacks
    const cancelEditing = useCallback(() => {
        setEditingIndex(null);
        setEditingInviteKey('');
    }, []);
    const editMember = useCallback((memberIndex, inviteKey) => {
        if (memberIndex === null)
            return;
        setMemberDetails((prev) => [
            ...prev.slice(0, memberIndex),
            Object.assign(Object.assign({}, prev[memberIndex]), { inviteKey }),
            ...prev.slice(memberIndex + 1),
        ]);
    }, []);
    const addMember = useCallback((inviteKey) => {
        if (!isValid(inviteKey))
            return;
        setMemberDetails((prev) => {
            if (prev.findIndex((md) => md.inviteKey === inviteKey) > -1)
                return prev;
            return [...prev, { inviteKey }];
        });
    }, []);
    const addMembers = useCallback((inviteKeys) => {
        setMemberDetails((prev) => {
            const newMembers = inviteKeys.filter((inviteKey) => prev.findIndex((md) => md.inviteKey === inviteKey) === -1);
            const invalidInviteKeys = newMembers.filter((inviteKey) => !isValid(inviteKey));
            const validInviteKeys = newMembers.filter((inviteKey) => isValid(inviteKey));
            return [
                ...invalidInviteKeys.map((inviteKey) => ({ inviteKey })),
                ...prev,
                ...validInviteKeys.map((inviteKey) => ({ inviteKey })),
            ];
        });
    }, []);
    const importFileContent = useCallback((content) => {
        addMembers(content
            .split('\n')
            .map((e) => e.split(',')[0])
            .filter((e) => e.length > 0));
    }, [addMembers]);
    // memo
    const invalidCount = useMemo(() => {
        return memberDetails.filter((member) => !isValid(member.inviteKey)).length;
    }, [memberDetails]);
    const memberCount = useMemo(() => memberDetails.length, [memberDetails]);
    const availableTierSeats = useMemo(() => {
        return ((organization === null || organization === void 0 ? void 0 : organization.tierSeats) || []).filter((tierSeat) => tierSeat.isUnlimitedSeats || (tierSeat.seatLimit || 0) > 0);
    }, [organization]);
    const tierInputs = useMemo(() => {
        return availableTierSeats.map((tierSeat) => ({
            label: `${tierSeat.tier.name} (${tierSeat.isUnlimitedSeats ? 'Unlimited' : tierSeat.seatsAvailable || 0})`,
            value: tierSeat.tier.id,
        }));
    }, [availableTierSeats]);
    const selectedTierSeat = useMemo(() => {
        return availableTierSeats.find((tierSeat) => tierSeat.tier.id === selectedTier);
    }, [selectedTier, availableTierSeats]);
    const Editor = useMemo(() => {
        return () => {
            const [val, setVal] = useState(initialInviteKey);
            return (_jsx(Input, { ariaLabel: `edit ${inviteKeyName}`, size: 'sm', placeholder: `Edit ${inviteKeyName}`, value: val, type: inputType, onChange: (v) => {
                    setVal(v || '');
                    setEditingInviteKey(v || '');
                }, autoFocus: true }));
        };
    }, [initialInviteKey]);
    const columns = useMemo(() => {
        return [
            columnHelper.accessor('inviteKey', {
                cell: (info) => {
                    const val = info.getValue();
                    return editingIndex === info.row.index ? (_jsx(Editor, {})) : isValid(val) ? (val) : (_jsx(StyledInvalidInviteKey, { children: val }));
                },
            }),
        ];
    }, [editingIndex, Editor]);
    const actions = useMemo(() => {
        return [
            {
                label: '',
                iconLeft: 'edit',
                buttonVariant: 'link',
                isHidden: (member) => editingIndex !== null,
                onPress: (member) => {
                    setEditingIndex(memberDetails.findIndex((md) => md.inviteKey === member.inviteKey));
                    setEditingInviteKey(member.inviteKey);
                },
            },
            {
                label: '',
                iconLeft: 'trash',
                buttonVariant: 'link',
                isHidden: (member) => editingIndex ===
                    memberDetails.findIndex((md) => md.inviteKey === member.inviteKey),
                onPress: (member) => {
                    cancelEditing();
                    setMemberDetails(memberDetails.filter((md) => md.inviteKey !== member.inviteKey));
                },
            },
            {
                label: '',
                iconLeft: 'check',
                buttonVariant: 'link',
                isHidden: (member) => editingIndex !==
                    memberDetails.findIndex((md) => md.inviteKey === member.inviteKey),
                onPress: () => {
                    editMember(editingIndex, editingInviteKey);
                    cancelEditing();
                },
            },
            {
                label: '',
                iconLeft: 'x',
                buttonVariant: 'link',
                isHidden: (member) => editingIndex !==
                    memberDetails.findIndex((md) => md.inviteKey === member.inviteKey),
                onPress: () => {
                    cancelEditing();
                },
            },
        ];
    }, [
        memberDetails,
        editingInviteKey,
        editingIndex,
        editMember,
        cancelEditing,
    ]);
    // effects
    useEffect(() => {
        if (availableTierSeats.length > 0) {
            setSelectedTier(availableTierSeats[0].tier.id);
        }
    }, [availableTierSeats]);
    return (_jsxs(StyledInviteMembersForm, Object.assign({ onSubmit: (e) => {
            e.preventDefault();
        } }, { children: [_jsxs(StyledTopSection, { children: [_jsxs(SingleInvite, { children: [_jsx(Input, { ariaLabel: `add ${inviteKeyName}`, placeholder: `Add ${inviteKeyName}`, value: newMember, size: 'sm', type: inputType, onChange: (val) => setNewMember(val || '') }), _jsx(Button, Object.assign({ ariaLabel: 'add', type: 'submit', isRectangle: true, size: 'sm', onPress: () => {
                                    addMember(newMember);
                                    setNewMember('');
                                }, isDisabled: !isValid(newMember) }, { children: "Add" }))] }), _jsx(Divider, {}), _jsx(UploadFileButton, Object.assign({ onChange: importFileContent, ariaLabel: 'upload csv', size: 'md' }, { children: "Upload CSV" }))] }), _jsx(StyledTable, { columns: columns, rows: memberDetails, actions: actions, isHeader: false, isSearchable: false, isPaginated: false, isBorder: true, isTotalRows: false, noDataMsg: `No ${inviteKeyName}s added yet` }), invalidCount > 0 && (_jsx(StyledSupportingText, { text: invalidCount === 1
                    ? `${invalidCount} ${inviteKeyName} is invalid`
                    : `${invalidCount} ${inviteKeyName}s are invalid` })), !(selectedTierSeat === null || selectedTierSeat === void 0 ? void 0 : selectedTierSeat.isUnlimitedSeats) &&
                ((selectedTierSeat === null || selectedTierSeat === void 0 ? void 0 : selectedTierSeat.seatsAvailable) || 0) < memberCount && (_jsx(StyledSupportingText, { text: `Too many invites for ${selectedTierSeat === null || selectedTierSeat === void 0 ? void 0 : selectedTierSeat.tier.name} tier - ${memberCount} selected exceeds ${(selectedTierSeat === null || selectedTierSeat === void 0 ? void 0 : selectedTierSeat.seatsAvailable) || 0} available` })), _jsx(TierSelect, { children: _jsx(RadioGroup, { label: 'Tier', ariaLabel: 'select tier', value: selectedTier, options: tierInputs, onChange: setSelectedTier }) }), _jsx(ButtonWrapper, { children: _jsx(StyledButton, Object.assign({ ariaLabel: 'invite members', isDisabled: memberCount === 0 ||
                        invalidCount > 0 ||
                        (!(selectedTierSeat === null || selectedTierSeat === void 0 ? void 0 : selectedTierSeat.isUnlimitedSeats) &&
                            ((selectedTierSeat === null || selectedTierSeat === void 0 ? void 0 : selectedTierSeat.seatsAvailable) || 0) < memberCount), onPress: () => onSubmit({
                        inviteKeys: memberDetails.map((md) => md.inviteKey),
                        tierID: selectedTier,
                    }) }, { children: `Invite ${memberCount > 0 ? `${memberCount}` : ''} Member${memberCount > 1 ? 's' : ''}` })) })] })));
};
const StyledInviteMembersForm = styled.form `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: ${(props) => props.theme.spacing[24]};
  gap: ${(props) => props.theme.spacing[16]};
  overflow: hidden;
`;
const StyledTopSection = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: min-content;
  gap: ${(props) => props.theme.spacing[16]};
`;
const ButtonWrapper = styled.div `
  display: flex;
  flex-shrink: 0;
  gap: ${(props) => props.theme.spacing[16]};
  overflow: hidden;
  width: 100%;
`;
const StyledButton = styled(Button) `
  width: 100%;
`;
const StyledInvalidInviteKey = styled.div `
  color: red;
`;
const SingleInvite = styled.div `
  display: flex;
  width: 100%;
  gap: ${(props) => props.theme.spacing[8]};
`;
// head
const TierSelect = styled.div `
  display: flex;
  width: 100%;
  gap: ${(props) => props.theme.spacing[8]};
`;
// main
const StyledTable = styled(Table) `
  overflow-y: auto;
  width: 100%;
  padding: ${(props) => props.theme.spacing[16]};
`;
const StyledSupportingText = styled(SupportingText) `
  width: 100%;
  color: red;
`;
InviteMembersForm.displayName = 'InviteMembersForm';
export default InviteMembersForm;
