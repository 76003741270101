import api from '.';

export interface CalendlyLinkResponse {
  meetingType: string;
  meetingUrl: string;
}

export const calendlyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generateCalendlyLink: builder.mutation<CalendlyLinkResponse, void>({
      query: () => ({
        url: '/calendly',
        method: 'POST',
      }),
    }),
  }),
});

export const { useGenerateCalendlyLinkMutation } = calendlyApi;

export default calendlyApi;
