var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
// components
import { Button, Icon, Input } from "../../../atoms";
import { Launcher, Message, TypingDots } from "../..";
// assets
// @transform-path ../../../../../../../src/assets/loader/keni.svg
import keni from "../../../../../../../src/assets/loader/keni.svg";
/**
 * Chat a chat widget.
 */
export const Chat = ({ className, messages, onSend, onReset, isTyping, isMobile, isApple, offsetY, }) => {
    // state
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    // refs
    const ref = useRef(null);
    // methods
    const handleSendMessage = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!message)
            return;
        setMessage('');
        try {
            yield (onSend === null || onSend === void 0 ? void 0 : onSend(message));
        }
        catch (error) {
            console.error(error);
        }
    }), [message, onSend]);
    // effects
    useEffect(() => {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({});
    }, [messages, isTyping, isOpen]);
    return (_jsx(Wrapper, Object.assign({ className: className, "$isMobile": isMobile, "$isApple": isApple, "$offsetY": offsetY }, { children: isOpen ? (_jsxs(ChatBody, { children: [_jsxs(Header, { children: [_jsxs(HeaderItem, { children: [_jsx(Keni, { children: _jsx("img", { src: keni, alt: 'Keni' }) }), _jsx(Name, { children: "KareHero AI" })] }), _jsx(HeaderItem, { children: _jsx(CloseButton, Object.assign({ onClick: () => setIsOpen(false) }, { children: _jsx(Icon, { icon: 'xmark', fontSize: 24 }) })) })] }), _jsxs(Body, { children: [_jsxs(ChatStart, { children: [_jsx(ChatStartHelper, { children: "This is the beginning of your conversation with KareHero AI." }), _jsx(Button, Object.assign({ ariaLabel: 'new conversation', onPress: onReset, size: 'sm', variant: 'link' }, { children: "Start new conversation" }))] }), messages === null || messages === void 0 ? void 0 : messages.map((message) => (_jsx(Message, { isSender: message.role === 'user', value: message.content }, message.id))), isTyping && (_jsx(Message, { children: _jsx(TypingDots, {}) })), _jsx(AlwaysScrollToBottom, { ref: ref })] }), _jsxs(Footer, { children: [_jsxs(MessageBox, Object.assign({ onSubmit: (e) => {
                                e.preventDefault();
                                handleSendMessage();
                            } }, { children: [_jsx(Input, { placeholder: 'Message...', value: message, onChange: (val) => setMessage(val) }), _jsx(SendButton, Object.assign({ type: 'submit' }, { children: _jsx(Icon, { icon: 'paper-plane-top', fontSize: 24, type: 'fas' }) }))] })), _jsx(Disclaimer, { children: "KareHero AI can make mistakes. Check important info." })] })] })) : (_jsx(Launcher, { isVisible: true, isApple: true, isOpen: isOpen, setIsOpen: setIsOpen })) })));
};
const Wrapper = styled.div `
  position: fixed;
  bottom: 0px;
  right: 0px;
  ${(props) => props.$isMobile &&
    css `
      bottom: 70px;
    `}
  ${(props) => props.$isApple &&
    css `
      bottom: 91px;
    `}
  z-index: 100000;

  padding: ${(props) => props.theme.spacing[12]};
`;
const ChatBody = styled.div `
  display: inline-flex;
  flex-direction: column;

  overflow: hidden;

  background: ${(props) => props.theme.color.neutral[10]};
  border-radius: 12px;
  box-shadow: ${(props) => props.theme.elevation[2]};
  font: ${(props) => props.theme.typography.body.sm.regular};
  width: 100%;
  max-width: 400px;
  height: 600px;
  max-height: 600px;
`;
const Header = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.color.primary[60]};
  gap: ${(props) => props.theme.spacing[12]};
  color: ${(props) => props.theme.color.neutral[10]};
  padding: ${(props) => props.theme.spacing[8]};
  padding-right: ${(props) => props.theme.spacing[16]};
`;
const HeaderItem = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[12]};
`;
const Keni = styled.div `
  width: 42px;
  height: 42px;

  & img {
    width: 100%;
    height: 100%;
  }
`;
const Name = styled.div `
  font: ${(props) => props.theme.typography.body.md.medium};
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
  padding: ${(props) => props.theme.spacing[16]};
  background: ${(props) => props.theme.color.neutral[20]};
  overflow-y: auto;
  flex: 1;
`;
const Footer = styled.div `
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[16]};
  padding-top: ${(props) => props.theme.spacing[16]};
  border-top: 1px solid ${(props) => props.theme.color.neutral[40]};
`;
const MessageBox = styled.form `
  display: flex;
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;
`;
const CloseButton = styled.button `
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.neutral[10]};

  transition-duration: 200ms;
  &:hover {
    color: ${(props) => props.theme.color.neutral[20]};
  }
`;
const SendButton = styled.button `
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.primary[60]};

  transition-duration: 200ms;
  &:hover {
    color: ${(props) => props.theme.color.primary[80]};
  }
`;
const Disclaimer = styled.div `
  font: ${(props) => props.theme.typography.body.xxs.regular};
  color: ${(props) => props.theme.color.neutral[70]};
  padding: 0 ${(props) => props.theme.spacing[16]};
  text-align: center;
`;
const AlwaysScrollToBottom = styled.div `
  height: 0;
  max-height: 0;
`;
const ChatStart = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
  padding-bottom: ${(props) => props.theme.spacing[20]};
`;
const ChatStartHelper = styled.div `
  text-align: center;
  font: ${(props) => props.theme.typography.body.xxs.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;
Chat.displayName = 'Chat';
export default Chat;
