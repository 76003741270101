// react
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// store
import { useLazyGetWebinarQuery } from 'store/api/cms';
import { Webinar as WebinarType } from '@karehero/models';

// components
import { WebinarContent as WebinarContentPage } from '@karehero/llama';

/**
 * WebinarContent shows the content of an Webinar from Sanity.
 */
const WebinarContent = () => {
  // state
  const [webinar, setWebinar] = useState<WebinarType>();

  // hooks
  const { webinarId } = useParams();
  const [getWebinar, { isLoading: isWebinarLoading }] =
    useLazyGetWebinarQuery();
  const navigate = useNavigate();

  // effects
  useEffect(() => {
    if (!webinarId) {
      navigate(`/library`);
      return;
    }
    (async () => {
      const { data, error } = await getWebinar(webinarId);
      if (error) {
        navigate(`/library`);
        return;
      }
      data && setWebinar(data);
    })();
  }, [webinarId, getWebinar, navigate]);

  return (
    <WebinarContentPage
      content={webinar}
      isLoading={!webinar ? true : isWebinarLoading}
      navigate={navigate}
      backPath={`/webinar`}
    />
  );
};

export default WebinarContent;
