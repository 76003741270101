/* eslint-disable import/prefer-default-export */
import { icons } from '../primitives/Icon';

export const makeRatingIconsData = (theme) => ({
  unsatisfactory: {
    icon: icons.check,
    label: 'Unsatisfactory',
    colour: theme.colour.feature.rating.Unsatisfactory,
  },
  inadequate: {
    icon: icons.check,
    label: 'Inadequate',
    colour: theme.colour.feature.rating.inadequate,
  },
  notPublished: {
    icon: icons.circleSolid,
    label: 'Not published',
  },
  weak: {
    icon: icons.circleSolid,
    label: 'Weak',
    colour: theme.colour.feature.rating.weak,
  },
  registered: {
    icon: icons.circleSolid,
    label: 'Registered',
    colour: theme.colour.feature.rating.registered,
  },
  adequate: {
    icon: icons.circleSolid,
    label: 'Adequate',
    colour: theme.colour.feature.rating.adequate,
  },
  requiresImprovement: {
    icon: icons.circleSolid,
    label: 'Requires improvement',
    colour: theme.colour.feature.rating.requiresImprovement,
  },
  good: {
    icon: icons.circleSolid,
    label: 'Good',
    colour: theme.colour.feature.rating.good,
  },
  veryGood: {
    icon: icons.star,
    label: 'Very good',
    colour: theme.colour.feature.rating.veryGood,
  },
  excellent: {
    icon: icons.star,
    label: 'Excellent',
    colour: theme.colour.feature.rating.excellent,
  },
  outstanding: {
    icon: icons.star,
    label: 'Outstanding',
    colour: theme.colour.feature.rating.outstanding,
  },
});
