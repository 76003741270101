import { useContext } from 'react';
import PropTypes from 'prop-types';

import { ProviderFilterContext } from '../../context/ProviderFilterContext';
import { CareTypeContext } from '../../context/CareTypeContext';

import Select from '../../components/primitives/form/Select';

export default function SortOrderFilter({ disabled }) {
  const {
    sortOrders,
    activeFilters: { enabledSortOrder },
    setCurrentSortOrder,
  } = useContext(ProviderFilterContext);
  const { careType } = useContext(CareTypeContext);

  const sortOptions = sortOrders[careType];
  const getLabelFromItem = ({ label }) => label;
  const getValueFromItem = ({ value }) => value;

  return (
    <Select
      name='Search results sort order'
      onChange={setCurrentSortOrder}
      items={sortOptions}
      getLabelFromItem={getLabelFromItem}
      getValueFromItem={getValueFromItem}
      value={enabledSortOrder}
      disabled={disabled}
    />
  );
}

SortOrderFilter.propTypes = {
  disabled: PropTypes.bool,
};

SortOrderFilter.defaultProps = {
  disabled: false,
};
