import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { providerType, regulatorType } from '../../types';
import { renderIf } from '../../RenderIf';

import TwoColumn from '../../primitives/layout/TwoColumn';
import Column from '../../primitives/Column';
import Heading from '../../primitives/typography/Heading';
import Title from '../../primitives/typography/Title';
import InlineText from '../../primitives/typography/InlineText';
import Spacer from '../../primitives/Spacer';
import Separator from '../../primitives/Separator';
import Map from '../../primitives/Map';
import ShowMore from '../../primitives/ShowMore';
import Specialisms from './components/Specialisms';
import Quote from '../../primitives/Quote';
import Gallery from '../../primitives/Gallery';

import InvisibleLink from './components/InvisibleLink';
import CareServiceTypes from './components/CareServiceTypes';
import Address from './components/Address';
import CareEnquiriesCard from './components/CareEnquiriesCard';
import RegulatorsRatings from './components/RegulatorsRatings';
import BackLink from './components/BackLink';
import Summary from './components/Summary';
import CovidResponse from './components/CovidResponse';
import CareHomeFunding from './components/CareHomeFunding';
import CareHomeFeatures from './components/CareHomeFeatures';
import EmptyGalleryPlaceholder from './components/EmptyGalleryPlaceholder';

export default function Profile({
  provider,
  googleMapsApiKey,
  buildSearchPath,
  lastSearchTerm,
  lastSearchRegulator,
  fireTrackingEvent,
  onEmailReveal,
  onPhoneReveal,
}) {
  const {
    name,
    enquiryEmail,
    enquiryPhone,
    photos = [],
    location,
    description,
    careServiceTypes,
    managerQuote,
    managerName,
    address,
    ratings,
    specialisms,
    pricePerWeek,
    bedCount,
    dateOpened,
    availability,
    covidResponse,
    doubleBedCount,
    enSuiteRoomCount,
    purposeBuilt,
    careType,
    hourlyRate,
    minimumVisitTime,
    regulatoryId,
  } = provider;
  const isCareHome = careType === 'careHome';

  const haveImages = photos.length > 0;
  const backLinkLocation = lastSearchTerm || address.postcode || address.city;
  const showMap = !!googleMapsApiKey && !!location && isCareHome;
  const shouldRenderMapAndGallery = showMap || haveImages;
  const RenderMapAndGallery = renderIf(shouldRenderMapAndGallery);
  const RenderOwnersDescription = renderIf(!!description);
  const RenderCareServiceTypes = renderIf(
    !!careServiceTypes && careServiceTypes.length > 0 && isCareHome,
  );
  const RenderManagerQuote = renderIf(!!managerQuote);
  const RenderCareHomeFunding = renderIf(!!lastSearchRegulator && isCareHome);
  const RenderRatings = renderIf(!!ratings);
  const RenderSpecialisms = renderIf(!!specialisms && specialisms.length > 0);
  const RenderCovidResponse = renderIf(isCareHome);
  const RenderCareHomeFeatures = renderIf(isCareHome);

  const mapAndGallerySplitPercentage = haveImages && !showMap ? 0 : 40;

  const careHomeFeatures = {
    bedCount,
    dateOpened,
    purposeBuilt,
    doubleBedCount,
    enSuiteRoomCount,
  };

  const hasCovidResponse = !!Object.values(covidResponse).flat().filter(Boolean)
    .length;

  const summary = {
    rating: ratings.overallRating,
    pricePerWeek,
    bedCount,
    dateOpened,
    availability,
    covidResponse: hasCovidResponse,
    hourlyRate,
    managerName,
    minimumVisitTime: minimumVisitTime?.label,
  };

  const topOfPage = useRef();
  const scrollTopOfPageIntoView = () => topOfPage.current.scrollIntoView();
  useEffect(scrollTopOfPageIntoView, [topOfPage]);

  const trackingWithProviderInfo = ({ name: eventName, properties }) =>
    fireTrackingEvent({
      name: eventName,
      properties: {
        providerName: name,
        regulatoryId,
        url: window?.location?.href,
        ...properties,
      },
    });

  return (
    <>
      <InvisibleLink id='top-of-page-marker' ref={topOfPage} />
      <TwoColumn
        noPaddingTopBottom={shouldRenderMapAndGallery}
        sidebarPercent={100}
        sidebar={
          <BackLink
            location={backLinkLocation}
            buildSearchPath={buildSearchPath}
            haveSearchTerm={!!lastSearchTerm}
          />
        }
        body={<></>}
      />
      <RenderMapAndGallery>
        <TwoColumn
          sidebarPercent={mapAndGallerySplitPercentage}
          sidebar={<Map coords={location} apiKey={googleMapsApiKey} />}
          body={
            haveImages ? (
              <Gallery images={photos} />
            ) : (
              <EmptyGalleryPlaceholder />
            )
          }
        />
      </RenderMapAndGallery>
      <TwoColumn
        noPaddingTopBottom
        sidebarPercent={40}
        sidebarPositionMobile='below'
        sidebar={
          <Column>
            <CareEnquiriesCard
              providerName={name}
              email={enquiryEmail}
              phoneNumber={enquiryPhone}
              fireTrackingEvent={trackingWithProviderInfo}
              onEmailReveal={onEmailReveal}
              onPhoneReveal={onPhoneReveal}
            />
          </Column>
        }
        body={
          <Column>
            <Heading>{name}</Heading>
            <Address address={address} />
            <Separator withMargin />
            <Title>Summary</Title>
            <Summary summaryItems={summary} careType={careType} />
            <Separator withMargin />
          </Column>
        }
      />
      <TwoColumn
        noPaddingTopBottom
        sidebarPercent={40}
        sidebar={<></>}
        body={
          <Column>
            <RenderOwnersDescription>
              <Title>Owner&apos;s description</Title>
              <Spacer top />
              <ShowMore
                text={description}
                moreTitle={<InlineText primary>Show More</InlineText>}
                lessTitle={<InlineText primary>Show Less</InlineText>}
                moreTitleText='Show More'
                lessTitleText='Show Less'
              />
              <Separator withMargin />
            </RenderOwnersDescription>
            <RenderCareServiceTypes>
              <Title>Types of care provided</Title>
              <CareServiceTypes serviceTypes={careServiceTypes} />
              <Separator withMargin />
            </RenderCareServiceTypes>
            <RenderSpecialisms>
              <Title>Services and specialisms</Title>
              <Specialisms specialisms={specialisms} />
              <Separator withMargin />
            </RenderSpecialisms>
            <RenderCareHomeFeatures>
              <Title>Care home features</Title>
              <CareHomeFeatures careHomeFeatures={careHomeFeatures} />
              <Separator withMargin />
            </RenderCareHomeFeatures>
            <RenderManagerQuote>
              <Title>Manager&apos;s quote</Title>
              <Quote author={managerName} title='Care home manager'>
                {managerQuote}
              </Quote>
              <Separator withMargin />
            </RenderManagerQuote>
            <RenderCareHomeFunding>
              <CareHomeFunding
                pricePerWeek={pricePerWeek}
                regulator={lastSearchRegulator}
              />
              <Separator withMargin />
            </RenderCareHomeFunding>
            <RenderRatings>
              <Title>Regulator&apos;s inspection rating</Title>
              <Spacer top>
                <RegulatorsRatings ratings={ratings} />
              </Spacer>
            </RenderRatings>
            <RenderCovidResponse>
              <Separator withMargin />
              <Title>Covid-19 response</Title>
              <Spacer top>
                <CovidResponse
                  covidResponse={covidResponse}
                  name={name}
                  hasCovidResponse={hasCovidResponse}
                />
              </Spacer>
            </RenderCovidResponse>
          </Column>
        }
      />
    </>
  );
}

Profile.propTypes = {
  provider: providerType.isRequired,
  googleMapsApiKey: PropTypes.string,
  buildSearchPath: PropTypes.func.isRequired,
  lastSearchRegulator: regulatorType,
  lastSearchTerm: PropTypes.string,
  fireTrackingEvent: PropTypes.func.isRequired,
  onEmailReveal: PropTypes.func,
  onPhoneReveal: PropTypes.func,
};

Profile.defaultProps = {
  googleMapsApiKey: undefined,
  lastSearchTerm: undefined,
  lastSearchRegulator: undefined,
  onEmailReveal: () => {},
  onPhoneReveal: () => {},
};
