import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// radix
import * as RadixDialog from '@radix-ui/react-dialog';
// icons
import { Icon } from "../Icon";
/**
 * Dialog opens a dialog window over the current page.
 */
export const Dialog = ({ title, description, variant = 'dialog', children, direction = 'right', tabLabel = 'Sheet', isClearOverlay = false, isMinContent = false, isTab = false, isCloseButton = true, isNoPadding = false, isOpen, setIsOpen, zIndex = 0, minSize = 'md', }) => {
    // memos
    const closeDialog = useMemo(() => {
        if (!isCloseButton && variant !== 'full')
            return;
        switch (variant) {
            case 'dialog':
                return (_jsx(DialogClose, Object.assign({ "aria-label": 'close', "$direction": direction, "$variant": variant }, { children: _jsx("span", Object.assign({ "aria-hidden": true }, { children: _jsx(Icon, { icon: 'xmark', fontSize: 24 }) })) })));
            case 'full':
                return (_jsx(DialogClose, Object.assign({ "aria-label": 'close', "$direction": direction, "$variant": variant }, { children: _jsx("span", Object.assign({ "aria-hidden": true }, { children: _jsx(Icon, { icon: 'xmark', type: 'fas', fontSize: 22 }) })) })));
            case 'sheet':
                const iconMap = {
                    top: 'chevrons-up',
                    bottom: 'chevrons-down',
                    left: 'chevrons-left',
                    right: 'chevrons-right',
                };
                const iconName = iconMap[direction];
                return (_jsx(DialogClose, Object.assign({ "aria-label": 'close', "$direction": direction, "$variant": variant }, { children: _jsx("span", Object.assign({ "aria-hidden": true }, { children: _jsx(Icon, { icon: iconName, fontSize: 16 }) })) })));
        }
    }, [direction, isCloseButton, variant]);
    const Content = useMemo(() => {
        switch (variant) {
            case 'dialog':
                return DialogContent;
            case 'sheet':
                return SheetContent;
            case 'full':
                return FullContent;
            default:
                return DialogContent;
        }
    }, [variant]);
    return (_jsxs(_Fragment, { children: [_jsx(RadixDialog.Root, Object.assign({ open: isOpen, onOpenChange: () => setIsOpen && setIsOpen(false) }, { children: _jsxs(RadixDialog.Portal, { children: [!isClearOverlay && _jsx(DialogOverlay, { "$zIndex": zIndex }), _jsxs(Content, Object.assign({ "$direction": direction, "$isMinContent": isMinContent, "$isCloseButton": isCloseButton, "$zIndex": zIndex, "$isNoPadding": isNoPadding, "$minSize": minSize }, { children: [closeDialog, title && _jsx(DialogTitle, { children: title }), description && (_jsx(DialogDescription, { children: description })), children] }))] }) })), isTab && (_jsx(Tab, Object.assign({ "$direction": direction, onClick: () => setIsOpen === null || setIsOpen === void 0 ? void 0 : setIsOpen(true) }, { children: tabLabel })))] }));
};
const DialogOverlay = styled(RadixDialog.Overlay) `
  background: ${(props) => props.theme.color.overlay};
  z-index: ${(props) => 5000000 + props.$zIndex * 100};
  position: fixed;
  inset: 0;
  &[data-state='open'] {
    animation: overlayShow 400ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-state='closed'] {
    animation: overlayHide 400ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes overlayHide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
const DialogContent = styled(RadixDialog.Content) `
  z-index: ${(props) => 5000001 + props.$zIndex * 100};
  background: ${(props) => props.theme.color.neutral[10]};
  border-radius: 24px;
  box-shadow: ${(props) => props.theme.elevation[6]};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: hidden;
  max-width: 1000px;
  max-height: 85vh;
  height: auto;

  width: calc(100% - ${(props) => props.theme.spacing[24]});
  ${(props) => props.theme.breakpoint.sm(css `
      width: auto;
    `)}

  ${(props) => {
    switch (props.$minSize) {
        case 'min':
            return css `
          min-width: 0px;
        `;
        case 'sm':
            return css `
          min-width: 520px;
          @media (max-width: 520px) {
            min-width: calc(100% - ${(props) => props.theme.spacing[24]});
          }
        `;
        case 'md':
            return css `
          min-width: 720px;
          @media (max-width: 720px) {
            min-width: calc(100% - ${(props) => props.theme.spacing[24]});
          }
        `;
        case 'lg':
            return css `
          min-width: 960px;
          @media (max-width: 960px) {
            min-width: calc(100% - ${(props) => props.theme.spacing[24]});
          }
        `;
        case 'xl':
            return css `
          min-width: 1200px;
          @media (max-width: 1200px) {
            min-width: calc(100% - ${(props) => props.theme.spacing[24]});
          }
        `;
    }
}}

  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[24]};
  ${(props) => props.$isNoPadding &&
    css `
      padding: 0;
      padding-top: ${(props) => props.theme.spacing[24]};
    `}

  border-radius: 16px;

  &:focus {
    outline: none;
  }

  &[data-state='open'] {
    animation: contentShow 400ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-state='closed'] {
    animation: contentHide 400ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @keyframes contentHide {
    from {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
  }
`;
const SheetContent = styled(RadixDialog.Content) `
  z-index: ${(props) => 5000001 + props.$zIndex * 100};
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[6]};
  position: fixed;
  ${(props) => props.$direction}: 0;

  box-sizing: border-box;

  padding: ${(props) => props.theme.spacing[80]}
    ${(props) => props.theme.spacing[32]};

  ${(props) => {
    switch (props.$direction) {
        case 'left':
        case 'right':
            return css `
          min-width: 320px;
          width: 100vw;
          max-width: 100vw;
          min-height: 100%;
          height: 100%;
          max-height: 100%;
          top: 0;
          ${props.theme.breakpoint.sm(css `
            min-width: 600px;
            ${props.$isMinContent
                ? css `
                  min-width: 320px;
                  width: min-content;
                `
                : css `
                  width: 50vw;
                `}
            padding: ${props.theme.spacing[80]};
          `)}
        `;
        case 'bottom':
        case 'top':
            return css `
          min-width: 100vw;
          width: 100vw;
          max-width: 100vw;
          min-height: 0;
          max-height: 100%;
          left: 0;
        `;
    }
}}

  ${(props) => {
    switch (props.$direction) {
        case 'left':
            return css `
          &[data-state='open'] {
            animation: contentShowLeft 400ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          &[data-state='closed'] {
            animation: contentHideLeft 400ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        `;
        case 'right':
            return css `
          &[data-state='open'] {
            animation: contentShowRight 400ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          &[data-state='closed'] {
            animation: contentHideRight 400ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        `;
        case 'bottom':
            return css `
          &[data-state='open'] {
            animation: contentShowBottom 400ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          &[data-state='closed'] {
            animation: contentHideBottom 400ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        `;
        case 'top':
            return css `
          &[data-state='open'] {
            animation: contentShowTop 400ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          &[data-state='closed'] {
            animation: contentHideTop 400ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
        `;
    }
}}

  &:focus {
    outline: none;
  }

  transform: translate(0%, 0%);
  @keyframes contentShowLeft {
    from {
      transform: translate(-100%, 0%);
    }
    to {
      transform: translate(0%, 0%);
    }
  }
  @keyframes contentShowRight {
    from {
      transform: translate(100%, 0%);
    }
    to {
      transform: translate(0%, 0%);
    }
  }
  @keyframes contentShowBottom {
    from {
      transform: translate(0%, 100%);
    }
    to {
      transform: translate(0%, 0%);
    }
  }
  @keyframes contentShowTop {
    from {
      transform: translate(0%, -100%);
    }
    to {
      transform: translate(0%, 0%);
    }
  }
  @keyframes contentHideLeft {
    from {
      transform: translate(0%, 0%);
    }
    to {
      transform: translate(-100%, 0%);
    }
  }
  @keyframes contentHideRight {
    from {
      transform: translate(0%, 0%);
    }
    to {
      transform: translate(100%, 0%);
    }
  }
  @keyframes contentHideBottom {
    from {
      transform: translate(0%, 0%);
    }
    to {
      transform: translate(0%, 100%);
    }
  }
  @keyframes contentHideTop {
    from {
      transform: translate(0%, 0%);
    }
    to {
      transform: translate(0%, -100%);
    }
  }
`;
const FullContent = styled(RadixDialog.Content) `
  z-index: ${(props) => 5000001 + props.$zIndex * 100};
  background: ${(props) => props.theme.color.cards.blue.secondary};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;

  overflow: hidden;

  justify-content: center;
  display: flex;
  flex-direction: column;

  &[data-state='open'] {
    animation: contentShow 800ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-state='closed'] {
    animation: contentHide 800ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes contentHide {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.9);
    }
  }
`;
const DialogClose = styled(RadixDialog.Close) `
  position: absolute;
  top: ${(props) => props.theme.spacing[16]};
  ${(props) => {
    switch (props.$variant) {
        case 'dialog':
            return css `
          right: ${props.theme.spacing[16]};
        `;
        case 'full':
            return css `
          top: ${props.theme.spacing[20]};
          right: ${props.theme.spacing[20]};
        `;
        case 'sheet':
            switch (props.$direction) {
                case 'left':
                    return css `
              right: ${(props) => props.theme.spacing[16]};
            `;
                case 'right':
                    return css `
              left: ${(props) => props.theme.spacing[16]};
            `;
                default:
                    return css `
              right: ${(props) => props.theme.spacing[16]};
            `;
            }
    }
}};
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  color: ${(props) => props.theme.color.neutral[80]};
  &:focus {
    outline: none;
  }
`;
const Tab = styled.div `
  position: fixed;
  top: 180px;
  ${(props) => props.$direction}: 0;
  background: ${(props) => props.theme.color.surfaces.mediumPurple};
  color: ${(props) => props.theme.color.neutral[10]};
  padding: ${(props) => props.theme.spacing[12]}
    ${(props) => props.theme.spacing[8]};

  font: ${(props) => props.theme.typography.body.sm.semibold};
  cursor: pointer;
  user-select: none;

  writing-mode: vertical-rl;
  text-orientation: mixed;
  ${(props) => props.$direction === 'left' &&
    css `
      transform: rotate(180deg);
    `}

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 99;
`;
const DialogTitle = styled(RadixDialog.Title) `
  margin: 0;
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.heading['4xl']};
  text-align: center;
`;
const DialogDescription = styled(RadixDialog.Description) `
  font: ${(props) => props.theme.typography.body.md.regular};
  margin: ${(props) => props.theme.spacing[16]} 0
    ${(props) => props.theme.spacing[20]};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: center;
`;
Dialog.displayName = 'Dialog';
export default Dialog;
