import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
/**
 * Divider displays a horizontal line with text in the middle.
 */
export const Divider = ({ className, isOr = true }) => {
    return (_jsxs(StyledDivider, Object.assign({ className: className }, { children: [_jsx(DividerLine, {}), isOr && (_jsxs(_Fragment, { children: ["OR", _jsx(DividerLine, {})] }))] })));
};
const StyledDivider = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  width: 100%;
  color: ${(props) => props.theme.color.neutral[70]};
  font: ${(props) => props.theme.typography.body.xs.regular};
`;
const DividerLine = styled.div `
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[50]};
  flex: 1;
`;
Divider.displayName = 'Divider';
export default Divider;
