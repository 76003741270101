import { styled } from '../../../styling';

const Container = styled.div`
  cursor: pointer;
  ${({ theme, focused }) =>
    focused && `outline: 1px solid ${theme.colour.primary};`}
  ${({ disabled }) => disabled && 'cursor: not-allowed'}
`;

export default Container;
