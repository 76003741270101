// styled-components
import { css } from 'styled-components';
export const generateFont = (fontFamily, fontSize, lineHeight, fontWeight) => css `
  ${fontWeight} ${fontSize}px/${lineHeight} '${fontFamily}', sans-serif
`;
const weights = {
    thin: 100,
    extralight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
};
export const generateWeightedFont = (fontFamily, fontSize, lineHeight) => {
    let weightedFont = {};
    for (const weightKey in weights) {
        const key = weightKey;
        const fontWeight = weights[key];
        if (!fontWeight) {
            continue;
        }
        weightedFont[key] = generateFont(fontFamily, fontSize, lineHeight, fontWeight);
    }
    return weightedFont;
};
export const generateBreakpoint = (min, type, columns, margin, gutter) => (serializedStyles) => css `
    @media (min-width: ${min}px) {
      ${serializedStyles}
    }
  `;
