import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// components
import { OnboardingActionType, toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
import { Card } from "../../atoms";
// assets
import { css } from 'styled-components';
/**
 * OnboardingRadio renders the Radio screen for onboarding flows.
 */
export const OnboardingRadio = toGenericOnboarding(({ config, currentIndex = 0, handleClick, }) => {
    var _a, _b, _c, _d, _e;
    return (_jsx(Background, { children: _jsxs(StyledOnboardingRadio, { children: [_jsxs(Wrapper, { children: [((_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.title) && (_jsx(Title, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.title })), ((_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.subTitle) && (_jsx(SubTitle, { children: (_d = config[currentIndex]) === null || _d === void 0 ? void 0 : _d.subTitle }))] }), _jsx(Content, { children: config[currentIndex].options && (_jsx(RadioWrapper, { children: (_e = config[currentIndex].options) === null || _e === void 0 ? void 0 : _e.map((option) => (_jsx(Radio, Object.assign({ to: option.to, onClick: () => {
                                handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.End);
                            } }, { children: _jsx(Card, Object.assign({ elevation: 0 }, { children: _jsxs(ItemWrapper, { children: [_jsx(TextWrapper, { children: option.text }), option.subText && (_jsx(SubTextWrapper, { children: option.subText }))] }) })) }), option.to))) })) })] }) }));
});
const StyledOnboardingRadio = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  gap: ${(props) => props.theme.spacing[32]};

  height: 100%;
`;
const Background = styled.div `
  height: 100%;
`;
const Content = styled.div `
  width: 100%;
  min-height: 0;
  overflow: hidden;
  padding: ${(props) => props.theme.spacing[12]};
  overflow-y: auto;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[32]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['6xl']};
  color: ${(props) => props.theme.color.primary[80]};
  min-height: 60px;
  text-align: left;
  width: 100%;
  ${(props) => props.theme.breakpoint.md(css `
      min-height: none;
      font: ${props.theme.typography.heading['6xl']};
    `)};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
  margin-bottom: ${(props) => props.theme.spacing[8]};
  text-align: left;
  width: 100%;
`;
const RadioWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  margin-bottom: ${(props) => props.theme.spacing[48]};
  overflow: visible;

  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Radio = styled(Link) `
  text-decoration: none;
  width: 100%;
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.sm.medium};
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;

  ${(props) => props.theme.breakpoint.sm(css `
      font: ${props.theme.typography.body.md.medium};
    `)}

  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.02);
  }
`;
const ItemWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]};
`;
const TextWrapper = styled.div `
  display: flex;
  align-items: center;
`;
const SubTextWrapper = styled.div `
  display: flex;
  align-items: center;
  font: ${(props) => props.theme.typography.body.sm.medium};
  color: ${(props) => props.theme.color.neutral[70]};
`;
OnboardingRadio.displayName = 'OnboardingRadio';
export default toGenericOnboarding(OnboardingRadio);
