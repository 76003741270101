import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Container } from "../../atoms/Container/Container";
import { Logo } from "../../atoms/Logo/Logo";
import { ResetPasswordForm } from "../../molecules/ResetPasswordForm/ResetPasswordForm";
import { FloatingWithHeroTemplate } from "../../templates/FloatingWithHeroTemplate/FloatingWithHeroTemplate";
// assets
// @transform-path ../../../../../../src/assets/hero.svg
import hero from "../../../../../../src/assets/hero.svg";
/**
 * ResetPassword is the page where the user can sign in.
 */
export const ResetPassword = ({ onSubmit }) => {
    return (_jsx(_Fragment, { children: _jsx(FloatingWithHeroTemplate, { content: _jsx(Content, { children: _jsxs(Container, Object.assign({ size: 'sm' }, { children: [_jsx(LogoContainer, { children: _jsx(Logo, { size: 'lg' }) }), _jsx(Title, { children: "Reset Password" }), _jsx(WelcomeText, { children: "Please enter a new password." }), _jsx(ResetPasswordForm, { onSubmit: onSubmit })] })) }), hero: _jsx(HeroContainer, { children: _jsx("img", { src: hero, alt: 'hero' }) }) }) }));
};
const LogoContainer = styled.div `
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing[32]};
`;
const Content = styled.div `
  padding: 0 ${(props) => props.theme.spacing[16]};
`;
const HeroContainer = styled.div `
  display: flex;
  height: 100%;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['6xl']};
  color: ${(props) => props.theme.color.primary[80]};
  margin-bottom: ${(props) => props.theme.spacing[12]};
  text-align: center;
`;
const WelcomeText = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  margin-bottom: ${(props) => props.theme.spacing[24]};
  text-align: center;
`;
ResetPassword.displayName = 'ResetPassword';
export default ResetPassword;
