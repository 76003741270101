import api from '.';
import { Personalization } from '@karehero/models';

export const personalizationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllPersonalization: builder.query<Personalization[], void>({
      query: () => '/personalization',
      providesTags: ['Personalization'],
    }),
  }),
});

export const { useGetAllPersonalizationQuery } = personalizationApi;

export default personalizationApi;
