import PropTypes from 'prop-types';
import { styled, useThemeHook } from '../styling';
import { locationType } from '../types';

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export default function Map({ apiKey, coords }) {
  const { colour } = useThemeHook();

  if (!coords) {
    return <></>;
  }

  const width = '800';
  const height = '300';
  const markerColour = colour.primary.replace('#', '');
  const mapString = `https://maps.googleapis.com/maps/api/staticmap?zoom=14&markers=color:0x${markerColour}|${coords.lat},${coords.lng}&size=${width}x${height}&maptype=roadmap&key=${apiKey}`;
  return (
    <Img alt='Map showing location of this care provider' src={mapString} />
  );
}

Map.propTypes = {
  apiKey: PropTypes.string.isRequired,
  coords: locationType.isRequired,
};
