// components
import { ActionPlanTable as ActionPlanTablePage } from '@karehero/llama';

// store
import {
  useGetAllActionPlanQuery,
  useUpdateActionPlanMutation,
} from 'store/api/actionPlan';

/**
 * ActionPlanTable is shows the limits and usage of enabled tiers for an organization.
 */
const ActionPlanTable = ({ isEditable = true }: { isEditable?: boolean }) => {
  // hooks
  const { data: actionPlanItems } = useGetAllActionPlanQuery();
  const [updateActionPlan] = useUpdateActionPlanMutation();

  return (
    <ActionPlanTablePage
      isEditable={isEditable}
      actionPlanItems={actionPlanItems}
      updateActionPlan={updateActionPlan}
    />
  );
};

export default ActionPlanTable;
