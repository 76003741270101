import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// components
import LoadingArticleContent from './LoadingArticleContent';
import { Chip, Icon, LoadingTransition, Skeleton, } from "../../atoms";
import { BackButton } from "../../molecules";
import { Outgrow, SanityContent } from "../../organisms";
// utils
import { buildImageUrl } from "../../../utils/sanity";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import { theme } from "../../../theme";
/**
 * ArticleContent displays the content for a single article.
 */
export const ArticleContent = ({ content, isLoading = true, onBookmark, isBookmarked, }) => {
    var _a, _b;
    // hooks
    const [width] = useWindowSize();
    // memo
    const isCtaMobile = useMemo(() => width < theme.breakpointValues.lgValue, [width]);
    const publishedDate = useMemo(() => {
        if (!(content === null || content === void 0 ? void 0 : content._createdAt)) {
            return '';
        }
        const date = new Date(content._createdAt);
        return `${date.toLocaleString('default', {
            month: 'long',
        })} ${date.getDate()}, ${date.getFullYear()}`;
    }, [content]);
    const headerImageUrl = useMemo(() => buildImageUrl(content === null || content === void 0 ? void 0 : content.headerImageUrl, 800), [content]);
    const readingTime = useMemo(() => {
        if (!(content === null || content === void 0 ? void 0 : content.content)) {
            return '';
        }
        const wordsPerMinute = 200;
        const numberOfWords = content.content.reduce((acc, curr) => {
            var _a;
            if (curr._type === 'block') {
                return (acc +
                    (((_a = curr === null || curr === void 0 ? void 0 : curr.children) === null || _a === void 0 ? void 0 : _a.reduce((acc, curr) => {
                        return acc + curr.text.split(' ').length;
                    }, 0)) || 0));
            }
            return acc;
        }, 0);
        const minutes = Math.ceil(numberOfWords / wordsPerMinute);
        return `${minutes} minute read`;
    }, [content]);
    return (_jsxs(StyledArticleContentPage, { children: [_jsx(BackButton, {}), _jsxs(StyledArticleContent, { children: [_jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Skeleton, { shape: 'header-image', isRounded: false, isMargin: false }), content: _jsx(HeaderImage, { children: _jsx("img", { src: headerImageUrl, alt: content === null || content === void 0 ? void 0 : content.title, onError: ({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        'https://cdn.sanity.io/images/x7r1t5o8/production/e8579e2a4c3766cf0cf99a24a9d0679d2d2bbeb4-5322x2365.jpg?h=800';
                                } }) }) }), _jsx(Wrapper, { children: _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(LoadingArticleContent, {}), content: _jsxs(ArticleContentWrapper, { children: [(content === null || content === void 0 ? void 0 : content.title) && _jsx(Title, { children: content === null || content === void 0 ? void 0 : content.title }), (content === null || content === void 0 ? void 0 : content.tags) && ((_a = content === null || content === void 0 ? void 0 : content.tags) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsx(Tags, { children: (_b = content === null || content === void 0 ? void 0 : content.tags) === null || _b === void 0 ? void 0 : _b.map((tag) => (_jsx(Chip, { label: tag.label }, `${content === null || content === void 0 ? void 0 : content.title}-${tag.label}`))) })), ((content === null || content === void 0 ? void 0 : content.publisher) || publishedDate) && (_jsxs(Attribution, { children: [(content === null || content === void 0 ? void 0 : content.publisher) && (_jsxs(Publisher, { children: ["By ", content === null || content === void 0 ? void 0 : content.publisher] })), _jsxs(DateText, { children: ["Published: ", publishedDate] })] })), _jsxs(Meta, { children: [_jsx(ReadingTime, { children: readingTime || '0 minute read' }), _jsxs(BookmarkWrapper, Object.assign({ onClick: (e) => {
                                                    e.stopPropagation();
                                                    content && (onBookmark === null || onBookmark === void 0 ? void 0 : onBookmark(content));
                                                } }, { children: [_jsx(Icon, { icon: 'bookmark', type: isBookmarked ? 'fal' : 'fas' }), isBookmarked ? 'Save' : 'Unsave'] }))] }), _jsx(SanityContent, { content: content === null || content === void 0 ? void 0 : content.content }), (content === null || content === void 0 ? void 0 : content.outgrowCalculatorUrl) && (_jsx(Outgrow, { calculatorUrl: content.outgrowCalculatorUrl }))] }) }) })] })] }));
};
const StyledArticleContentPage = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[12]};
  padding-bottom: ${(props) => props.theme.spacing[48]};
`;
const StyledArticleContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[48]};
`;
const Wrapper = styled.div `
  display: flex;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[128]};
`;
const ArticleContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const HeaderImage = styled.div `
  border-radius: 8px;
  overflow: hidden;
  height: 180px;
  ${(props) => props.theme.breakpoint.sm(css `
      height: 300px;
    `)}
  display: flex;
  align-items: center;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['7xl']};
  color: ${(props) => props.theme.color.primary[80]};
  margin-bottom: ${(props) => props.theme.spacing[32]};
`;
const Tags = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
  margin-bottom: ${(props) => props.theme.spacing[32]};
`;
const Attribution = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]};
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const ReadingTime = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Publisher = styled.div `
  font: ${(props) => props.theme.typography.body.xs.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const DateText = styled.div `
  font: ${(props) => props.theme.typography.body.xs.regular};
  color: ${(props) => props.theme.color.neutral[70]};
`;
const Meta = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${(props) => props.theme.spacing[16]} 0;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
  border-left: none;
  border-right: none;
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const BookmarkWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  height: 24px;
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.primary[90]};
  cursor: pointer;
  user-select: none;

  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;
ArticleContent.displayName = 'ArticleContent';
export default ArticleContent;
