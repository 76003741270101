// react
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';

// store
import {
  useRegisterMemberByDomainMutation,
  useRegisterMemberByInviteKeyMutation,
} from 'store/api/iam';
import { useGetOrganizationInfoQuery } from 'store/api/organization';

// hooks
import { useKinde } from 'hooks';

// components
import {
  InviteKeyRegistration as InviteKeyRegistrationPage,
  Loading as LoadingPage,
  OnboardingFlow,
  OnboardingKeni,
  Toast,
} from '@karehero/llama';

// config
import { onboardingKeniConfirmEmailConfig } from 'config/onboarding';

/**
 * InviteKeyRegistration allows registering with an invite key or domain.
 */
const InviteKeyRegistration = () => {
  // states
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isOnboardingLoading, setIsOnboardingLoading] = useState(false);

  // hooks
  const { signIn } = useKinde();
  const navigate = useNavigate();
  const { organizationID } = useParams();
  const [registerMemberByDomain] = useRegisterMemberByDomainMutation();
  const [registerMemberByInviteKey] = useRegisterMemberByInviteKeyMutation();

  const {
    data: organization,
    isLoading,
    isError,
  } = useGetOrganizationInfoQuery(organizationID || '');

  // effects
  useEffect(() => {
    if (isError) navigate('/sign-in');
  }, [isError, navigate]);

  // methods
  const handleRegisterMemberByDomain = useCallback(
    async (email: string): Promise<boolean> => {
      const res = await registerMemberByDomain({ email });
      if ('error' in res) {
        if ('status' in res.error) {
          if (res.error.status === 409) {
            signIn();
          }
        }
        return false;
      }
      setIsOnboardingLoading(true);
      return true;
    },
    [registerMemberByDomain, signIn],
  );

  const handleRegisterMemberByInviteKey = useCallback(
    async (organizationID: string, inviteKey: string) => {
      const res = await registerMemberByInviteKey({
        organizationID,
        inviteKey,
      });
      if ('data' in res) {
        // @ts-ignore
        window.location.href = res.data.message;
      }
    },
    [registerMemberByInviteKey],
  );

  const handleSubmit = useCallback(
    async (organizationID: string, inviteKey: string, email: string) => {
      if (!inviteKey.trim() && !email.trim()) {
        setIsToastOpen(true);
        return;
      }

      inviteKey.trim()
        ? handleRegisterMemberByInviteKey(organizationID, inviteKey)
        : handleRegisterMemberByDomain(email);
    },
    [handleRegisterMemberByInviteKey, handleRegisterMemberByDomain],
  );

  // memos
  const onboardingMemberRegisterConfig = useMemo(
    () => [
      {
        id: 'keni',
        Component: OnboardingKeni,
        config: onboardingKeniConfirmEmailConfig,
      },
    ],
    [],
  );

  return isOnboardingLoading ? (
    <OnboardingFlow
      config={onboardingMemberRegisterConfig}
      onComplete={() => {}}
    />
  ) : isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <Toast
        title='Invalid Input'
        description='Please enter a valid invite key or your work email address'
        variant='error'
        isOpen={isToastOpen}
        setIsOpen={setIsToastOpen}
        duration={3000}
      />
      {organization && (
        <InviteKeyRegistrationPage
          organization={organization}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default InviteKeyRegistration;
