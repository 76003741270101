import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
// component
import { LoadingTransition, Search, Title, TooltipOnboarding, } from "../../atoms";
import { ChipGroup } from "../../molecules";
import { ArticleFiltered, ArticleSummary } from "../../organisms";
const onboardingSteps = 2;
/**
 * ArticleOverview is a page that displays all of the content that available
 * to the user.
 */
export const ArticleOverview = ({ library, bookmarkedArticles, onBookmark, isLoading, searchValue, onSearchChange, isSearchLoading, quickResultsHeader, quickResults, filterChips, filteredArticles, isFilteredLoading, isOnboarding, toBookmarks, onOnboardingComplete, isEasterEgg, }) => {
    // state
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [onboardingIndex, setOnboardingIndex] = useState(isOnboarding ? 0 : -1);
    const dummyArticles = useMemo(() => {
        let dummyArticles = [];
        for (let i = 0; i < 32; i++) {
            dummyArticles.push({ title: `Article ${i}` });
        }
        return dummyArticles;
    }, []);
    // memos
    const dummyLibrary = useMemo(() => {
        let dummyLibrary = [];
        for (let i = 0; i < 4; i++) {
            dummyLibrary.push({
                title: `Category ${i}`,
                articles: dummyArticles,
            });
        }
        return dummyLibrary;
    }, [dummyArticles]);
    return (_jsxs(StyledArticleOverview, { children: [_jsx(TooltipOnboarding, Object.assign({ index: 0, title: 'Searching', content: 'Explore the library by using the search bar and filters', total: onboardingSteps, current: onboardingIndex, setCurrent: setOnboardingIndex, onComplete: onOnboardingComplete }, { children: _jsxs(MainHeader, { children: [_jsxs(Header, { children: [_jsx(Title, Object.assign({ isNoMargin: true }, { children: "Library" })), _jsxs(PageHeader, { children: [_jsx(HeaderLeft, { children: _jsx(Description, { children: "This is where you\u2019ll find useful resources, interesting read and useful videos." }) }), _jsx(HeaderRight, { children: _jsx(Search, { isOpen: isSearchOpen, setIsOpen: setIsSearchOpen, value: searchValue, isLoading: isSearchLoading, onChange: onSearchChange, quickResultsHeader: quickResultsHeader, quickResults: quickResults, isEasterEgg: isEasterEgg }) })] })] }), _jsx(FilterWrapper, { children: _jsx(ChipGroup, { chips: [
                                    ...(filterChips ? filterChips : []),
                                    {
                                        label: 'Bookmarks',
                                        icon: 'bookmark',
                                        to: toBookmarks || '/',
                                    },
                                ] }) })] }) })), _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Content, { children: isFilteredLoading ? (_jsx(ArticleFiltered, { articles: dummyArticles, isLoading: true })) : (_jsx(ArticleSummary, { library: dummyLibrary, isLoading: true })) }), content: _jsx(_Fragment, { children: filteredArticles ? (_jsx(Content, { children: _jsx(ArticleFiltered, { articles: filteredArticles, bookmarkedArticles: bookmarkedArticles, onBookmark: onBookmark, isLoading: false }) })) : (_jsx(TooltipOnboarding, Object.assign({ index: 1, title: 'Discover', content: 'Find articles that may be of interest to you', total: onboardingSteps, current: onboardingIndex, setCurrent: setOnboardingIndex, onComplete: onOnboardingComplete }, { children: _jsx(Content, { children: _jsx(ArticleSummary, { library: library, bookmarkedArticles: bookmarkedArticles, onBookmark: onBookmark, isLoading: false }) }) }))) }) })] }));
};
const StyledArticleOverview = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
  padding-bottom: ${(props) => props.theme.spacing[8]};
  padding-left: ${(props) => props.theme.spacing[4]};
  padding-right: ${(props) => props.theme.spacing[4]};
  overflow-x: visible;
  ${(props) => props.theme.breakpoint.sm(css `
      overflow-x: hidden;
    `)}
`;
const MainHeader = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
  width: 100%;
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
`;
const PageHeader = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};

  ${(props) => props.theme.breakpoint.md(css `
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `)}
`;
const HeaderLeft = styled.div `
  display: flex;
  flex-direction: column;
`;
const HeaderRight = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const Content = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[32]};
  flex-wrap: wrap;
`;
const FilterWrapper = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[16]};
  max-width: 100%;
`;
ArticleOverview.displayName = 'ArticleOverview';
export default ArticleOverview;
