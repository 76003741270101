import PropTypes from 'prop-types';

import { styled, useThemeHook } from '../styling';
import Row from './Row';
import Strong from './typography/Strong';
import InlineText from './typography/InlineText';
import { ColourIcon } from './Icon';
import { iconType } from '../types';
import Column from './Column';

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ theme }) => theme.spacing.units(4)};
  margin-right: ${({ theme }) => theme.spacing.units(4)};
`;

export default function IconLabel({
  icon,
  label,
  description,
  colour,
  disabled,
  alignCentre,
}) {
  const theme = useThemeHook();

  return (
    <Row alignCentre={alignCentre}>
      <IconContainer>
        <ColourIcon
          icon={icon}
          colour={
            disabled
              ? theme.colour.status.disabled
              : colour || theme.colour.neutral.dark
          }
        />
      </IconContainer>
      <Column>
        <Strong disabled={disabled}>{label}</Strong>
        <InlineText disabled={disabled}>{description}</InlineText>
      </Column>
    </Row>
  );
}

IconLabel.propTypes = {
  icon: iconType.isRequired,
  label: PropTypes.node,
  colour: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  alignCentre: PropTypes.bool,
};

IconLabel.defaultProps = {
  colour: undefined,
  label: undefined,
  description: undefined,
  disabled: undefined,
  alignCentre: false,
};
