import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
import { Container, MultiSelectChip } from "../../atoms";
/**
 * OnboardingInterests renders the Register screen for onboarding flows.
 */
export const OnboardingInterests = toGenericOnboarding(({ config, currentIndex, setIsNextDisabled, }) => {
    var _a, _b;
    if (!config)
        return null;
    return (_jsx(Container, Object.assign({ size: 'md' }, { children: _jsxs(Content, { children: [_jsx(Title, { children: "What topics are you interested in?" }), _jsx(Text, { children: "We will personalise your library for you, and you can always choose more later" }), _jsx(MultiSelectChip, { label: '', description: '', isCustom: false, options: [
                        {
                            value: 'all',
                            label: 'All Topics',
                        },
                        {
                            value: 'care-providers',
                            label: 'Care Providers',
                        },
                        {
                            value: 'housing',
                            label: 'Housing',
                        },
                        {
                            value: 'cognitive',
                            label: 'Cognitive',
                        },
                        {
                            value: 'medical',
                            label: 'Medical',
                        },
                        {
                            value: 'leaving-hospital',
                            label: 'Leaving Hospital',
                        },
                        {
                            value: 'care-needs',
                            label: 'Care Needs',
                        },
                        {
                            value: 'dementia',
                            label: 'Dementia',
                        },
                        {
                            value: 'power-of-attorney',
                            label: 'Power of Attorney',
                        },
                        {
                            value: 'care-funding',
                            label: 'Care Funding',
                        },
                        {
                            value: 'support',
                            label: 'Support',
                        },
                        {
                            value: 'finding-care',
                            label: 'Finding Care',
                        },
                        {
                            value: 'bereavement',
                            label: 'Bereavement',
                        },
                    ], value: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.value, onChange: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.onChange })] }) })));
});
const Title = styled.div `
  width: 100%;
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.heading['3xl']};

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.heading['6xl']};
    `)}
`;
const Text = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.xs.regular};

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.body.xl.regular};
    `)}
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  max-height: 100%;
  gap: ${(props) => props.theme.spacing[16]};
  padding-top: ${(props) => props.theme.spacing[32]};
`;
OnboardingInterests.displayName = 'OnboardingInterests';
export default toGenericOnboarding(OnboardingInterests);
