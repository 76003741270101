import { styled } from '../../styling';

const Strong = styled.strong`
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme, colour }) => colour || theme.colour.neutral.dark};
  ${({ theme, disabled }) =>
    disabled && `color: ${theme.colour.status.disabled}`};
  ${({ theme, light }) => light && `color: ${theme.colour.neutral.light}`};
  font-family: ${({ theme }) => theme.typography.fontFamilyBody};
  text-transform: ${({ transform }) => transform || 'initial'};
`;

export default Strong;
