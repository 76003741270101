import PropTypes from 'prop-types';

import { styled } from '../../../styling';

const Container = styled.div`
  display: flex;
  justify-content: center;
  border-radius: ${({ theme }) => theme.spacing.units(6)};
  padding: ${({ theme }) => theme.spacing[8]}
    ${({ theme }) => theme.spacing[12]};
  background: ${({ theme }) => theme.color.neutral[10]};
  color: ${({ theme }) => theme.color.neutral[90]};
  border: 1px solid ${({ theme }) => theme.color.neutral[40]};
  font: ${({ theme }) => theme.typography.body.sm.medium};
  box-shadow: ${({ theme }) => theme.elevation[2]};
  position: absolute;
  top: 0;
  left: 0;
`;

export default function AvailabilityPill({ children }) {
  return <Container>{children}</Container>;
}

AvailabilityPill.propTypes = {
  children: PropTypes.node.isRequired,
};
