import PropTypes from 'prop-types';
import { styled } from '../../styling';

const Input = styled.input`
  border-radius: ${({ theme }) => theme.spacing.units(1)};
  padding: ${({ theme }) =>
    `${theme.spacing.units(1)} ${theme.spacing.units(2)}`};
  border: 1px solid ${({ theme }) => theme.colour.primary};
  outline-color: ${({ theme }) => theme.colour.primary};
  ${({ grow }) => grow && 'flex-grow: 1'};
  ${({ grow }) => grow && 'width: 100%;'};
  height: ${({ theme }) => theme.spacing.units(8)};
`;

const TextBox = ({
  placeholder,
  value,
  onChange,
  grow,
  ariaLabel,
  keyboardEventHandler,
}) => (
  <Input
    grow={grow}
    type='text'
    placeholder={placeholder}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    aria-label={ariaLabel}
    onKeyDown={keyboardEventHandler}
  />
);

TextBox.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  grow: PropTypes.bool,
  ariaLabel: PropTypes.string,
  keyboardEventHandler: PropTypes.func,
};

TextBox.defaultProps = {
  placeholder: undefined,
  grow: false,
  ariaLabel: undefined,
  keyboardEventHandler: undefined,
};

export default TextBox;
