import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import { providerType } from '../../types';

import {
  Body,
  CareServiceTypeContainer,
  Container,
  Footer,
  Header,
} from './components/Layout';
import IconLabel from '../../primitives/IconLabel';
import CareProvidedLabel from './components/CareProvidedLabel';
import CoverImage from './components/CoverImage';

import Row from '../../primitives/Row';
import Column from '../../primitives/Column';
import Title from '../../primitives/typography/Title';
import Paragraph from '../../primitives/typography/Paragraph';
import Button from '../../primitives/Button';
import { icons } from '../../primitives/Icon';
import Spacer from '../../primitives/Spacer';
import Currency from '../../primitives/Currency';
import Distance from './components/Distance';
import AvailabilityPill from './components/AvailabilityPill';
import { makeRatingIconsData } from '../makeRatingIconsData';
import { useThemeHook } from '../../styling';
import InlineText from '../../primitives/typography/InlineText';

const CardContainer = styled(Container)`
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.elevation[1]};
  border: 1px solid ${({ theme }) => theme.color.neutral[40]};
  overflow: hidden;
`;

const CardBody = styled(Body)`
  padding: ${({ theme }) => theme.spacing[8]};
  padding-bottom: ${({ theme }) => theme.spacing[12]};
`;

export default function ProviderCard({
  provider: {
    careType,
    name,
    regulatoryId,
    address,
    coverPhoto,
    pricePerWeek,
    ratings,
    distance,
    availability,
    careServiceTypes,
    minimumVisitTime,
    hourlyRate,
  },
  onReadMoreClick,
}) {
  const theme = useThemeHook();

  const isCareHome = careType === 'careHome';
  const isHomeCare = careType === 'homeCare';
  const overallRating =
    ratings &&
    ratings.overallRating &&
    makeRatingIconsData(theme)[ratings.overallRating];
  const coverPhotoSourcePath = prop('medium', coverPhoto);

  const infoIcons = [
    {
      show: !!distance && isCareHome,
      key: 'distance',
      icon: icons.mapMarker,
      label: (
        <>
          <Distance value={distance} /> miles away
        </>
      ),
    },
    overallRating && {
      show: !!overallRating,
      key: 'rating',
      icon: overallRating.icon,
      label: (
        <>
          {overallRating.label} -{' '}
          <InlineText>Regulator&apos;s rating</InlineText>
        </>
      ),
      colour: overallRating.colour,
    },
    !!pricePerWeek && {
      show: !!pricePerWeek && isCareHome,
      key: 'price',
      icon: icons.poundSign,
      label: (
        <>
          <Currency value={pricePerWeek} /> -{' '}
          <InlineText>Starting price per week</InlineText>
        </>
      ),
    },
    !!hourlyRate && {
      show: !!hourlyRate && isHomeCare,
      key: 'hourlyRate',
      icon: icons.poundSign,
      label: (
        <>
          <Currency value={hourlyRate} /> -{' '}
          <InlineText>Starting hourly rate</InlineText>
        </>
      ),
    },
    {
      show: !!minimumVisitTime && isHomeCare,
      key: 'minimumVisitTime',
      icon: icons.clock,
      label: (
        <>
          {minimumVisitTime?.label} -{' '}
          <InlineText>Minimum visit time</InlineText>
        </>
      ),
    },
  ];

  const oneLineAddress = [
    address.streetAddress1,
    address.city,
    address.postcode,
  ]
    .filter(Boolean)
    .join(', ');

  const availabilityLabel = {
    careHome: 'Rooms available',
    homeCare: 'Accepting clients',
  }[careType];

  return (
    <CardContainer>
      <Header>
        <CoverImage source={coverPhotoSourcePath} />
        {availability && (
          <AvailabilityPill>{availabilityLabel}</AvailabilityPill>
        )}
      </Header>
      <CardBody>
        <Column>
          <Title>{name}</Title>
          {oneLineAddress && (
            <Spacer top>
              <Paragraph>{oneLineAddress}</Paragraph>
            </Spacer>
          )}

          {careServiceTypes && (
            <CareServiceTypeContainer flexWrap>
              {careServiceTypes.map((care) => (
                <Spacer right top key={care}>
                  <CareProvidedLabel label={care} />
                </Spacer>
              ))}
            </CareServiceTypeContainer>
          )}

          {infoIcons.filter(Boolean).map(
            ({ show, key, icon, label, colour }) =>
              show && (
                <Spacer top key={key}>
                  <IconLabel
                    alignCentre
                    icon={icon}
                    label={label}
                    colour={colour}
                  />
                </Spacer>
              ),
          )}
        </Column>
      </CardBody>
      <Footer>
        <Row fullWidth>
          <Button
            fullWidth
            onClick={() => onReadMoreClick(regulatoryId)}
            title={`Read more about ${name}`}
          >
            Read More
          </Button>
        </Row>
      </Footer>
    </CardContainer>
  );
}

ProviderCard.propTypes = {
  provider: providerType.isRequired,
  onReadMoreClick: PropTypes.func.isRequired,
};
