import PropTypes from 'prop-types';

import Spacer from '../../components/primitives/Spacer';
import Button from '../../components/primitives/Button';
import Row from '../../components/primitives/Row';

import useProviderSearch from '../../hooks/useProviderSearch';
import useOnPageEvent from '../../hooks/useOnPageEvent';

export default function GetNextSearchResultsButton({ text }) {
  const { getNextSearchResults } = useProviderSearch();
  const { onLoadMoreProvidersClick } = useOnPageEvent();

  const logClickAndLoadMore = () => {
    onLoadMoreProvidersClick({});
    getNextSearchResults();
  };

  return (
    <Row justifyCentre>
      <Spacer top>
        <Button noChevron onClick={logClickAndLoadMore}>
          {text}
        </Button>
      </Spacer>
    </Row>
  );
}

GetNextSearchResultsButton.propTypes = {
  text: PropTypes.string,
};

GetNextSearchResultsButton.defaultProps = {
  text: 'Load more',
};
