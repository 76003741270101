import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { Icon } from "../Icon/Icon";
/**
 * TextIcon renders a text with icon.
 */
export const TextIcon = ({ text, centerText, showIcon }) => {
    return (_jsxs(TextContainer, Object.assign({ style: { justifyContent: centerText ? 'center' : 'left' } }, { children: [showIcon && (_jsx(IconWrapper, { children: _jsx(Icon, { icon: 'check-circle', fontSize: 18 }) })), _jsx(GreetingText, Object.assign({ style: { textAlign: centerText ? 'center' : 'left' } }, { children: text }))] })));
};
const TextContainer = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[12]};
`;
const IconWrapper = styled.div `
  color: ${(props) => props.theme.color.success[40]};
`;
const GreetingText = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  text-align: left;
  color: ${(props) => props.theme.color.neutral[90]};
`;
TextIcon.displayName = 'TextIcon';
export default TextIcon;
