import PropTypes from 'prop-types';

import { styled } from '../../../styling';
import BackgroundImage from '../../../primitives/BackgroundImage';
import { Icon, icons } from '../../../primitives/Icon';

const Image = styled(BackgroundImage)`
  width: calc(100% + ${({ theme }) => theme.spacing.units(6)});
  margin: ${({ theme }) => theme.spacing.units(-3)};
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.colour.neutral.mid};
`;

const NoImage = styled.div`
  width: calc(100% + ${({ theme }) => theme.spacing.units(6)});
  margin: ${({ theme }) => theme.spacing.units(-3)};
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LargeIcon = styled(Icon)`
  font-size: ${({ theme }) => theme.spacing.units(40)};
  color: ${({ theme }) => theme.colour.neutral.mid};
`;

export default function CoverImage({ source }) {
  return source ? (
    <Image source={source} />
  ) : (
    <NoImage>
      <LargeIcon icon={icons.house} />
    </NoImage>
  );
}

CoverImage.propTypes = {
  source: PropTypes.string,
};

CoverImage.defaultProps = {
  source: undefined,
};
