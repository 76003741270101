import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Title } from "../../atoms";
import { createFieldHelper, FieldType, } from "../EditorFields/fieldHelper";
import { EditorFields } from "../EditorFields/EditorFields";
const gdprFields = createFieldHelper({
    gdprSelections: {
        label: '',
        ariaLabel: 'gdpr-selection',
        field: {
            type: FieldType.MultiSelectBox,
            variant: 'default',
            options: [
                {
                    value: 'communication-consent',
                    label: 'I have received consent from the referred client to receive communications from KareHero.',
                },
                {
                    value: 'data-share-consent',
                    label: 'I have received consent from the referred client that they would like KareHero to share relevant information about their care needs with me.',
                },
            ],
        },
    },
});
/**
 * GdprConsent renders the account settings page.
 */
export const GdprConsent = ({ value, setValue }) => {
    return (_jsxs(Wrapper, { children: [_jsx(Title, Object.assign({ isNoMargin: true }, { children: "GDPR notice and consent" })), _jsx(EditorFields, { fields: gdprFields, value: value, onChange: setValue }), _jsxs(Description, { children: ["For more information on our privacy practices and how we are committed to protecting and respecting your privacy, please review our", ' ', _jsx(StyledInternalLink, Object.assign({ to: `https://www.karehero.com/privacy-policy`, target: '_blank', rel: 'noopener noreferrer' }, { children: 'Privacy Policy' })), "."] }), _jsx(Description, { children: "By submitting you consent to allow KareHero to store and process the personal information provided to deliver the requested service." })] }));
};
// styles
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const StyledInternalLink = styled(Link) `
  color: ${(props) => props.theme.color.primary[90]};
  font: ${(props) => props.theme.typography.body.sm.medium};
  text-decoration: underline;
`;
GdprConsent.displayName = 'GdprConsent';
export default GdprConsent;
