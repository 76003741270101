import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { Avatar } from "../../atoms";
/**
 * ProfileSummary shows the user's name, role, and avatar.
 */
export const ProfileSummary = ({ imgSrc, fallback, name, role, size = 'sm', isText = true, mode = 'light', icon, }) => {
    return (_jsxs(StyledProfileSummary, { children: [_jsx(Avatar, { src: imgSrc, fallback: fallback, size: size, icon: icon }), isText && (_jsxs("div", { children: [_jsx(Role, Object.assign({ size: size, mode: mode }, { children: role })), name && (_jsx(Name, Object.assign({ size: size, mode: mode }, { children: name })))] }))] }));
};
const StyledProfileSummary = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
`;
const Name = styled.div `
  font: ${(props) => props.theme.typography.body.sm.medium};
  color: ${(props) => props.mode === 'light'
    ? props.theme.color.neutral[90]
    : props.theme.color.neutral[10]};
  ${(props) => {
    switch (props.size) {
        case 'sm':
            return css `
          font: ${props.theme.typography.body.md.medium};
        `;
        case 'md':
            return css `
          font: ${props.theme.typography.body.lg.medium};
        `;
        case 'lg':
            return css `
          font: ${props.theme.typography.body.xl.medium};
        `;
    }
}}
`;
const Role = styled.div `
  color: ${(props) => props.mode === 'light'
    ? props.theme.color.primary[80]
    : props.theme.color.neutral[10]};
  ${(props) => {
    switch (props.size) {
        case 'sm':
            return css `
          font: ${props.theme.typography.body.sm.medium};
        `;
        case 'md':
            return css `
          font: ${props.theme.typography.body.md.medium};
        `;
        case 'lg':
            return css `
          font: ${props.theme.typography.body.lg.medium};
        `;
    }
}}
`;
ProfileSummary.displayName = 'ProfileSummary';
export default ProfileSummary;
