import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { OnboardingActionType, toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
import { Container, Input, Title } from "../../atoms";
import { FloatingTemplate } from "../../templates";
/**
 * OnboardingCreateMember renders the Register screen for onboarding flows.
 */
export const OnboardingCreateMember = toGenericOnboarding(({ config, currentIndex, handleClick, isNextDisabled, setIsNextDisabled, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if (!config)
        return null;
    return (_jsx(FloatingTemplate, { content: _jsxs(Container, Object.assign({ size: 'sm' }, { children: [_jsxs(Content, { children: [((_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.title) && (_jsx(StyledTitle, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.title })), ((_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.subTitle) && (_jsx(SubTitle, { children: (_d = config[currentIndex]) === null || _d === void 0 ? void 0 : _d.subTitle })), _jsx("form", Object.assign({ onSubmit: (e) => {
                                e.preventDefault();
                                !isNextDisabled && (handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.Next));
                            } }, { children: _jsx(Input, { label: (_e = config[currentIndex]) === null || _e === void 0 ? void 0 : _e.label, value: (_f = config[currentIndex]) === null || _f === void 0 ? void 0 : _f.value, onChange: (_g = config[currentIndex]) === null || _g === void 0 ? void 0 : _g.onChange, type: (_h = config[currentIndex]) === null || _h === void 0 ? void 0 : _h.type, validate: (value) => {
                                    var _a;
                                    const isValid = (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.validate(value);
                                    setIsNextDisabled(!isValid);
                                    return isValid;
                                } }) }))] }), ((_j = config[currentIndex]) === null || _j === void 0 ? void 0 : _j.isDisclaimer) && (_jsxs(Disclaimer, { children: ["By continuing to use this app you agree to our", ' ', _jsx("a", Object.assign({ href: 'https://www.karehero.com/privacy-policy', target: '_blank' }, { children: "privacy policy" }))] }))] })) }));
});
const Disclaimer = styled.div `
  color: ${(props) => props.theme.color.neutral[80]};
  font: ${(props) => props.theme.typography.body.sm.regular};
  padding-top: ${(props) => props.theme.spacing[24]};
  text-align: center;
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
const StyledTitle = styled(Title) `
  text-align: center;
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  padding-bottom: ${(props) => props.theme.spacing[24]};
  text-align: center;
`;
OnboardingCreateMember.displayName = 'OnboardingRegister';
export default toGenericOnboarding(OnboardingCreateMember);
