// store
import { useGetAllServiceQuery } from 'store/api/cms';

// components
import { ServiceOverview as ServiceOverviewPage } from '@karehero/llama';

/**
 * ServiceOverview shows the list of available Services.
 */
const ServiceOverview = () => {
  // hooks
  const { data: services } = useGetAllServiceQuery();

  return (
    <ServiceOverviewPage
      services={services}
      isLoading={!services}
      toBookCall='/book-call'
    />
  );
};

export default ServiceOverview;
